export const NoticeIcon = () => {
  return (
    <svg
      width='37'
      height='40'
      viewBox='0 0 37 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.42435 14.7942L8.42433 14.7942L6.61496 25.7793C6.42719 26.9311 5.79989 27.9652 4.86511 28.6638L8.42435 14.7942ZM8.42435 14.7942L8.42468 14.792C8.78436 12.3807 10.0009 10.1798 11.8515 8.59262C13.702 7.00545 16.0626 6.13832 18.5005 6.15016L18.5019 6.15015C20.9409 6.13926 23.3021 7.00794 25.1525 8.59691C27.0029 10.1859 28.2185 12.3886 28.5764 14.8012L28.5763 14.8012L28.5767 14.8036L30.3853 25.7793C30.5731 26.9312 31.2005 27.9653 32.1354 28.664L33.5772 29.7456L33.6672 29.6256L33.5772 29.7456C33.7968 29.9103 33.959 30.14 34.0409 30.4021C34.1227 30.6641 34.1201 30.9453 34.0332 31.2058C33.9464 31.4662 33.7798 31.6928 33.5571 31.8533C33.3343 32.0138 33.0667 32.1002 32.7922 32.1002H4.20854H4.20849C3.93386 32.1002 3.66617 32.0139 3.44335 31.8533C3.22053 31.6928 3.05389 31.4662 2.96704 31.2057C2.8802 30.9451 2.87755 30.6638 2.95948 30.4017C3.0414 30.1396 3.20375 29.9099 3.4235 29.7452L3.42355 29.7451L4.86489 28.664L8.42435 14.7942ZM3.63513 27.0241L3.63492 27.0243L2.19383 28.1051C1.62984 28.5279 1.21319 29.1175 1.00291 29.7903C0.792633 30.463 0.799385 31.1849 1.02221 31.8536C1.24504 32.5224 1.67264 33.104 2.24444 33.5162C2.81623 33.9284 3.50323 34.1502 4.2081 34.1502H32.7917C32.7917 34.1502 32.7917 34.1502 32.7917 34.1502C33.4967 34.1504 34.1836 33.9287 34.7555 33.5166C35.3274 33.1045 35.7552 32.5228 35.9781 31.854C36.201 31.1853 36.2078 30.4633 35.9975 29.7904C35.7872 29.1175 35.3705 28.5279 34.8064 28.1051L33.3649 27.0243L33.3647 27.0241C32.8535 26.6421 32.5105 26.0766 32.408 25.4466L32.4079 25.4464L30.6013 14.4814C30.6012 14.4811 30.6012 14.4808 30.6012 14.4805C30.1564 11.5911 28.6924 8.95618 26.4739 7.05221C24.2552 5.14804 21.4284 4.10067 18.5046 4.09951C15.5809 4.09836 12.7532 5.14349 10.533 7.0459C8.31304 8.94815 6.84697 11.582 6.3999 14.4711C6.39987 14.4713 6.39983 14.4716 6.39979 14.4718L4.59192 25.4464L4.59187 25.4466C4.48935 26.0766 4.14635 26.6421 3.63513 27.0241Z'
        fill='white'
        stroke='white'
        strokeWidth='0.3'
      />
      <path
        d='M20.4002 6.16478C20.5924 6.35701 20.8532 6.465 21.125 6.465C21.3968 6.465 21.6576 6.35701 21.8498 6.16478C22.042 5.97256 22.15 5.71185 22.15 5.44V4.25C22.15 3.28196 21.7654 2.35357 21.0809 1.66906C20.3964 0.984553 19.468 0.6 18.5 0.6C17.532 0.6 16.6036 0.984553 15.9191 1.66906C15.2346 2.35357 14.85 3.28196 14.85 4.25V5.44C14.85 5.71185 14.958 5.97256 15.1502 6.16478C15.3424 6.35701 15.6032 6.465 15.875 6.465C16.1468 6.465 16.4076 6.35701 16.5998 6.16478C16.792 5.97256 16.9 5.71185 16.9 5.44V4.25C16.9 3.82565 17.0686 3.41869 17.3686 3.11863C17.6687 2.81857 18.0757 2.65 18.5 2.65C18.9243 2.65 19.3313 2.81857 19.6314 3.11863C19.9314 3.41869 20.1 3.82565 20.1 4.25V5.44C20.1 5.71185 20.208 5.97256 20.4002 6.16478Z'
        fill='white'
        stroke='white'
        strokeWidth='0.3'
      />
      <path
        d='M12.225 33.125L12.225 33.1252C12.2269 34.7888 12.8885 36.3837 14.0649 37.5601C15.2413 38.7365 16.8362 39.3981 18.4998 39.4H18.5002C20.1638 39.3981 21.7587 38.7365 22.9351 37.5601C24.1115 36.3837 24.7731 34.7888 24.775 33.1252V33.125C24.775 32.8532 24.667 32.5924 24.4748 32.4002C24.2826 32.208 24.0218 32.1 23.75 32.1C23.4782 32.1 23.2174 32.208 23.0252 32.4002C22.833 32.5924 22.725 32.8532 22.725 33.125C22.725 34.2455 22.2799 35.3202 21.4875 36.1125C20.6952 36.9049 19.6205 37.35 18.5 37.35C17.3795 37.35 16.3048 36.9049 15.5125 36.1125C14.7201 35.3202 14.275 34.2455 14.275 33.125C14.275 32.8532 14.167 32.5924 13.9748 32.4002C13.7826 32.208 13.5218 32.1 13.25 32.1C12.9782 32.1 12.7174 32.208 12.5252 32.4002C12.333 32.5924 12.225 32.8532 12.225 33.125Z'
        fill='white'
        stroke='white'
        strokeWidth='0.3'
      />
    </svg>
  );
};
