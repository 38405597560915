export const BuildingIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.40313 22.15H21.7971C21.8907 22.15 21.9805 22.1129 22.0467 22.0466C22.1129 21.9804 22.1501 21.8906 22.1501 21.797V19.8606C22.1501 19.767 22.1129 19.6772 22.0467 19.611C21.9805 19.5448 21.8907 19.5076 21.7971 19.5076H2.40313C2.3095 19.5076 2.2197 19.5448 2.1535 19.611C2.08729 19.6772 2.0501 19.767 2.0501 19.8606V21.797C2.0501 21.8906 2.08729 21.9804 2.1535 22.0466C2.2197 22.1129 2.3095 22.15 2.40313 22.15ZM21.444 20.2137V21.444H2.75616V20.2137H21.444Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
      <path
        d='M14.389 20.1104C14.4552 20.1766 14.545 20.2138 14.6386 20.2138C14.685 20.2139 14.731 20.2049 14.7739 20.1872C14.8168 20.1695 14.8559 20.1435 14.8888 20.1107C14.9217 20.078 14.9478 20.039 14.9656 19.9961C14.9834 19.9532 14.9926 19.9072 14.9926 19.8607V2.40313C14.9926 2.3095 14.9554 2.2197 14.8892 2.1535C14.823 2.08729 14.7332 2.0501 14.6395 2.0501H3.61529C3.52166 2.0501 3.43186 2.08729 3.36566 2.1535C3.29945 2.2197 3.26226 2.3095 3.26226 2.40313V19.8607C3.26226 19.9544 3.29945 20.0442 3.36566 20.1104C3.43186 20.1766 3.52166 20.2138 3.61529 20.2138C3.70892 20.2138 3.79871 20.1766 3.86492 20.1104C3.93112 20.0442 3.96832 19.9544 3.96832 19.8607V2.75616H14.2856V19.8607C14.2856 19.9544 14.3228 20.0442 14.389 20.1104Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
      <path
        d='M20.585 20.1634C20.5047 20.1634 20.4276 20.1314 20.3708 20.0746C20.3139 20.0178 20.282 19.9407 20.282 19.8603V8.66699L14.5296 6.44153C14.4916 6.4278 14.4568 6.40664 14.4272 6.37928C14.3975 6.35192 14.3736 6.3189 14.3569 6.28218C14.3401 6.24546 14.3309 6.20576 14.3297 6.16542C14.3285 6.12509 14.3354 6.08492 14.35 6.04728C14.3645 6.00964 14.3865 5.97528 14.4145 5.94623C14.4425 5.91718 14.476 5.89401 14.5131 5.87809C14.5502 5.86217 14.5901 5.85382 14.6304 5.85352C14.6708 5.85323 14.7108 5.861 14.7481 5.87637L20.695 8.17668C20.752 8.19874 20.801 8.23752 20.8356 8.28792C20.8701 8.33832 20.8886 8.39799 20.8887 8.45911V19.8603C20.8887 19.9002 20.8808 19.9396 20.8656 19.9764C20.8503 20.0133 20.8279 20.0467 20.7997 20.0748C20.7715 20.103 20.738 20.1253 20.7012 20.1405C20.6644 20.1557 20.6249 20.1635 20.585 20.1634Z'
        fill='#4B4B4B'
      />
      <path
        d='M5.48369 6.89034H8.0346C8.12823 6.89034 8.21803 6.85314 8.28423 6.78694C8.35044 6.72073 8.38763 6.63094 8.38763 6.53731V4.01397C8.38763 3.92034 8.35044 3.83054 8.28423 3.76434C8.21803 3.69813 8.12823 3.66094 8.0346 3.66094H5.48369C5.39006 3.66094 5.30027 3.69813 5.23406 3.76434C5.16786 3.83054 5.13066 3.92034 5.13066 4.01397V6.53731C5.13066 6.63094 5.16786 6.72073 5.23406 6.78694C5.30027 6.85314 5.39006 6.89034 5.48369 6.89034ZM7.68157 4.367V6.18428H5.83672V4.367H7.68157Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
      <path
        d='M10.2186 6.89034H12.7695C12.8631 6.89034 12.9529 6.85314 13.0191 6.78694C13.0853 6.72073 13.1225 6.63094 13.1225 6.53731V4.01397C13.1225 3.92034 13.0853 3.83054 13.0191 3.76434C12.9529 3.69813 12.8631 3.66094 12.7695 3.66094H10.2186C10.1249 3.66094 10.0351 3.69813 9.96893 3.76434C9.90272 3.83054 9.86553 3.92034 9.86553 4.01397V6.53731C9.86553 6.63094 9.90272 6.72073 9.96893 6.78694C10.0351 6.85314 10.1249 6.89034 10.2186 6.89034ZM12.4164 4.367V6.18428H10.5716V4.367H12.4164Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
      <path
        d='M5.48369 11.2985H8.0346C8.12823 11.2985 8.21803 11.2613 8.28423 11.1951C8.35044 11.1289 8.38763 11.0391 8.38763 10.9455V8.42217C8.38763 8.32854 8.35044 8.23875 8.28423 8.17254C8.21803 8.10633 8.12823 8.06914 8.0346 8.06914H5.48369C5.39006 8.06914 5.30027 8.10633 5.23406 8.17254C5.16786 8.23875 5.13066 8.32854 5.13066 8.42217V10.9455C5.13066 11.0391 5.16786 11.1289 5.23406 11.1951C5.30027 11.2613 5.39006 11.2985 5.48369 11.2985ZM7.68157 8.7752V10.5925H5.83672V8.7752H7.68157Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
      <path
        d='M10.2186 11.2985H12.7695C12.8631 11.2985 12.9529 11.2613 13.0191 11.1951C13.0853 11.1289 13.1225 11.0391 13.1225 10.9455V8.42217C13.1225 8.32854 13.0853 8.23875 13.0191 8.17254C12.9529 8.10633 12.8631 8.06914 12.7695 8.06914H10.2186C10.1249 8.06914 10.0351 8.10633 9.96893 8.17254C9.90272 8.23875 9.86553 8.32854 9.86553 8.42217V10.9455C9.86553 11.0391 9.90272 11.1289 9.96893 11.1951C10.0351 11.2613 10.1249 11.2985 10.2186 11.2985ZM12.4164 8.7752V10.5925H10.5716V8.7752H12.4164Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
      <path
        d='M11.3519 20.1102C11.4181 20.1764 11.5079 20.2136 11.6015 20.2136C11.6951 20.2136 11.7849 20.1764 11.8511 20.1102C11.9173 20.044 11.9545 19.9542 11.9545 19.8606V13.1424C11.9545 13.0488 11.9173 12.959 11.8511 12.8928C11.7849 12.8266 11.6951 12.7894 11.6015 12.7894H6.6524C6.55877 12.7894 6.46897 12.8266 6.40277 12.8928C6.33656 12.959 6.29937 13.0488 6.29937 13.1424V19.8606C6.29937 19.9542 6.33656 20.044 6.40277 20.1102C6.46897 20.1764 6.55877 20.2136 6.6524 20.2136C6.74602 20.2136 6.83582 20.1764 6.90203 20.1102C6.96823 20.044 7.00543 19.9542 7.00543 19.8606V13.4954H11.2485V19.8606C11.2485 19.9542 11.2857 20.044 11.3519 20.1102Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
      <path
        d='M16.5958 10.2127H18.6285C18.7221 10.2127 18.8119 10.1755 18.8781 10.1093C18.9443 10.0431 18.9815 9.9533 18.9815 9.85967C18.9815 9.76604 18.9443 9.67625 18.8781 9.61004C18.8119 9.54383 18.7221 9.50664 18.6285 9.50664H16.5958C16.5021 9.50664 16.4123 9.54383 16.3461 9.61004C16.2799 9.67625 16.2427 9.76604 16.2427 9.85967C16.2427 9.9533 16.2799 10.0431 16.3461 10.1093C16.4123 10.1755 16.5021 10.2127 16.5958 10.2127Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
      <path
        d='M16.5958 12.8602H18.6285C18.7221 12.8602 18.8119 12.823 18.8781 12.7568C18.9443 12.6906 18.9815 12.6008 18.9815 12.5071C18.9815 12.4135 18.9443 12.3237 18.8781 12.2575C18.8119 12.1913 18.7221 12.1541 18.6285 12.1541H16.5958C16.5021 12.1541 16.4123 12.1913 16.3461 12.2575C16.2799 12.3237 16.2427 12.4135 16.2427 12.5071C16.2427 12.6008 16.2799 12.6906 16.3461 12.7568C16.4123 12.823 16.5021 12.8602 16.5958 12.8602Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
      <path
        d='M16.5958 15.5071H18.6285C18.7221 15.5071 18.8119 15.4699 18.8781 15.4037C18.9443 15.3375 18.9815 15.2477 18.9815 15.1541C18.9815 15.0605 18.9443 14.9707 18.8781 14.9045C18.8119 14.8383 18.7221 14.8011 18.6285 14.8011H16.5958C16.5021 14.8011 16.4123 14.8383 16.3461 14.9045C16.2799 14.9707 16.2427 15.0605 16.2427 15.1541C16.2427 15.2477 16.2799 15.3375 16.3461 15.4037C16.4123 15.4699 16.5021 15.5071 16.5958 15.5071Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
      <path
        d='M16.5958 18.1546H18.6285C18.7221 18.1546 18.8119 18.1174 18.8781 18.0512C18.9443 17.985 18.9815 17.8952 18.9815 17.8016C18.9815 17.7079 18.9443 17.6181 18.8781 17.5519C18.8119 17.4857 18.7221 17.4485 18.6285 17.4485H16.5958C16.5021 17.4485 16.4123 17.4857 16.3461 17.5519C16.2799 17.6181 16.2427 17.7079 16.2427 17.8016C16.2427 17.8952 16.2799 17.985 16.3461 18.0512C16.4123 18.1174 16.5021 18.1546 16.5958 18.1546Z'
        fill='#4B4B4B'
        stroke='#4B4B4B'
        strokeWidth='0.1'
      />
    </svg>
  );
};
