import { AxiosStatic } from "axios";
import { SearchResponse, AggregationsAggregate } from "@elastic/elasticsearch/lib/api/types";

class OpenSearchClient {
  private endpoint: string;
  private axios: AxiosStatic;

  constructor(axios: AxiosStatic) {
    this.endpoint = process.env.SLEEPWELL_OPENSEARCH_API_GATEWAY_HOST || "https://b8c5mofatl.execute-api.ap-northeast-1.amazonaws.com/v1";
    this.axios = axios;
  }

  public async search<T>(indexName: string, query: object): Promise<SearchResponse<T, Record<string, AggregationsAggregate>>> {
    try {
      const token = this.getIdToken();
      const response = await this.axios.post(`${this.endpoint}/${indexName}/_search`, query, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error during OpenSearch query:', error);
      throw error;
    }
  }

  getIdToken = () => {
    const idToken = localStorage.getItem('idToken');
    if (!idToken) {
      throw new Error("Authentication token is not available in local storage.");
    }
    return idToken;
  }
}
export default OpenSearchClient;
