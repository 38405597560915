export enum AnalyzationStatus {
  WAITING_FOR_ANALYZE = 'waitingForAnalyze',
  WAITING_FOR_RE_CALCULATING = 'waitingForReCalculating',
  STARTING = 'starting',
  ANALYZING = 'analyzing',
  WAITING_FOR_CALCULATING = 'waitingForCalculating',
  CALCULATING = 'calculating',
  WAITING_FOR_CONFIRM = 'waitingForConfirm',
  WAITING_FOR_CONFIRM_REPORT = 'waitingForConfirmReport',
  REQUEST_FOR_RE_MEASURE = 'requestForReMeasure',
  FAILED = 'failed',
  CONFIRMED = 'confirmed',
}

export enum AnalyzationType {
  ANALYSIS = 'analysis',
  RE_ANALYSIS = 'reAnalysis',
  RE_CALCULATE = 'reCalculate',
}

export enum DashboardCategory {
  TWO_WEEKS_DIFFERENCE = 'twoWeeksDifference',
  ONE_WEEK_AND_TWO_WEEKS_DIFFERENCE = 'oneWeekAnd2WeeksDifference',
  LESS_THAN_ONE_WEEK_DIFFERENCE = 'lessThanOneWeekDifference',
}

export const getAnalyzationStatusLabel = (status: AnalyzationStatus): string => {
  switch (status) {
    case AnalyzationStatus.WAITING_FOR_ANALYZE:
      return "解析待ち";
    case AnalyzationStatus.WAITING_FOR_RE_CALCULATING:
      return "再計算待ち";
    case AnalyzationStatus.STARTING:
      return "起動中";
    case AnalyzationStatus.ANALYZING:
      return "EDF解析中";
    case AnalyzationStatus.WAITING_FOR_CALCULATING:
        return "データ解析完了";
    case AnalyzationStatus.CALCULATING:
          return "データ計算中";
    case AnalyzationStatus.WAITING_FOR_CONFIRM:
      return "確認待ち";
    case AnalyzationStatus.WAITING_FOR_CONFIRM_REPORT:
        return "確認待ち";
    case AnalyzationStatus.REQUEST_FOR_RE_MEASURE:
      return "再計測依頼";
    case AnalyzationStatus.FAILED:
        return "処理不可能";
    case AnalyzationStatus.CONFIRMED:
        return "レポート作成済み";
    default:
      return "不明";
  }
}


export const isAnalyzationProcessDone = (status: AnalyzationStatus): boolean => {
  if([AnalyzationStatus.WAITING_FOR_CONFIRM, AnalyzationStatus.WAITING_FOR_CONFIRM_REPORT, AnalyzationStatus.CONFIRMED, AnalyzationStatus.REQUEST_FOR_RE_MEASURE].includes(status)){
    return true;
  }
  return false;
}

// EDFファイルの変換
export const yearArray = [
  {value: "2001", label: "1"},
  {value: "2002", label: "2"},
  {value: "2003", label: "3"},
  {value: "2004", label: "4"},
  {value: "2005", label: "5"},
  {value: "2006", label: "6"},
  {value: "2007", label: "7"},
  {value: "2008", label: "8"},
  {value: "2009", label: "9"},
  {value: "2010", label: "A"},
  {value: "2011", label: "B"},
  {value: "2012", label: "C"},
  {value: "2013", label: "D"},
  {value: "2014", label: "E"},
  {value: "2015", label: "F"},
  {value: "2016", label: "G"},
  {value: "2017", label: "H"},
  {value: "2018", label: "I"},
  {value: "2019", label: "J"},
  {value: "2020", label: "K"},
  {value: "2021", label: "L"},
  {value: "2022", label: "M"},
  {value: "2023", label: "N"},
  {value: "2024", label: "O"},
  {value: "2025", label: "P"},
  {value: "2026", label: "Q"},
  {value: "2027", label: "R"},
  {value: "2028", label: "S"},
  {value: "2029", label: "T"},
  {value: "2030", label: "U"},
]

const monthArray = [
  {value: "1", label: "1"},
  {value: "2", label: "2"},
  {value: "3", label: "3"},
  {value: "4", label: "4"},
  {value: "5", label: "5"},
  {value: "6", label: "6"},
  {value: "7", label: "7"},
  {value: "8", label: "8"},
  {value: "9", label: "9"},
  {value: "10", label: "A"},
  {value: "11", label: "B"},
  {value: "12", label: "C"},
]

const dayArray = [
  {value: "1", label: "1"},
  {value: "2", label: "2"},
  {value: "3", label: "3"},
  {value: "4", label: "4"},
  {value: "5", label: "5"},
  {value: "6", label: "6"},
  {value: "7", label: "7"},
  {value: "8", label: "8"},
  {value: "9", label: "9"},
  {value: "10", label: "A"},
  {value: "11", label: "B"},
  {value: "12", label: "C"},
  {value: "13", label: "D"},
  {value: "14", label: "E"},
  {value: "15", label: "F"},
  {value: "16", label: "G"},
  {value: "17", label: "H"},
  {value: "18", label: "I"},
  {value: "19", label: "J"},
  {value: "20", label: "K"},
  {value: "21", label: "L"},
  {value: "22", label: "M"},
  {value: "23", label: "N"},
  {value: "24", label: "O"},
  {value: "25", label: "P"},
  {value: "26", label: "Q"},
  {value: "27", label: "R"},
  {value: "28", label: "S"},
  {value: "29", label: "T"},
  {value: "30", label: "U"},
  {value: "31", label: "V"},
]

const hourArray = [
  {value: "0", label: "0"},
  {value: "1", label: "1"},
  {value: "2", label: "2"},
  {value: "3", label: "3"},
  {value: "4", label: "4"},
  {value: "5", label: "5"},
  {value: "6", label: "6"},
  {value: "7", label: "7"},
  {value: "8", label: "8"},
  {value: "9", label: "9"},
  {value: "10", label: "A"},
  {value: "11", label: "B"},
  {value: "12", label: "C"},
  {value: "13", label: "D"},
  {value: "14", label: "E"},
  {value: "15", label: "F"},
  {value: "16", label: "G"},
  {value: "17", label: "H"},
  {value: "18", label: "I"},
  {value: "19", label: "J"},
  {value: "20", label: "K"},
  {value: "21", label: "L"},
  {value: "22", label: "M"},
  {value: "23", label: "N"}
]
const minuteArray = [
  {value: "0", label: "0"},
  {value: "1", label: "0"},
  {value: "2", label: "1"},
  {value: "3", label: "1"},
  {value: "4", label: "2"},
  {value: "5", label: "2"},
  {value: "6", label: "3"},
  {value: "7", label: "3"},
  {value: "8", label: "4"},
  {value: "9", label: "4"},
  {value: "10", label: "5"},
  {value: "11", label: "5"},
  {value: "12", label: "6"},
  {value: "13", label: "6"},
  {value: "14", label: "7"},
  {value: "15", label: "7"},
  {value: "16", label: "8"},
  {value: "17", label: "8"},
  {value: "18", label: "9"},
  {value: "19", label: "9"},
  {value: "20", label: "A"},
  {value: "21", label: "A"},
  {value: "22", label: "B"},
  {value: "23", label: "B"},
  {value: "24", label: "C"},
  {value: "25", label: "C"},
  {value: "26", label: "D"},
  {value: "27", label: "D"},
  {value: "28", label: "E"},
  {value: "29", label: "E"},
  {value: "30", label: "F"},
  {value: "31", label: "F"},
  {value: "32", label: "G"},
  {value: "33", label: "G"},
  {value: "34", label: "H"},
  {value: "35", label: "H"},
  {value: "36", label: "I"},
  {value: "37", label: "I"},
  {value: "38", label: "J"},
  {value: "39", label: "J"},
  {value: "40", label: "K"},
  {value: "41", label: "K"},
  {value: "42", label: "L"},
  {value: "43", label: "L"},
  {value: "44", label: "M"},
  {value: "45", label: "M"},
  {value: "46", label: "N"},
  {value: "47", label: "N"},
  {value: "48", label: "O"},
  {value: "49", label: "O"},
  {value: "50", label: "P"},
  {value: "51", label: "P"},
  {value: "52", label: "Q"},
  {value: "53", label: "Q"},
  {value: "54", label: "R"},
  {value: "55", label: "R"},
  {value: "56", label: "S"},
  {value: "57", label: "S"},
  {value: "58", label: "T"},
  {value: "59", label: "T"},
]