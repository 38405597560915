import React, { useEffect } from 'react';
import { styled } from '@mui/system';
//import { Grid } from '@mui/material';
import { Snackbar, SnackbarCloseReason } from '@mui/material';
import Alert from '@mui/material/Alert';
import { BodyText } from './Text';
import { CompleteIcon } from './icons/CompleteIcon';
import { ErrorIcon } from './icons/ErrorIcon';

type Props = {
  snackState: {
    open: boolean;
    message: string;
    success: boolean;
  };
  setSnackState: (data: {
    open: boolean;
    message: string;
    success: boolean;
  }) => void;
  marginTop?: string | number;
  isNotAutoHide?: boolean;
  isDetailed?: boolean;
  openDetail?: () => void;
};

const CustomizedSnackbar: React.VFC<Props> = (props) => {
  const { snackState, setSnackState } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClose = (
    event: React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackState({
      open: false,
      message: snackState.message,
      success: snackState.success,
    });
  };
  const handleCloseAlert = (event: React.SyntheticEvent<any, Event>) => {
    setSnackState({
      open: false,
      message: snackState.message,
      success: snackState.success,
    });
  };
  return (
    <StyledSnackbar
      open={props.snackState.open}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      autoHideDuration={5000}
      onClose={()=> props.setSnackState({open: false, message: '', success: false})}
      key={`bototm_center`}
      style={{}}
    >
      {snackState.success ? (
        <Alert
          style={{
            width: '100%',
            background: '#F3FBFF',
            fontWeight: 'bold',
            color: '#529BC5',
            border: '1px solid #529BC5',
          }}
          icon={<CompleteIcon/>}
          severity='success'
          onClose={handleCloseAlert}
        >
          {snackState.message}
        </Alert>
      ) : (
        <Alert
          style={{ width: '100%', fontWeight: 'bold', color: '#F15454', background: '#FFF0F0', border: '1px solid #F15454' }}
          severity='error'
          onClose={handleCloseAlert}
          icon={<ErrorIcon />}
        >
          <FlexWrap>
            {snackState.message}
            {props.isDetailed && props.openDetail ? (
              <StyledBodyText onClick={props.openDetail}>
                詳細を見る &gt;{' '}
              </StyledBodyText>
            ) : (
              <></>
            )}
          </FlexWrap>
        </Alert>
      )}
    </StyledSnackbar>
  );
};

export default CustomizedSnackbar;

const StyledSnackbar = styled(Snackbar)`
  position: fixed;
  width: 700px;
  z-index: 999999;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    width: 80%;
    z-index: 999999;
  }
`;
const FlexWrap = styled('div')`
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyledBodyText = styled(BodyText)`
  cursor: pointer;
`;
