import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SidebarMenuButton } from '../molecules/SidebarMenuButton';
import {
  HospitalIcon,
  UserIcon,
  BuildingIcon,
  SearchIcon,
  ReportIcon,
  SettingsIcon,
  PaperIcon,
} from '../atoms/icons';
import Box from '@mui/material/Box';
import { ConstructionOutlined } from '@mui/icons-material';

const dataMenus = [
  {
    title: '睡眠診断情報一覧',
    path: '/sleepAnalytics',
    icon: <ReportIcon />,
  },
];

const analysisMenus = [
  {
    title: '解析依頼',
    path: '/analyzation-request',
    icon: <SettingsIcon />,
  },
];

const managementMenus = [
  {
    title: '受診者情報',
    path: '/patients',
    icon: <UserIcon />,
  },
  {
    title: '受診者所属',
    path: '/affiliations',
    icon: <BuildingIcon />,
  },
  {
    title: '提携先専門医',
    path: '/medicalPartners',
    icon: <SearchIcon />,
  },
  {
    title: '機器管理',
    path: '/equipments',
    icon: <SettingsIcon />,
  },
  {
    title: 'アカウント管理',
    path: '/clinicUsers',
    icon: <UserIcon />,
  },
];

const settingsMenus = [
  {
    title: '設定',
    path: '/clinic',
    icon: <HospitalIcon />,
  },
  {
    title: 'マニュアルダウンロード',
    path: '/documents',
    icon: <PaperIcon />,
  },
];

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (locationPath: string, path: string) => {
    if(path === "/"){
      return path === location.pathname
    } else {
      return location.pathname.includes(path);
    }
  }
  return (
    <Container>
      <StyledAccordion
        defaultExpanded={dataMenus.some(({ path }) => {
          const p = location.pathname.slice(1);
          if (p === '') {
            return true;
          }
          return location.pathname.includes(path);
        })}
      >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>データ確認</StyledAccordionSummary>
        <StyledAccordionDetails>
          <Box>
            <SidebarMenuButton
              icon={<ReportIcon />}
              isActive={location.pathname.slice(1) === ''}
              onClick={() => navigate('/')}
            >
              解析一覧
            </SidebarMenuButton>
          </Box>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        defaultExpanded={analysisMenus.some(({ path }) => location.pathname.includes(path))}
      >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>解析依頼</StyledAccordionSummary>
        <StyledAccordionDetails>
          <Box>
            {analysisMenus.map(({ title, path, icon }) => {
          
              return (
                <SidebarMenuButton
                  icon={icon}
                  isActive={isActive(location.pathname, path)}
                  onClick={() => navigate(path)}
                >
                  {title}
                </SidebarMenuButton>
              );
            })}
          </Box>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        defaultExpanded={managementMenus.some(({ path }) => location.pathname.includes(path))}
      >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>登録・管理</StyledAccordionSummary>
        <StyledAccordionDetails>
          <Box>
            {managementMenus.map(({ title, path, icon }) => {
              return (
                <SidebarMenuButton
                  icon={icon}
                  isActive={location.pathname.includes(path)}
                  onClick={() => navigate(path)}
                >
                  {title}
                </SidebarMenuButton>
              );
            })}
          </Box>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        defaultExpanded={settingsMenus.some(({ path }) => {
          const p = location.pathname
          return p === path
        })}
      >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          設定・書式ダウンロード
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Box>
            {settingsMenus.map(({ title, path, icon }) => {
              const p = location.pathname
              return (
                <SidebarMenuButton
                  icon={icon}
                  isActive={p === path}
                  onClick={() => navigate(path)}
                >
                  {title}
                </SidebarMenuButton>
              );
            })}
          </Box>
        </StyledAccordionDetails>
      </StyledAccordion>
    </Container>
  );
};

const StyledAccordion = styled(Accordion)({
  border: 'none',
  boxShadow: 'none',
  backgroundColor: '#f2f2f2',
  '::before': {
    height: 0,
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  border: 'none',
  fontSize: '15px',
  paddingLeft: '10px',
  paddingRight: '10px',
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
});

const Container = styled('div')({
  backgroundColor: '#f2f2f2',
  width: '212px',
  height: '100%',
  paddingTop: '20px',
  boxSizing: 'border-box',
});
