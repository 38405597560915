import { styled } from '@mui/system';
import { DateRange, SearchDateRangePicker } from "../../molecules/DateRangePicker";
import { Box, OutlinedInput, Select } from '@mui/material';
import { CheckBox } from '../../atoms/CheckBox';
import { Radio } from '../../molecules/Radio';
import { GrayText } from '../../atoms/Text';
import { prefectureOnlyList } from '@sleepwell_new_platform/common-functions/lib/admins';
import { SelectInput } from '../../molecules/Select';
import { SearchTextInput } from '../../atoms/CustomTextField';
import { useEffect, useState } from 'react';
import { SleepwellApi } from "@sleepwell_new_platform/common-functions/lib/api";
import axios from "axios";
const Api = new SleepwellApi(axios);

type Props = {
  dateRange: { startDate: any, endDate: any };
  firstAnalyzationDateRange: { startDate: any, endDate: any };
  setDateRange: any;
  setFirstAnalyzationDateRange: any;
  edfFileName: string;
  setEdfFileName: any;
  equipmentId: string;
  setEquipmentId: any;
  lowerAge: string | number | null;
  setLowerAge: any;
  upperAge: string | number | null;
  setUpperAge: any;
  prefecture: string;
  gender: string;
  setGender: any;
  setPrefecture: any;
  medicineInfo: string;
  setMedicineInfo: any;
  sickHistory: string;
  setSickHistory: any;
  patientNumber: string | number | null;
  setPatientNumber: any;
  diseaseId: string;
  setdiseaseId: any;
  waitingForAnalyze: boolean;
  setWaitingForAnalyze: any;
  waitingForReCalculating: boolean;
  setWaitingForReCalculating: any;
  starting: boolean;
  setStarting: any;
  analyzing: boolean;
  setAnalyzing: any;
  calculating: boolean;
  setCalculating: any;
  confirmed: boolean;
  setConfirmed: any;
  waitingForConfirm: boolean;
  setWaitingForConfirm: any;
  waitingForConfirmReport: boolean;
  setWaitingForConfirmReport: any;
  requestForReMeasure: boolean;
  setRequestForReMeasure: any;
  failed: boolean;
  setFailed: any;
  allFinished: boolean;
  setAllFinished: any;
  allAnalyzing: boolean;
  setAllAnalyzing: any;
};

export const SearchArea = (props: Props) => {
  const {
    dateRange,
    setDateRange,
    firstAnalyzationDateRange,
    setFirstAnalyzationDateRange,
    edfFileName,
    setEdfFileName,
    equipmentId,
    setEquipmentId,
    lowerAge,
    setLowerAge,
    upperAge,
    setUpperAge,
    prefecture,
    setPrefecture,
    gender,
    setGender,
    medicineInfo,
    setMedicineInfo,
    sickHistory,
    setSickHistory,
    patientNumber,
    setPatientNumber,
    diseaseId,
    setdiseaseId,
    waitingForAnalyze,
    setWaitingForAnalyze,
    waitingForReCalculating,
    setWaitingForReCalculating,
    starting,
    setStarting,
    analyzing,
    setAnalyzing,
    calculating,
    setCalculating,
    confirmed,
    setConfirmed,
    waitingForConfirm,
    setWaitingForConfirm,
    waitingForConfirmReport,
    setWaitingForConfirmReport,
    requestForReMeasure,
    setRequestForReMeasure,
    failed,
    setFailed,
    allFinished,
    setAllFinished,
    allAnalyzing,
    setAllAnalyzing
  } = props;
  const [diseaseIdList, setdiseaseIdList] = useState<Array<any>>([]);
  const onChangeDateRange = async (dateRange: DateRange) => {
    setDateRange(dateRange);
  };
  const onChangeFirstAnalyzationDateRange = async (dateRange: DateRange) => {
    setFirstAnalyzationDateRange(dateRange);
  };

  const getdiseaseIds = async () => {
    const diseases = await Api.getDiseases();
    const diseaseList = diseases?.data?.result?.Items
    const list: any = [{
      value: '',
      label: '-'
    }];
    for (let i = 0; i < diseaseList.length; i++) {
      list.push({
        value: diseaseList[i].id,
        label: diseaseList[i].name
      });
    }
    setdiseaseIdList(list);
  };
  useEffect(() => {
    getdiseaseIds();
    // eslint-disable-next-line
  }, []);
  return (
    <Wrap>
      <Box
        display={'flex'}
        gap={'24px'}
      >
        <SearchDateRangePicker
          title={'測定開始日'}
          value={dateRange}
          onChange={onChangeDateRange}
        />
        <SearchDateRangePicker
          title={'登録日（初回解析日）'}
          value={firstAnalyzationDateRange}
          onChange={onChangeFirstAnalyzationDateRange}
        />
      </Box>
      <Box
        display={'flex'}
        gap={'20px'}
        marginTop={'15px'}
        marginBottom={'0px'}
        textAlign={'center'}
      >
        <CheckBox
          label={'一括選択(解析待ち)'}
          checked={allAnalyzing}
          onChange={() => {
            if (allAnalyzing) {
              setAllAnalyzing(false);
              setWaitingForAnalyze(false);
              setWaitingForReCalculating(false);
              setStarting(false);
              setAnalyzing(false);
              setCalculating(false);
            } else {
              setAllAnalyzing(true);
              setWaitingForAnalyze(true);
              setWaitingForReCalculating(true);
              setStarting(true);
              setAnalyzing(true);
              setCalculating(true);
            }
          }} />
        <CheckBox
          label={'解析待ち'}
          checked={waitingForAnalyze}
          onChange={(value) => setWaitingForAnalyze(value)}
        />
        <CheckBox
          label={'再計算待ち'}
          checked={waitingForReCalculating}
          onChange={(value) => setWaitingForReCalculating(value)}
        />
        <CheckBox
          label={'起動中'}
          checked={starting}
          onChange={(value) => setStarting(value)}
        />
        <CheckBox
          label={'EDF解析中'}
          checked={analyzing}
          onChange={(value) => setAnalyzing(value)}
        />
        <CheckBox
          label={'データ計算中'}
          checked={calculating}
          onChange={(value) => setCalculating(value)}
        />
      </Box>
      <Box
        display={'flex'}
        gap={'20px'}
        marginBottom={'10px'}
      >
        <CheckBox
          label={'一括選択(解析終了)'}
          checked={allFinished}
          onChange={() => {
            if (allFinished) {
              setAllFinished(false);
              setConfirmed(false);
              setWaitingForConfirmReport(false);
              setWaitingForConfirm(false);
              setRequestForReMeasure(false);
              setFailed(false);

            } else {
              setAllFinished(true);
              setConfirmed(true);
              setWaitingForConfirmReport(true);
              setWaitingForConfirm(true);
              setRequestForReMeasure(true);
              setFailed(true);
            }
          }} />
        <CheckBox
          label={'レポート作成済み'}
          checked={confirmed}
          onChange={(value) => setConfirmed(value)}
        />
        <CheckBox
          label={'確認待ち'}
          checked={waitingForConfirm}
          onChange={(value) => {
            setWaitingForConfirm(value);
            setWaitingForConfirmReport(value);
          }}
        />
        <CheckBox
          label={'再計測依頼'}
          checked={requestForReMeasure}
          onChange={(value) => setRequestForReMeasure(value)}
        />
        <CheckBox
          label={'処理不可能'}
          checked={failed}
          onChange={(value) => setFailed(value)}
        />
      </Box>
      <Box
        display={'flex'}
        gap={'20px'}
        marginBottom={'0px'}
        alignItems={'flex-end'}
      >
        <SearchTextInput
          name='EDFファイル名'
          value={edfFileName}
          setFunction={setEdfFileName}
          placeholder={'EDFファイル名'}
          width={250}
        />
        <SearchTextInput
          name='機器ID'
          value={equipmentId}
          setFunction={setEquipmentId}
          placeholder={'機器ID'}
          width={200}
        />
        <div style={{ marginTop: 0 }}>

          <SelectInput
            title={'疾病中の病気'} //都道府県
            items={diseaseIdList}
            //required={true}
            value={diseaseId}
            width={'500px'}
            onChange={(e: any) => {
              if (e.target.value === '') {
                setdiseaseId('');
              } else {
                setdiseaseId(e.target.value)
              }
            }}
          />
        </div>
      </Box>
      <Box
        display={'flex'}
        gap={'20px'}
        marginBottom={'20px'}
        alignItems={'flex-end'}
      >
        <SearchTextInput
          name='受診者番号'
          value={patientNumber}
          setFunction={setPatientNumber}
          placeholder={'受診者番号'}
          width={250}
        />
        <SelectInput
          width={'200px'}
          title={"都道府県"}
          items={prefectureOnlyList}
          value={prefecture}
          onChange={(e: any) => {
            setPrefecture(e.target.value);
          }}
          placeholder={"選択してください"}
        />
        <Box
          marginLeft={'24px'}
        />
        <div>
          <GrayText style={{ color: '#353535', fontSize: 17 }}>{'性別'}</GrayText>
          <Radio
            row
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            options={[
              { value: '男性', label: '男性' },
              { value: '女性', label: '女性' },
            ]}
          />
        </div>
        <Container>
          <Box
            marginLeft={'24px'}
            //margin={'0 0 0 20'}
            display={'flex'}
            gap={'20px'}
            alignItems={'center'}
          //width={100}
          >{'年齢'}
          </Box>
          <Box
            display={'flex'}
            gap={'20px'}
            marginLeft={'24px'}
            alignItems={'center'}
          >
            <SearchTextInput
              name=''
              value={lowerAge}
              setFunction={setLowerAge}
              placeholder={'年齢を入力'}
              width={150}
            />
            <Box mr={2} ml={2}>〜</Box>
            <SearchTextInput
              name=' '
              value={upperAge}
              setFunction={setUpperAge}
              placeholder={'年齢を入力'}
              width={150}
            />
          </Box>
        </Container>
      </Box>
      <Box
        display={'flex'}
        gap={'20px'}
        marginBottom={'0px'}
        alignItems={'flex-end'}
      >
        <SearchTextInput
          name='投薬'
          value={medicineInfo}
          setFunction={setMedicineInfo}
          placeholder={'投薬'}
          width={250}
        />
        <SearchTextInput
          name='病歴'
          value={sickHistory}
          setFunction={setSickHistory}
          placeholder={'病歴'}
          width={250}
        />
      </Box>
      <Box
        display={'flex'}
        gap={'20px'}
        marginTop={'0px'}
      //marginBottom={'24px'}
      >
      </Box>
    </Wrap>
  )
}
const Wrap = styled('div')(() => ({
  marginBottom: 16,
  width: '80%',
  background: "#F2F2F2",
  alignItems: 'flex-start',
  padding: '20px',
  maxWidth: '1180px',
  height: 'auto'
}));

const FilterTitle = styled("div")({
  color: "#353535",
  marginBottom: 4
})

const StyledOutlinedInput = styled(OutlinedInput)`
  width: 100px;
  padding-right: 32px;
  background-color: #fff;
  .MuiInputBase-input {
    padding: 10px 12px
  }
`
const AgeText = styled("div")`
  color: #353535;
`

const Container = styled("div")`
  display: block;
`

const AgeRange = styled("span")`
  margin: 0 8px;
  color: #353535;
`

const StyledSelect = styled(Select)`
  width: 300px;
  background-color: #fff;
  .MuiSelect-select {
    padding: 10px 12px
  }
`
