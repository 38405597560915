import React from 'react';
import { Grid } from '@mui/material';

export const SimpleGrid = (props: any) => {
  return (
        <Grid item xs={props.size ? props.size : 12}>
            {props.children}
        </Grid>
  );
}
