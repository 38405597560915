import { styled } from '@mui/system';
import { Bold, GenderText, ListContainerText, NameContainerText } from '../atoms/Text';
import { Spacer } from '../atoms/Spacer';
import {
  getAge,
  getDateOfBirthday,
  getMonthOfBirthday,
  getYearOfBirthday,
} from '../../utils/Moment';
import { useNavigate } from 'react-router-dom';
import {
  convertGenderTypeToIcon,
  convertGenderTypeToName,
} from '../../utils/Types';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

type PersonalInfo1 = {
  受診者番号?: string;
  年齢?: string;
  居住都道府県?: string;
  ID?: string;
  Age?: string;
  Address?: string;
};

type PersonalInfo2 = {
  病歴?: string;
  投薬情報?: string;
  備考?: string;
  History?: string;
  MedicalInfo?: string;
  Memo?: string;
};

type ListProps = {
  personalInfo1: PersonalInfo1;
  personalInfo2: PersonalInfo2;
  patientId: string;
  gender: string;
  patientName: string;
  birthdayMillis: number;
  width? : string | number;
  lang: 'ja' | 'en';
};

export const ListKeyValuePersonalInfo = ({
  patientId,
  personalInfo1,
  personalInfo2,
  gender,
  patientName,
  birthdayMillis,
  width,
  lang
}: ListProps) => {
  const PersonalInfoList = ({ info }: { info: { [key: string]: string } }) => {
    return (
      <>
        {Object.entries(info).map(([key, value]) => (
          <ListKeyValueContainer>
            <KeyValueContainer>
              <KeyContainer>
                <ListContainerText small>
                  <Typography style={{fontSize: 14, color: "#898989"}}>{key}: </Typography>
                </ListContainerText>
              </KeyContainer>
              <ValueContainer>
                <ListContainerText small>
                <Typography style={{fontSize: 14, color: "#4B4B4B"}}>
                  {value}
                  </Typography>
                  </ListContainerText>
              </ValueContainer>
            </KeyValueContainer>
          </ListKeyValueContainer>
        ))}
      </>
    );
  };
  return (
    <Container
     width={width}
    >
      <FlexDirectionRow>
        <GenderContainer>
          <CircleContainer>{convertGenderTypeToIcon(gender)}</CircleContainer>
          <GenderText>{convertGenderTypeToName(gender, lang)}</GenderText>
        </GenderContainer>
        <NameContainer>
          <NameContainerText>
          <Typography style={{fontSize: 15, color: "#353535"}}>{patientName}</Typography>
          </NameContainerText>
          <Spacer height={3} />
          <ListContainerText small>
          <Typography style={{fontSize: 15, color: "#898989"}}>
            {lang === "ja" ? '生年月日 ' : 'Birthday '}
            {birthdayMillis
              ? dayjs(birthdayMillis).format('YYYY年MM月DD日')
              : '-/-/-'}
              </Typography>
          </ListContainerText>
        </NameContainer>
        <ListContainer>
          <PersonalInfoList info={personalInfo1} />
        </ListContainer>
        <ListContainer>
          <PersonalInfoList info={personalInfo2} />
        </ListContainer>
        {/*<StyledEditButton
          onClick={() => navigate(`/patients/${patientId}/edit`)}
        >
          {'情報を編集'}
            </StyledEditButton>*/}
      </FlexDirectionRow>
    </Container>
  );
};

const Container = styled('div')<{width?: number | string}>`
  width: ${(props) => props.width || '100%'};
  background: #fafafa;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 1px;
`;
const ListKeyValueContainer = styled('div')`
  display: flex;
  flex-flow: column;
  justify-align: center;
  justify-content: left;
`;
const KeyValueContainer = styled('div')`
  display: flex;
  flex-direction: raw;
`;
const KeyContainer = styled('div')`
  width: 110px;
  heith 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ValueContainer = styled('div')`
  width: 150px;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const FlexDirectionRow = styled('div')`
  display: flex;
`;
const GenderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 114px;
  background: #e5e5e5;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 1px;
`;
const CircleContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background: #fff;
`;
const NameContainer = styled('div')`
  flex-flow: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  justify-align: center;
  justify-content: left;
  width: 230px;
  height: 100%;
`;
const ListContainer = styled('div')`
  margin: auto 0;
`;
const StyledEditButton = styled('div')`
  margin: ${(props) => props.theme.spacing(1.8)}px
    ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  display: flex;
  align-items: right;
  justify-content: right;
  width: 210px;
  height: 30px;
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
`;
