export const colums = [
  {value: "edfFileName", label: "EDFファイル名"},
  {value: "equipmentId", label: "機器ID"},
  {value: "rentalPeriod", label: "貸出期間"},
  {value: "patientNumber", label: "受診者番号"},
  {value: "patientName", label: "受診者氏名"},
  {value: "rentalStatus", label: "貸出状況"},
  {value: "analyzatinoProgram", label: "解析プログラム"},
  {value: "analyzerVersionId", label: "バージョン", width: 150},  
  {value: "snoreQuestion", label: "いびき質問票"},
  {value: "athens", label: "アテネ不眠尺度"},
]

export const analyzationDataColums = [
  {value: "equipmentId", label: "機器ID"},
  {value: "measureStartDate", label: "測定開始日"},
  {value: "edfFileName", label: "EDFファイル名"},
  {value: "patientNumber", label: "受診者番号"},
  {value: "status", label: "状態"},
  {value: "fftResult", label: "FFT解析結果"},
  {value: "doctorA3", label: "ドック向けA3レポート"},
  {value: "doctorA4", label: "ドック向けA4レポート"},
  {value: "research", label: "睡眠変数詳細版レポート"},
  {value: "snoreQuestion", label: "いびき質問票"},
  {value: "athens", label: "アテネ不眠尺度"},
  {value: "commentAdminUser", label: "SleepWellからのコメント"},
]

export const attributeColumns = [
  {value: "prefecture", label: "都道府県"},
  {value: "diseaseList", label: "診療中の疾病"},
  {value: "medicineInfo", label: "投薬情報"},
  {value: "sickHistory", label: "病歴"}
]

export const  analyzationResultColumns = [
  {value: "tib", label: "測定記録時間(TIB)"},
  {value: "sl", label: "入眠潜時(SL)"},
  {value: "n3sl", label: "ノンレム深睡眠潜時"},
  {value: "rsl", label: "レム睡眠潜時"},
  {value: "spt", label: "睡眠時間(SPT)"},
  {value: "tst", label: "全睡眠時間(TST)"},
  {value: "n1StageTotalMin", label: "ステージ合計時間ノンレム1"},
  {value: "n1StageRatio", label: "ステージ割合ノンレム1"},
  {value: "n2StageTotalMin", label: "ステージ合計時間ノンレム2"},
  {value: "n2StageRatio", label: "ステージ割合ノンレム2"},
  {value: "n3StageTotalMin", label: "ステージ合計時間ノンレム3"},
  {value: "n3StageRatio", label: "ステージ割合ノンレム3"},
  {value: "rStageTotalMin", label: "ステージ合計時間レム"},
  {value: "rStageRatio", label: "ステージ割合レム"},
  {value: "wStageTotalMin", label: "ステージ合計時間覚醒"},
  {value: "wStageRatio", label: "ステージ割合覚醒"},
  {value: "sptWIndex", label: "SPTにおける中途覚醒 覚醒指数"},
  {value: "sptWakeResponseParEpoch", label: "SPTにおける中途覚醒 総エポック数"},
  {value: "sptTotalContinuousWTime", label: "SPTにおける中途覚醒 総覚醒回数"},
  {value: "sptWaso", label: "SPTにおける中途覚醒 総覚醒時間(WASO)"},
  {value: "beforeW2hWIndex", label: "起床前2時間覚醒 覚醒指数"},
  {value: "beforeSptWakeResponseParEpoch", label: "起床前2時間覚醒 総エポック数"},
  {value: "beforeW2hTotalContinuousWTime", label: "起床前2時間覚醒 総覚醒回数"},
  {value: "beforeW2hWaso", label: "早朝覚醒割合"},
  {value: "firstSleepCycle", label: "第1睡眠周期"},
  {value: "secondSleepCycle", label: "第2睡眠周期"},
  {value: "thirdSleepCycle", label: "第3睡眠周期"},
  {value: "fourthSleepCycle", label: "第4睡眠周期"},
  {value: "fifthSleepCycle", label: "第5睡眠周期"},
  {value: "sixthSleepCycle", label: "第6睡眠周期"},
  {value: "averageSleepCycle", label: "平均睡眠周期"},
  {value: "seTib", label: "睡眠効率(TST/TIB)"},
  {value: "seSpt", label: "睡眠効率(SPT/TST)"},
  {value: "firstSleepCycleDeltaPow", label: "第1睡眠周期のδパワー値"},
  {value: "firstSleepCycleTime", label: "第1睡眠周期時間(REMは除く)"},
  {value: "firstSleepCycleDeltaPowParMin", label: "第1睡眠周期のδパワー値/ 1分あたり"},
  {value: "totalNonremPow", label: "SPTのδパワー値"},
  {value: "totalNonremDeltaPowParMin", label: "第1睡眠周期/睡眠時間"},
  {value: "firstSleepCycleDeltaPowParToalDeltaPow", label: "SPTのδパワー値/1分あたり"},
]

export const athenPointList = [
  [
    {value: 1, label: "1点"},
    {value: 2, label: "2点"},
    {value: 3, label: "3点"},
    {value: 4, label: "4点"},
    {value: 5, label: "5点"},
    {value: 6, label: "6点"},
    {value: 7, label: "7点"},
    {value: 8, label: "8点"},
    {value: 9, label: "9点"},
    {value: 10, label: "10点"},
    {value: 11, label: "11点"},
    {value: 12, label: "12点"},
    {value: 13, label: "13点"},
    {value: 14, label: "14点"},
    {value: 15, label: "15点"},
    {value: 16, label: "16点"},
    {value: 17, label: "17点"},
    {value: 18, label: "18点"},
    {value: 19, label: "19点"},
    {value: 20, label: "20点"},
    {value: 21, label: "21点"},
    {value: 22, label: "22点"},
    {value: 23, label: "23点"},
    {value: 24, label: "24点"},
  ]
]

export const snorePointList = [
  {value: 1, label: "1点"},
  {value: 2, label: "2点"},
  {value: 3, label: "3点"},
  {value: 4, label: "4点"},
  {value: 5, label: "5点"},
  {value: 6, label: "6点"},
  {value: 7, label: "7点"},
  {value: 8, label: "8点"},
  {value: 9, label: "9点"},
  {value: 10, label: "10点"},
  {value: 11, label: "11点"},
  {value: 12, label: "12点"},
  {value: 13, label: "13点"},
  {value: 14, label: "14点"},
  {value: 15, label: "15点"},
  {value: 16, label: "16点"},
  {value: 17, label: "17点"},
  {value: 18, label: "18点"},
  {value: 19, label: "19点"},
  {value: 20, label: "20点"},
  {value: 21, label: "21点"},
  {value: 22, label: "22点"},
  {value: 23, label: "23点"},
  {value: 24, label: "24点"},
]

