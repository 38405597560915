/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateQuestionsDailyAnswersForSleepInput = {
  id?: string | null,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export enum QuestionPositiveEnum {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  OTHER = "OTHER",
  EMPTY = "EMPTY",
}


export type ModelQuestionsDailyAnswersForSleepConditionInput = {
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForSleepConditionInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForSleepConditionInput | null > | null,
  not?: ModelQuestionsDailyAnswersForSleepConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelQuestionPositiveEnumInput = {
  eq?: QuestionPositiveEnum | null,
  ne?: QuestionPositiveEnum | null,
};

export type QuestionsDailyAnswersForSleep = {
  __typename: "QuestionsDailyAnswersForSleep",
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version: number,
  _deleted?: boolean | null,
  isDelete?: number,
  _lastChangedAt: number,
};

export type UpdateQuestionsDailyAnswersForSleepInput = {
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type DeleteQuestionsDailyAnswersForSleepInput = {
  id: string,
  _version?: number | null,
};

export type CreateQuestionsDailyAnswersForFeelingInput = {
  id?: string | null,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type ModelQuestionsDailyAnswersForFeelingConditionInput = {
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForFeelingConditionInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForFeelingConditionInput | null > | null,
  not?: ModelQuestionsDailyAnswersForFeelingConditionInput | null,
};

export type QuestionsDailyAnswersForFeeling = {
  __typename: "QuestionsDailyAnswersForFeeling",
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version: number,
  _deleted?: boolean | null,
  isDelete?: number,
  _lastChangedAt: number,
};

export type UpdateQuestionsDailyAnswersForFeelingInput = {
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type DeleteQuestionsDailyAnswersForFeelingInput = {
  id: string,
  _version?: number | null,
};

export type CreateQuestionsDailyAnswersForConditionInput = {
  id?: string | null,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type ModelQuestionsDailyAnswersForConditionConditionInput = {
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForConditionConditionInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForConditionConditionInput | null > | null,
  not?: ModelQuestionsDailyAnswersForConditionConditionInput | null,
};

export type QuestionsDailyAnswersForCondition = {
  __typename: "QuestionsDailyAnswersForCondition",
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version: number,
  _deleted?: boolean | null,
  isDelete?: number,
  _lastChangedAt: number,
};

export type UpdateQuestionsDailyAnswersForConditionInput = {
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type DeleteQuestionsDailyAnswersForConditionInput = {
  id: string,
  _version?: number | null,
};

export type CreateQuestionsDailyAnswersForActivityAndStressInput = {
  id?: string | null,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type ModelQuestionsDailyAnswersForActivityAndStressConditionInput = {
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForActivityAndStressConditionInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForActivityAndStressConditionInput | null > | null,
  not?: ModelQuestionsDailyAnswersForActivityAndStressConditionInput | null,
};

export type QuestionsDailyAnswersForActivityAndStress = {
  __typename: "QuestionsDailyAnswersForActivityAndStress",
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version: number,
  _deleted?: boolean | null,
  isDelete?: number,
  _lastChangedAt: number,
};

export type UpdateQuestionsDailyAnswersForActivityAndStressInput = {
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type DeleteQuestionsDailyAnswersForActivityAndStressInput = {
  id: string,
  _version?: number | null,
};

export type CreateQuestionsDailyAnswersForDailyLifeInput = {
  id?: string | null,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type ModelQuestionsDailyAnswersForDailyLifeConditionInput = {
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForDailyLifeConditionInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForDailyLifeConditionInput | null > | null,
  not?: ModelQuestionsDailyAnswersForDailyLifeConditionInput | null,
};

export type QuestionsDailyAnswersForDailyLife = {
  __typename: "QuestionsDailyAnswersForDailyLife",
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version: number,
  _deleted?: boolean | null,
  isDelete?: number,
  _lastChangedAt: number,
};

export type UpdateQuestionsDailyAnswersForDailyLifeInput = {
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type DeleteQuestionsDailyAnswersForDailyLifeInput = {
  id: string,
  _version?: number | null,
};

export type CreateQuestionsDailyAnswersForConsultingToDoctorInput = {
  id?: string | null,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type ModelQuestionsDailyAnswersForConsultingToDoctorConditionInput = {
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForConsultingToDoctorConditionInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForConsultingToDoctorConditionInput | null > | null,
  not?: ModelQuestionsDailyAnswersForConsultingToDoctorConditionInput | null,
};

export type QuestionsDailyAnswersForConsultingToDoctor = {
  __typename: "QuestionsDailyAnswersForConsultingToDoctor",
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version: number,
  _deleted?: boolean | null,
  isDelete?: number,
  _lastChangedAt: number,
};

export type UpdateQuestionsDailyAnswersForConsultingToDoctorInput = {
  id: string,
  firstQuestion?: string | null,
  secondQuestion?: string | null,
  thirdQuestion?: string | null,
  fourthQuestion?: string | null,
  fifthQuestion?: string | null,
  sixthQuestion?: string | null,
  seventhQuestion?: string | null,
  eighthQuestion?: string | null,
  ninthQuestion?: string | null,
  tenthQuestion?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  firstQuestionType?: QuestionPositiveEnum | null,
  secondQuestionType?: QuestionPositiveEnum | null,
  thirdQuestionType?: QuestionPositiveEnum | null,
  fourthQuestionType?: QuestionPositiveEnum | null,
  fifthQuestionType?: QuestionPositiveEnum | null,
  sixthQuestionType?: QuestionPositiveEnum | null,
  seventhQuestionType?: QuestionPositiveEnum | null,
  eighthQuestionType?: QuestionPositiveEnum | null,
  ninthQuestionType?: QuestionPositiveEnum | null,
  tenthQuestionType?: QuestionPositiveEnum | null,
  _version?: number | null,
};

export type DeleteQuestionsDailyAnswersForConsultingToDoctorInput = {
  id: string,
  _version?: number | null,
};

export type CreateDailyAnswersInput = {
  id?: string | null,
  firstQuestion?: boolean | null,
  secondQuestion?: boolean | null,
  thirdQuestion?: boolean | null,
  fourthQuestion?: boolean | null,
  fifthQuestion?: boolean | null,
  sixthQuestion?: boolean | null,
  seventhQuestion?: boolean | null,
  eighthQuestion?: boolean | null,
  ninthQuestion?: boolean | null,
  tenthQuestion?: boolean | null,
  memo?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  patientId?: string | null,
  clinicId?: string | null,
  type?: DailyAnswersTypeEnum | null,
  categoryDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export enum DailyAnswersTypeEnum {
  ANSWERSFORSLEEP = "ANSWERSFORSLEEP",
  ANSWERSFORFEELING = "ANSWERSFORFEELING",
  ANSWERSFORCONDITION = "ANSWERSFORCONDITION",
  ANSWERSFORACTIVITYANDSTRESS = "ANSWERSFORACTIVITYANDSTRESS",
  ANSWERSFORDAILYLIFE = "ANSWERSFORDAILYLIFE",
  ANSWERSFORCONSULTINGTODOCTOR = "ANSWERSFORCONSULTINGTODOCTOR",
}


export type ModelDailyAnswersConditionInput = {
  firstQuestion?: ModelBooleanInput | null,
  secondQuestion?: ModelBooleanInput | null,
  thirdQuestion?: ModelBooleanInput | null,
  fourthQuestion?: ModelBooleanInput | null,
  fifthQuestion?: ModelBooleanInput | null,
  sixthQuestion?: ModelBooleanInput | null,
  seventhQuestion?: ModelBooleanInput | null,
  eighthQuestion?: ModelBooleanInput | null,
  ninthQuestion?: ModelBooleanInput | null,
  tenthQuestion?: ModelBooleanInput | null,
  memo?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  patientId?: ModelStringInput | null,
  clinicId?: ModelStringInput | null,
  type?: ModelDailyAnswersTypeEnumInput | null,
  categoryDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDailyAnswersConditionInput | null > | null,
  or?: Array< ModelDailyAnswersConditionInput | null > | null,
  not?: ModelDailyAnswersConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelDailyAnswersTypeEnumInput = {
  eq?: DailyAnswersTypeEnum | null,
  ne?: DailyAnswersTypeEnum | null,
};

export type DailyAnswers = {
  __typename: "DailyAnswers",
  id: string,
  firstQuestion?: boolean | null,
  secondQuestion?: boolean | null,
  thirdQuestion?: boolean | null,
  fourthQuestion?: boolean | null,
  fifthQuestion?: boolean | null,
  sixthQuestion?: boolean | null,
  seventhQuestion?: boolean | null,
  eighthQuestion?: boolean | null,
  ninthQuestion?: boolean | null,
  tenthQuestion?: boolean | null,
  memo?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  patientId?: string | null,
  clinicId?: string | null,
  type?: DailyAnswersTypeEnum | null,
  categoryDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDailyAnswersInput = {
  id: string,
  firstQuestion?: boolean | null,
  secondQuestion?: boolean | null,
  thirdQuestion?: boolean | null,
  fourthQuestion?: boolean | null,
  fifthQuestion?: boolean | null,
  sixthQuestion?: boolean | null,
  seventhQuestion?: boolean | null,
  eighthQuestion?: boolean | null,
  ninthQuestion?: boolean | null,
  tenthQuestion?: boolean | null,
  memo?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  patientId?: string | null,
  clinicId?: string | null,
  type?: DailyAnswersTypeEnum | null,
  categoryDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteDailyAnswersInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdminsInput = {
  id?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  adminId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
  adminsAdminEmailId?: string | null,
};

export type ModelAdminsConditionInput = {
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  adminId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminsConditionInput | null > | null,
  or?: Array< ModelAdminsConditionInput | null > | null,
  not?: ModelAdminsConditionInput | null,
  adminsAdminEmailId?: ModelIDInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Admins = {
  __typename: "Admins",
  id: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  adminEmail?: AdminEmails | null,
  adminId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  adminsAdminEmailId?: string | null,
};

export type AdminEmails = {
  __typename: "AdminEmails",
  id: string,
  email?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAdminsInput = {
  id: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  adminId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
  adminsAdminEmailId?: string | null,
};

export type DeleteAdminsInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdminEmailsInput = {
  id?: string | null,
  email?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelAdminEmailsConditionInput = {
  email?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminEmailsConditionInput | null > | null,
  or?: Array< ModelAdminEmailsConditionInput | null > | null,
  not?: ModelAdminEmailsConditionInput | null,
};

export type UpdateAdminEmailsInput = {
  id: string,
  email?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteAdminEmailsInput = {
  id: string,
  _version?: number | null,
};

export type CreateMembersInput = {
  id?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  email?: string | null,
  name?: string | null,
  finishedInitRegister?: boolean | null,
  uid?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
  membersRoleId?: string | null,
  membersClinicsId?: string | null,
};

export type ModelMembersConditionInput = {
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  finishedInitRegister?: ModelBooleanInput | null,
  uid?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMembersConditionInput | null > | null,
  or?: Array< ModelMembersConditionInput | null > | null,
  not?: ModelMembersConditionInput | null,
  membersRoleId?: ModelIDInput | null,
  membersClinicsId?: ModelIDInput | null,
};

export type Members = {
  __typename: "Members",
  id: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  email?: string | null,
  name?: string | null,
  finishedInitRegister?: boolean | null,
  uid?: string | null,
  role?: Roles | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  Clinics?: Clinics | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  membersRoleId?: string | null,
  membersClinicsId?: string | null,
};

export type Roles = {
  __typename: "Roles",
  id: string,
  email?: string | null,
  uid?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  clinic?: Clinics | null,
  type?: RoleTypeEnum | null,
  patientNumber?: string | null,
  phoneNumber?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  rolesClinicId?: string | null,
};

export type Clinics = {
  __typename: "Clinics",
  id: string,
  clinicName?: string | null,
  address?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  memo?: string | null,
  phoneNumber?: string | null,
  postalCode?: string | null,
  representativeDoctorId?: string | null,
  status?: StatusEnum | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum StatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export enum RoleTypeEnum {
  DOCTOR = "DOCTOR",
  STAFF = "STAFF",
  CO_MEDICAL = "CO_MEDICAL",
  OWNER = "OWNER",
  PATIENT = "PATIENT",
}


export type UpdateMembersInput = {
  id: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  email?: string | null,
  name?: string | null,
  finishedInitRegister?: boolean | null,
  uid?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
  membersRoleId?: string | null,
  membersClinicsId?: string | null,
};

export type DeleteMembersInput = {
  id: string,
  _version?: number | null,
};

export type CreatePatientsInput = {
  id?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  email?: string | null,
  name?: string | null,
  finishedInitRegister?: boolean | null,
  uid?: string | null,
  patientNumber?: string | null,
  phoneNumber?: string | null,
  memo?: string | null,
  memoForDoctor?: string | null,
  gender?: string | null,
  birthday?: string | null,
  medicalHistory?: string | null,
  prescribedMedicine?: string | null,
  chargedDoctorId?: string | null,
  appointmentDate?: string | null,
  firstScheduledMeasurementDate?: string | null,
  secondScheduledMeasurementDate?: string | null,
  thirdScheduledMeasurementDate?: string | null,
  firstVisitDate?: string | null,
  prefecture?: string | null,
  startOfUse?: string | null,
  endDate?: string | null,
  status?: StatusEnum | null,
  activeDate?: string | null,
  inactiveDate?: string | null,
  deviceToken?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
  patientsClinicId?: string | null,
  patientsInitialAnswerForFeelingId?: string | null,
  patientsInitialAnswerForSleepId?: string | null,
};

export type ModelPatientsConditionInput = {
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  finishedInitRegister?: ModelBooleanInput | null,
  uid?: ModelStringInput | null,
  patientNumber?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  memoForDoctor?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  medicalHistory?: ModelStringInput | null,
  prescribedMedicine?: ModelStringInput | null,
  chargedDoctorId?: ModelStringInput | null,
  appointmentDate?: ModelStringInput | null,
  firstScheduledMeasurementDate?: ModelStringInput | null,
  secondScheduledMeasurementDate?: ModelStringInput | null,
  thirdScheduledMeasurementDate?: ModelStringInput | null,
  firstVisitDate?: ModelStringInput | null,
  prefecture?: ModelStringInput | null,
  startOfUse?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  status?: ModelStatusEnumInput | null,
  activeDate?: ModelStringInput | null,
  inactiveDate?: ModelStringInput | null,
  deviceToken?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPatientsConditionInput | null > | null,
  or?: Array< ModelPatientsConditionInput | null > | null,
  not?: ModelPatientsConditionInput | null,
  patientsClinicId?: ModelIDInput | null,
  patientsInitialAnswerForFeelingId?: ModelIDInput | null,
  patientsInitialAnswerForSleepId?: ModelIDInput | null,
};

export type ModelStatusEnumInput = {
  eq?: StatusEnum | null,
  ne?: StatusEnum | null,
};

export type Patients = {
  __typename: "Patients",
  id: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  email?: string | null,
  name?: string | null,
  finishedInitRegister?: boolean | null,
  uid?: string | null,
  patientNumber?: string | null,
  phoneNumber?: string | null,
  memo?: string | null,
  memoForDoctor?: string | null,
  gender?: string | null,
  birthday?: string | null,
  medicalHistory?: string | null,
  prescribedMedicine?: string | null,
  chargedDoctorId?: string | null,
  clinic?: Clinics | null,
  appointmentDate?: string | null,
  firstScheduledMeasurementDate?: string | null,
  secondScheduledMeasurementDate?: string | null,
  thirdScheduledMeasurementDate?: string | null,
  firstVisitDate?: string | null,
  prefecture?: string | null,
  startOfUse?: string | null,
  endDate?: string | null,
  status?: StatusEnum | null,
  activeDate?: string | null,
  inactiveDate?: string | null,
  deviceToken?: string | null,
  initialAnswerForFeeling?: InitialAnswerForFeeling | null,
  initialAnswerForSleep?: InitialAnswerForSleep | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  patientsClinicId?: string | null,
  patientsInitialAnswerForFeelingId?: string | null,
  patientsInitialAnswerForSleepId?: string | null,
};

export type InitialAnswerForFeeling = {
  __typename: "InitialAnswerForFeeling",
  id: string,
  question1?: number | null,
  question2?: number | null,
  question3?: number | null,
  question4?: number | null,
  question5?: number | null,
  question6?: number | null,
  question7?: number | null,
  question8?: number | null,
  question9?: number | null,
  question10?: number | null,
  question11?: number | null,
  question12?: number | null,
  question13?: number | null,
  question14?: number | null,
  totalScore?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type InitialAnswerForSleep = {
  __typename: "InitialAnswerForSleep",
  id: string,
  question1?: number | null,
  question2?: number | null,
  question3?: number | null,
  question4?: number | null,
  question5?: number | null,
  question6?: number | null,
  question7?: number | null,
  question8?: number | null,
  totalScore?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePatientsInput = {
  id: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  email?: string | null,
  name?: string | null,
  finishedInitRegister?: boolean | null,
  uid?: string | null,
  patientNumber?: string | null,
  phoneNumber?: string | null,
  memo?: string | null,
  memoForDoctor?: string | null,
  gender?: string | null,
  birthday?: string | null,
  medicalHistory?: string | null,
  prescribedMedicine?: string | null,
  chargedDoctorId?: string | null,
  appointmentDate?: string | null,
  firstScheduledMeasurementDate?: string | null,
  secondScheduledMeasurementDate?: string | null,
  thirdScheduledMeasurementDate?: string | null,
  firstVisitDate?: string | null,
  prefecture?: string | null,
  startOfUse?: string | null,
  endDate?: string | null,
  status?: StatusEnum | null,
  activeDate?: string | null,
  inactiveDate?: string | null,
  deviceToken?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
  patientsClinicId?: string | null,
  patientsInitialAnswerForFeelingId?: string | null,
  patientsInitialAnswerForSleepId?: string | null,
};

export type DeletePatientsInput = {
  id: string,
  _version?: number | null,
};

export type CreateClinicsInput = {
  id?: string | null,
  clinicName?: string | null,
  address?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  memo?: string | null,
  phoneNumber?: string | null,
  postalCode?: string | null,
  representativeDoctorId?: string | null,
  status?: StatusEnum | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelClinicsConditionInput = {
  clinicName?: ModelStringInput | null,
  address?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  representativeDoctorId?: ModelStringInput | null,
  status?: ModelStatusEnumInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClinicsConditionInput | null > | null,
  or?: Array< ModelClinicsConditionInput | null > | null,
  not?: ModelClinicsConditionInput | null,
};

export type UpdateClinicsInput = {
  id: string,
  clinicName?: string | null,
  address?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  memo?: string | null,
  phoneNumber?: string | null,
  postalCode?: string | null,
  representativeDoctorId?: string | null,
  status?: StatusEnum | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteClinicsInput = {
  id: string,
  _version?: number | null,
};

export type CreateRolesInput = {
  id?: string | null,
  email?: string | null,
  uid?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  type?: RoleTypeEnum | null,
  patientNumber?: string | null,
  phoneNumber?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
  rolesClinicId?: string | null,
};

export type ModelRolesConditionInput = {
  email?: ModelStringInput | null,
  uid?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  type?: ModelRoleTypeEnumInput | null,
  patientNumber?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRolesConditionInput | null > | null,
  or?: Array< ModelRolesConditionInput | null > | null,
  not?: ModelRolesConditionInput | null,
  rolesClinicId?: ModelIDInput | null,
};

export type ModelRoleTypeEnumInput = {
  eq?: RoleTypeEnum | null,
  ne?: RoleTypeEnum | null,
};

export type UpdateRolesInput = {
  id: string,
  email?: string | null,
  uid?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  type?: RoleTypeEnum | null,
  patientNumber?: string | null,
  phoneNumber?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
  rolesClinicId?: string | null,
};

export type DeleteRolesInput = {
  id: string,
  _version?: number | null,
};

export type CreateInitialAnswerForSleepInput = {
  id?: string | null,
  question1?: number | null,
  question2?: number | null,
  question3?: number | null,
  question4?: number | null,
  question5?: number | null,
  question6?: number | null,
  question7?: number | null,
  question8?: number | null,
  totalScore?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type ModelInitialAnswerForSleepConditionInput = {
  question1?: ModelIntInput | null,
  question2?: ModelIntInput | null,
  question3?: ModelIntInput | null,
  question4?: ModelIntInput | null,
  question5?: ModelIntInput | null,
  question6?: ModelIntInput | null,
  question7?: ModelIntInput | null,
  question8?: ModelIntInput | null,
  totalScore?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelInitialAnswerForSleepConditionInput | null > | null,
  or?: Array< ModelInitialAnswerForSleepConditionInput | null > | null,
  not?: ModelInitialAnswerForSleepConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateInitialAnswerForSleepInput = {
  id: string,
  question1?: number | null,
  question2?: number | null,
  question3?: number | null,
  question4?: number | null,
  question5?: number | null,
  question6?: number | null,
  question7?: number | null,
  question8?: number | null,
  totalScore?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type DeleteInitialAnswerForSleepInput = {
  id: string,
  _version?: number | null,
};

export type CreateInitialAnswerForFeelingInput = {
  id?: string | null,
  question1?: number | null,
  question2?: number | null,
  question3?: number | null,
  question4?: number | null,
  question5?: number | null,
  question6?: number | null,
  question7?: number | null,
  question8?: number | null,
  question9?: number | null,
  question10?: number | null,
  question11?: number | null,
  question12?: number | null,
  question13?: number | null,
  question14?: number | null,
  totalScore?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type ModelInitialAnswerForFeelingConditionInput = {
  question1?: ModelIntInput | null,
  question2?: ModelIntInput | null,
  question3?: ModelIntInput | null,
  question4?: ModelIntInput | null,
  question5?: ModelIntInput | null,
  question6?: ModelIntInput | null,
  question7?: ModelIntInput | null,
  question8?: ModelIntInput | null,
  question9?: ModelIntInput | null,
  question10?: ModelIntInput | null,
  question11?: ModelIntInput | null,
  question12?: ModelIntInput | null,
  question13?: ModelIntInput | null,
  question14?: ModelIntInput | null,
  totalScore?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelInitialAnswerForFeelingConditionInput | null > | null,
  or?: Array< ModelInitialAnswerForFeelingConditionInput | null > | null,
  not?: ModelInitialAnswerForFeelingConditionInput | null,
};

export type UpdateInitialAnswerForFeelingInput = {
  id: string,
  question1?: number | null,
  question2?: number | null,
  question3?: number | null,
  question4?: number | null,
  question5?: number | null,
  question6?: number | null,
  question7?: number | null,
  question8?: number | null,
  question9?: number | null,
  question10?: number | null,
  question11?: number | null,
  question12?: number | null,
  question13?: number | null,
  question14?: number | null,
  totalScore?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
};

export type DeleteInitialAnswerForFeelingInput = {
  id: string,
  _version?: number | null,
};

export type CreateDailyAnswerForSleepInput = {
  id?: string | null,
  _version?: number | null,
};

export type ModelDailyAnswerForSleepConditionInput = {
  and?: Array< ModelDailyAnswerForSleepConditionInput | null > | null,
  or?: Array< ModelDailyAnswerForSleepConditionInput | null > | null,
  not?: ModelDailyAnswerForSleepConditionInput | null,
};

export type DailyAnswerForSleep = {
  __typename: "DailyAnswerForSleep",
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDailyAnswerForSleepInput = {
  id: string,
  _version?: number | null,
};

export type DeleteDailyAnswerForSleepInput = {
  id: string,
  _version?: number | null,
};

export type CreateDailyAnswerForFeelingInput = {
  id?: string | null,
  _version?: number | null,
};

export type ModelDailyAnswerForFeelingConditionInput = {
  and?: Array< ModelDailyAnswerForFeelingConditionInput | null > | null,
  or?: Array< ModelDailyAnswerForFeelingConditionInput | null > | null,
  not?: ModelDailyAnswerForFeelingConditionInput | null,
};

export type DailyAnswerForFeeling = {
  __typename: "DailyAnswerForFeeling",
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDailyAnswerForFeelingInput = {
  id: string,
  _version?: number | null,
};

export type DeleteDailyAnswerForFeelingInput = {
  id: string,
  _version?: number | null,
};

export type CreateAnswerForConditionInput = {
  id?: string | null,
  _version?: number | null,
};

export type ModelAnswerForConditionConditionInput = {
  and?: Array< ModelAnswerForConditionConditionInput | null > | null,
  or?: Array< ModelAnswerForConditionConditionInput | null > | null,
  not?: ModelAnswerForConditionConditionInput | null,
};

export type AnswerForCondition = {
  __typename: "AnswerForCondition",
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAnswerForConditionInput = {
  id: string,
  _version?: number | null,
};

export type DeleteAnswerForConditionInput = {
  id: string,
  _version?: number | null,
};

export type CreateAnswersForActivityAndStressInput = {
  id?: string | null,
  _version?: number | null,
};

export type ModelAnswersForActivityAndStressConditionInput = {
  and?: Array< ModelAnswersForActivityAndStressConditionInput | null > | null,
  or?: Array< ModelAnswersForActivityAndStressConditionInput | null > | null,
  not?: ModelAnswersForActivityAndStressConditionInput | null,
};

export type AnswersForActivityAndStress = {
  __typename: "AnswersForActivityAndStress",
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAnswersForActivityAndStressInput = {
  id: string,
  _version?: number | null,
};

export type DeleteAnswersForActivityAndStressInput = {
  id: string,
  _version?: number | null,
};

export type CreateAnswersForDailyLifeInput = {
  id?: string | null,
  _version?: number | null,
};

export type ModelAnswersForDailyLifeConditionInput = {
  and?: Array< ModelAnswersForDailyLifeConditionInput | null > | null,
  or?: Array< ModelAnswersForDailyLifeConditionInput | null > | null,
  not?: ModelAnswersForDailyLifeConditionInput | null,
};

export type AnswersForDailyLife = {
  __typename: "AnswersForDailyLife",
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAnswersForDailyLifeInput = {
  id: string,
  _version?: number | null,
};

export type DeleteAnswersForDailyLifeInput = {
  id: string,
  _version?: number | null,
};

export type CreateAnswersForConsultingToDoctorInput = {
  id?: string | null,
  _version?: number | null,
};

export type ModelAnswersForConsultingToDoctorConditionInput = {
  and?: Array< ModelAnswersForConsultingToDoctorConditionInput | null > | null,
  or?: Array< ModelAnswersForConsultingToDoctorConditionInput | null > | null,
  not?: ModelAnswersForConsultingToDoctorConditionInput | null,
};

export type AnswersForConsultingToDoctor = {
  __typename: "AnswersForConsultingToDoctor",
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAnswersForConsultingToDoctorInput = {
  id: string,
  _version?: number | null,
};

export type DeleteAnswersForConsultingToDoctorInput = {
  id: string,
  _version?: number | null,
};

export type CreateAccessLogsInput = {
  id?: string | null,
  patientId?: string | null,
  type?: AccessLogsTypeEnum | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export enum AccessLogsTypeEnum {
  START = "START",
  RESUMED = "RESUMED",
  INACTIVE = "INACTIVE",
  PAUSED = "PAUSED",
}


export type ModelAccessLogsConditionInput = {
  patientId?: ModelStringInput | null,
  type?: ModelAccessLogsTypeEnumInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccessLogsConditionInput | null > | null,
  or?: Array< ModelAccessLogsConditionInput | null > | null,
  not?: ModelAccessLogsConditionInput | null,
};

export type ModelAccessLogsTypeEnumInput = {
  eq?: AccessLogsTypeEnum | null,
  ne?: AccessLogsTypeEnum | null,
};

export type AccessLogs = {
  __typename: "AccessLogs",
  id: string,
  patientId?: string | null,
  type?: AccessLogsTypeEnum | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAccessLogsInput = {
  id: string,
  patientId?: string | null,
  type?: AccessLogsTypeEnum | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteAccessLogsInput = {
  id: string,
  _version?: number | null,
};

export type CreateWordsOfEncouragementInput = {
  id?: string | null,
  _version?: number | null,
};

export type ModelWordsOfEncouragementConditionInput = {
  and?: Array< ModelWordsOfEncouragementConditionInput | null > | null,
  or?: Array< ModelWordsOfEncouragementConditionInput | null > | null,
  not?: ModelWordsOfEncouragementConditionInput | null,
};

export type WordsOfEncouragement = {
  __typename: "WordsOfEncouragement",
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateWordsOfEncouragementInput = {
  id: string,
  _version?: number | null,
};

export type DeleteWordsOfEncouragementInput = {
  id: string,
  _version?: number | null,
};

export type CreateQuestionsInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
  questionsQuestionsDailyAnswersForSleepId?: string | null,
  questionsQuestionsDailyAnswersForFeelingId?: string | null,
  questionsQuestionsDailyAnswersForConditionId?: string | null,
  questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
  questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
  questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
};

export type ModelQuestionsConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelQuestionsConditionInput | null > | null,
  or?: Array< ModelQuestionsConditionInput | null > | null,
  not?: ModelQuestionsConditionInput | null,
  questionsQuestionsDailyAnswersForSleepId?: ModelIDInput | null,
  questionsQuestionsDailyAnswersForFeelingId?: ModelIDInput | null,
  questionsQuestionsDailyAnswersForConditionId?: ModelIDInput | null,
  questionsQuestionsDailyAnswersForActivityAndStressId?: ModelIDInput | null,
  questionsQuestionsDailyAnswersForDailyLifeId?: ModelIDInput | null,
  questionsQuestionsDailyAnswersForConsultingToDoctorId?: ModelIDInput | null,
};

export type Questions = {
  __typename: "Questions",
  id: string,
  questionsDailyAnswersForSleep?: QuestionsDailyAnswersForSleep | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  questionsDailyAnswersForFeeling?: QuestionsDailyAnswersForFeeling | null,
  questionsDailyAnswersForCondition?: QuestionsDailyAnswersForCondition | null,
  questionsDailyAnswersForActivityAndStress?: QuestionsDailyAnswersForActivityAndStress | null,
  questionsDailyAnswersForDailyLife?: QuestionsDailyAnswersForDailyLife | null,
  questionsDailyAnswersForConsultingToDoctor?: QuestionsDailyAnswersForConsultingToDoctor | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  questionsQuestionsDailyAnswersForSleepId?: string | null,
  questionsQuestionsDailyAnswersForFeelingId?: string | null,
  questionsQuestionsDailyAnswersForConditionId?: string | null,
  questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
  questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
  questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
};

export type UpdateQuestionsInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  _version?: number | null,
  questionsQuestionsDailyAnswersForSleepId?: string | null,
  questionsQuestionsDailyAnswersForFeelingId?: string | null,
  questionsQuestionsDailyAnswersForConditionId?: string | null,
  questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
  questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
  questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
};

export type DeleteQuestionsInput = {
  id: string,
  _version?: number | null,
};

export type CreateAnswersListInput = {
  id?: string | null,
  _version?: number | null,
};

export type ModelAnswersListConditionInput = {
  and?: Array< ModelAnswersListConditionInput | null > | null,
  or?: Array< ModelAnswersListConditionInput | null > | null,
  not?: ModelAnswersListConditionInput | null,
};

export type AnswersList = {
  __typename: "AnswersList",
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAnswersListInput = {
  id: string,
  _version?: number | null,
};

export type DeleteAnswersListInput = {
  id: string,
  _version?: number | null,
};

export type ModelQuestionsDailyAnswersForSleepFilterInput = {
  id?: ModelIDInput | null,
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForSleepFilterInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForSleepFilterInput | null > | null,
  not?: ModelQuestionsDailyAnswersForSleepFilterInput | null,
};

export type ModelQuestionsDailyAnswersForSleepConnection = {
  __typename: "ModelQuestionsDailyAnswersForSleepConnection",
  items:  Array<QuestionsDailyAnswersForSleep | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelQuestionsDailyAnswersForFeelingFilterInput = {
  id?: ModelIDInput | null,
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForFeelingFilterInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForFeelingFilterInput | null > | null,
  not?: ModelQuestionsDailyAnswersForFeelingFilterInput | null,
};

export type ModelQuestionsDailyAnswersForFeelingConnection = {
  __typename: "ModelQuestionsDailyAnswersForFeelingConnection",
  items:  Array<QuestionsDailyAnswersForFeeling | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelQuestionsDailyAnswersForConditionFilterInput = {
  id?: ModelIDInput | null,
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForConditionFilterInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForConditionFilterInput | null > | null,
  not?: ModelQuestionsDailyAnswersForConditionFilterInput | null,
};

export type ModelQuestionsDailyAnswersForConditionConnection = {
  __typename: "ModelQuestionsDailyAnswersForConditionConnection",
  items:  Array<QuestionsDailyAnswersForCondition | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelQuestionsDailyAnswersForActivityAndStressFilterInput = {
  id?: ModelIDInput | null,
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForActivityAndStressFilterInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForActivityAndStressFilterInput | null > | null,
  not?: ModelQuestionsDailyAnswersForActivityAndStressFilterInput | null,
};

export type ModelQuestionsDailyAnswersForActivityAndStressConnection = {
  __typename: "ModelQuestionsDailyAnswersForActivityAndStressConnection",
  items:  Array<QuestionsDailyAnswersForActivityAndStress | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelQuestionsDailyAnswersForDailyLifeFilterInput = {
  id?: ModelIDInput | null,
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForDailyLifeFilterInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForDailyLifeFilterInput | null > | null,
  not?: ModelQuestionsDailyAnswersForDailyLifeFilterInput | null,
};

export type ModelQuestionsDailyAnswersForDailyLifeConnection = {
  __typename: "ModelQuestionsDailyAnswersForDailyLifeConnection",
  items:  Array<QuestionsDailyAnswersForDailyLife | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelQuestionsDailyAnswersForConsultingToDoctorFilterInput = {
  id?: ModelIDInput | null,
  firstQuestion?: ModelStringInput | null,
  secondQuestion?: ModelStringInput | null,
  thirdQuestion?: ModelStringInput | null,
  fourthQuestion?: ModelStringInput | null,
  fifthQuestion?: ModelStringInput | null,
  sixthQuestion?: ModelStringInput | null,
  seventhQuestion?: ModelStringInput | null,
  eighthQuestion?: ModelStringInput | null,
  ninthQuestion?: ModelStringInput | null,
  tenthQuestion?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  firstQuestionType?: ModelQuestionPositiveEnumInput | null,
  secondQuestionType?: ModelQuestionPositiveEnumInput | null,
  thirdQuestionType?: ModelQuestionPositiveEnumInput | null,
  fourthQuestionType?: ModelQuestionPositiveEnumInput | null,
  fifthQuestionType?: ModelQuestionPositiveEnumInput | null,
  sixthQuestionType?: ModelQuestionPositiveEnumInput | null,
  seventhQuestionType?: ModelQuestionPositiveEnumInput | null,
  eighthQuestionType?: ModelQuestionPositiveEnumInput | null,
  ninthQuestionType?: ModelQuestionPositiveEnumInput | null,
  tenthQuestionType?: ModelQuestionPositiveEnumInput | null,
  and?: Array< ModelQuestionsDailyAnswersForConsultingToDoctorFilterInput | null > | null,
  or?: Array< ModelQuestionsDailyAnswersForConsultingToDoctorFilterInput | null > | null,
  not?: ModelQuestionsDailyAnswersForConsultingToDoctorFilterInput | null,
};

export type ModelQuestionsDailyAnswersForConsultingToDoctorConnection = {
  __typename: "ModelQuestionsDailyAnswersForConsultingToDoctorConnection",
  items:  Array<QuestionsDailyAnswersForConsultingToDoctor | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDailyAnswersFilterInput = {
  id?: ModelIDInput | null,
  firstQuestion?: ModelBooleanInput | null,
  secondQuestion?: ModelBooleanInput | null,
  thirdQuestion?: ModelBooleanInput | null,
  fourthQuestion?: ModelBooleanInput | null,
  fifthQuestion?: ModelBooleanInput | null,
  sixthQuestion?: ModelBooleanInput | null,
  seventhQuestion?: ModelBooleanInput | null,
  eighthQuestion?: ModelBooleanInput | null,
  ninthQuestion?: ModelBooleanInput | null,
  tenthQuestion?: ModelBooleanInput | null,
  memo?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  patientId?: ModelStringInput | null,
  clinicId?: ModelStringInput | null,
  type?: ModelDailyAnswersTypeEnumInput | null,
  categoryDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDailyAnswersFilterInput | null > | null,
  or?: Array< ModelDailyAnswersFilterInput | null > | null,
  not?: ModelDailyAnswersFilterInput | null,
};

export type ModelDailyAnswersConnection = {
  __typename: "ModelDailyAnswersConnection",
  items:  Array<DailyAnswers | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdminsFilterInput = {
  id?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  adminId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminsFilterInput | null > | null,
  or?: Array< ModelAdminsFilterInput | null > | null,
  not?: ModelAdminsFilterInput | null,
  adminsAdminEmailId?: ModelIDInput | null,
};

export type ModelAdminsConnection = {
  __typename: "ModelAdminsConnection",
  items:  Array<Admins | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdminEmailsFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminEmailsFilterInput | null > | null,
  or?: Array< ModelAdminEmailsFilterInput | null > | null,
  not?: ModelAdminEmailsFilterInput | null,
};

export type ModelAdminEmailsConnection = {
  __typename: "ModelAdminEmailsConnection",
  items:  Array<AdminEmails | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMembersFilterInput = {
  id?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  finishedInitRegister?: ModelBooleanInput | null,
  uid?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMembersFilterInput | null > | null,
  or?: Array< ModelMembersFilterInput | null > | null,
  not?: ModelMembersFilterInput | null,
  membersRoleId?: ModelIDInput | null,
  membersClinicsId?: ModelIDInput | null,
};

export type ModelMembersConnection = {
  __typename: "ModelMembersConnection",
  items:  Array<Members | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPatientsFilterInput = {
  id?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  finishedInitRegister?: ModelBooleanInput | null,
  uid?: ModelStringInput | null,
  patientNumber?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  memoForDoctor?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  medicalHistory?: ModelStringInput | null,
  prescribedMedicine?: ModelStringInput | null,
  chargedDoctorId?: ModelStringInput | null,
  appointmentDate?: ModelStringInput | null,
  firstScheduledMeasurementDate?: ModelStringInput | null,
  secondScheduledMeasurementDate?: ModelStringInput | null,
  thirdScheduledMeasurementDate?: ModelStringInput | null,
  firstVisitDate?: ModelStringInput | null,
  prefecture?: ModelStringInput | null,
  startOfUse?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  status?: ModelStatusEnumInput | null,
  activeDate?: ModelStringInput | null,
  inactiveDate?: ModelStringInput | null,
  deviceToken?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPatientsFilterInput | null > | null,
  or?: Array< ModelPatientsFilterInput | null > | null,
  not?: ModelPatientsFilterInput | null,
  patientsClinicId?: ModelIDInput | null,
  patientsInitialAnswerForFeelingId?: ModelIDInput | null,
  patientsInitialAnswerForSleepId?: ModelIDInput | null,
};

export type ModelPatientsConnection = {
  __typename: "ModelPatientsConnection",
  items:  Array<Patients | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelClinicsFilterInput = {
  id?: ModelIDInput | null,
  clinicName?: ModelStringInput | null,
  address?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  representativeDoctorId?: ModelStringInput | null,
  status?: ModelStatusEnumInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClinicsFilterInput | null > | null,
  or?: Array< ModelClinicsFilterInput | null > | null,
  not?: ModelClinicsFilterInput | null,
};

export type ModelClinicsConnection = {
  __typename: "ModelClinicsConnection",
  items:  Array<Clinics | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRolesFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  uid?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  type?: ModelRoleTypeEnumInput | null,
  patientNumber?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRolesFilterInput | null > | null,
  or?: Array< ModelRolesFilterInput | null > | null,
  not?: ModelRolesFilterInput | null,
  rolesClinicId?: ModelIDInput | null,
};

export type ModelRolesConnection = {
  __typename: "ModelRolesConnection",
  items:  Array<Roles | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInitialAnswerForSleepFilterInput = {
  id?: ModelIDInput | null,
  question1?: ModelIntInput | null,
  question2?: ModelIntInput | null,
  question3?: ModelIntInput | null,
  question4?: ModelIntInput | null,
  question5?: ModelIntInput | null,
  question6?: ModelIntInput | null,
  question7?: ModelIntInput | null,
  question8?: ModelIntInput | null,
  totalScore?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelInitialAnswerForSleepFilterInput | null > | null,
  or?: Array< ModelInitialAnswerForSleepFilterInput | null > | null,
  not?: ModelInitialAnswerForSleepFilterInput | null,
};

export type ModelInitialAnswerForSleepConnection = {
  __typename: "ModelInitialAnswerForSleepConnection",
  items:  Array<InitialAnswerForSleep | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInitialAnswerForFeelingFilterInput = {
  id?: ModelIDInput | null,
  question1?: ModelIntInput | null,
  question2?: ModelIntInput | null,
  question3?: ModelIntInput | null,
  question4?: ModelIntInput | null,
  question5?: ModelIntInput | null,
  question6?: ModelIntInput | null,
  question7?: ModelIntInput | null,
  question8?: ModelIntInput | null,
  question9?: ModelIntInput | null,
  question10?: ModelIntInput | null,
  question11?: ModelIntInput | null,
  question12?: ModelIntInput | null,
  question13?: ModelIntInput | null,
  question14?: ModelIntInput | null,
  totalScore?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelInitialAnswerForFeelingFilterInput | null > | null,
  or?: Array< ModelInitialAnswerForFeelingFilterInput | null > | null,
  not?: ModelInitialAnswerForFeelingFilterInput | null,
};

export type ModelInitialAnswerForFeelingConnection = {
  __typename: "ModelInitialAnswerForFeelingConnection",
  items:  Array<InitialAnswerForFeeling | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDailyAnswerForSleepFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelDailyAnswerForSleepFilterInput | null > | null,
  or?: Array< ModelDailyAnswerForSleepFilterInput | null > | null,
  not?: ModelDailyAnswerForSleepFilterInput | null,
};

export type ModelDailyAnswerForSleepConnection = {
  __typename: "ModelDailyAnswerForSleepConnection",
  items:  Array<DailyAnswerForSleep | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDailyAnswerForFeelingFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelDailyAnswerForFeelingFilterInput | null > | null,
  or?: Array< ModelDailyAnswerForFeelingFilterInput | null > | null,
  not?: ModelDailyAnswerForFeelingFilterInput | null,
};

export type ModelDailyAnswerForFeelingConnection = {
  __typename: "ModelDailyAnswerForFeelingConnection",
  items:  Array<DailyAnswerForFeeling | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAnswerForConditionFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelAnswerForConditionFilterInput | null > | null,
  or?: Array< ModelAnswerForConditionFilterInput | null > | null,
  not?: ModelAnswerForConditionFilterInput | null,
};

export type ModelAnswerForConditionConnection = {
  __typename: "ModelAnswerForConditionConnection",
  items:  Array<AnswerForCondition | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAnswersForActivityAndStressFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelAnswersForActivityAndStressFilterInput | null > | null,
  or?: Array< ModelAnswersForActivityAndStressFilterInput | null > | null,
  not?: ModelAnswersForActivityAndStressFilterInput | null,
};

export type ModelAnswersForActivityAndStressConnection = {
  __typename: "ModelAnswersForActivityAndStressConnection",
  items:  Array<AnswersForActivityAndStress | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAnswersForDailyLifeFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelAnswersForDailyLifeFilterInput | null > | null,
  or?: Array< ModelAnswersForDailyLifeFilterInput | null > | null,
  not?: ModelAnswersForDailyLifeFilterInput | null,
};

export type ModelAnswersForDailyLifeConnection = {
  __typename: "ModelAnswersForDailyLifeConnection",
  items:  Array<AnswersForDailyLife | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAnswersForConsultingToDoctorFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelAnswersForConsultingToDoctorFilterInput | null > | null,
  or?: Array< ModelAnswersForConsultingToDoctorFilterInput | null > | null,
  not?: ModelAnswersForConsultingToDoctorFilterInput | null,
};

export type ModelAnswersForConsultingToDoctorConnection = {
  __typename: "ModelAnswersForConsultingToDoctorConnection",
  items:  Array<AnswersForConsultingToDoctor | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAccessLogsFilterInput = {
  id?: ModelIDInput | null,
  patientId?: ModelStringInput | null,
  type?: ModelAccessLogsTypeEnumInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccessLogsFilterInput | null > | null,
  or?: Array< ModelAccessLogsFilterInput | null > | null,
  not?: ModelAccessLogsFilterInput | null,
};

export type ModelAccessLogsConnection = {
  __typename: "ModelAccessLogsConnection",
  items:  Array<AccessLogs | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWordsOfEncouragementFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelWordsOfEncouragementFilterInput | null > | null,
  or?: Array< ModelWordsOfEncouragementFilterInput | null > | null,
  not?: ModelWordsOfEncouragementFilterInput | null,
};

export type ModelWordsOfEncouragementConnection = {
  __typename: "ModelWordsOfEncouragementConnection",
  items:  Array<WordsOfEncouragement | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelQuestionsFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelQuestionsFilterInput | null > | null,
  or?: Array< ModelQuestionsFilterInput | null > | null,
  not?: ModelQuestionsFilterInput | null,
  questionsQuestionsDailyAnswersForSleepId?: ModelIDInput | null,
  questionsQuestionsDailyAnswersForFeelingId?: ModelIDInput | null,
  questionsQuestionsDailyAnswersForConditionId?: ModelIDInput | null,
  questionsQuestionsDailyAnswersForActivityAndStressId?: ModelIDInput | null,
  questionsQuestionsDailyAnswersForDailyLifeId?: ModelIDInput | null,
  questionsQuestionsDailyAnswersForConsultingToDoctorId?: ModelIDInput | null,
};

export type ModelQuestionsConnection = {
  __typename: "ModelQuestionsConnection",
  items:  Array<Questions | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAnswersListFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelAnswersListFilterInput | null > | null,
  or?: Array< ModelAnswersListFilterInput | null > | null,
  not?: ModelAnswersListFilterInput | null,
};

export type ModelAnswersListConnection = {
  __typename: "ModelAnswersListConnection",
  items:  Array<AnswersList | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type CreateQuestionsDailyAnswersForSleepMutationVariables = {
  input: CreateQuestionsDailyAnswersForSleepInput,
  condition?: ModelQuestionsDailyAnswersForSleepConditionInput | null,
};

export type CreateQuestionsDailyAnswersForSleepMutation = {
  createQuestionsDailyAnswersForSleep?:  {
    __typename: "QuestionsDailyAnswersForSleep",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateQuestionsDailyAnswersForSleepMutationVariables = {
  input: UpdateQuestionsDailyAnswersForSleepInput,
  condition?: ModelQuestionsDailyAnswersForSleepConditionInput | null,
};

export type UpdateQuestionsDailyAnswersForSleepMutation = {
  updateQuestionsDailyAnswersForSleep?:  {
    __typename: "QuestionsDailyAnswersForSleep",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteQuestionsDailyAnswersForSleepMutationVariables = {
  input: DeleteQuestionsDailyAnswersForSleepInput,
  condition?: ModelQuestionsDailyAnswersForSleepConditionInput | null,
};

export type DeleteQuestionsDailyAnswersForSleepMutation = {
  deleteQuestionsDailyAnswersForSleep?:  {
    __typename: "QuestionsDailyAnswersForSleep",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateQuestionsDailyAnswersForFeelingMutationVariables = {
  input: CreateQuestionsDailyAnswersForFeelingInput,
  condition?: ModelQuestionsDailyAnswersForFeelingConditionInput | null,
};

export type CreateQuestionsDailyAnswersForFeelingMutation = {
  createQuestionsDailyAnswersForFeeling?:  {
    __typename: "QuestionsDailyAnswersForFeeling",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateQuestionsDailyAnswersForFeelingMutationVariables = {
  input: UpdateQuestionsDailyAnswersForFeelingInput,
  condition?: ModelQuestionsDailyAnswersForFeelingConditionInput | null,
};

export type UpdateQuestionsDailyAnswersForFeelingMutation = {
  updateQuestionsDailyAnswersForFeeling?:  {
    __typename: "QuestionsDailyAnswersForFeeling",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteQuestionsDailyAnswersForFeelingMutationVariables = {
  input: DeleteQuestionsDailyAnswersForFeelingInput,
  condition?: ModelQuestionsDailyAnswersForFeelingConditionInput | null,
};

export type DeleteQuestionsDailyAnswersForFeelingMutation = {
  deleteQuestionsDailyAnswersForFeeling?:  {
    __typename: "QuestionsDailyAnswersForFeeling",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateQuestionsDailyAnswersForConditionMutationVariables = {
  input: CreateQuestionsDailyAnswersForConditionInput,
  condition?: ModelQuestionsDailyAnswersForConditionConditionInput | null,
};

export type CreateQuestionsDailyAnswersForConditionMutation = {
  createQuestionsDailyAnswersForCondition?:  {
    __typename: "QuestionsDailyAnswersForCondition",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateQuestionsDailyAnswersForConditionMutationVariables = {
  input: UpdateQuestionsDailyAnswersForConditionInput,
  condition?: ModelQuestionsDailyAnswersForConditionConditionInput | null,
};

export type UpdateQuestionsDailyAnswersForConditionMutation = {
  updateQuestionsDailyAnswersForCondition?:  {
    __typename: "QuestionsDailyAnswersForCondition",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteQuestionsDailyAnswersForConditionMutationVariables = {
  input: DeleteQuestionsDailyAnswersForConditionInput,
  condition?: ModelQuestionsDailyAnswersForConditionConditionInput | null,
};

export type DeleteQuestionsDailyAnswersForConditionMutation = {
  deleteQuestionsDailyAnswersForCondition?:  {
    __typename: "QuestionsDailyAnswersForCondition",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateQuestionsDailyAnswersForActivityAndStressMutationVariables = {
  input: CreateQuestionsDailyAnswersForActivityAndStressInput,
  condition?: ModelQuestionsDailyAnswersForActivityAndStressConditionInput | null,
};

export type CreateQuestionsDailyAnswersForActivityAndStressMutation = {
  createQuestionsDailyAnswersForActivityAndStress?:  {
    __typename: "QuestionsDailyAnswersForActivityAndStress",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateQuestionsDailyAnswersForActivityAndStressMutationVariables = {
  input: UpdateQuestionsDailyAnswersForActivityAndStressInput,
  condition?: ModelQuestionsDailyAnswersForActivityAndStressConditionInput | null,
};

export type UpdateQuestionsDailyAnswersForActivityAndStressMutation = {
  updateQuestionsDailyAnswersForActivityAndStress?:  {
    __typename: "QuestionsDailyAnswersForActivityAndStress",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteQuestionsDailyAnswersForActivityAndStressMutationVariables = {
  input: DeleteQuestionsDailyAnswersForActivityAndStressInput,
  condition?: ModelQuestionsDailyAnswersForActivityAndStressConditionInput | null,
};

export type DeleteQuestionsDailyAnswersForActivityAndStressMutation = {
  deleteQuestionsDailyAnswersForActivityAndStress?:  {
    __typename: "QuestionsDailyAnswersForActivityAndStress",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateQuestionsDailyAnswersForDailyLifeMutationVariables = {
  input: CreateQuestionsDailyAnswersForDailyLifeInput,
  condition?: ModelQuestionsDailyAnswersForDailyLifeConditionInput | null,
};

export type CreateQuestionsDailyAnswersForDailyLifeMutation = {
  createQuestionsDailyAnswersForDailyLife?:  {
    __typename: "QuestionsDailyAnswersForDailyLife",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateQuestionsDailyAnswersForDailyLifeMutationVariables = {
  input: UpdateQuestionsDailyAnswersForDailyLifeInput,
  condition?: ModelQuestionsDailyAnswersForDailyLifeConditionInput | null,
};

export type UpdateQuestionsDailyAnswersForDailyLifeMutation = {
  updateQuestionsDailyAnswersForDailyLife?:  {
    __typename: "QuestionsDailyAnswersForDailyLife",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteQuestionsDailyAnswersForDailyLifeMutationVariables = {
  input: DeleteQuestionsDailyAnswersForDailyLifeInput,
  condition?: ModelQuestionsDailyAnswersForDailyLifeConditionInput | null,
};

export type DeleteQuestionsDailyAnswersForDailyLifeMutation = {
  deleteQuestionsDailyAnswersForDailyLife?:  {
    __typename: "QuestionsDailyAnswersForDailyLife",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateQuestionsDailyAnswersForConsultingToDoctorMutationVariables = {
  input: CreateQuestionsDailyAnswersForConsultingToDoctorInput,
  condition?: ModelQuestionsDailyAnswersForConsultingToDoctorConditionInput | null,
};

export type CreateQuestionsDailyAnswersForConsultingToDoctorMutation = {
  createQuestionsDailyAnswersForConsultingToDoctor?:  {
    __typename: "QuestionsDailyAnswersForConsultingToDoctor",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateQuestionsDailyAnswersForConsultingToDoctorMutationVariables = {
  input: UpdateQuestionsDailyAnswersForConsultingToDoctorInput,
  condition?: ModelQuestionsDailyAnswersForConsultingToDoctorConditionInput | null,
};

export type UpdateQuestionsDailyAnswersForConsultingToDoctorMutation = {
  updateQuestionsDailyAnswersForConsultingToDoctor?:  {
    __typename: "QuestionsDailyAnswersForConsultingToDoctor",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteQuestionsDailyAnswersForConsultingToDoctorMutationVariables = {
  input: DeleteQuestionsDailyAnswersForConsultingToDoctorInput,
  condition?: ModelQuestionsDailyAnswersForConsultingToDoctorConditionInput | null,
};

export type DeleteQuestionsDailyAnswersForConsultingToDoctorMutation = {
  deleteQuestionsDailyAnswersForConsultingToDoctor?:  {
    __typename: "QuestionsDailyAnswersForConsultingToDoctor",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDailyAnswersMutationVariables = {
  input: CreateDailyAnswersInput,
  condition?: ModelDailyAnswersConditionInput | null,
};

export type CreateDailyAnswersMutation = {
  createDailyAnswers?:  {
    __typename: "DailyAnswers",
    id: string,
    firstQuestion?: boolean | null,
    secondQuestion?: boolean | null,
    thirdQuestion?: boolean | null,
    fourthQuestion?: boolean | null,
    fifthQuestion?: boolean | null,
    sixthQuestion?: boolean | null,
    seventhQuestion?: boolean | null,
    eighthQuestion?: boolean | null,
    ninthQuestion?: boolean | null,
    tenthQuestion?: boolean | null,
    memo?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    patientId?: string | null,
    clinicId?: string | null,
    type?: DailyAnswersTypeEnum | null,
    categoryDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDailyAnswersMutationVariables = {
  input: UpdateDailyAnswersInput,
  condition?: ModelDailyAnswersConditionInput | null,
};

export type UpdateDailyAnswersMutation = {
  updateDailyAnswers?:  {
    __typename: "DailyAnswers",
    id: string,
    firstQuestion?: boolean | null,
    secondQuestion?: boolean | null,
    thirdQuestion?: boolean | null,
    fourthQuestion?: boolean | null,
    fifthQuestion?: boolean | null,
    sixthQuestion?: boolean | null,
    seventhQuestion?: boolean | null,
    eighthQuestion?: boolean | null,
    ninthQuestion?: boolean | null,
    tenthQuestion?: boolean | null,
    memo?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    patientId?: string | null,
    clinicId?: string | null,
    type?: DailyAnswersTypeEnum | null,
    categoryDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDailyAnswersMutationVariables = {
  input: DeleteDailyAnswersInput,
  condition?: ModelDailyAnswersConditionInput | null,
};

export type DeleteDailyAnswersMutation = {
  deleteDailyAnswers?:  {
    __typename: "DailyAnswers",
    id: string,
    firstQuestion?: boolean | null,
    secondQuestion?: boolean | null,
    thirdQuestion?: boolean | null,
    fourthQuestion?: boolean | null,
    fifthQuestion?: boolean | null,
    sixthQuestion?: boolean | null,
    seventhQuestion?: boolean | null,
    eighthQuestion?: boolean | null,
    ninthQuestion?: boolean | null,
    tenthQuestion?: boolean | null,
    memo?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    patientId?: string | null,
    clinicId?: string | null,
    type?: DailyAnswersTypeEnum | null,
    categoryDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAdminsMutationVariables = {
  input: CreateAdminsInput,
  condition?: ModelAdminsConditionInput | null,
};

export type CreateAdminsMutation = {
  createAdmins?:  {
    __typename: "Admins",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    adminEmail?:  {
      __typename: "AdminEmails",
      id: string,
      email?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    adminId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    adminsAdminEmailId?: string | null,
  } | null,
};

export type UpdateAdminsMutationVariables = {
  input: UpdateAdminsInput,
  condition?: ModelAdminsConditionInput | null,
};

export type UpdateAdminsMutation = {
  updateAdmins?:  {
    __typename: "Admins",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    adminEmail?:  {
      __typename: "AdminEmails",
      id: string,
      email?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    adminId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    adminsAdminEmailId?: string | null,
  } | null,
};

export type DeleteAdminsMutationVariables = {
  input: DeleteAdminsInput,
  condition?: ModelAdminsConditionInput | null,
};

export type DeleteAdminsMutation = {
  deleteAdmins?:  {
    __typename: "Admins",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    adminEmail?:  {
      __typename: "AdminEmails",
      id: string,
      email?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    adminId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    adminsAdminEmailId?: string | null,
  } | null,
};

export type CreateAdminEmailsMutationVariables = {
  input: CreateAdminEmailsInput,
  condition?: ModelAdminEmailsConditionInput | null,
};

export type CreateAdminEmailsMutation = {
  createAdminEmails?:  {
    __typename: "AdminEmails",
    id: string,
    email?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAdminEmailsMutationVariables = {
  input: UpdateAdminEmailsInput,
  condition?: ModelAdminEmailsConditionInput | null,
};

export type UpdateAdminEmailsMutation = {
  updateAdminEmails?:  {
    __typename: "AdminEmails",
    id: string,
    email?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAdminEmailsMutationVariables = {
  input: DeleteAdminEmailsInput,
  condition?: ModelAdminEmailsConditionInput | null,
};

export type DeleteAdminEmailsMutation = {
  deleteAdminEmails?:  {
    __typename: "AdminEmails",
    id: string,
    email?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMembersMutationVariables = {
  input: CreateMembersInput,
  condition?: ModelMembersConditionInput | null,
};

export type CreateMembersMutation = {
  createMembers?:  {
    __typename: "Members",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    role?:  {
      __typename: "Roles",
      id: string,
      email?: string | null,
      uid?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      type?: RoleTypeEnum | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      rolesClinicId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    Clinics?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    membersRoleId?: string | null,
    membersClinicsId?: string | null,
  } | null,
};

export type UpdateMembersMutationVariables = {
  input: UpdateMembersInput,
  condition?: ModelMembersConditionInput | null,
};

export type UpdateMembersMutation = {
  updateMembers?:  {
    __typename: "Members",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    role?:  {
      __typename: "Roles",
      id: string,
      email?: string | null,
      uid?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      type?: RoleTypeEnum | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      rolesClinicId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    Clinics?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    membersRoleId?: string | null,
    membersClinicsId?: string | null,
  } | null,
};

export type DeleteMembersMutationVariables = {
  input: DeleteMembersInput,
  condition?: ModelMembersConditionInput | null,
};

export type DeleteMembersMutation = {
  deleteMembers?:  {
    __typename: "Members",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    role?:  {
      __typename: "Roles",
      id: string,
      email?: string | null,
      uid?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      type?: RoleTypeEnum | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      rolesClinicId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    Clinics?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    membersRoleId?: string | null,
    membersClinicsId?: string | null,
  } | null,
};

export type CreatePatientsMutationVariables = {
  input: CreatePatientsInput,
  condition?: ModelPatientsConditionInput | null,
};

export type CreatePatientsMutation = {
  createPatients?:  {
    __typename: "Patients",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    memo?: string | null,
    memoForDoctor?: string | null,
    gender?: string | null,
    birthday?: string | null,
    medicalHistory?: string | null,
    prescribedMedicine?: string | null,
    chargedDoctorId?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    appointmentDate?: string | null,
    firstScheduledMeasurementDate?: string | null,
    secondScheduledMeasurementDate?: string | null,
    thirdScheduledMeasurementDate?: string | null,
    firstVisitDate?: string | null,
    prefecture?: string | null,
    startOfUse?: string | null,
    endDate?: string | null,
    status?: StatusEnum | null,
    activeDate?: string | null,
    inactiveDate?: string | null,
    deviceToken?: string | null,
    initialAnswerForFeeling?:  {
      __typename: "InitialAnswerForFeeling",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      question9?: number | null,
      question10?: number | null,
      question11?: number | null,
      question12?: number | null,
      question13?: number | null,
      question14?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    initialAnswerForSleep?:  {
      __typename: "InitialAnswerForSleep",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    patientsClinicId?: string | null,
    patientsInitialAnswerForFeelingId?: string | null,
    patientsInitialAnswerForSleepId?: string | null,
  } | null,
};

export type UpdatePatientsMutationVariables = {
  input: UpdatePatientsInput,
  condition?: ModelPatientsConditionInput | null,
};

export type UpdatePatientsMutation = {
  updatePatients?:  {
    __typename: "Patients",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    memo?: string | null,
    memoForDoctor?: string | null,
    gender?: string | null,
    birthday?: string | null,
    medicalHistory?: string | null,
    prescribedMedicine?: string | null,
    chargedDoctorId?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    appointmentDate?: string | null,
    firstScheduledMeasurementDate?: string | null,
    secondScheduledMeasurementDate?: string | null,
    thirdScheduledMeasurementDate?: string | null,
    firstVisitDate?: string | null,
    prefecture?: string | null,
    startOfUse?: string | null,
    endDate?: string | null,
    status?: StatusEnum | null,
    activeDate?: string | null,
    inactiveDate?: string | null,
    deviceToken?: string | null,
    initialAnswerForFeeling?:  {
      __typename: "InitialAnswerForFeeling",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      question9?: number | null,
      question10?: number | null,
      question11?: number | null,
      question12?: number | null,
      question13?: number | null,
      question14?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    initialAnswerForSleep?:  {
      __typename: "InitialAnswerForSleep",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    patientsClinicId?: string | null,
    patientsInitialAnswerForFeelingId?: string | null,
    patientsInitialAnswerForSleepId?: string | null,
  } | null,
};

export type DeletePatientsMutationVariables = {
  input: DeletePatientsInput,
  condition?: ModelPatientsConditionInput | null,
};

export type DeletePatientsMutation = {
  deletePatients?:  {
    __typename: "Patients",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    memo?: string | null,
    memoForDoctor?: string | null,
    gender?: string | null,
    birthday?: string | null,
    medicalHistory?: string | null,
    prescribedMedicine?: string | null,
    chargedDoctorId?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    appointmentDate?: string | null,
    firstScheduledMeasurementDate?: string | null,
    secondScheduledMeasurementDate?: string | null,
    thirdScheduledMeasurementDate?: string | null,
    firstVisitDate?: string | null,
    prefecture?: string | null,
    startOfUse?: string | null,
    endDate?: string | null,
    status?: StatusEnum | null,
    activeDate?: string | null,
    inactiveDate?: string | null,
    deviceToken?: string | null,
    initialAnswerForFeeling?:  {
      __typename: "InitialAnswerForFeeling",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      question9?: number | null,
      question10?: number | null,
      question11?: number | null,
      question12?: number | null,
      question13?: number | null,
      question14?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    initialAnswerForSleep?:  {
      __typename: "InitialAnswerForSleep",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    patientsClinicId?: string | null,
    patientsInitialAnswerForFeelingId?: string | null,
    patientsInitialAnswerForSleepId?: string | null,
  } | null,
};

export type CreateClinicsMutationVariables = {
  input: CreateClinicsInput,
  condition?: ModelClinicsConditionInput | null,
};

export type CreateClinicsMutation = {
  createClinics?:  {
    __typename: "Clinics",
    id: string,
    clinicName?: string | null,
    address?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    memo?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    representativeDoctorId?: string | null,
    status?: StatusEnum | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateClinicsMutationVariables = {
  input: UpdateClinicsInput,
  condition?: ModelClinicsConditionInput | null,
};

export type UpdateClinicsMutation = {
  updateClinics?:  {
    __typename: "Clinics",
    id: string,
    clinicName?: string | null,
    address?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    memo?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    representativeDoctorId?: string | null,
    status?: StatusEnum | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteClinicsMutationVariables = {
  input: DeleteClinicsInput,
  condition?: ModelClinicsConditionInput | null,
};

export type DeleteClinicsMutation = {
  deleteClinics?:  {
    __typename: "Clinics",
    id: string,
    clinicName?: string | null,
    address?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    memo?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    representativeDoctorId?: string | null,
    status?: StatusEnum | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRolesMutationVariables = {
  input: CreateRolesInput,
  condition?: ModelRolesConditionInput | null,
};

export type CreateRolesMutation = {
  createRoles?:  {
    __typename: "Roles",
    id: string,
    email?: string | null,
    uid?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type?: RoleTypeEnum | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    rolesClinicId?: string | null,
  } | null,
};

export type UpdateRolesMutationVariables = {
  input: UpdateRolesInput,
  condition?: ModelRolesConditionInput | null,
};

export type UpdateRolesMutation = {
  updateRoles?:  {
    __typename: "Roles",
    id: string,
    email?: string | null,
    uid?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type?: RoleTypeEnum | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    rolesClinicId?: string | null,
  } | null,
};

export type DeleteRolesMutationVariables = {
  input: DeleteRolesInput,
  condition?: ModelRolesConditionInput | null,
};

export type DeleteRolesMutation = {
  deleteRoles?:  {
    __typename: "Roles",
    id: string,
    email?: string | null,
    uid?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type?: RoleTypeEnum | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    rolesClinicId?: string | null,
  } | null,
};

export type CreateInitialAnswerForSleepMutationVariables = {
  input: CreateInitialAnswerForSleepInput,
  condition?: ModelInitialAnswerForSleepConditionInput | null,
};

export type CreateInitialAnswerForSleepMutation = {
  createInitialAnswerForSleep?:  {
    __typename: "InitialAnswerForSleep",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInitialAnswerForSleepMutationVariables = {
  input: UpdateInitialAnswerForSleepInput,
  condition?: ModelInitialAnswerForSleepConditionInput | null,
};

export type UpdateInitialAnswerForSleepMutation = {
  updateInitialAnswerForSleep?:  {
    __typename: "InitialAnswerForSleep",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInitialAnswerForSleepMutationVariables = {
  input: DeleteInitialAnswerForSleepInput,
  condition?: ModelInitialAnswerForSleepConditionInput | null,
};

export type DeleteInitialAnswerForSleepMutation = {
  deleteInitialAnswerForSleep?:  {
    __typename: "InitialAnswerForSleep",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInitialAnswerForFeelingMutationVariables = {
  input: CreateInitialAnswerForFeelingInput,
  condition?: ModelInitialAnswerForFeelingConditionInput | null,
};

export type CreateInitialAnswerForFeelingMutation = {
  createInitialAnswerForFeeling?:  {
    __typename: "InitialAnswerForFeeling",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    question9?: number | null,
    question10?: number | null,
    question11?: number | null,
    question12?: number | null,
    question13?: number | null,
    question14?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInitialAnswerForFeelingMutationVariables = {
  input: UpdateInitialAnswerForFeelingInput,
  condition?: ModelInitialAnswerForFeelingConditionInput | null,
};

export type UpdateInitialAnswerForFeelingMutation = {
  updateInitialAnswerForFeeling?:  {
    __typename: "InitialAnswerForFeeling",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    question9?: number | null,
    question10?: number | null,
    question11?: number | null,
    question12?: number | null,
    question13?: number | null,
    question14?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInitialAnswerForFeelingMutationVariables = {
  input: DeleteInitialAnswerForFeelingInput,
  condition?: ModelInitialAnswerForFeelingConditionInput | null,
};

export type DeleteInitialAnswerForFeelingMutation = {
  deleteInitialAnswerForFeeling?:  {
    __typename: "InitialAnswerForFeeling",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    question9?: number | null,
    question10?: number | null,
    question11?: number | null,
    question12?: number | null,
    question13?: number | null,
    question14?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDailyAnswerForSleepMutationVariables = {
  input: CreateDailyAnswerForSleepInput,
  condition?: ModelDailyAnswerForSleepConditionInput | null,
};

export type CreateDailyAnswerForSleepMutation = {
  createDailyAnswerForSleep?:  {
    __typename: "DailyAnswerForSleep",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDailyAnswerForSleepMutationVariables = {
  input: UpdateDailyAnswerForSleepInput,
  condition?: ModelDailyAnswerForSleepConditionInput | null,
};

export type UpdateDailyAnswerForSleepMutation = {
  updateDailyAnswerForSleep?:  {
    __typename: "DailyAnswerForSleep",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDailyAnswerForSleepMutationVariables = {
  input: DeleteDailyAnswerForSleepInput,
  condition?: ModelDailyAnswerForSleepConditionInput | null,
};

export type DeleteDailyAnswerForSleepMutation = {
  deleteDailyAnswerForSleep?:  {
    __typename: "DailyAnswerForSleep",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDailyAnswerForFeelingMutationVariables = {
  input: CreateDailyAnswerForFeelingInput,
  condition?: ModelDailyAnswerForFeelingConditionInput | null,
};

export type CreateDailyAnswerForFeelingMutation = {
  createDailyAnswerForFeeling?:  {
    __typename: "DailyAnswerForFeeling",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDailyAnswerForFeelingMutationVariables = {
  input: UpdateDailyAnswerForFeelingInput,
  condition?: ModelDailyAnswerForFeelingConditionInput | null,
};

export type UpdateDailyAnswerForFeelingMutation = {
  updateDailyAnswerForFeeling?:  {
    __typename: "DailyAnswerForFeeling",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDailyAnswerForFeelingMutationVariables = {
  input: DeleteDailyAnswerForFeelingInput,
  condition?: ModelDailyAnswerForFeelingConditionInput | null,
};

export type DeleteDailyAnswerForFeelingMutation = {
  deleteDailyAnswerForFeeling?:  {
    __typename: "DailyAnswerForFeeling",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAnswerForConditionMutationVariables = {
  input: CreateAnswerForConditionInput,
  condition?: ModelAnswerForConditionConditionInput | null,
};

export type CreateAnswerForConditionMutation = {
  createAnswerForCondition?:  {
    __typename: "AnswerForCondition",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAnswerForConditionMutationVariables = {
  input: UpdateAnswerForConditionInput,
  condition?: ModelAnswerForConditionConditionInput | null,
};

export type UpdateAnswerForConditionMutation = {
  updateAnswerForCondition?:  {
    __typename: "AnswerForCondition",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAnswerForConditionMutationVariables = {
  input: DeleteAnswerForConditionInput,
  condition?: ModelAnswerForConditionConditionInput | null,
};

export type DeleteAnswerForConditionMutation = {
  deleteAnswerForCondition?:  {
    __typename: "AnswerForCondition",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAnswersForActivityAndStressMutationVariables = {
  input: CreateAnswersForActivityAndStressInput,
  condition?: ModelAnswersForActivityAndStressConditionInput | null,
};

export type CreateAnswersForActivityAndStressMutation = {
  createAnswersForActivityAndStress?:  {
    __typename: "AnswersForActivityAndStress",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAnswersForActivityAndStressMutationVariables = {
  input: UpdateAnswersForActivityAndStressInput,
  condition?: ModelAnswersForActivityAndStressConditionInput | null,
};

export type UpdateAnswersForActivityAndStressMutation = {
  updateAnswersForActivityAndStress?:  {
    __typename: "AnswersForActivityAndStress",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAnswersForActivityAndStressMutationVariables = {
  input: DeleteAnswersForActivityAndStressInput,
  condition?: ModelAnswersForActivityAndStressConditionInput | null,
};

export type DeleteAnswersForActivityAndStressMutation = {
  deleteAnswersForActivityAndStress?:  {
    __typename: "AnswersForActivityAndStress",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAnswersForDailyLifeMutationVariables = {
  input: CreateAnswersForDailyLifeInput,
  condition?: ModelAnswersForDailyLifeConditionInput | null,
};

export type CreateAnswersForDailyLifeMutation = {
  createAnswersForDailyLife?:  {
    __typename: "AnswersForDailyLife",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAnswersForDailyLifeMutationVariables = {
  input: UpdateAnswersForDailyLifeInput,
  condition?: ModelAnswersForDailyLifeConditionInput | null,
};

export type UpdateAnswersForDailyLifeMutation = {
  updateAnswersForDailyLife?:  {
    __typename: "AnswersForDailyLife",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAnswersForDailyLifeMutationVariables = {
  input: DeleteAnswersForDailyLifeInput,
  condition?: ModelAnswersForDailyLifeConditionInput | null,
};

export type DeleteAnswersForDailyLifeMutation = {
  deleteAnswersForDailyLife?:  {
    __typename: "AnswersForDailyLife",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAnswersForConsultingToDoctorMutationVariables = {
  input: CreateAnswersForConsultingToDoctorInput,
  condition?: ModelAnswersForConsultingToDoctorConditionInput | null,
};

export type CreateAnswersForConsultingToDoctorMutation = {
  createAnswersForConsultingToDoctor?:  {
    __typename: "AnswersForConsultingToDoctor",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAnswersForConsultingToDoctorMutationVariables = {
  input: UpdateAnswersForConsultingToDoctorInput,
  condition?: ModelAnswersForConsultingToDoctorConditionInput | null,
};

export type UpdateAnswersForConsultingToDoctorMutation = {
  updateAnswersForConsultingToDoctor?:  {
    __typename: "AnswersForConsultingToDoctor",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAnswersForConsultingToDoctorMutationVariables = {
  input: DeleteAnswersForConsultingToDoctorInput,
  condition?: ModelAnswersForConsultingToDoctorConditionInput | null,
};

export type DeleteAnswersForConsultingToDoctorMutation = {
  deleteAnswersForConsultingToDoctor?:  {
    __typename: "AnswersForConsultingToDoctor",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAccessLogsMutationVariables = {
  input: CreateAccessLogsInput,
  condition?: ModelAccessLogsConditionInput | null,
};

export type CreateAccessLogsMutation = {
  createAccessLogs?:  {
    __typename: "AccessLogs",
    id: string,
    patientId?: string | null,
    type?: AccessLogsTypeEnum | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAccessLogsMutationVariables = {
  input: UpdateAccessLogsInput,
  condition?: ModelAccessLogsConditionInput | null,
};

export type UpdateAccessLogsMutation = {
  updateAccessLogs?:  {
    __typename: "AccessLogs",
    id: string,
    patientId?: string | null,
    type?: AccessLogsTypeEnum | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAccessLogsMutationVariables = {
  input: DeleteAccessLogsInput,
  condition?: ModelAccessLogsConditionInput | null,
};

export type DeleteAccessLogsMutation = {
  deleteAccessLogs?:  {
    __typename: "AccessLogs",
    id: string,
    patientId?: string | null,
    type?: AccessLogsTypeEnum | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWordsOfEncouragementMutationVariables = {
  input: CreateWordsOfEncouragementInput,
  condition?: ModelWordsOfEncouragementConditionInput | null,
};

export type CreateWordsOfEncouragementMutation = {
  createWordsOfEncouragement?:  {
    __typename: "WordsOfEncouragement",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWordsOfEncouragementMutationVariables = {
  input: UpdateWordsOfEncouragementInput,
  condition?: ModelWordsOfEncouragementConditionInput | null,
};

export type UpdateWordsOfEncouragementMutation = {
  updateWordsOfEncouragement?:  {
    __typename: "WordsOfEncouragement",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWordsOfEncouragementMutationVariables = {
  input: DeleteWordsOfEncouragementInput,
  condition?: ModelWordsOfEncouragementConditionInput | null,
};

export type DeleteWordsOfEncouragementMutation = {
  deleteWordsOfEncouragement?:  {
    __typename: "WordsOfEncouragement",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateQuestionsMutationVariables = {
  input: CreateQuestionsInput,
  condition?: ModelQuestionsConditionInput | null,
};

export type CreateQuestionsMutation = {
  createQuestions?:  {
    __typename: "Questions",
    id: string,
    questionsDailyAnswersForSleep?:  {
      __typename: "QuestionsDailyAnswersForSleep",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionsDailyAnswersForFeeling?:  {
      __typename: "QuestionsDailyAnswersForFeeling",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForCondition?:  {
      __typename: "QuestionsDailyAnswersForCondition",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForActivityAndStress?:  {
      __typename: "QuestionsDailyAnswersForActivityAndStress",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForDailyLife?:  {
      __typename: "QuestionsDailyAnswersForDailyLife",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForConsultingToDoctor?:  {
      __typename: "QuestionsDailyAnswersForConsultingToDoctor",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    questionsQuestionsDailyAnswersForSleepId?: string | null,
    questionsQuestionsDailyAnswersForFeelingId?: string | null,
    questionsQuestionsDailyAnswersForConditionId?: string | null,
    questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
    questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
    questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
  } | null,
};

export type UpdateQuestionsMutationVariables = {
  input: UpdateQuestionsInput,
  condition?: ModelQuestionsConditionInput | null,
};

export type UpdateQuestionsMutation = {
  updateQuestions?:  {
    __typename: "Questions",
    id: string,
    questionsDailyAnswersForSleep?:  {
      __typename: "QuestionsDailyAnswersForSleep",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionsDailyAnswersForFeeling?:  {
      __typename: "QuestionsDailyAnswersForFeeling",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForCondition?:  {
      __typename: "QuestionsDailyAnswersForCondition",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForActivityAndStress?:  {
      __typename: "QuestionsDailyAnswersForActivityAndStress",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForDailyLife?:  {
      __typename: "QuestionsDailyAnswersForDailyLife",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForConsultingToDoctor?:  {
      __typename: "QuestionsDailyAnswersForConsultingToDoctor",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    questionsQuestionsDailyAnswersForSleepId?: string | null,
    questionsQuestionsDailyAnswersForFeelingId?: string | null,
    questionsQuestionsDailyAnswersForConditionId?: string | null,
    questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
    questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
    questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
  } | null,
};

export type DeleteQuestionsMutationVariables = {
  input: DeleteQuestionsInput,
  condition?: ModelQuestionsConditionInput | null,
};

export type DeleteQuestionsMutation = {
  deleteQuestions?:  {
    __typename: "Questions",
    id: string,
    questionsDailyAnswersForSleep?:  {
      __typename: "QuestionsDailyAnswersForSleep",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionsDailyAnswersForFeeling?:  {
      __typename: "QuestionsDailyAnswersForFeeling",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForCondition?:  {
      __typename: "QuestionsDailyAnswersForCondition",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForActivityAndStress?:  {
      __typename: "QuestionsDailyAnswersForActivityAndStress",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForDailyLife?:  {
      __typename: "QuestionsDailyAnswersForDailyLife",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForConsultingToDoctor?:  {
      __typename: "QuestionsDailyAnswersForConsultingToDoctor",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    questionsQuestionsDailyAnswersForSleepId?: string | null,
    questionsQuestionsDailyAnswersForFeelingId?: string | null,
    questionsQuestionsDailyAnswersForConditionId?: string | null,
    questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
    questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
    questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
  } | null,
};

export type CreateAnswersListMutationVariables = {
  input: CreateAnswersListInput,
  condition?: ModelAnswersListConditionInput | null,
};

export type CreateAnswersListMutation = {
  createAnswersList?:  {
    __typename: "AnswersList",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAnswersListMutationVariables = {
  input: UpdateAnswersListInput,
  condition?: ModelAnswersListConditionInput | null,
};

export type UpdateAnswersListMutation = {
  updateAnswersList?:  {
    __typename: "AnswersList",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAnswersListMutationVariables = {
  input: DeleteAnswersListInput,
  condition?: ModelAnswersListConditionInput | null,
};

export type DeleteAnswersListMutation = {
  deleteAnswersList?:  {
    __typename: "AnswersList",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetQuestionsDailyAnswersForSleepQueryVariables = {
  id: string,
};

export type GetQuestionsDailyAnswersForSleepQuery = {
  getQuestionsDailyAnswersForSleep?:  {
    __typename: "QuestionsDailyAnswersForSleep",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListQuestionsDailyAnswersForSleepsQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForSleepFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsDailyAnswersForSleepsQuery = {
  listQuestionsDailyAnswersForSleeps?:  {
    __typename: "ModelQuestionsDailyAnswersForSleepConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForSleep",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuestionsDailyAnswersForSleepsQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForSleepFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuestionsDailyAnswersForSleepsQuery = {
  syncQuestionsDailyAnswersForSleeps?:  {
    __typename: "ModelQuestionsDailyAnswersForSleepConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForSleep",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetQuestionsDailyAnswersForFeelingQueryVariables = {
  id: string,
};

export type GetQuestionsDailyAnswersForFeelingQuery = {
  getQuestionsDailyAnswersForFeeling?:  {
    __typename: "QuestionsDailyAnswersForFeeling",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListQuestionsDailyAnswersForFeelingsQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForFeelingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsDailyAnswersForFeelingsQuery = {
  listQuestionsDailyAnswersForFeelings?:  {
    __typename: "ModelQuestionsDailyAnswersForFeelingConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForFeeling",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuestionsDailyAnswersForFeelingsQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForFeelingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuestionsDailyAnswersForFeelingsQuery = {
  syncQuestionsDailyAnswersForFeelings?:  {
    __typename: "ModelQuestionsDailyAnswersForFeelingConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForFeeling",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetQuestionsDailyAnswersForConditionQueryVariables = {
  id: string,
};

export type GetQuestionsDailyAnswersForConditionQuery = {
  getQuestionsDailyAnswersForCondition?:  {
    __typename: "QuestionsDailyAnswersForCondition",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListQuestionsDailyAnswersForConditionsQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForConditionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsDailyAnswersForConditionsQuery = {
  listQuestionsDailyAnswersForConditions?:  {
    __typename: "ModelQuestionsDailyAnswersForConditionConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForCondition",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuestionsDailyAnswersForConditionsQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForConditionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuestionsDailyAnswersForConditionsQuery = {
  syncQuestionsDailyAnswersForConditions?:  {
    __typename: "ModelQuestionsDailyAnswersForConditionConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForCondition",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetQuestionsDailyAnswersForActivityAndStressQueryVariables = {
  id: string,
};

export type GetQuestionsDailyAnswersForActivityAndStressQuery = {
  getQuestionsDailyAnswersForActivityAndStress?:  {
    __typename: "QuestionsDailyAnswersForActivityAndStress",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListQuestionsDailyAnswersForActivityAndStressesQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForActivityAndStressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsDailyAnswersForActivityAndStressesQuery = {
  listQuestionsDailyAnswersForActivityAndStresses?:  {
    __typename: "ModelQuestionsDailyAnswersForActivityAndStressConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForActivityAndStress",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuestionsDailyAnswersForActivityAndStressesQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForActivityAndStressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuestionsDailyAnswersForActivityAndStressesQuery = {
  syncQuestionsDailyAnswersForActivityAndStresses?:  {
    __typename: "ModelQuestionsDailyAnswersForActivityAndStressConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForActivityAndStress",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetQuestionsDailyAnswersForDailyLifeQueryVariables = {
  id: string,
};

export type GetQuestionsDailyAnswersForDailyLifeQuery = {
  getQuestionsDailyAnswersForDailyLife?:  {
    __typename: "QuestionsDailyAnswersForDailyLife",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListQuestionsDailyAnswersForDailyLivesQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForDailyLifeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsDailyAnswersForDailyLivesQuery = {
  listQuestionsDailyAnswersForDailyLives?:  {
    __typename: "ModelQuestionsDailyAnswersForDailyLifeConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForDailyLife",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuestionsDailyAnswersForDailyLivesQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForDailyLifeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuestionsDailyAnswersForDailyLivesQuery = {
  syncQuestionsDailyAnswersForDailyLives?:  {
    __typename: "ModelQuestionsDailyAnswersForDailyLifeConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForDailyLife",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetQuestionsDailyAnswersForConsultingToDoctorQueryVariables = {
  id: string,
};

export type GetQuestionsDailyAnswersForConsultingToDoctorQuery = {
  getQuestionsDailyAnswersForConsultingToDoctor?:  {
    __typename: "QuestionsDailyAnswersForConsultingToDoctor",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListQuestionsDailyAnswersForConsultingToDoctorsQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForConsultingToDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsDailyAnswersForConsultingToDoctorsQuery = {
  listQuestionsDailyAnswersForConsultingToDoctors?:  {
    __typename: "ModelQuestionsDailyAnswersForConsultingToDoctorConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForConsultingToDoctor",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuestionsDailyAnswersForConsultingToDoctorsQueryVariables = {
  filter?: ModelQuestionsDailyAnswersForConsultingToDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuestionsDailyAnswersForConsultingToDoctorsQuery = {
  syncQuestionsDailyAnswersForConsultingToDoctors?:  {
    __typename: "ModelQuestionsDailyAnswersForConsultingToDoctorConnection",
    items:  Array< {
      __typename: "QuestionsDailyAnswersForConsultingToDoctor",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDailyAnswersQueryVariables = {
  id: string,
};

export type GetDailyAnswersQuery = {
  getDailyAnswers?:  {
    __typename: "DailyAnswers",
    id: string,
    firstQuestion?: boolean | null,
    secondQuestion?: boolean | null,
    thirdQuestion?: boolean | null,
    fourthQuestion?: boolean | null,
    fifthQuestion?: boolean | null,
    sixthQuestion?: boolean | null,
    seventhQuestion?: boolean | null,
    eighthQuestion?: boolean | null,
    ninthQuestion?: boolean | null,
    tenthQuestion?: boolean | null,
    memo?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    patientId?: string | null,
    clinicId?: string | null,
    type?: DailyAnswersTypeEnum | null,
    categoryDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDailyAnswersQueryVariables = {
  filter?: ModelDailyAnswersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDailyAnswersQuery = {
  listDailyAnswers?:  {
    __typename: "ModelDailyAnswersConnection",
    items:  Array< {
      __typename: "DailyAnswers",
      id: string,
      firstQuestion?: boolean | null,
      secondQuestion?: boolean | null,
      thirdQuestion?: boolean | null,
      fourthQuestion?: boolean | null,
      fifthQuestion?: boolean | null,
      sixthQuestion?: boolean | null,
      seventhQuestion?: boolean | null,
      eighthQuestion?: boolean | null,
      ninthQuestion?: boolean | null,
      tenthQuestion?: boolean | null,
      memo?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      patientId?: string | null,
      clinicId?: string | null,
      type?: DailyAnswersTypeEnum | null,
      categoryDate?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDailyAnswersQueryVariables = {
  filter?: ModelDailyAnswersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDailyAnswersQuery = {
  syncDailyAnswers?:  {
    __typename: "ModelDailyAnswersConnection",
    items:  Array< {
      __typename: "DailyAnswers",
      id: string,
      firstQuestion?: boolean | null,
      secondQuestion?: boolean | null,
      thirdQuestion?: boolean | null,
      fourthQuestion?: boolean | null,
      fifthQuestion?: boolean | null,
      sixthQuestion?: boolean | null,
      seventhQuestion?: boolean | null,
      eighthQuestion?: boolean | null,
      ninthQuestion?: boolean | null,
      tenthQuestion?: boolean | null,
      memo?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      patientId?: string | null,
      clinicId?: string | null,
      type?: DailyAnswersTypeEnum | null,
      categoryDate?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdminsQueryVariables = {
  id: string,
};

export type GetAdminsQuery = {
  getAdmins?:  {
    __typename: "Admins",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    adminEmail?:  {
      __typename: "AdminEmails",
      id: string,
      email?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    adminId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    adminsAdminEmailId?: string | null,
  } | null,
};

export type ListAdminsQueryVariables = {
  filter?: ModelAdminsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminsQuery = {
  listAdmins?:  {
    __typename: "ModelAdminsConnection",
    items:  Array< {
      __typename: "Admins",
      id: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      adminId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      adminsAdminEmailId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdminsQueryVariables = {
  filter?: ModelAdminsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdminsQuery = {
  syncAdmins?:  {
    __typename: "ModelAdminsConnection",
    items:  Array< {
      __typename: "Admins",
      id: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      adminId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      adminsAdminEmailId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdminEmailsQueryVariables = {
  id: string,
};

export type GetAdminEmailsQuery = {
  getAdminEmails?:  {
    __typename: "AdminEmails",
    id: string,
    email?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAdminEmailsQueryVariables = {
  filter?: ModelAdminEmailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminEmailsQuery = {
  listAdminEmails?:  {
    __typename: "ModelAdminEmailsConnection",
    items:  Array< {
      __typename: "AdminEmails",
      id: string,
      email?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdminEmailsQueryVariables = {
  filter?: ModelAdminEmailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdminEmailsQuery = {
  syncAdminEmails?:  {
    __typename: "ModelAdminEmailsConnection",
    items:  Array< {
      __typename: "AdminEmails",
      id: string,
      email?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMembersQueryVariables = {
  id: string,
};

export type GetMembersQuery = {
  getMembers?:  {
    __typename: "Members",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    role?:  {
      __typename: "Roles",
      id: string,
      email?: string | null,
      uid?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      type?: RoleTypeEnum | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      rolesClinicId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    Clinics?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    membersRoleId?: string | null,
    membersClinicsId?: string | null,
  } | null,
};

export type ListMembersQueryVariables = {
  filter?: ModelMembersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMembersQuery = {
  listMembers?:  {
    __typename: "ModelMembersConnection",
    items:  Array< {
      __typename: "Members",
      id: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      email?: string | null,
      name?: string | null,
      finishedInitRegister?: boolean | null,
      uid?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      membersRoleId?: string | null,
      membersClinicsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMembersQueryVariables = {
  filter?: ModelMembersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMembersQuery = {
  syncMembers?:  {
    __typename: "ModelMembersConnection",
    items:  Array< {
      __typename: "Members",
      id: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      email?: string | null,
      name?: string | null,
      finishedInitRegister?: boolean | null,
      uid?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      membersRoleId?: string | null,
      membersClinicsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPatientsQueryVariables = {
  id: string,
};

export type GetPatientsQuery = {
  getPatients?:  {
    __typename: "Patients",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    memo?: string | null,
    memoForDoctor?: string | null,
    gender?: string | null,
    birthday?: string | null,
    medicalHistory?: string | null,
    prescribedMedicine?: string | null,
    chargedDoctorId?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    appointmentDate?: string | null,
    firstScheduledMeasurementDate?: string | null,
    secondScheduledMeasurementDate?: string | null,
    thirdScheduledMeasurementDate?: string | null,
    firstVisitDate?: string | null,
    prefecture?: string | null,
    startOfUse?: string | null,
    endDate?: string | null,
    status?: StatusEnum | null,
    activeDate?: string | null,
    inactiveDate?: string | null,
    deviceToken?: string | null,
    initialAnswerForFeeling?:  {
      __typename: "InitialAnswerForFeeling",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      question9?: number | null,
      question10?: number | null,
      question11?: number | null,
      question12?: number | null,
      question13?: number | null,
      question14?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    initialAnswerForSleep?:  {
      __typename: "InitialAnswerForSleep",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    patientsClinicId?: string | null,
    patientsInitialAnswerForFeelingId?: string | null,
    patientsInitialAnswerForSleepId?: string | null,
  } | null,
};

export type ListPatientsQueryVariables = {
  filter?: ModelPatientsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsQuery = {
  listPatients?:  {
    __typename: "ModelPatientsConnection",
    items:  Array< {
      __typename: "Patients",
      id: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      email?: string | null,
      name?: string | null,
      finishedInitRegister?: boolean | null,
      uid?: string | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      memo?: string | null,
      memoForDoctor?: string | null,
      gender?: string | null,
      birthday?: string | null,
      medicalHistory?: string | null,
      prescribedMedicine?: string | null,
      chargedDoctorId?: string | null,
      appointmentDate?: string | null,
      firstScheduledMeasurementDate?: string | null,
      secondScheduledMeasurementDate?: string | null,
      thirdScheduledMeasurementDate?: string | null,
      firstVisitDate?: string | null,
      prefecture?: string | null,
      startOfUse?: string | null,
      endDate?: string | null,
      status?: StatusEnum | null,
      activeDate?: string | null,
      inactiveDate?: string | null,
      deviceToken?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      patientsClinicId?: string | null,
      patientsInitialAnswerForFeelingId?: string | null,
      patientsInitialAnswerForSleepId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPatientsQueryVariables = {
  filter?: ModelPatientsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPatientsQuery = {
  syncPatients?:  {
    __typename: "ModelPatientsConnection",
    items:  Array< {
      __typename: "Patients",
      id: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      email?: string | null,
      name?: string | null,
      finishedInitRegister?: boolean | null,
      uid?: string | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      memo?: string | null,
      memoForDoctor?: string | null,
      gender?: string | null,
      birthday?: string | null,
      medicalHistory?: string | null,
      prescribedMedicine?: string | null,
      chargedDoctorId?: string | null,
      appointmentDate?: string | null,
      firstScheduledMeasurementDate?: string | null,
      secondScheduledMeasurementDate?: string | null,
      thirdScheduledMeasurementDate?: string | null,
      firstVisitDate?: string | null,
      prefecture?: string | null,
      startOfUse?: string | null,
      endDate?: string | null,
      status?: StatusEnum | null,
      activeDate?: string | null,
      inactiveDate?: string | null,
      deviceToken?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      patientsClinicId?: string | null,
      patientsInitialAnswerForFeelingId?: string | null,
      patientsInitialAnswerForSleepId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClinicsQueryVariables = {
  id: string,
};

export type GetClinicsQuery = {
  getClinics?:  {
    __typename: "Clinics",
    id: string,
    clinicName?: string | null,
    address?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    memo?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    representativeDoctorId?: string | null,
    status?: StatusEnum | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListClinicsQueryVariables = {
  filter?: ModelClinicsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClinicsQuery = {
  listClinics?:  {
    __typename: "ModelClinicsConnection",
    items:  Array< {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClinicsQueryVariables = {
  filter?: ModelClinicsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClinicsQuery = {
  syncClinics?:  {
    __typename: "ModelClinicsConnection",
    items:  Array< {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRolesQueryVariables = {
  id: string,
};

export type GetRolesQuery = {
  getRoles?:  {
    __typename: "Roles",
    id: string,
    email?: string | null,
    uid?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type?: RoleTypeEnum | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    rolesClinicId?: string | null,
  } | null,
};

export type ListRolesQueryVariables = {
  filter?: ModelRolesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRolesQuery = {
  listRoles?:  {
    __typename: "ModelRolesConnection",
    items:  Array< {
      __typename: "Roles",
      id: string,
      email?: string | null,
      uid?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      type?: RoleTypeEnum | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      rolesClinicId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRolesQueryVariables = {
  filter?: ModelRolesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRolesQuery = {
  syncRoles?:  {
    __typename: "ModelRolesConnection",
    items:  Array< {
      __typename: "Roles",
      id: string,
      email?: string | null,
      uid?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      type?: RoleTypeEnum | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      rolesClinicId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInitialAnswerForSleepQueryVariables = {
  id: string,
};

export type GetInitialAnswerForSleepQuery = {
  getInitialAnswerForSleep?:  {
    __typename: "InitialAnswerForSleep",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInitialAnswerForSleepsQueryVariables = {
  filter?: ModelInitialAnswerForSleepFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInitialAnswerForSleepsQuery = {
  listInitialAnswerForSleeps?:  {
    __typename: "ModelInitialAnswerForSleepConnection",
    items:  Array< {
      __typename: "InitialAnswerForSleep",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInitialAnswerForSleepsQueryVariables = {
  filter?: ModelInitialAnswerForSleepFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInitialAnswerForSleepsQuery = {
  syncInitialAnswerForSleeps?:  {
    __typename: "ModelInitialAnswerForSleepConnection",
    items:  Array< {
      __typename: "InitialAnswerForSleep",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInitialAnswerForFeelingQueryVariables = {
  id: string,
};

export type GetInitialAnswerForFeelingQuery = {
  getInitialAnswerForFeeling?:  {
    __typename: "InitialAnswerForFeeling",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    question9?: number | null,
    question10?: number | null,
    question11?: number | null,
    question12?: number | null,
    question13?: number | null,
    question14?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInitialAnswerForFeelingsQueryVariables = {
  filter?: ModelInitialAnswerForFeelingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInitialAnswerForFeelingsQuery = {
  listInitialAnswerForFeelings?:  {
    __typename: "ModelInitialAnswerForFeelingConnection",
    items:  Array< {
      __typename: "InitialAnswerForFeeling",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      question9?: number | null,
      question10?: number | null,
      question11?: number | null,
      question12?: number | null,
      question13?: number | null,
      question14?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInitialAnswerForFeelingsQueryVariables = {
  filter?: ModelInitialAnswerForFeelingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInitialAnswerForFeelingsQuery = {
  syncInitialAnswerForFeelings?:  {
    __typename: "ModelInitialAnswerForFeelingConnection",
    items:  Array< {
      __typename: "InitialAnswerForFeeling",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      question9?: number | null,
      question10?: number | null,
      question11?: number | null,
      question12?: number | null,
      question13?: number | null,
      question14?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDailyAnswerForSleepQueryVariables = {
  id: string,
};

export type GetDailyAnswerForSleepQuery = {
  getDailyAnswerForSleep?:  {
    __typename: "DailyAnswerForSleep",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDailyAnswerForSleepsQueryVariables = {
  filter?: ModelDailyAnswerForSleepFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDailyAnswerForSleepsQuery = {
  listDailyAnswerForSleeps?:  {
    __typename: "ModelDailyAnswerForSleepConnection",
    items:  Array< {
      __typename: "DailyAnswerForSleep",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDailyAnswerForSleepsQueryVariables = {
  filter?: ModelDailyAnswerForSleepFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDailyAnswerForSleepsQuery = {
  syncDailyAnswerForSleeps?:  {
    __typename: "ModelDailyAnswerForSleepConnection",
    items:  Array< {
      __typename: "DailyAnswerForSleep",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDailyAnswerForFeelingQueryVariables = {
  id: string,
};

export type GetDailyAnswerForFeelingQuery = {
  getDailyAnswerForFeeling?:  {
    __typename: "DailyAnswerForFeeling",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDailyAnswerForFeelingsQueryVariables = {
  filter?: ModelDailyAnswerForFeelingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDailyAnswerForFeelingsQuery = {
  listDailyAnswerForFeelings?:  {
    __typename: "ModelDailyAnswerForFeelingConnection",
    items:  Array< {
      __typename: "DailyAnswerForFeeling",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDailyAnswerForFeelingsQueryVariables = {
  filter?: ModelDailyAnswerForFeelingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDailyAnswerForFeelingsQuery = {
  syncDailyAnswerForFeelings?:  {
    __typename: "ModelDailyAnswerForFeelingConnection",
    items:  Array< {
      __typename: "DailyAnswerForFeeling",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAnswerForConditionQueryVariables = {
  id: string,
};

export type GetAnswerForConditionQuery = {
  getAnswerForCondition?:  {
    __typename: "AnswerForCondition",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAnswerForConditionsQueryVariables = {
  filter?: ModelAnswerForConditionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswerForConditionsQuery = {
  listAnswerForConditions?:  {
    __typename: "ModelAnswerForConditionConnection",
    items:  Array< {
      __typename: "AnswerForCondition",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAnswerForConditionsQueryVariables = {
  filter?: ModelAnswerForConditionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAnswerForConditionsQuery = {
  syncAnswerForConditions?:  {
    __typename: "ModelAnswerForConditionConnection",
    items:  Array< {
      __typename: "AnswerForCondition",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAnswersForActivityAndStressQueryVariables = {
  id: string,
};

export type GetAnswersForActivityAndStressQuery = {
  getAnswersForActivityAndStress?:  {
    __typename: "AnswersForActivityAndStress",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAnswersForActivityAndStressesQueryVariables = {
  filter?: ModelAnswersForActivityAndStressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswersForActivityAndStressesQuery = {
  listAnswersForActivityAndStresses?:  {
    __typename: "ModelAnswersForActivityAndStressConnection",
    items:  Array< {
      __typename: "AnswersForActivityAndStress",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAnswersForActivityAndStressesQueryVariables = {
  filter?: ModelAnswersForActivityAndStressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAnswersForActivityAndStressesQuery = {
  syncAnswersForActivityAndStresses?:  {
    __typename: "ModelAnswersForActivityAndStressConnection",
    items:  Array< {
      __typename: "AnswersForActivityAndStress",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAnswersForDailyLifeQueryVariables = {
  id: string,
};

export type GetAnswersForDailyLifeQuery = {
  getAnswersForDailyLife?:  {
    __typename: "AnswersForDailyLife",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAnswersForDailyLivesQueryVariables = {
  filter?: ModelAnswersForDailyLifeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswersForDailyLivesQuery = {
  listAnswersForDailyLives?:  {
    __typename: "ModelAnswersForDailyLifeConnection",
    items:  Array< {
      __typename: "AnswersForDailyLife",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAnswersForDailyLivesQueryVariables = {
  filter?: ModelAnswersForDailyLifeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAnswersForDailyLivesQuery = {
  syncAnswersForDailyLives?:  {
    __typename: "ModelAnswersForDailyLifeConnection",
    items:  Array< {
      __typename: "AnswersForDailyLife",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAnswersForConsultingToDoctorQueryVariables = {
  id: string,
};

export type GetAnswersForConsultingToDoctorQuery = {
  getAnswersForConsultingToDoctor?:  {
    __typename: "AnswersForConsultingToDoctor",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAnswersForConsultingToDoctorsQueryVariables = {
  filter?: ModelAnswersForConsultingToDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswersForConsultingToDoctorsQuery = {
  listAnswersForConsultingToDoctors?:  {
    __typename: "ModelAnswersForConsultingToDoctorConnection",
    items:  Array< {
      __typename: "AnswersForConsultingToDoctor",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAnswersForConsultingToDoctorsQueryVariables = {
  filter?: ModelAnswersForConsultingToDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAnswersForConsultingToDoctorsQuery = {
  syncAnswersForConsultingToDoctors?:  {
    __typename: "ModelAnswersForConsultingToDoctorConnection",
    items:  Array< {
      __typename: "AnswersForConsultingToDoctor",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAccessLogsQueryVariables = {
  id: string,
};

export type GetAccessLogsQuery = {
  getAccessLogs?:  {
    __typename: "AccessLogs",
    id: string,
    patientId?: string | null,
    type?: AccessLogsTypeEnum | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAccessLogsQueryVariables = {
  filter?: ModelAccessLogsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccessLogsQuery = {
  listAccessLogs?:  {
    __typename: "ModelAccessLogsConnection",
    items:  Array< {
      __typename: "AccessLogs",
      id: string,
      patientId?: string | null,
      type?: AccessLogsTypeEnum | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAccessLogsQueryVariables = {
  filter?: ModelAccessLogsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAccessLogsQuery = {
  syncAccessLogs?:  {
    __typename: "ModelAccessLogsConnection",
    items:  Array< {
      __typename: "AccessLogs",
      id: string,
      patientId?: string | null,
      type?: AccessLogsTypeEnum | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWordsOfEncouragementQueryVariables = {
  id: string,
};

export type GetWordsOfEncouragementQuery = {
  getWordsOfEncouragement?:  {
    __typename: "WordsOfEncouragement",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWordsOfEncouragementsQueryVariables = {
  filter?: ModelWordsOfEncouragementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWordsOfEncouragementsQuery = {
  listWordsOfEncouragements?:  {
    __typename: "ModelWordsOfEncouragementConnection",
    items:  Array< {
      __typename: "WordsOfEncouragement",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWordsOfEncouragementsQueryVariables = {
  filter?: ModelWordsOfEncouragementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWordsOfEncouragementsQuery = {
  syncWordsOfEncouragements?:  {
    __typename: "ModelWordsOfEncouragementConnection",
    items:  Array< {
      __typename: "WordsOfEncouragement",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetQuestionsQueryVariables = {
  id: string,
};

export type GetQuestionsQuery = {
  getQuestions?:  {
    __typename: "Questions",
    id: string,
    questionsDailyAnswersForSleep?:  {
      __typename: "QuestionsDailyAnswersForSleep",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionsDailyAnswersForFeeling?:  {
      __typename: "QuestionsDailyAnswersForFeeling",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForCondition?:  {
      __typename: "QuestionsDailyAnswersForCondition",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForActivityAndStress?:  {
      __typename: "QuestionsDailyAnswersForActivityAndStress",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForDailyLife?:  {
      __typename: "QuestionsDailyAnswersForDailyLife",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForConsultingToDoctor?:  {
      __typename: "QuestionsDailyAnswersForConsultingToDoctor",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    questionsQuestionsDailyAnswersForSleepId?: string | null,
    questionsQuestionsDailyAnswersForFeelingId?: string | null,
    questionsQuestionsDailyAnswersForConditionId?: string | null,
    questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
    questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
    questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
  } | null,
};

export type ListQuestionsQueryVariables = {
  filter?: ModelQuestionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionsConnection",
    items:  Array< {
      __typename: "Questions",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      questionsQuestionsDailyAnswersForSleepId?: string | null,
      questionsQuestionsDailyAnswersForFeelingId?: string | null,
      questionsQuestionsDailyAnswersForConditionId?: string | null,
      questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
      questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
      questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuestionsQueryVariables = {
  filter?: ModelQuestionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuestionsQuery = {
  syncQuestions?:  {
    __typename: "ModelQuestionsConnection",
    items:  Array< {
      __typename: "Questions",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      questionsQuestionsDailyAnswersForSleepId?: string | null,
      questionsQuestionsDailyAnswersForFeelingId?: string | null,
      questionsQuestionsDailyAnswersForConditionId?: string | null,
      questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
      questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
      questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAnswersListQueryVariables = {
  id: string,
};

export type GetAnswersListQuery = {
  getAnswersList?:  {
    __typename: "AnswersList",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAnswersListsQueryVariables = {
  filter?: ModelAnswersListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswersListsQuery = {
  listAnswersLists?:  {
    __typename: "ModelAnswersListConnection",
    items:  Array< {
      __typename: "AnswersList",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAnswersListsQueryVariables = {
  filter?: ModelAnswersListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAnswersListsQuery = {
  syncAnswersLists?:  {
    __typename: "ModelAnswersListConnection",
    items:  Array< {
      __typename: "AnswersList",
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateQuestionsDailyAnswersForSleepSubscription = {
  onCreateQuestionsDailyAnswersForSleep?:  {
    __typename: "QuestionsDailyAnswersForSleep",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateQuestionsDailyAnswersForSleepSubscription = {
  onUpdateQuestionsDailyAnswersForSleep?:  {
    __typename: "QuestionsDailyAnswersForSleep",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteQuestionsDailyAnswersForSleepSubscription = {
  onDeleteQuestionsDailyAnswersForSleep?:  {
    __typename: "QuestionsDailyAnswersForSleep",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateQuestionsDailyAnswersForFeelingSubscription = {
  onCreateQuestionsDailyAnswersForFeeling?:  {
    __typename: "QuestionsDailyAnswersForFeeling",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateQuestionsDailyAnswersForFeelingSubscription = {
  onUpdateQuestionsDailyAnswersForFeeling?:  {
    __typename: "QuestionsDailyAnswersForFeeling",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteQuestionsDailyAnswersForFeelingSubscription = {
  onDeleteQuestionsDailyAnswersForFeeling?:  {
    __typename: "QuestionsDailyAnswersForFeeling",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateQuestionsDailyAnswersForConditionSubscription = {
  onCreateQuestionsDailyAnswersForCondition?:  {
    __typename: "QuestionsDailyAnswersForCondition",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateQuestionsDailyAnswersForConditionSubscription = {
  onUpdateQuestionsDailyAnswersForCondition?:  {
    __typename: "QuestionsDailyAnswersForCondition",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteQuestionsDailyAnswersForConditionSubscription = {
  onDeleteQuestionsDailyAnswersForCondition?:  {
    __typename: "QuestionsDailyAnswersForCondition",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateQuestionsDailyAnswersForActivityAndStressSubscription = {
  onCreateQuestionsDailyAnswersForActivityAndStress?:  {
    __typename: "QuestionsDailyAnswersForActivityAndStress",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateQuestionsDailyAnswersForActivityAndStressSubscription = {
  onUpdateQuestionsDailyAnswersForActivityAndStress?:  {
    __typename: "QuestionsDailyAnswersForActivityAndStress",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteQuestionsDailyAnswersForActivityAndStressSubscription = {
  onDeleteQuestionsDailyAnswersForActivityAndStress?:  {
    __typename: "QuestionsDailyAnswersForActivityAndStress",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateQuestionsDailyAnswersForDailyLifeSubscription = {
  onCreateQuestionsDailyAnswersForDailyLife?:  {
    __typename: "QuestionsDailyAnswersForDailyLife",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateQuestionsDailyAnswersForDailyLifeSubscription = {
  onUpdateQuestionsDailyAnswersForDailyLife?:  {
    __typename: "QuestionsDailyAnswersForDailyLife",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteQuestionsDailyAnswersForDailyLifeSubscription = {
  onDeleteQuestionsDailyAnswersForDailyLife?:  {
    __typename: "QuestionsDailyAnswersForDailyLife",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateQuestionsDailyAnswersForConsultingToDoctorSubscription = {
  onCreateQuestionsDailyAnswersForConsultingToDoctor?:  {
    __typename: "QuestionsDailyAnswersForConsultingToDoctor",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateQuestionsDailyAnswersForConsultingToDoctorSubscription = {
  onUpdateQuestionsDailyAnswersForConsultingToDoctor?:  {
    __typename: "QuestionsDailyAnswersForConsultingToDoctor",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteQuestionsDailyAnswersForConsultingToDoctorSubscription = {
  onDeleteQuestionsDailyAnswersForConsultingToDoctor?:  {
    __typename: "QuestionsDailyAnswersForConsultingToDoctor",
    id: string,
    firstQuestion?: string | null,
    secondQuestion?: string | null,
    thirdQuestion?: string | null,
    fourthQuestion?: string | null,
    fifthQuestion?: string | null,
    sixthQuestion?: string | null,
    seventhQuestion?: string | null,
    eighthQuestion?: string | null,
    ninthQuestion?: string | null,
    tenthQuestion?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    firstQuestionType?: QuestionPositiveEnum | null,
    secondQuestionType?: QuestionPositiveEnum | null,
    thirdQuestionType?: QuestionPositiveEnum | null,
    fourthQuestionType?: QuestionPositiveEnum | null,
    fifthQuestionType?: QuestionPositiveEnum | null,
    sixthQuestionType?: QuestionPositiveEnum | null,
    seventhQuestionType?: QuestionPositiveEnum | null,
    eighthQuestionType?: QuestionPositiveEnum | null,
    ninthQuestionType?: QuestionPositiveEnum | null,
    tenthQuestionType?: QuestionPositiveEnum | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDailyAnswersSubscription = {
  onCreateDailyAnswers?:  {
    __typename: "DailyAnswers",
    id: string,
    firstQuestion?: boolean | null,
    secondQuestion?: boolean | null,
    thirdQuestion?: boolean | null,
    fourthQuestion?: boolean | null,
    fifthQuestion?: boolean | null,
    sixthQuestion?: boolean | null,
    seventhQuestion?: boolean | null,
    eighthQuestion?: boolean | null,
    ninthQuestion?: boolean | null,
    tenthQuestion?: boolean | null,
    memo?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    patientId?: string | null,
    clinicId?: string | null,
    type?: DailyAnswersTypeEnum | null,
    categoryDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDailyAnswersSubscription = {
  onUpdateDailyAnswers?:  {
    __typename: "DailyAnswers",
    id: string,
    firstQuestion?: boolean | null,
    secondQuestion?: boolean | null,
    thirdQuestion?: boolean | null,
    fourthQuestion?: boolean | null,
    fifthQuestion?: boolean | null,
    sixthQuestion?: boolean | null,
    seventhQuestion?: boolean | null,
    eighthQuestion?: boolean | null,
    ninthQuestion?: boolean | null,
    tenthQuestion?: boolean | null,
    memo?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    patientId?: string | null,
    clinicId?: string | null,
    type?: DailyAnswersTypeEnum | null,
    categoryDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDailyAnswersSubscription = {
  onDeleteDailyAnswers?:  {
    __typename: "DailyAnswers",
    id: string,
    firstQuestion?: boolean | null,
    secondQuestion?: boolean | null,
    thirdQuestion?: boolean | null,
    fourthQuestion?: boolean | null,
    fifthQuestion?: boolean | null,
    sixthQuestion?: boolean | null,
    seventhQuestion?: boolean | null,
    eighthQuestion?: boolean | null,
    ninthQuestion?: boolean | null,
    tenthQuestion?: boolean | null,
    memo?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    patientId?: string | null,
    clinicId?: string | null,
    type?: DailyAnswersTypeEnum | null,
    categoryDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAdminsSubscription = {
  onCreateAdmins?:  {
    __typename: "Admins",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    adminEmail?:  {
      __typename: "AdminEmails",
      id: string,
      email?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    adminId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    adminsAdminEmailId?: string | null,
  } | null,
};

export type OnUpdateAdminsSubscription = {
  onUpdateAdmins?:  {
    __typename: "Admins",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    adminEmail?:  {
      __typename: "AdminEmails",
      id: string,
      email?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    adminId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    adminsAdminEmailId?: string | null,
  } | null,
};

export type OnDeleteAdminsSubscription = {
  onDeleteAdmins?:  {
    __typename: "Admins",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    adminEmail?:  {
      __typename: "AdminEmails",
      id: string,
      email?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    adminId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    adminsAdminEmailId?: string | null,
  } | null,
};

export type OnCreateAdminEmailsSubscription = {
  onCreateAdminEmails?:  {
    __typename: "AdminEmails",
    id: string,
    email?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAdminEmailsSubscription = {
  onUpdateAdminEmails?:  {
    __typename: "AdminEmails",
    id: string,
    email?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAdminEmailsSubscription = {
  onDeleteAdminEmails?:  {
    __typename: "AdminEmails",
    id: string,
    email?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMembersSubscription = {
  onCreateMembers?:  {
    __typename: "Members",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    role?:  {
      __typename: "Roles",
      id: string,
      email?: string | null,
      uid?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      type?: RoleTypeEnum | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      rolesClinicId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    Clinics?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    membersRoleId?: string | null,
    membersClinicsId?: string | null,
  } | null,
};

export type OnUpdateMembersSubscription = {
  onUpdateMembers?:  {
    __typename: "Members",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    role?:  {
      __typename: "Roles",
      id: string,
      email?: string | null,
      uid?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      type?: RoleTypeEnum | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      rolesClinicId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    Clinics?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    membersRoleId?: string | null,
    membersClinicsId?: string | null,
  } | null,
};

export type OnDeleteMembersSubscription = {
  onDeleteMembers?:  {
    __typename: "Members",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    role?:  {
      __typename: "Roles",
      id: string,
      email?: string | null,
      uid?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      type?: RoleTypeEnum | null,
      patientNumber?: string | null,
      phoneNumber?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      rolesClinicId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    Clinics?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    membersRoleId?: string | null,
    membersClinicsId?: string | null,
  } | null,
};

export type OnCreatePatientsSubscription = {
  onCreatePatients?:  {
    __typename: "Patients",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    memo?: string | null,
    memoForDoctor?: string | null,
    gender?: string | null,
    birthday?: string | null,
    medicalHistory?: string | null,
    prescribedMedicine?: string | null,
    chargedDoctorId?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    appointmentDate?: string | null,
    firstScheduledMeasurementDate?: string | null,
    secondScheduledMeasurementDate?: string | null,
    thirdScheduledMeasurementDate?: string | null,
    firstVisitDate?: string | null,
    prefecture?: string | null,
    startOfUse?: string | null,
    endDate?: string | null,
    status?: StatusEnum | null,
    activeDate?: string | null,
    inactiveDate?: string | null,
    deviceToken?: string | null,
    initialAnswerForFeeling?:  {
      __typename: "InitialAnswerForFeeling",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      question9?: number | null,
      question10?: number | null,
      question11?: number | null,
      question12?: number | null,
      question13?: number | null,
      question14?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    initialAnswerForSleep?:  {
      __typename: "InitialAnswerForSleep",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    patientsClinicId?: string | null,
    patientsInitialAnswerForFeelingId?: string | null,
    patientsInitialAnswerForSleepId?: string | null,
  } | null,
};

export type OnUpdatePatientsSubscription = {
  onUpdatePatients?:  {
    __typename: "Patients",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    memo?: string | null,
    memoForDoctor?: string | null,
    gender?: string | null,
    birthday?: string | null,
    medicalHistory?: string | null,
    prescribedMedicine?: string | null,
    chargedDoctorId?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    appointmentDate?: string | null,
    firstScheduledMeasurementDate?: string | null,
    secondScheduledMeasurementDate?: string | null,
    thirdScheduledMeasurementDate?: string | null,
    firstVisitDate?: string | null,
    prefecture?: string | null,
    startOfUse?: string | null,
    endDate?: string | null,
    status?: StatusEnum | null,
    activeDate?: string | null,
    inactiveDate?: string | null,
    deviceToken?: string | null,
    initialAnswerForFeeling?:  {
      __typename: "InitialAnswerForFeeling",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      question9?: number | null,
      question10?: number | null,
      question11?: number | null,
      question12?: number | null,
      question13?: number | null,
      question14?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    initialAnswerForSleep?:  {
      __typename: "InitialAnswerForSleep",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    patientsClinicId?: string | null,
    patientsInitialAnswerForFeelingId?: string | null,
    patientsInitialAnswerForSleepId?: string | null,
  } | null,
};

export type OnDeletePatientsSubscription = {
  onDeletePatients?:  {
    __typename: "Patients",
    id: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    email?: string | null,
    name?: string | null,
    finishedInitRegister?: boolean | null,
    uid?: string | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    memo?: string | null,
    memoForDoctor?: string | null,
    gender?: string | null,
    birthday?: string | null,
    medicalHistory?: string | null,
    prescribedMedicine?: string | null,
    chargedDoctorId?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    appointmentDate?: string | null,
    firstScheduledMeasurementDate?: string | null,
    secondScheduledMeasurementDate?: string | null,
    thirdScheduledMeasurementDate?: string | null,
    firstVisitDate?: string | null,
    prefecture?: string | null,
    startOfUse?: string | null,
    endDate?: string | null,
    status?: StatusEnum | null,
    activeDate?: string | null,
    inactiveDate?: string | null,
    deviceToken?: string | null,
    initialAnswerForFeeling?:  {
      __typename: "InitialAnswerForFeeling",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      question9?: number | null,
      question10?: number | null,
      question11?: number | null,
      question12?: number | null,
      question13?: number | null,
      question14?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    initialAnswerForSleep?:  {
      __typename: "InitialAnswerForSleep",
      id: string,
      question1?: number | null,
      question2?: number | null,
      question3?: number | null,
      question4?: number | null,
      question5?: number | null,
      question6?: number | null,
      question7?: number | null,
      question8?: number | null,
      totalScore?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    patientsClinicId?: string | null,
    patientsInitialAnswerForFeelingId?: string | null,
    patientsInitialAnswerForSleepId?: string | null,
  } | null,
};

export type OnCreateClinicsSubscription = {
  onCreateClinics?:  {
    __typename: "Clinics",
    id: string,
    clinicName?: string | null,
    address?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    memo?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    representativeDoctorId?: string | null,
    status?: StatusEnum | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateClinicsSubscription = {
  onUpdateClinics?:  {
    __typename: "Clinics",
    id: string,
    clinicName?: string | null,
    address?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    memo?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    representativeDoctorId?: string | null,
    status?: StatusEnum | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteClinicsSubscription = {
  onDeleteClinics?:  {
    __typename: "Clinics",
    id: string,
    clinicName?: string | null,
    address?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    memo?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    representativeDoctorId?: string | null,
    status?: StatusEnum | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateRolesSubscription = {
  onCreateRoles?:  {
    __typename: "Roles",
    id: string,
    email?: string | null,
    uid?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type?: RoleTypeEnum | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    rolesClinicId?: string | null,
  } | null,
};

export type OnUpdateRolesSubscription = {
  onUpdateRoles?:  {
    __typename: "Roles",
    id: string,
    email?: string | null,
    uid?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type?: RoleTypeEnum | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    rolesClinicId?: string | null,
  } | null,
};

export type OnDeleteRolesSubscription = {
  onDeleteRoles?:  {
    __typename: "Roles",
    id: string,
    email?: string | null,
    uid?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    clinic?:  {
      __typename: "Clinics",
      id: string,
      clinicName?: string | null,
      address?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      memo?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      representativeDoctorId?: string | null,
      status?: StatusEnum | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type?: RoleTypeEnum | null,
    patientNumber?: string | null,
    phoneNumber?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    rolesClinicId?: string | null,
  } | null,
};

export type OnCreateInitialAnswerForSleepSubscription = {
  onCreateInitialAnswerForSleep?:  {
    __typename: "InitialAnswerForSleep",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInitialAnswerForSleepSubscription = {
  onUpdateInitialAnswerForSleep?:  {
    __typename: "InitialAnswerForSleep",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInitialAnswerForSleepSubscription = {
  onDeleteInitialAnswerForSleep?:  {
    __typename: "InitialAnswerForSleep",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInitialAnswerForFeelingSubscription = {
  onCreateInitialAnswerForFeeling?:  {
    __typename: "InitialAnswerForFeeling",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    question9?: number | null,
    question10?: number | null,
    question11?: number | null,
    question12?: number | null,
    question13?: number | null,
    question14?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInitialAnswerForFeelingSubscription = {
  onUpdateInitialAnswerForFeeling?:  {
    __typename: "InitialAnswerForFeeling",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    question9?: number | null,
    question10?: number | null,
    question11?: number | null,
    question12?: number | null,
    question13?: number | null,
    question14?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInitialAnswerForFeelingSubscription = {
  onDeleteInitialAnswerForFeeling?:  {
    __typename: "InitialAnswerForFeeling",
    id: string,
    question1?: number | null,
    question2?: number | null,
    question3?: number | null,
    question4?: number | null,
    question5?: number | null,
    question6?: number | null,
    question7?: number | null,
    question8?: number | null,
    question9?: number | null,
    question10?: number | null,
    question11?: number | null,
    question12?: number | null,
    question13?: number | null,
    question14?: number | null,
    totalScore?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDailyAnswerForSleepSubscription = {
  onCreateDailyAnswerForSleep?:  {
    __typename: "DailyAnswerForSleep",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDailyAnswerForSleepSubscription = {
  onUpdateDailyAnswerForSleep?:  {
    __typename: "DailyAnswerForSleep",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDailyAnswerForSleepSubscription = {
  onDeleteDailyAnswerForSleep?:  {
    __typename: "DailyAnswerForSleep",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDailyAnswerForFeelingSubscription = {
  onCreateDailyAnswerForFeeling?:  {
    __typename: "DailyAnswerForFeeling",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDailyAnswerForFeelingSubscription = {
  onUpdateDailyAnswerForFeeling?:  {
    __typename: "DailyAnswerForFeeling",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDailyAnswerForFeelingSubscription = {
  onDeleteDailyAnswerForFeeling?:  {
    __typename: "DailyAnswerForFeeling",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAnswerForConditionSubscription = {
  onCreateAnswerForCondition?:  {
    __typename: "AnswerForCondition",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAnswerForConditionSubscription = {
  onUpdateAnswerForCondition?:  {
    __typename: "AnswerForCondition",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAnswerForConditionSubscription = {
  onDeleteAnswerForCondition?:  {
    __typename: "AnswerForCondition",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAnswersForActivityAndStressSubscription = {
  onCreateAnswersForActivityAndStress?:  {
    __typename: "AnswersForActivityAndStress",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAnswersForActivityAndStressSubscription = {
  onUpdateAnswersForActivityAndStress?:  {
    __typename: "AnswersForActivityAndStress",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAnswersForActivityAndStressSubscription = {
  onDeleteAnswersForActivityAndStress?:  {
    __typename: "AnswersForActivityAndStress",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAnswersForDailyLifeSubscription = {
  onCreateAnswersForDailyLife?:  {
    __typename: "AnswersForDailyLife",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAnswersForDailyLifeSubscription = {
  onUpdateAnswersForDailyLife?:  {
    __typename: "AnswersForDailyLife",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAnswersForDailyLifeSubscription = {
  onDeleteAnswersForDailyLife?:  {
    __typename: "AnswersForDailyLife",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAnswersForConsultingToDoctorSubscription = {
  onCreateAnswersForConsultingToDoctor?:  {
    __typename: "AnswersForConsultingToDoctor",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAnswersForConsultingToDoctorSubscription = {
  onUpdateAnswersForConsultingToDoctor?:  {
    __typename: "AnswersForConsultingToDoctor",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAnswersForConsultingToDoctorSubscription = {
  onDeleteAnswersForConsultingToDoctor?:  {
    __typename: "AnswersForConsultingToDoctor",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAccessLogsSubscription = {
  onCreateAccessLogs?:  {
    __typename: "AccessLogs",
    id: string,
    patientId?: string | null,
    type?: AccessLogsTypeEnum | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAccessLogsSubscription = {
  onUpdateAccessLogs?:  {
    __typename: "AccessLogs",
    id: string,
    patientId?: string | null,
    type?: AccessLogsTypeEnum | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAccessLogsSubscription = {
  onDeleteAccessLogs?:  {
    __typename: "AccessLogs",
    id: string,
    patientId?: string | null,
    type?: AccessLogsTypeEnum | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWordsOfEncouragementSubscription = {
  onCreateWordsOfEncouragement?:  {
    __typename: "WordsOfEncouragement",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWordsOfEncouragementSubscription = {
  onUpdateWordsOfEncouragement?:  {
    __typename: "WordsOfEncouragement",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWordsOfEncouragementSubscription = {
  onDeleteWordsOfEncouragement?:  {
    __typename: "WordsOfEncouragement",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateQuestionsSubscription = {
  onCreateQuestions?:  {
    __typename: "Questions",
    id: string,
    questionsDailyAnswersForSleep?:  {
      __typename: "QuestionsDailyAnswersForSleep",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionsDailyAnswersForFeeling?:  {
      __typename: "QuestionsDailyAnswersForFeeling",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForCondition?:  {
      __typename: "QuestionsDailyAnswersForCondition",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForActivityAndStress?:  {
      __typename: "QuestionsDailyAnswersForActivityAndStress",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForDailyLife?:  {
      __typename: "QuestionsDailyAnswersForDailyLife",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForConsultingToDoctor?:  {
      __typename: "QuestionsDailyAnswersForConsultingToDoctor",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    questionsQuestionsDailyAnswersForSleepId?: string | null,
    questionsQuestionsDailyAnswersForFeelingId?: string | null,
    questionsQuestionsDailyAnswersForConditionId?: string | null,
    questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
    questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
    questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
  } | null,
};

export type OnUpdateQuestionsSubscription = {
  onUpdateQuestions?:  {
    __typename: "Questions",
    id: string,
    questionsDailyAnswersForSleep?:  {
      __typename: "QuestionsDailyAnswersForSleep",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionsDailyAnswersForFeeling?:  {
      __typename: "QuestionsDailyAnswersForFeeling",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForCondition?:  {
      __typename: "QuestionsDailyAnswersForCondition",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForActivityAndStress?:  {
      __typename: "QuestionsDailyAnswersForActivityAndStress",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForDailyLife?:  {
      __typename: "QuestionsDailyAnswersForDailyLife",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForConsultingToDoctor?:  {
      __typename: "QuestionsDailyAnswersForConsultingToDoctor",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    questionsQuestionsDailyAnswersForSleepId?: string | null,
    questionsQuestionsDailyAnswersForFeelingId?: string | null,
    questionsQuestionsDailyAnswersForConditionId?: string | null,
    questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
    questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
    questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
  } | null,
};

export type OnDeleteQuestionsSubscription = {
  onDeleteQuestions?:  {
    __typename: "Questions",
    id: string,
    questionsDailyAnswersForSleep?:  {
      __typename: "QuestionsDailyAnswersForSleep",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    questionsDailyAnswersForFeeling?:  {
      __typename: "QuestionsDailyAnswersForFeeling",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForCondition?:  {
      __typename: "QuestionsDailyAnswersForCondition",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForActivityAndStress?:  {
      __typename: "QuestionsDailyAnswersForActivityAndStress",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForDailyLife?:  {
      __typename: "QuestionsDailyAnswersForDailyLife",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    questionsDailyAnswersForConsultingToDoctor?:  {
      __typename: "QuestionsDailyAnswersForConsultingToDoctor",
      id: string,
      firstQuestion?: string | null,
      secondQuestion?: string | null,
      thirdQuestion?: string | null,
      fourthQuestion?: string | null,
      fifthQuestion?: string | null,
      sixthQuestion?: string | null,
      seventhQuestion?: string | null,
      eighthQuestion?: string | null,
      ninthQuestion?: string | null,
      tenthQuestion?: string | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      firstQuestionType?: QuestionPositiveEnum | null,
      secondQuestionType?: QuestionPositiveEnum | null,
      thirdQuestionType?: QuestionPositiveEnum | null,
      fourthQuestionType?: QuestionPositiveEnum | null,
      fifthQuestionType?: QuestionPositiveEnum | null,
      sixthQuestionType?: QuestionPositiveEnum | null,
      seventhQuestionType?: QuestionPositiveEnum | null,
      eighthQuestionType?: QuestionPositiveEnum | null,
      ninthQuestionType?: QuestionPositiveEnum | null,
      tenthQuestionType?: QuestionPositiveEnum | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    questionsQuestionsDailyAnswersForSleepId?: string | null,
    questionsQuestionsDailyAnswersForFeelingId?: string | null,
    questionsQuestionsDailyAnswersForConditionId?: string | null,
    questionsQuestionsDailyAnswersForActivityAndStressId?: string | null,
    questionsQuestionsDailyAnswersForDailyLifeId?: string | null,
    questionsQuestionsDailyAnswersForConsultingToDoctorId?: string | null,
  } | null,
};

export type OnCreateAnswersListSubscription = {
  onCreateAnswersList?:  {
    __typename: "AnswersList",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAnswersListSubscription = {
  onUpdateAnswersList?:  {
    __typename: "AnswersList",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAnswersListSubscription = {
  onDeleteAnswersList?:  {
    __typename: "AnswersList",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
