import { ChangeEvent } from 'react';
import MuiRadio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/system';

type Option = {
  value: unknown;
  label: string;
};

type Props = {
  row?: boolean;
  options: Option[];
  value?: unknown;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const Icon = styled('div')({
  width: 24,
  height: 24,
  backgroundColor: '#DDE9F0',
  borderRadius: '50%',
});

const CheckedIconContainer = styled('div')({
  width: 24,
  height: 24,
  backgroundColor: '#8ABAD6',
  position: 'relative',
  borderRadius: '50%',
});

const CheckedIconInner = styled('div')({
  width: 12,
  height: 12,
  backgroundColor: '#fff',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '50%',
});

const CheckedIcon = () => {
  return (
    <CheckedIconContainer>
      <CheckedIconInner />
    </CheckedIconContainer>
  );
};

export const Radio = ({ options, row, value: curValue, onChange }: Props) => {
  return (
    <FormControl>
      <RadioGroup row={row} onChange={onChange}>
        <>
          {options.map(({ value, label }, i) => {
            return (
              <FormControlLabel
                key={i}
                value={value}
                control={
                  <MuiRadio icon={<Icon />} checkedIcon={<CheckedIcon />} />
                }
                label={label}
                checked={value === curValue}
              />
            );
          })}
        </>
      </RadioGroup>
    </FormControl>
  );
};
