import { Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { theme } from '../../provider/ThemeProvider';
import { nl2br } from "../../utils/nl2br";

type TableProps = {
  rows: {title:string, value:string | React.ReactNode | null |JSX.Element}[];
  hover?:boolean;
  width?: string | number;
  small?: boolean;
};

export const HeadlessTable= (props: TableProps) => {
  const { rows, hover, width, small } = props;
  return (
    <Table>
      <TableBody style={{border: "1px solid  rgba(224, 224, 224, 1)"}}>
        {rows.map((row, index) => {
          return (
            <TableRow hover={hover} role='checkbox' tabIndex={-1} key={index}>
              <CustomizedTitle  width={width}>{row.title}</CustomizedTitle>
              <CustomizedTableCell small={small}>{nl2br(row.value)}</CustomizedTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};


interface MultiColmunsTableProps {
  headers: string[];
  rows: {value: string, align: "center" | "inherit" | "left" | "right" | "justify" | undefined}[][];
  indexColors?: string[];
  isAllCellCenter?: boolean;
  isWhiteIndexFont?: boolean;
  isFirstCellColor?: boolean;
  width?: string;
  printHeaderWidthAuto?:boolean;
}

/**
 * 複数列のテーブルを作成する
 * @param headers ヘッダー　配列の先頭は空白
 * @param rows 行　配列の先頭はタイトル
 * @param indexColors 行タイトルの背景色
 * @param isAllCellCenter セルを全て中央寄せにするか
 * @param isFirstCellColor 最初のセルの色付け
 * @returns 
 */
export const MultiColmunsTable: React.FC<MultiColmunsTableProps> = ({
  headers,
  rows,
  indexColors,
  isAllCellCenter,
  isWhiteIndexFont,
  width, isFirstCellColor,
  printHeaderWidthAuto,
}) => {

  return (
    <StyledTableContainer sx={width ? {width: 'fit-content'} : {}}>
      <CustomizedTable aria-label="custom table" sx={width ? {width : width} : {}}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              index === 0  && !isFirstCellColor? 
              (
                <CustomizedTableFirstCell  align="center">{nl2br(header)}</CustomizedTableFirstCell>
              )
              : 
              (
                <CustomizedTableHeadCell align="center">{nl2br(header)}</CustomizedTableHeadCell>
              )
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((r, cellIndex) => (
                cellIndex === 0 ? (
                  <CustomizedTableHeadColmunCell
                    component="th"
                    scope="row"
                    color={indexColors && indexColors[index] ? indexColors[index] : undefined}
                    fontColor={isWhiteIndexFont ? 'white' : theme.palette.text.primary}
                    printWidthAuto={printHeaderWidthAuto}
                  >
                    {nl2br(r.value)}
                  </CustomizedTableHeadColmunCell>
                )
                : (
                  <StyledTableCell align={isAllCellCenter ? 'center' : r.align}>{nl2br(r.value)}</StyledTableCell>
                )
              ))}
            </TableRow>
          ))}
        </TableBody>
      </CustomizedTable>
    </StyledTableContainer>
  );
};
interface MultiColspanColmunsTable {
  headers: {value: string, colspan: number}[];
  rows: {value?: string | number, colspan: number}[][];
  indexColors?: string[];
  isAllCellCenter?: boolean;
  isWhiteIndexFont?: boolean;
  isFirstCellColor?: boolean;
  width?: string;
}


export const MultiColspanColmunsTable: React.FC<MultiColspanColmunsTable> = ({
  headers,
  rows,
  indexColors,
  isAllCellCenter,
  isWhiteIndexFont,
  width, isFirstCellColor
}) => {

  return (
    <TableContainer component={Paper} sx={width ? {width: 'fit-content'} : {}} style={{width: width}}>
      <CustomizedTable aria-label="custom table" sx={width ? {width : width} : {}}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              index === 0  && !isFirstCellColor? 
              (
                <TableCell colSpan={header.colspan} style={{padding: '0px 2px'}}>{header.value}</TableCell>
              )
              : 
              (
                <CustomizedTableHeadCell align="center" colSpan={header.colspan} style={{padding: '0px 4px'}}>{header.value}</CustomizedTableHeadCell>
              )
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((cell, cellIndex) => (
                cellIndex === 0 ? (
                  <CustomizedTableHeadColmunCell
                    component="th"
                    scope="row"
                    colSpan={cell.colspan}
                    color={indexColors && indexColors[index] ? indexColors[index] : undefined}
                    fontColor={isWhiteIndexFont ? 'white' : theme.palette.text.primary}
                    style={{padding: '8px 8px', width: 200}}
                  >
                    {cell.value}
                  </CustomizedTableHeadColmunCell>
                )
                : (
                  <TableCell align={isAllCellCenter ? 'center' : 'left'}  colSpan={cell.colspan} style={{padding: '0', minWidth: 30}}> {cell.value}</TableCell>
                )
              ))}
            </TableRow>
          ))}
        </TableBody>
      </CustomizedTable>
    </TableContainer>
  );
};

interface ToltalJudgeTableProps {
  headers: string[];
  rows: string[][];
  indexColors?: string[];
  isAllCellCenter?: boolean;
  isWhiteIndexFont?: boolean;
  isFirstCellColor?: boolean;
  width?: string;
  printHeaderWidthAuto?:boolean;
}



export const ToltalJudgeTable: React.FC<ToltalJudgeTableProps> = ({  headers,
  rows,
  isAllCellCenter,
  width}) => {
  return(
    <TableContainer  sx={width ? {width: 'fit-content'} : {}}>
    <CustomizedTable aria-label="custom table" sx={width ? {width : width} : {}} style={{borderBottom: "1px solid rgba(224, 224, 224, 1)", minWidth: "40px"}}>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            (
              <CustomizedTableHeadCell align="center">{header}</CustomizedTableHeadCell>
            )
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index}>
            {row.map((cell, cellIndex) => (
              (
                <TotalJudgeTableCell align={isAllCellCenter ? 'center' : 'left'}>{cell}</TotalJudgeTableCell>
              )
            ))}
          </TableRow>
        ))}
      </TableBody>
    </CustomizedTable>
  </TableContainer>
  )
}

interface DashBoardTableProps {
  headers: React.ReactNode[];
  rows:  React.ReactNode[][];
  indexColors?: string[];
  isAllCellCenter?: boolean;
  isWhiteIndexFont?: boolean;
  isFirstCellColor?: boolean;
  width?: string;
}

export const DashBoardTable: React.FC<DashBoardTableProps> = ({  headers,
  rows,
  isAllCellCenter,
  width,
  indexColors
}) => {
  return(
    <TableContainer  sx={width ? {width: 'fit-content'} : {}}>
    <CustomizedTable aria-label="custom table" sx={width ? {width : width} : {}} style={{borderBottom: "1px solid rgba(224, 224, 224, 1)", minWidth: "40px"}}>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            (
              <CustomizedTableHeadCell align="center" style={{background: "#898989", color: 'white', padding: '10px 8px'}}>{header}</CustomizedTableHeadCell>
            )
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index}>
            {row.map((cell, cellIndex) => (
              cellIndex === 0 ? (
                <CustomizedTableHeadColmunCell
                  component="th"
                  scope="row"
                  color={indexColors && indexColors[index] ? indexColors[index] : undefined}
                  fontColor={theme.palette.text.primary}
                  style={{background: "white", padding:  '10px 12px', minWidth: 90}}
                >
                  {cell}
                </CustomizedTableHeadColmunCell>
              )
              : (
                <TableCell align={isAllCellCenter ? 'center' : 'left'}  style={{ background: "white",  padding:  '10px 4px', whiteSpace: "inherit"}}> {cell}</TableCell>
              )
            ))}
          </TableRow>
        ))}
      </TableBody>
    </CustomizedTable>
  </TableContainer>
  )
}

type CustomizedTitleProps = {
  width?: string | number;
}

const CustomizedTitle = styled(TableCell)<CustomizedTitleProps>(({ width }) => ({
  fontSize: '16px',
  marginLeft: '8px',
  background: "#F2F2F2",
  width: width ? width : '350px',
  fontFamily: 'ABeeZee'
}));


const CustomizedTableCell = styled(TableCell)<{small?:boolean}>`
  padding: 12px 16px;
  word-break: break-all;
  font-family: ABeeZee;
  font-size: ${({small})=> small? "12px":"14px"};
`

const CustomizedTable = styled(Table)({
  minWidth: 500,
  '& th': {
    border: '1px solid rgba(224, 224, 224, 1)',
    fontWeight: 'bold',
  },
  '& td': {
    border: '1px solid rgba(224, 224, 224, 1)',
    whiteSpace: 'pre-wrap'
  },
  '& tr:first-child th': {
    maxWidth: '15rem',
    minWidth: '5rem',
  },
  '& tr:last-child td': {
  },
  '& tr td:first-child': {
    maxWidth: '15rem',
    minWidth: '5rem',
  },
  '& tr td:last-child': {
  },
});

const CustomizedTableHeadCell = styled(TableCell)({
  background: "#E7F3FB",
  padding:'4px',
  '@media print': {
  }
});

const CustomizedTableHeadColmunCell = styled(TableCell).bind({})<{ color?: string, fontColor: string, printWidthAuto?:boolean; }>`
  background: ${({ color }) => color ? color : "#F7F5F5"};
  color: ${({ fontColor }) => fontColor};
  max-width: 15rem;
  min-width: 4rem;
  padding: 4px 8px;
  @media print {
    width: ${({printWidthAuto})=> printWidthAuto ? 'auto': '30%'};
  }
`;
const StyledTableCell = styled(TableCell)`
  padding: 4px 8px;
  @media print {
    padding: 4px 8px;
    max-width: 30%;
  }
`
const StyledTableContainer = styled(TableContainer)`
  @media print {
    .MuiTable-root {
      min-width: auto;
    }
  }
`

const TotalJudgeTableCell = styled(TableCell)({
  color: "rgb(241, 113, 96)",
  fontSize: 24,
  fontWeight: "bold",
  padding: 0
})

const CustomizedTableFirstCell = styled(TableCell)({
  padding:'4px',
  '@media print': {
  }
});
