import React from "react";

export const nl2br = (text: string | null | undefined | React.ReactNode) => {
  const list: React.ReactNode[] = [];
  if(typeof text !== 'string') return text;
  if (text) {
    const regex = /(\n)/g;
    const httpTag = "http://";
    const httpsTage = "https://";
    text.split(regex).map((line, i) => {
      if (line.match(regex)) {
        list.push(<br key={i} />);
      } else if (line.match(httpTag) || line.match(httpsTage)) {
        list.push(<a key={i} href={line} target="_blank" rel="noopener noreferrer">{line}</a>);
      } else {
        list.push(line);
      }
    });
  } else {
    list.push(<div />);
  }
  return list;
};
