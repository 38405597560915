import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createSelectorHook, useDispatch } from 'react-redux';
import { testReducer } from './test';
import { loginedReducer } from './logined';
import { idTokenReducer } from './idToken';
import { questionsReducer } from './questions';
import { hasNotReadNotificationSliceReducer } from './hasNotReadNotification';

const reducer = combineReducers({
  test: testReducer,
  logined: loginedReducer,
  idToken: idTokenReducer,
  questions: questionsReducer,
  hasNotReadNotification: hasNotReadNotificationSliceReducer
});

export const store = configureStore({
  reducer,
});

type RootState = ReturnType<typeof store.getState>;

// @ts-ignore
export const useTypedSelector = createSelectorHook<RootState>();
export declare type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
