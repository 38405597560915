export const minToHHMM = (min?: number, lang?: 'ja' | 'en') => {
  if(!lang  || lang === 'ja') {
    if(min===0) return '0秒';
    if(!min) return '';
    const hh = Math.floor(min / 60);
    const mm = Math.floor(min % 60);
    const ss = Math.round((min * 60) % 60);
  
    if(!hh && !mm) {
      return `${ss}秒`;
    } 
    else if (!hh) {
      return `${mm}分${ss}秒`;
    }
    else if (!mm && !ss) {
      return `${hh}時間`;
    }
    else if (!ss) {
      return `${hh}時間${mm}分`;
    }
    else {
      return `${hh}時間${mm}分${ss}秒`;
    }
  } else {
    if(min===0) return '0sec';
    if(!min) return '';
    const hh = Math.floor(min / 60);
    const mm = Math.floor(min % 60);
    const ss = Math.round((min * 60) % 60);
  
    if(!hh && !mm) {
      return `${ss}sec`;
    } 
    else if (!hh) {
      return `${mm}min${ss}sec`;
    }
    else if (!mm && !ss) {
      return `${hh}hr`;
    }
    else if (!ss) {
      return `${hh}hr${mm}min`;
    }
    else {
      return `${hh}hr${mm}min${ss}sec`;
    }
  }
}