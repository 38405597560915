import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TwoRowsLabelsChart, { DataPoint } from './TimeSerisGrap';
import { Affiliation, Analyzation, Patients } from '@sleepwell_new_platform/common-types';
import { AnalyzationResult } from '@sleepwell_new_platform/common-types/src/analyzationResults';
import { Progress } from '../../atoms/Progress';
import dayjs from '../../../lib/dayjs';
import { theme } from '../../../provider/ThemeProvider';
import { ToltalJudgeTable } from '../../molecules/Table';
import { AverageItem } from '@sleepwell_new_platform/common-types/src/averageItems';
import { getAge } from '../../../utils/Moment';
import { convertGenderType } from '../../../utils/Types';
import { styled } from '@mui/material';

type Props = {
  selectedAnalyzations: Analyzation[];
  selectedAnalyzationResults: AnalyzationResult[];
  isLoadingTimeSerisData: boolean;
  patients: Patients[];
  affiliations: Affiliation[];
  averageItems: AverageItem[];
  lang: 'ja' | 'en';
}

function calculateAge(birthday: string) {
  // バースデイの日付をDateオブジェクトとしてパース
  let birthDate = new Date(birthday);
  let today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  // 今年の誕生日をまだ迎えていない場合は、年齢を1歳減らす
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}


const Timeseries = React.memo((props: Props) => {
  const { selectedAnalyzations, selectedAnalyzationResults, isLoadingTimeSerisData, patients, affiliations, averageItems, lang} = props;
  const patient = useMemo(() => {
    if(!selectedAnalyzations || !patients) return;
    const analyzation = selectedAnalyzations[0];
    const patient = patients.find(patient => patient.id === analyzation.patientId);
    return patient;
  }, [selectedAnalyzations, patients]);

  const affiliationName = useMemo(() => {
    if(!selectedAnalyzations || !affiliations) return;
    const analyzation = selectedAnalyzations[0];
    const affiliation = affiliations.find(affiliation => affiliation.id === analyzation.affiliationId);
    return affiliation?.name;
  }, [selectedAnalyzations, affiliations]);

  const age = useMemo(() => {
    const analyzation = selectedAnalyzations[0];
    const patient = patients.find(patient => patient.id === analyzation.patientId);
    const birthDate = patient?.birthday;
    const measureStartDate = analyzation?.measureStartDate;
    if (!birthDate || !measureStartDate) return 0;
    const birthDateYear = dayjs(birthDate).year();
    const measureStartDateYear = dayjs(measureStartDate).year();
    return measureStartDateYear - birthDateYear;
  }
  , [selectedAnalyzations, patients]);
  

  const colums = useMemo(() => {
    let tempColums =  [];
    if(lang === 'ja') {
      tempColums =  [
        {value: 'tib', title: "1.総就床時間", subTitle: '測定時間', unit: "分"},
        {value: 'sl', title: "2.寝つき", subTitle: '入眠潜時', unit: "分"},
        {value: 'totalDetalPowerParMin', title: "3.熟睡度", subTitle: '第1睡眠周期のδパワー値/1分あたり', unit: "μV²/分"},
        {value: 'wStageTotalMin', title: "4.中途覚醒", subTitle: '', unit: "%"},
        {value: 'seTib', title: "5.睡眠効率TST/TIB", subTitle: '', unit: "%"},
        {value: 'sleepRism', title: "6.睡眠リズム", subTitle: '', unit: "分"},
        {value: 'matureSleepBrainwaveAge', title: "7.熟眠脳波年齢", subTitle: '', unit: "歳"},
      ]
    } else {
      tempColums = [
        {value: 'tib', title: "1.Time in Bed", subTitle: 'Time length of measurement', unit: "minutes"},
        {value: 'sl', title: "2.sleep latency", subTitle: 'Sleep latency', unit: "minutes"},
        {value: 'totalDetalPowerParMin', title: "3.Depth of Sleep", subTitle: 'delta power at 1st period/min', unit: "μV²/minutes"},
        {value: 'wStageTotalMin', title: "4.Wake after sleep onset", subTitle: '', unit: "%"},
        {value: 'seTib', title: "5.Sleep Efficiency TST/TIB", subTitle: '', unit: "%"},
        {value: 'sleepRism', title: "6.rhythm", subTitle: 'Stage R latency', unit: "minutes"},
        {value: 'matureSleepBrainwaveAge', title: "7.Deep Sleep Brainwave Age", subTitle: '', unit: "age"},
      ]
    }
    if(age < 19){
      tempColums = tempColums.filter(column => column.value !== 'sleepRism' && column.value !== 'matureSleepBrainwaveAge');
      // if(lang === 'ja') {
      //   tempColums.push( {value: 'matureSleepBrainwaveAge',  title: "6.熟眠脳波年齢", subTitle: '', unit: "歳"})
      // }else {
      //   tempColums.push( {value: 'matureSleepBrainwaveAge', title: "6.Deep Sleep Brainwave Age", subTitle: '', unit: "age"})
      // }
    }
    return tempColums;
  }, [lang, age]) 

  const arrayData = useMemo(() => {
    if(!selectedAnalyzationResults) return;
    const analyzation = selectedAnalyzations[0];
    const patient = patients.find(patient => patient.id === analyzation.patientId);
    const gender = convertGenderType(patient?.gender ?? "");
    // const sortedAnalyzationResult = selectedAnalyzationResults.sort((a, b) => b.createdAt　- a.createdAt < 0 ? 1 :  -1);
    const sortedAnalyzationResult = selectedAnalyzationResults.sort((a, b) => {
      const aAnalyzationId = a.analyzationId;
      const aAnalyzation = selectedAnalyzations.find((doc) => doc.id === aAnalyzationId);
      const bAnalyzationId = b.analyzationId;
      const bAnalyzation = selectedAnalyzations.find((doc) => doc.id === bAnalyzationId);
      return (bAnalyzation?.measureStartDate ?? 0) - (aAnalyzation?.measureStartDate ?? 0) < 0 ? 1 :  -1;
    });

    const list: Array<{timeDataList: DataPoint[], title: string, average: number, subTitle: string, unit: string, range: number[]}> = [];
    const filteredAverageItems  = averageItems.filter((doc) => doc.ageMaxRange >= age && doc.ageMinRange <= age && doc.gender === gender);
    const averageData = filteredAverageItems[0];
    colums.map(column => {
      const arrayList: DataPoint[] = [];
      if(sortedAnalyzationResult){
        sortedAnalyzationResult.map(analyzation => {
          const analyzationId = analyzation?.analyzationId;
          const analyzationData = selectedAnalyzations.find((doc) => doc.id === analyzationId);
          arrayList.push({
            date: dayjs(analyzationData?.measureStartDate).format('YYYY/MM/DD'),
            value: column?.value ? getValue(column?.value as keyof AnalyzationResult, analyzation) : 0
        })
      });
      }

    list.push({
      timeDataList: arrayList,
      title: column.title,
      average: getAverage(column.value as keyof AnalyzationResult, averageData, sortedAnalyzationResult),
      subTitle: column.subTitle,
      unit: column.unit,
      range: getRage(column.value, age)
    });
    // カラムで回す
  });
  return list;
}, [selectedAnalyzationResults, patients, lang, age]);

const headersColumns = useMemo(() => {
  const sortedAnalyzationResult = selectedAnalyzationResults.sort((a, b) => b.createdAt　- a.createdAt < 0 ? 1 :  -1);
  return  sortedAnalyzationResult ? sortedAnalyzationResult.map(analyzation => dayjs(analyzation?.createdAt).format('YYYY/MM/DD')) : sortedAnalyzationResult;
},[selectedAnalyzationResults]);

const toltalJudgeColumns = useMemo(() => {
  const sortedAnalyzationResult = selectedAnalyzationResults.sort((a, b) => b.createdAt　- a.createdAt < 0 ? 1 :  -1);
  return sortedAnalyzationResult ? sortedAnalyzationResult.map(analyzation => analyzation?.totalJudgeInfo.totalJudge) : sortedAnalyzationResult;
},[selectedAnalyzationResults]);

  if(isLoadingTimeSerisData || !arrayData) return <div style={{height: '60vh'}}><Progress width={'calc(100% - 64px);'}/></div>
  return(
      <Container>
      <Box sx={{mt: 4}}>
        {lang === 'ja' ? 
        <Typography variant="body1">
        作成日：{dayjs().format('YYYY年MM月DD日')}　氏名：{patient?.name ?? ""}　受診者番号：{patient?.patientNumber ?? ""}　所属：{affiliationName} 　年齢：{age}歳　性別： {patient?.gender ?? ""}
        </Typography>: 
         <Typography variant="body1">
         Created date：{dayjs().format('YYYY年MM月DD日')}　Name：{patient?.name ?? ""}　ID：{patient?.patientNumber ?? ""}　Affiation：{affiliationName} 　Age：{age} years old　Sex： {patient?.gender ?? ""}
         </Typography>}
      </Box>
      <Box
        sx={{
          backgroundColor: "#4B4B4B",
          width: "100%",
          mt: 2,
          mb: 2.5,
          }}
          display={'flex'}
          >
        <Typography variant="body1" sx={{
          py: 1.5,
          pl: 2.5,
          fontWeight: 700,
          fontSize: '20px',
          color: theme.palette.text.secondary}}>
          {lang === 'ja' ? "総合判定" : 'inspection result'}
        </Typography>
      </Box>
      <ToltalJudgeTable
        headers={headersColumns}
        rows={[toltalJudgeColumns]}
        isAllCellCenter
      />
      {arrayData && arrayData.map((data, index) => (
          <>
          {index === 4 && <div style={{ pageBreakAfter: 'always' }}></div>}
          <TwoRowsLabelsChart
            title={data.title}
            average={data.average}
            timeDataList={data.timeDataList}
            subTitle={data.subTitle}
            unit={data.unit}
            lang={lang}
            className={index === 5 ? "print-padding-top" : ""}
            range={data.range}
          />
        </>))}
    </Container>
  )
});
export default  Timeseries;

// value, label, date, average


const getAverage = (key: string, averageData: AverageItem, analyzationResults: AnalyzationResult[]) => {
  if(key === "totalDetalPowerParMin"){
    // 熟眠度
    const analyzation = analyzationResults.find((doc) => doc?.sleepQualityAverage !== null);
    return analyzation?.sleepQualityAverage ?? 0;
  }
  if(key === "totalDetalPowerParMin"){
    return !isNaN(Number(averageData["firstSleepCycleDeltaPowParMin"])) ? Number(averageData["firstSleepCycleDeltaPowParMin"]) : 0;
  }
  if(key === "sleepRism"){
      // 睡眠リズム
      const analyzation = analyzationResults.find((doc) => doc?.sleepRismAverage !== null);
      return analyzation?.sleepRismAverage ?? 0;
  }
  if(key === 'wStageTotalMin'){
    // 中途覚醒
    const analyzation = analyzationResults.find((doc) => doc?.wStageTotalMinAverage !== null);
    return analyzation?.wStageTotalMinAverage ?? 0;
  }
  if(key === 'seTib'){
    // 睡眠効率TST/TIB
    const analyzation = analyzationResults.find((doc) => doc?.seTibAverage !== null);
    return analyzation?.seTibAverage ?? 0;
  } 
  return !isNaN(Number(averageData[key as keyof AverageItem])) ? Number(averageData[key as keyof AverageItem]) : 0;
}

const getValue = (key: string, analyzation: AnalyzationResult) => {
  if(key === "totalDetalPowerParMin"){
    // 熟眠度
    return analyzation?.firstNonremDeltaPowerInfo?.totalDeltaPowParMin;
  }
  if(key === "sleepRism"){
    // 睡眠リズム
    return analyzation?.sleepRism;
  }
  if(key === 'wStageTotalMin'){
    // 中途覚醒
    return analyzation?.wStageRatio;
  }
  return  Number(analyzation?.[key as keyof AnalyzationResult]) ?? 0
}

const getRage = (key: string, age: number) => {
  switch(key){
    case 'tib':
      return [180, 240, 360,480, 600];
    case 'sl':
      return [0, 15, 30, 45, 60, 75, 90];
    case 'totalDetalPowerParMin':
      if(age < 19){
        return [0, 5000, 10000, 15000, 20000];
      } else {
      return [0, 1000, 2000, 3000, 4000, 5000];
      }
    case 'wStageTotalMin':
      return [0, 5, 10, 15, 20, 25, 30];
    case 'seTib':
      return [60, 70, 80, 90, 100];
    case 'sleepRism':
      return [0, 0.1, 0.2, 0.3];
    case 'matureSleepBrainwaveAge':
      return [20, 40, 60 ,80];
    default:
      return [];
}
}
const Container = styled('div')`
  @media print {
    border: none;
    padding: 0 12px;
  }
`;