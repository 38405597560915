import { Pages } from './components/pages';
import { store } from './redux';
import { Provider } from 'react-redux';
import { ThemeProviderWrap } from './provider/ThemeProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const App = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProviderWrap>
        <Provider store={store}>
          <Pages />
        </Provider>
        </ThemeProviderWrap>
      </QueryClientProvider>
    </>
  );
};

export default App;
