import { useLocation } from "react-router-dom";
import { PersonalAnalyzations } from "../organisms/personalAnalyzation/PersonalAnalyzations";
import Page from "../templates/Page"

const PersonalAnalyzationsPage = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const patientId = pathname.split("/")[2];
  const query = new URLSearchParams(location.search);
  const analyzationIds = query.get("analyzationIds");
  console.log(analyzationIds?.split(","))
  
  return(
    <Page
      title={"受診者ごと解析一覧"}
    >
      <PersonalAnalyzations
        patientId={patientId}
        analyzationIds={analyzationIds?.split(",")}
      />
    </Page>
  )
}

export default  PersonalAnalyzationsPage;