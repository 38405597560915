export const DownloadIcon = () => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.6875 12.6123C0.869836 12.6123 1.0447 12.6847 1.17364 12.8137C1.30257 12.9426 1.375 13.1175 1.375 13.2998V16.7373C1.375 17.102 1.51987 17.4517 1.77773 17.7096C2.03559 17.9674 2.38533 18.1123 2.75 18.1123H19.25C19.6147 18.1123 19.9644 17.9674 20.2223 17.7096C20.4801 17.4517 20.625 17.102 20.625 16.7373V13.2998C20.625 13.1175 20.6974 12.9426 20.8264 12.8137C20.9553 12.6847 21.1302 12.6123 21.3125 12.6123C21.4948 12.6123 21.6697 12.6847 21.7986 12.8137C21.9276 12.9426 22 13.1175 22 13.2998V16.7373C22 17.4667 21.7103 18.1661 21.1945 18.6818C20.6788 19.1976 19.9793 19.4873 19.25 19.4873H2.75C2.02065 19.4873 1.32118 19.1976 0.805456 18.6818C0.289731 18.1661 0 17.4667 0 16.7373V13.2998C0 13.1175 0.0724328 12.9426 0.201364 12.8137C0.330295 12.6847 0.505164 12.6123 0.6875 12.6123Z" fill="#529BC5" />
      <path d="M10.5131 15.2992C10.577 15.3633 10.6529 15.4141 10.7364 15.4487C10.8199 15.4834 10.9095 15.5012 10.9999 15.5012C11.0903 15.5012 11.1799 15.4834 11.2634 15.4487C11.3469 15.4141 11.4228 15.3633 11.4866 15.2992L15.6116 11.1742C15.7407 11.0452 15.8133 10.8701 15.8133 10.6875C15.8133 10.5049 15.7407 10.3298 15.6116 10.2008C15.4825 10.0717 15.3075 9.99913 15.1249 9.99913C14.9423 9.99913 14.7672 10.0717 14.6381 10.2008L11.6874 13.1529V1.0625C11.6874 0.880164 11.615 0.705295 11.486 0.576364C11.3571 0.447433 11.1822 0.375 10.9999 0.375C10.8176 0.375 10.6427 0.447433 10.5138 0.576364C10.3848 0.705295 10.3124 0.880164 10.3124 1.0625V13.1529L7.36164 10.2008C7.23255 10.0717 7.05746 9.99913 6.87489 9.99913C6.69233 9.99913 6.51724 10.0717 6.38814 10.2008C6.25905 10.3298 6.18652 10.5049 6.18652 10.6875C6.18652 10.8701 6.25905 11.0452 6.38814 11.1742L10.5131 15.2992Z" fill="#529BC5" />
    </svg>
  )
}

export const DownLoad = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 20C5.45 20 4.979 19.804 4.587 19.412C4.195 19.02 3.99934 18.5493 4 18V15H6V18H18V15H20V18C20 18.55 19.804 19.021 19.412 19.413C19.02 19.805 18.5493 20.0007 18 20H6ZM12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16Z" fill="#529BC5"/>
    </svg>
  )
}