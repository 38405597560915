import { Typography } from '@mui/material';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/system';
import { forwardRef } from 'react';

type Props = TextFieldProps & {
  title?: string,
  required?: boolean,
  width?: string | number
};

export const TextField = (props: Props) => {
  const title = props.title;
  const required = props.required ? props.required : false;
  const width = props.width || '100%';
  return (
    <>
      {title && <Title data-required={required} variant="body1" gutterBottom>{title}</Title>}
      <StyledTextField {...props} variant='outlined' style={{width: width}}/>
    </>);
};

export const AnaluzationTextField = (props: Props) => {
  return <StyledTextField variant='outlined' {...props} />;
};

export const ForwardTextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <TextField {...props} inputRef={ref} style={{width: props?.width}}/>;
});

export const AnaluzationForwardTextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <>
      {props.title && (
        <Title
          data-required={props.required}
          variant="body1"
        >
          {props.title}
        </Title>
      )}
      <AnaluzationTextField {...props} inputRef={ref} style={{width: props?.width}} required={props.required} />
    </>
  );
});

const StyledTextField = styled(MuiTextField)(() => ({
  '.MuiOutlinedInput-root': {
    padding: '10px 20px',
  },
  '.MuiInputBase-root': {
    padding: '0 20px'
  },
  '.MuiInputBase-input': {
    paddingLeft: 0,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10
  }
}));

const Title = styled(Typography)`
    margin: 10px  ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1)}px 0;
    margin-top: 10px;
    width: calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left: ${props => props.theme.spacing(2)}px;
          font-size: 18px;
          color:red;
        }
    }
    //font-family: Noto Sans JP;
    font-family: YuGothic;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    color: #4B4B4B;
`

export const SearchDateRangeTitle = styled(Typography)`
    margin-top: 0px;
    width: calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left: ${props => props.theme.spacing(2)}px;
          font-size: 18px;
          color:red;
        }
    }
    //font-family: Noto Sans JP;
    font-family: YuGothic;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #4B4B4B;
`

