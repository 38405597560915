import { AnalyzationRequest } from "../organisms/AnalyzationRequest";
import Page from "../templates/Page"

const AnalyzationRequestPage = () => {

  return (
    <Page
      title={'解析依頼ページ'}
    >
      <AnalyzationRequest/>
    </Page>
  )
}

export default AnalyzationRequestPage;