export const manDeltaPowerData = [
  {age: "19", value: 4792.4},
  {age: "20", value: 4558.2},
  {age: "21", value: 4324.0},
  {age: "22", value: 4089.8},
  {age: "23", value: 3855.7},
  {age: "24", value: 3621.5},
  {age: "25", value: 3387.3},
  {age: "26", value: 3153.1},
  {age: "27", value: 3013.4},
  {age: "28", value: 2873.6},
  {age: "29", value: 2733.8},
  {age: "30", value: 2594.0},
  {age: "31", value: 2454.3},
  {age: "32", value: 2314.5},
  {age: "33", value: 2174.7},
  {age: "34", value: 2034.9},
  {age: "35", value: 1895.2},
  {age: "36", value: 1849.3},
  {age: "37", value: 1803.5},
  {age: "38", value: 1757.7},
  {age: "39", value: 1711.9},
  {age: "40", value: 1666.1},
  {age: "41", value: 1620.3},
  {age: "42", value: 1574.5},
  {age: "43", value: 1528.7},
  {age: "44", value: 1482.9},
  {age: "45", value: 1437.1},
  {age: "46", value: 1412.1},
  {age: "47", value: 1387.1},
  {age: "48", value: 1362.1},
  {age: "49", value: 1337.1},
  {age: "50", value: 1312.2},
  {age: "51", value: 1287.2},
  {age: "52", value: 1262.2},
  {age: "53", value: 1237.2},
  {age: "54", value: 1212.2},
  {age: "55", value: 1187.2},
  {age: "56", value: 1174.7},
  {age: "57", value: 1162.1},
  {age: "58", value: 1149.5},
  {age: "59", value: 1137.0},
  {age: "60", value: 1124.4},
  {age: "61", value: 1111.8},
  {age: "62", value: 1099.3},
  {age: "63", value: 1086.7},
  {age: "64", value: 1074.1},
  {age: "65", value: 1061.6},
  {age: "66", value: 1031.6},
  {age: "67", value: 1001.6},
  {age: "68", value: 971.6},
  {age: "69", value: 941.6},
  {age: "70", value: 911.6},
  {age: "71", value: 881.6},
  {age: "72", value: 851.6},
  {age: "73", value: 821.6},
  {age: "74", value: 791.6},
  {age: "75", value: 761.6}
]

export const womanDeltaPowerData = [
  {age: "19", value: 4720.2},
  {age: "20", value: 4566.3},
  {age: "21", value: 4412.4},
  {age: "22", value: 4258.5},
  {age: "23", value: 4104.6},
  {age: "24", value: 3950.7},
  {age: "25", value: 3796.8},
  {age: "26", value: 3642.9},
  {age: "27", value: 3524.3},
  {age: "28", value: 3405.7},
  {age: "29", value: 3287.1},
  {age: "30", value: 3168.5},
  {age: "31", value: 3049.9},
  {age: "32", value: 2931.3},
  {age: "33", value: 2812.7},
  {age: "34", value: 2694.1},
  {age: "35", value: 2575.5},
  {age: "36", value: 2501.5},
  {age: "37", value: 2427.6},
  {age: "38", value: 2353.6},
  {age: "39", value: 2279.6},
  {age: "40", value: 2205.7},
  {age: "41", value: 2131.7},
  {age: "42", value: 2057.8},
  {age: "43", value: 1983.8},
  {age: "44", value: 1909.9},
  {age: "45", value: 1835.9},
  {age: "46", value: 1826.6},
  {age: "47", value: 1817.4},
  {age: "48", value: 1808.1},
  {age: "49", value: 1798.8},
  {age: "50", value: 1789.6},
  {age: "51", value: 1780.3},
  {age: "52", value: 1771.0},
  {age: "53", value: 1761.8},
  {age: "54", value: 1752.5},
  {age: "55", value: 1743.2},
  {age: "56", value: 1734.8},
  {age: "57", value: 1726.4},
  {age: "58", value: 1718.0},
  {age: "59", value: 1709.6},
  {age: "60", value: 1701.2},
  {age: "61", value: 1692.8},
  {age: "62", value: 1684.4},
  {age: "63", value: 1676.0},
  {age: "64", value: 1667.6},
  {age: "65", value: 1659.2},
  {age: "66", value: 1636.3},
  {age: "67", value: 1613.4},
  {age: "68", value: 1590.5},
  {age: "69", value: 1567.6},
  {age: "70", value: 1544.7},
  {age: "71", value: 1521.8},
  {age: "72", value: 1498.9},
  {age: "73", value: 1476.0},
  {age: "74", value: 1453.1},
  {age: "75", value: 1430.2}
]