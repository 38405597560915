import dayjs from "dayjs";

export const convertToGlobalPhoneNumber = (phoneNumber: string) => {
  return '+81' + phoneNumber.slice(1);
};

export const convertGlobalToLocalPhoneNumber = (phoneNumber: string | null | undefined) => {
  if (phoneNumber) {
    return '0' + phoneNumber?.slice(3);
  }
  return '';
};

export const maskPhoneNumber = (phoneNumber: string) => {
  // 例： +819012345678 -> *******5678
  return '*******' + phoneNumber.slice(9);
};

export const calculateAge = (birthday: string, measureStartDate: number) => {
  const measuredDate = dayjs(measureStartDate);
  const birth = dayjs(birthday);
  const age = measuredDate.diff(birth, 'year');
  return age;
}

export const getYear = (birthday: string) => {
  const birth = dayjs(birthday);
  return birth.format('YYYY');
}

export const getMonth = (birthday: string) => {
  const birth = dayjs(birthday);
  return birth.format('MM');
}
export const getDay = (birthday: string) => {
  const birth = dayjs(birthday);
  return birth.format('DD');
}


export const convertToYYYYMMDD = (data?: string) => {
  if(data){
    const date = data.replaceAll('/', '').replaceAll('-', '');
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    const result = `${year}/${month}/${day}`;
    return dayjs(result).valueOf();
  } else {
    return dayjs().valueOf();
  }
}

export const roundToTwoDecimalPlaces = (num: number) => {
  return Math.round(num * 10) / 10;
}

// 1以下なら1を返す
// 5以上なら5を返す
export const roundToFive = (num: number) => {
  if (num <= 1) {
    return 1;
  }
  if (num >= 5) {
    return 5;
  }
  return num;
}