import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { BodyText } from '../atoms/Text';
import { styled } from '@mui/system';

type Props = {
  open: boolean;
  message?: string
}

export const SimpleBackdrop=(props: Props) => {
  const { open, message } = props;
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          open
        }
      >
      <Wrap>
        <CircularProgress color="inherit" />
        <div style={{marginTop: 24}}><BodyText style={{fontWeight: 700, fontSize: 16, color: 'white'}}>{message ? message : ''}</BodyText></div>
        </Wrap>
      </Backdrop>
    </div>
  );
}


const Wrap = styled('div')({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})
