import { Typography, AccordionSummary, AccordionDetails, Accordion as SimpleAccordion } from "@mui/material"
import { ReactNode } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
  title: string;
  children: ReactNode;
  expanded: string | false;
  handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  panel: string
}
export const Accordion = (props: Props) => {
  const {title, children, expanded, handleChange, panel} = props;
  return(
    <SimpleAccordion expanded={expanded === panel} onChange={handleChange(panel)}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      style={{background: "#F2F2F2", color: "#529BC5"}}
    >
      <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bold"}}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails style={{padding: 0}}>
      {children}
    </AccordionDetails>
  </SimpleAccordion>
  )
}