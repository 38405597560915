import { styled } from '@mui/material';
import { Patients } from '@sleepwell_new_platform/common-types';
import { AverageItem } from '@sleepwell_new_platform/common-types/src/averageItems';
import React, { useMemo } from 'react';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, ResponsiveContainer, ReferenceLine, ReferenceDot, XAxisProps} from 'recharts';
import { convertGenderType } from '../../../utils/Types';
import { manDeltaPowerData, womanDeltaPowerData } from '../../../static/deltaPower';

type Props = {
  height?: number | string;
  averageItems: AverageItem[];
  patientData: Patients | undefined;
  lang: 'ja' | 'en';
  matureSleepBrainwaveAge: number;
  totalDeltaPowParMin: number
}

const DeletaSampleChart = (props: Props) => {
  const { averageItems, patientData, lang, matureSleepBrainwaveAge, totalDeltaPowParMin } = props;
  const ageData = useMemo(() => {
    const gender = convertGenderType(patientData?.gender);
  if(gender === 'woman'){
    return womanDeltaPowerData;
   } else {
    return manDeltaPowerData;
   }
  },[averageItems]);

  const ageCategory = useMemo(() => {
    const age = matureSleepBrainwaveAge;
    if(age > 75) return '75';
    return String(Math.round(age));
  },[])
  const xAxisProps = (lang: string) =>{
    return {
      height: 60,
      dataKey: 'age',
      label: lang === 'ja' ? '年齢' : 'Age'
    }
  };
  const yAxisData  = useMemo(() => {
    const IntMatureSleepBrainwaveAge = Math.floor(matureSleepBrainwaveAge);
    // 19 ~ 74歳
    if(IntMatureSleepBrainwaveAge < 75 && IntMatureSleepBrainwaveAge > 19){
      return ageData.find((data) => data.age === String(IntMatureSleepBrainwaveAge))?.value;
    }
    if(IntMatureSleepBrainwaveAge === 19){
      // 25歳の場合 5000 より大きい場合は 5000 を返す
      const average = ageData.find((data) => data.age === '19')?.value;
      if((average ?? 0) < totalDeltaPowParMin && totalDeltaPowParMin < 5000) return totalDeltaPowParMin;
      if((average ?? 0) < totalDeltaPowParMin && totalDeltaPowParMin > 5000) return 5000;
      return average;
    }
    if(IntMatureSleepBrainwaveAge >= 75){
      // 75歳の場合 平均値より より小さい場合は 熟眠度の測定値 を返す
      const average = ageData.find((data) => data.age === '75')?.value;
      if(average ?? 0 > totalDeltaPowParMin) return totalDeltaPowParMin;
      return average;
    }
  },[ageData, matureSleepBrainwaveAge, totalDeltaPowParMin]);
  const customTicks = ['19', '22', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75'];
  return (
    <ChartWrap>
    <SubContainer>
    <div style={{fontSize: 14, marginBottom: 8}}>{lang === 'ja' ?'パワー値(μV2/エポック)' : 'power (μV²/epoch)'}</div>
    <ResponsiveContainer width="100%" height={props.height ?? 400}>
      <AreaChart data={ageData} margin={{ left: -20, right: 20, top: 10  }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis  
          {...xAxisProps(lang)} 
          ticks={customTicks}
          tickLine={true} // ティックラインを表示
          tick={{ fill: '#666', fontSize: 12 }} 
          />
        <YAxis label={{ value: '', position: 'insideLeft', angle: -90, offset: -10, fontSize: 12 }} domain={[0, 5000]} tick={{ fill: '#666', fontSize: 12 }}/>
        <Tooltip />
        <Area type="linear" dataKey="value" stroke="#529BC5" fill="#529BC5" />
        <ReferenceDot x={ageCategory} y={yAxisData} stroke="rgb(241, 113, 95)" fill="rgb(241, 113, 95)" />
      </AreaChart>
    </ResponsiveContainer>
    </SubContainer>
  </ChartWrap>
  );
};

export default DeletaSampleChart;
const ChartWrap = styled('div')({
  border: "1px solid #E2E8F0",
  background: "#FFF",
  padding: "24px 12px",
});

const SubContainer = styled('div')({
  '@media print': {
    transform: 'scale(0.9) translateY(-5%)',
  }
});