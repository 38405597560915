import React from 'react';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';

/*type SelectItem = {
  label: string;
  value: string;
}*/

type Props = {
  title?: string,
  items: any[],
  value: string | number | null,
  helperText?: string,
  required?: boolean,
  placeholder?: string,
  disabled?: boolean,
  onChange: (e: any) => void,
  error?: boolean,
  width?: number | string
}

export const SelectInput = ({
  title,
  items,
  value,
  helperText,
  required,
  placeholder,
  disabled,
  onChange,
  error,
  width
}: Props) => {
  return (
    <Div>
      {title && <Title data-required={required} variant="body1" gutterBottom style={{fontSize: 16, marginBottom: 0}}>{title}</Title>}
      <StyledForm variant="outlined"   style={{ width:  width ? width : 375 }}>
        <StyledSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
          style={{ width:  width ? width : 375, backgroundColor: '#fff',  }}
          displayEmpty
        >
          {placeholder && <MenuItem value="" disabled>{placeholder}</MenuItem>}
          {items.map((item: any, index: number) => (
            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
          ))}
        </StyledSelect>
        <FormHelperText sx={{ color: error ? '#d32f2f' : '#4B4B4B' }}>{helperText}</FormHelperText>
      </StyledForm>
    </Div>
  );
}

const StyledSelect = styled(Select)`
    margin: ${props => props.theme.spacing(0)}px ${props => props.theme.spacing(0)}px ${props => props.theme.spacing(0)}px ${props => props.theme.spacing(1)}px;
    .MuiSelect-select {
      padding: 10px 20px;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
      width: 375px;
    }
`
const StyledForm = styled(FormControl)`
`
const Div = styled('div')`
    display: flex;
    flex-flow: column;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  }
`
const Title = styled(Typography)`
    margin: 10px  ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1)}px 0;
    width: calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left: 5px;
          font-size: 20px;
          color:red;
        }
    }
    //font-family: Noto Sans JP;
    font-family: YuGothic;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    color: #4B4B4B;
`
