import dayjs from 'dayjs'
import 'dayjs/locale/ja'

dayjs.locale('ja') // use locale


const ONEDAY = 24 * 60 * 60 * 1000; //1日をミリ秒に変換(JSにおける1日を表す)
const DUMMY_LEAP_DAY_SERIAL_VAL = 61;

// UNIX時間は1970年からの経過m秒なので、1900年から1970年までの経過日数を求める。
// 17は閏年の数。1900年に存在しない閏年を足す。
// シリアル値が「1900/1/0」という存在しない日付分を足す
const DATES_OFFSET = 70 * 365 + 17 + 1 + 1;

const changeJSDate = (serialNumber: number): number => {
  // シリアル値→UNIX時間
  return (serialNumber - DATES_OFFSET) * ONEDAY;
};

/**
 * SEE: https://dev.classmethod.jp/articles/seriall_changedate/
 * @param serialNumber
 * @returns
 */
export const numDate = (serialNumber: number): dayjs.Dayjs => {
  const JS_DATE = changeJSDate(serialNumber);
  if (DUMMY_LEAP_DAY_SERIAL_VAL <= serialNumber) {
    return dayjs(JS_DATE);
  }
  return dayjs(JS_DATE + ONEDAY);
};


export default dayjs
