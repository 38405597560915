import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
//import { logout } from '../amplify/auth';
import { AwsResourcesToRedux } from '../aws/api/awsResourcesToRedux';
import { Progress } from '../components/atoms/Progress';
import { PageFrame } from '../components/organisms/PageFrame';
import { useTypedSelector } from '../redux';
import {
  SleepwellApi
} from '@sleepwell_new_platform/common-functions/lib/api';
import axios from 'axios';
const api = new SleepwellApi(axios);

const IsLogined = (props: any) => {
  const logined = useTypedSelector(state => state.logined);
  const idToken = localStorage?.idToken;
  //const clinicName = localStorage?.clinicName;
  const clinicId = localStorage?.clinicId;
  const username = localStorage?.username;
  //const idToken = useTypedSelector(state => state.idToken);
  const [loadingAdmin, setLoadingAdmin] = useState(true);
  const [clinicName, setClinicName] = useState('');
  const getClinic = async() => {
    const clinic = await api.getClinics(clinicId);
    setClinicName(clinic[0]?.nameJP);
  };
  useEffect(() => {
    if (logined) {
      //loading判定にadminIdを使用
      //if (admin.adminId) {
      setLoadingAdmin(false);
      //}
      getClinic();
    }
  }, [logined, idToken/*admin*/]);

  if (logined === false) {
    return <Navigate to='/login' />
  }
  if (!localStorage?.idToken) {
    return <Navigate to='/login' />
  }

  if (loadingAdmin) {
    return (
      <>
        <AwsResourcesToRedux />
        <Progress width='100%'/>
      </>
    );
  } else {
    return (
      <>
        <PageFrame clinicName={clinicName} username={username}>
          <AwsResourcesToRedux />
          {props.children}
        </PageFrame>
      </>
    )
  }
}
export default IsLogined;
