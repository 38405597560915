import { Box, Typography, styled } from "@mui/material";
import { theme } from '../../provider/ThemeProvider'

type HeadlineProps = {
  title: string,
};

export const Headline = ({ title }: HeadlineProps) => {
  return (
    <StyledHeadlineBox
      sx={{
        backgroundColor: "#4B4B4B",
        width: 1
      }}
    >
      <StyledPrintTypography variant="body1" sx={{
          py: 1,
          pl: 1,
          fontWeight: 600,
          color: theme.palette.text.secondary}}>
          {title}
      </StyledPrintTypography>
    </StyledHeadlineBox>
  );
};

const StyledPrintTypography = styled(Typography)`
  font-size: 16px;
  @media print {
    font-size: 16px;
  }
`

const StyledHeadlineBox = styled(Box)`
  @media print {
  }
`

export const SubHeadline = ({ title }: HeadlineProps) => {
  return (
    <StyledSubBox
      sx={{
        width: 'fit-content',
      }}
    >
      <Typography variant="body1" sx={{
          borderBottom: "1px solid #529BC5",
          fontSize: "16px",
          lineHeight: "32px",
          color: theme.palette.text.primary}}>
          {title}
      </Typography>
    </StyledSubBox>
  );
};

const StyledSubBox = styled(Box)`
  margin-top: 28px;
  margin-bottom: 16px;
  @media print {
    margin-top: 16px !important;
    margin-bottom: 16px;
  }
`

type ResultHeadlineProps = {
  rank: string,
  result: string,
  lang: 'ja' | 'en'
}

export const ResultHeadline = (props: ResultHeadlineProps) => {
  const lang = props.lang;
  return (
    <Container>
      <Typography variant="body1"  component={"div"}
      sx={{
        fontSize: "14px",
        fontWeight: 700,
        paddingRight: '12px',
        color: theme.palette.text.secondary}}
      >{lang === "ja" ? "総合判定" : "Inspection Result"}</Typography>
      <CircleText text={props.rank}/>
        <Typography variant="body1" sx={{
          fontWeight: 600,
          fontSize: "16px",
          paddingLeft: '16px',
          color: theme.palette.text.secondary}}>
          {props.result}
      </Typography>
      <Box
        display={"flex"}
        sx={{
          paddingLeft: '40px',
        }}
      >  
       <Typography variant="body1" sx={{
          paddingRight: '12px',
          fontSize: "12px",
          fontWeight: 500,
          color: theme.palette.text.secondary}}>{lang === "ja" ? "A: 良好" : "A : Good"}</Typography>
        <Typography variant="body1" sx={{
          paddingRight: '12px',
          fontSize: "12px",
          fontWeight: 500,
          color: theme.palette.text.secondary}}>{lang === "ja" ? "B: 差支えなし" : "B : Without exception"}</Typography>
        <Typography variant="body1" sx={{
          paddingRight: '12px',
          fontSize: "12px",
          fontWeight: 500,
          color: theme.palette.text.secondary}}>{lang === "ja" ?"C: 要経過観察、要生活改善" : "C : Lifestyle habit improvement and observation are necessary."}</Typography>
        <Typography variant="body1" sx={{
          paddingRight: '12px',
          fontSize: "12px",
          fontWeight: 500,
          color: theme.palette.text.secondary}}>{lang === "ja" ?"D: 要精密検査、要受診" : "D : Detailed examination needed. Have a medical examination."}</Typography>
      </Box>
    </Container>
  );
};

const Container = styled(Box)`
  background-color: #4B4B4B;
  padding: 12px;
  display: flex;
  align-items: center;
`


type SleepResultHeadlineProps = {
  title: string,
}


export const SleepResultHeadline = (props: SleepResultHeadlineProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "#4B4B4B",
        width: 1,
        alignItems: "center",
      }}
      mt={2.5}
      display={"flex"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
        }}
      >  
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{
          paddingLeft: "16px",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" sx={{
          py: 2,
          pl: 1,
          fontWeight: 600,
          fontSize: "20px",
          color: "white"}}>
          {props.title}
      </Typography>
      </Box>
    </Box>
    <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          ml: 8,
          pt: 1.5,
          pb: 2,
        }}
      >  
       <Typography variant="body1" sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: theme.palette.text.secondary}}>{"A: 良好"}</Typography>
        <Typography variant="body1" sx={{
        fontSize: "14px",
        fontWeight: 500,
        color: theme.palette.text.secondary}}>{"B: 差支えなし"}</Typography>
        <Typography variant="body1" sx={{
        fontSize: "14px", 
        fontWeight: 500,
        color: theme.palette.text.secondary}}>{"C: 要経過観察"}</Typography>
        <Typography variant="body1" sx={{
        fontSize: "14px",
        fontWeight: 500,
        color: theme.palette.text.secondary}}>{"D: 要精密検査"}</Typography>
      </Box>
    </Box>
  );
};


const CircleContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 38px;
`;

const Circle = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  height: 38px;
  width: 38px;
`;

const Text = styled('span')`
  color: #353535;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
`;

type CircleTextProps = {
  text: string,
}
const CircleText = (props: CircleTextProps) => {
  return (
    <CircleContainer>
      <Circle>
        <Text>{props.text}</Text>
      </Circle>
    </CircleContainer>
  );
};


