export type ClinicRoleTypeDisplayName = '管理者' | '運用管理者（事務長）' | '主担当者' | '補助者' | 'スタッフ';
export type DocumentCategorTypeDisplayName = 'マニュアル' | 'ポリシー';

export enum StatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export enum SpecializedMedicalListEnum {
  A = "a",
  B = "b",
  C = "c",
  D = "d"
}

export enum AdminRolesTypeEnum {
  SUPERADMIN = "superAdmin",
  ADMIN = "admin",
  ANONYMOUS = "anonymous"
}

export enum ClinicRoleTypeEnum {
  OWNER = "owner",
  MANAGER = "manager",
  DOCTOR = "doctor",
  COMEDICAL = "coMedical",
  STAFF = "staff",
}

export enum PriorityTypeEnum {
  NORMAL = "normal",
  HIGH = "high",
}

export enum CategoryTypeEnum {
  CLINIC = "医療機関",
  ENTERPRISE = "研究機関・企業",
  HEALTHCHECKFACILITY = "健診施設",
  OTHER = "その他"
}

export enum DocumentCategoryTypeEnum {
  MANUAL = "manual",
  POLICY = "ploicy",
}

export enum StatusTypeEnum {
  ACTIVE = "active",
  INACTIVE = "inActive",
}

/*export enum CheckBoxTypeEnum {
  TRUE = "true",
  FALSE = "false",
}*/
export const prefectureList = [
  {value: "北海道", label: "北海道", area: '北海道地方' },
  {value: "青森県", label: "青森県" , area:'東北地方'},
  {value: "岩手県", label: "岩手県" , area:'東北地方'},
  {value: "宮城県", label: "宮城県" , area:'東北地方'},
  {value: "秋田県", label: "秋田県" , area:'東北地方'},
  {value: "山形県", label: "山形県" , area:'東北地方'},
  {value: "福島県", label: "福島県" , area:'東北地方'},
  {value: "茨城県", label: "茨城県" , area: '関東地方'},
  {value: "栃木県", label: "栃木県" , area: '関東地方'},
  {value: "群馬県", label: "群馬県" , area: '関東地方'},
  {value: "埼玉県", label: "埼玉県" , area: '関東地方'},
  {value: "千葉県", label: "千葉県" , area: '関東地方'},
  {value: "東京都", label: "東京都" , area: '関東地方' },
  {value: "神奈川県", label: "神奈川県" , area: '関東地方' },
  {value: "新潟県", label: "新潟県" , area: '中部地方'},
  {value: "富山県", label: "富山県" , area: '中部地方'},
  {value: "石川県", label: "石川県" , area: '中部地方'},
  {value: "福井県", label: "福井県" , area: '中部地方'},
  {value: "山梨県", label: "山梨県" , area: '中部地方'},
  {value: "長野県", label: "長野県" , area: '中部地方'},
  {value: "岐阜県", label: "岐阜県" , area: '中部地方'},
  {value: "静岡県", label: "静岡県" , area: '中部地方'},
  {value: "愛知県", label: "愛知県" , area: '中部地方'},
  {value: "三重県", label: "三重県" , area: '近畿地方'},
  {value: "滋賀県", label: "滋賀県" , area: '近畿地方'},
  {value: "京都府", label: "京都府" , area: '近畿地方'},
  {value: "大阪府", label: "大阪府" , area: '近畿地方'},
  {value: "兵庫県", label: "兵庫県" , area: '近畿地方'},
  {value: "奈良県", label: "奈良県" , area: '近畿地方'},
  {value: "和歌山県", label: "和歌山県" , area: '近畿地方'},
  {value: "鳥取県", label: "鳥取県" , area: '中国地方'},
  {value: "島根県", label: "島根県" , area: '中国地方'},
  {value: "岡山県", label: "岡山県" , area: '中国地方'},
  {value: "広島県", label: "広島県" , area: '中国地方'},
  {value: "山口県", label: "山口県" , area: '中国地方'},
  {value: "徳島県", label: "徳島県" , area: '四国地方'},
  {value: "香川県", label: "香川県" , area: '四国地方'},
  {value: "愛媛県", label: "愛媛県" , area: '四国地方'},
  {value: "高知県", label: "高知県" , area: '四国地方'},
  {value: "福岡県", label: "福岡県" , area: '九州地方'},
  {value: "佐賀県", label: "佐賀県" , area: '九州地方'},
  {value: "長崎県", label: "長崎県" , area: '九州地方'},
  {value: "熊本県", label: "熊本県" , area: '九州地方'},
  {value: "大分県", label: "大分県" , area: '九州地方'},
  {value: "宮崎県", label: "宮崎県" , area: '九州地方'},
  {value: "鹿児島県", label: "鹿児島県" , area: '九州地方'},
  {value: "沖縄県", label: "沖縄県" , area: '沖縄地方'},
]

export const areaList = [
  {value: "北海道地方", label: "北海道地方" },
  {value: "東北地方", label: "東北地方" },
  {value: "関東地方", label: "関東地方" },
  {value: "中部地方", label: "中部地方" },
  {value: "近畿地方", label: "近畿地方" },
  {value: "中国地方", label: "中国地方" },
  {value: "四国地方", label: "四国地方" },
  {value: "九州地方", label: "九州地方" },
  {value: "沖縄地方", label: "沖縄地方" },
]

export enum PrefectureTypeEnum {
  HOKKAIDOU = 0,
  AOMORI = 1,
  IWATE = 2,
  MIYAGI = 3,
  AKITA = 4,
  YAMAGATA = 5,
  FUKUSHIMA = 6,
  IBARAKI = 7,
  TOCHIGI = 8,
  GUNMA = 9,
  SAITAMA = 10,
  CHIBA = 11,
  TOKYO = 12,
  KANAGAWA = 13,
  NIGATA = 14,
  TOYAMA = 15,
  ISHIKAWA = 16,
  FUKUI = 17,
  YAMANASHI = 18,
  NAGANO = 19,
  GIFU = 20,
  SHIZUOKA = 21,
  AICHI = 22,
  MIE = 23,
  SHIGA = 24,
  KYOTO = 25,
  OSAKA = 26,
  HYOGO = 27,
  NARA = 28,
  WAKAYAMA = 29,
  TOTTORI = 30,
  SHIMANE = 31,
  OKAYAMA = 32,
  HIROSHIMA = 33,
  YAMAGUCHI = 34,
  TOKUSHIMA = 35,
  KAGAWA = 36,
  EHIME = 37,
  KOUCHI = 38,
  FUKUOKA = 39,
  SAGA = 40,
  NAGASAKI = 41,
  KUMAMOTO = 42,
  OOITA = 43,
  MIYAZAKI = 44,
  KAGOSHIMA = 45,
  OKINAWA = 46,
}

export enum PrefectureNameEnum {
  HOKKAIDOU = '北海道',
  AOMORI = '青森県',
  IWATE = '岩手県',
  MIYAGI = '宮城県',
  AKITA = '秋田県',
  YAMAGATA = '山形県',
  FUKUSHIMA = '福島県',
  IBARAKI = '茨城県',
  TOCHIGI = '栃木県',
  GUNMA = '群馬県',
  SAITAMA = '埼玉県',
  CHIBA = '千葉県',
  TOKYO = '東京都',
  KANAGAWA = '神奈川県',
  NIGATA = '新潟県',
  TOYAMA = '富山県',
  ISHIKAWA = '石川県',
  FUKUI = '福井県',
  YAMANASHI = '山梨県',
  NAGANO = '長野県',
  GIFU = '岐阜県',
  SHIZUOKA = '静岡県',
  AICHI = '愛知県',
  MIE = '三重県',
  SHIGA = '滋賀県',
  KYOTO = '京都府',
  OSAKA = '大阪府',
  HYOGO = '兵庫県',
  NARA = '奈良県',
  WAKAYAMA = '和歌山県',
  TOTTORI = '鳥取県',
  SHIMANE = '島根県',
  OKAYAMA = '岡山県',
  HIROSHIMA = '広島県',
  YAMAGUCHI = '山口県',
  TOKUSHIMA = '徳島県',
  KAGAWA = '香川県',
  EHIME = '愛媛県',
  KOUCHI = '高知県',
  FUKUOKA = '福岡県',
  SAGA = '佐賀県',
  NAGASAKI = '長崎県',
  KUMAMOTO = '熊本県',
  OOITA = '大分県',
  MIYAZAKI = '宮崎県',
  KAGOSHIMA = '鹿児島県',
  OKINAWA = '沖縄県',
}

export enum MedicalPartnersTypeEnum {
  GENERAL_SLEEP_SPECIALIST = '睡眠総合専門医・不眠症',
  SLEEP_APNOEA_SYNDROME = '睡眠時無呼吸症候群・専門医',
  MENTAL_SPECIALIST = 'こころの専門医'
}

export const medicalPartnersList = [
  { value: MedicalPartnersTypeEnum.GENERAL_SLEEP_SPECIALIST, label: MedicalPartnersTypeEnum.GENERAL_SLEEP_SPECIALIST },
  { value: MedicalPartnersTypeEnum.SLEEP_APNOEA_SYNDROME, label: MedicalPartnersTypeEnum.SLEEP_APNOEA_SYNDROME },
  { value: MedicalPartnersTypeEnum.MENTAL_SPECIALIST, label: MedicalPartnersTypeEnum.MENTAL_SPECIALIST },
]

export enum AnalyzeServerTypeEnum {
  DEFAULTANALYZE = '通常解析用'
}

export enum ReportLangTypeEnum {
  JA = 'ja',
  EN = 'en'
}

export enum ReportLangMedicalPartnersTypeEnum {
  JA = 'ja',
  EN = 'en',
  BOTH = 'both'
}

export enum ReportLangMedicalPartnersNameEnum {
  JA = '日本語のみ',
  EN = '英語のみ',
  BOTH = '両方'
}

export enum ReportTypeEnum {
  A3_FOR_DOCK_AND_MEDICAL_INSTITUTIONS = 'doctorA3',
  A4_FOR_DOC_AND_MEDICAL_INSTITUTIONS = 'doctorA4',
  DETAILED_VERSION_OF_SLEEP_VARIABLES = 'research'
}

export enum InputPatientBirthDayTypeEnum {
  YYYYMMDD = 'yyyymmdd',
  YYYYMM = 'yyyymm',
  YYYY = 'yyyy'
}

export enum InputPatientAddressTypeEnum {
  PREFECTURE = 'prefecture',
  AREA = 'area',
  NONE = 'none'
}

export type AdminsState = {
  id: string,
  role: AdminRolesTypeEnum,
  name: string,
  phoneNumber: string,
  email: string,
  updatedBy: string,
  createdBy: string,
  createdAt: number,
  updatedAt: number,
  isDeleted: number,
}

export type AdminsUpdateParams = {
  tableName: string,
  id: string,
  role?: string,
  name: string,
  email: string,
  phoneNumber: string,
  removeRole?: string,
}

export const roleTypes = [
  {
    value: AdminRolesTypeEnum.SUPERADMIN,
    title: '特権システム管理者',
  },
  {
    value: AdminRolesTypeEnum.ADMIN,
    title: 'システム管理者',
  },
  {
    value: AdminRolesTypeEnum.ANONYMOUS,
    title: '匿名加工閲覧ユーザ',
  },
];

export const priorityTypes = [
  {
    value: PriorityTypeEnum.NORMAL,
    title: 'normal',
  },
  {
    value: PriorityTypeEnum.HIGH,
    title: 'high',
  }
];

export const clinicRoleTypes = [
  {
    value: ClinicRoleTypeEnum.OWNER,
    title: '管理者',
  },
  {
    value: ClinicRoleTypeEnum.DOCTOR,
    title: '主担当者',
  },
  {
    value: ClinicRoleTypeEnum.COMEDICAL,
    title: '補助者',
  },
];

export enum ExpireDateTypeEnum {
  DISABLE = '入力しない',
  ENABLE = '入力する'
}

export const expireDateList = [
  {
    value: ExpireDateTypeEnum.DISABLE,
    label: '入力しない',
  },
  {
    value: ExpireDateTypeEnum.ENABLE,
    label: '入力する',
  }
];

export const categoryList = [
  {
    value: CategoryTypeEnum.CLINIC,
    label: '医療機関',
  },
  {
    value: CategoryTypeEnum.ENTERPRISE,
    label: '研究機関・企業',
  },
  {
    value: CategoryTypeEnum.HEALTHCHECKFACILITY,
    label: '健診施設',
  },
  {
    value: CategoryTypeEnum.OTHER,
    label: 'その他',
  }
];

export const documentCategoryList = [
  {
    value: DocumentCategoryTypeEnum.MANUAL,
    label: 'マニュアル',
  },
  {
    value: DocumentCategoryTypeEnum.POLICY,
    label: 'ポリシー',
  }
];

export const statusList = [
  {
    value: true,
    label: '停止',
  },
  {
    value: false,
    label: '稼働中',
  }
];

/*export const prefectureList = [
  { value: PrefectureTypeEnum.HOKKAIDOU, label: '北海道' },
  { value: PrefectureTypeEnum.AOMORI, label: '青森県' },
  { value: PrefectureTypeEnum.IWATE, label: '岩手県' },
  { value: PrefectureTypeEnum.MIYAGI, label: '宮城県' },
  { value: PrefectureTypeEnum.AKITA, label: '秋田県' },
  { value: PrefectureTypeEnum.YAMAGATA, label: '山形県' },
  { value: PrefectureTypeEnum.FUKUSHIMA, label: '福島県' },
  { value: PrefectureTypeEnum.IBARAKI, label: '茨城県' },
  { value: PrefectureTypeEnum.TOCHIGI, label: '栃木県' },
  { value: PrefectureTypeEnum.GUNMA, label: '群馬県' },
  { value: PrefectureTypeEnum.SAITAMA, label: '埼玉県' },
  { value: PrefectureTypeEnum.CHIBA, label: '千葉県' },
  { value: PrefectureTypeEnum.TOKYO, label: '東京都' },
  { value: PrefectureTypeEnum.KANAGAWA, label: '神奈川県' },
  { value: PrefectureTypeEnum.NIGATA, label: '新潟県' },
  { value: PrefectureTypeEnum.TOYAMA, label: '富山県' },
  { value: PrefectureTypeEnum.ISHIKAWA, label: '石川県' },
  { value: PrefectureTypeEnum.FUKUI, label: '福井県' },
  { value: PrefectureTypeEnum.YAMANASHI, label: '山梨県' },
  { value: PrefectureTypeEnum.NAGANO, label: '長野県' },
  { value: PrefectureTypeEnum.GIFU, label: '岐阜県' },
  { value: PrefectureTypeEnum.SHIZUOKA, label: '静岡県' },
  { value: PrefectureTypeEnum.AICHI, label: '愛知県' },
  { value: PrefectureTypeEnum.MIE, label: '三重県' },
  { value: PrefectureTypeEnum.SHIGA, label: '滋賀県' },
  { value: PrefectureTypeEnum.KYOTO, label: '京都府' },
  { value: PrefectureTypeEnum.OSAKA, label: '大阪府' },
  { value: PrefectureTypeEnum.HYOGO, label: '兵庫県' },
  { value: PrefectureTypeEnum.NARA, label: '奈良県' },
  { value: PrefectureTypeEnum.WAKAYAMA, label: '和歌山県' },
  { value: PrefectureTypeEnum.TOTTORI, label: '鳥取県' },
  { value: PrefectureTypeEnum.SHIMANE, label: '島根県' },
  { value: PrefectureTypeEnum.OKAYAMA, label: '岡山県' },
  { value: PrefectureTypeEnum.HIROSHIMA, label: '広島県' },
  { value: PrefectureTypeEnum.YAMAGUCHI, label: '山口県' },
  { value: PrefectureTypeEnum.TOKUSHIMA, label: '徳島県' },
  { value: PrefectureTypeEnum.KAGAWA, label: '香川県' },
  { value: PrefectureTypeEnum.EHIME, label: '愛媛県' },
  { value: PrefectureTypeEnum.KOUCHI, label: '高知県' },
  { value: PrefectureTypeEnum.FUKUOKA, label: '福岡県' },
  { value: PrefectureTypeEnum.SAGA, label: '佐賀県' },
  { value: PrefectureTypeEnum.NAGASAKI, label: '長崎県' },
  { value: PrefectureTypeEnum.KUMAMOTO, label: '熊本県' },
  { value: PrefectureTypeEnum.OOITA, label: '大分県' },
  { value: PrefectureTypeEnum.MIYAZAKI, label: '宮崎県' },
  { value: PrefectureTypeEnum.KAGOSHIMA, label: '鹿児島県' },
  { value: PrefectureTypeEnum.OKINAWA, label: '沖縄県' },
];*/

export const prefectureOnlyList = [
  { value: "北海道", label: "北海道" },
  { value: "青森県", label: "青森県" },
  { value: "岩手県", label: "岩手県" },
  { value: "宮城県", label: "宮城県" },
  { value: "秋田県", label: "秋田県" },
  { value: "山形県", label: "山形県" },
  { value: "福島県", label: "福島県" },
  { value: "茨城県", label: "茨城県" },
  { value: "栃木県", label: "栃木県" },
  { value: "群馬県", label: "群馬県" },
  { value: "埼玉県", label: "埼玉県" },
  { value: "千葉県", label: "千葉県" },
  { value: "東京都", label: "東京都" },
  { value: "神奈川県", label: "神奈川県" },
  { value: "新潟県", label: "新潟県" },
  { value: "富山県", label: "富山県" },
  { value: "石川県", label: "石川県" },
  { value: "福井県", label: "福井県" },
  { value: "山梨県", label: "山梨県" },
  { value: "長野県", label: "長野県" },
  { value: "岐阜県", label: "岐阜県" },
  { value: "静岡県", label: "静岡県" },
  { value: "愛知県", label: "愛知県" },
  { value: "三重県", label: "三重県" },
  { value: "滋賀県", label: "滋賀県" },
  { value: "京都府", label: "京都府" },
  { value: "大阪府", label: "大阪府" },
  { value: "兵庫県", label: "兵庫県" },
  { value: "奈良県", label: "奈良県" },
  { value: "和歌山県", label: "和歌山県" },
  { value: "鳥取県", label: "鳥取県" },
  { value: "島根県", label: "島根県" },
  { value: "岡山県", label: "岡山県" },
  { value: "広島県", label: "広島県" },
  { value: "山口県", label: "山口県" },
  { value: "徳島県", label: "徳島県" },
  { value: "香川県", label: "香川県" },
  { value: "愛媛県", label: "愛媛県" },
  { value: "高知県", label: "高知県" },
  { value: "福岡県", label: "福岡県" },
  { value: "佐賀県", label: "佐賀県" },
  { value: "長崎県", label: "長崎県" },
  { value: "熊本県", label: "熊本県" },
  { value: "大分県", label: "大分県" },
  { value: "宮崎県", label: "宮崎県" },
  { value: "鹿児島県", label: "鹿児島県" },
  { value: "沖縄県", label: "沖縄県" },
]

export const analyizeServerList = [
  { value: AnalyzeServerTypeEnum.DEFAULTANALYZE, label: '通常解析用' }
];

export const reportLangTypes = [
  { value: ReportLangTypeEnum.JA, title: '日本語' },
  { value: ReportLangTypeEnum.EN, title: '英語' }
];

export const reportLangMedicalPartnerList = [
  { value: ReportLangMedicalPartnersTypeEnum.JA, label: ReportLangMedicalPartnersNameEnum.JA },
  { value: ReportLangMedicalPartnersTypeEnum.EN, label: ReportLangMedicalPartnersNameEnum.EN },
  { value: ReportLangMedicalPartnersTypeEnum.BOTH, label: ReportLangMedicalPartnersNameEnum.BOTH }
];

export const reportTypes = [
  { value: ReportTypeEnum.A3_FOR_DOCK_AND_MEDICAL_INSTITUTIONS, title: 'ドック・医療機関向けA3' },
  { value: ReportTypeEnum.A4_FOR_DOC_AND_MEDICAL_INSTITUTIONS, title: 'ドック・医療機関向けA4' },
  { value: ReportTypeEnum.DETAILED_VERSION_OF_SLEEP_VARIABLES, title: '睡眠変数詳細版（研究者向け）' },
];

export const inputPatientBirthDayTypes = [
  { value: InputPatientBirthDayTypeEnum.YYYYMMDD, title: '年月日を入力' },
  { value: InputPatientBirthDayTypeEnum.YYYYMM, title: '年月のみ入力（入力した年月の15日生まれとして扱います）' },
  { value: InputPatientBirthDayTypeEnum.YYYY, title: '年のみ入力（入力した年の7月1日生まれとして扱います）' },
];

export const inputPatientAddressTypes = [
  { value: InputPatientAddressTypeEnum.PREFECTURE, title: '都道府県を入力' },
  { value: InputPatientAddressTypeEnum.AREA, title: '地域（関東、関西など）まで入力' },
  { value: InputPatientAddressTypeEnum.NONE, title: '入力なし' },
];

export const typeNameMap = new Map([
  [AdminRolesTypeEnum.SUPERADMIN, '特権システム管理者'],
  [AdminRolesTypeEnum.ADMIN, 'システム管理者'],
  [AdminRolesTypeEnum.ANONYMOUS, '匿名加工閲覧ユーザ'],
]);

export type TypeDisplayName = '特権システム管理者' | 'システム管理者' | '匿名加工閲覧ユーザ';
export const convertName2RoleType = (
  typeDisplayName: TypeDisplayName
): AdminRolesTypeEnum => {
  switch (typeDisplayName) {
    case '特権システム管理者':
      return AdminRolesTypeEnum.SUPERADMIN;
    case 'システム管理者':
      return AdminRolesTypeEnum.ADMIN;
    case '匿名加工閲覧ユーザ':
      return AdminRolesTypeEnum.ANONYMOUS;
  }
};

export const convertRoleType2Name = (
  type: AdminRolesTypeEnum | null | undefined
): TypeDisplayName => {
  switch (type) {
    case AdminRolesTypeEnum.SUPERADMIN:
      return '特権システム管理者';
    case AdminRolesTypeEnum.ADMIN:
      return 'システム管理者';
    case AdminRolesTypeEnum.ANONYMOUS:
      return '匿名加工閲覧ユーザ';
    default:
      return '匿名加工閲覧ユーザ';
  }
};

export const convertCheckBoxType2Name = (
  type: boolean | null | undefined
) => {
  switch (type) {
    case true:
      return '有効';
    case false:
      return '';
    default:
      return '';
  }
};

export const convertCheckBoxType2Circle = (
  type: boolean | null | undefined
) => {
  switch (type) {
    case true:
      return '⚪️';
    case false:
      return '';
    default:
      return '';
  }
};

export const convertReportLang2Name = (
  type: string | null | undefined
) => {
  switch (type) {
    case ReportLangTypeEnum.JA:
      return '日本語';
    case ReportLangTypeEnum.EN:
      return '英語';
    default:
      return '';
  }
};

export const convertReportType2Name = (
  type: string | null | undefined
) => {
  switch (type) {
    case ReportTypeEnum.A3_FOR_DOCK_AND_MEDICAL_INSTITUTIONS:
      return 'ドック・医療機関向けA3';
    case ReportTypeEnum.A4_FOR_DOC_AND_MEDICAL_INSTITUTIONS:
      return 'ドック・医療機関向けA4';
    case ReportTypeEnum.DETAILED_VERSION_OF_SLEEP_VARIABLES:
      return '睡眠変数詳細版（研究者向け）';
    default:
      return '';
  }
};

export const convertSpecializedMedicalList2Name = (
  list: any
) => {
  let tmpList = [];
  for (let i = 0; i < list.length; i++) {
    switch (list[i]) {
      case 'a':
        tmpList.push('A判定 ');
        break;
      case 'b':
        tmpList.push('B判定 ');
        break;
      case 'c':
        tmpList.push('C判定 ');
        break;
      case 'd':
        tmpList.push('D判定');
        break;
      default:
        break;
    }
  }
  return tmpList;
};

export const convertIsInputPatientBirthday2Name = (
  type: string | null | undefined
) => {
  switch (type) {
    case 'yyyymmdd':
      return '年月日を入力';
    case 'yyyymm':
      return '年月のみ入力（入力した年月の15日生まれとして扱います）';
    case 'yyyy':
      return '年のみ入力（入力した年の7月1日生まれとして扱います）';
    default:
      return '';
  }
};

export const convertIsInputPatientAddress2Name = (
  type: string | null | undefined
) => {
  switch (type) {
    case 'prefecture':
      return '都道府県を入力';
    case 'area':
      return '地域（関東、関西など）まで入力';
    case 'none':
      return '入力なし';
    default:
      return '';
  }
};

export const convertName2ClinicRoleType = (
  typeDisplayName: ClinicRoleTypeDisplayName
): ClinicRoleTypeEnum => {
  switch (typeDisplayName) {
    case '管理者':
      return ClinicRoleTypeEnum.OWNER;
    case '運用管理者（事務長）':
      return ClinicRoleTypeEnum.MANAGER;
    case '主担当者':
      return ClinicRoleTypeEnum.DOCTOR;
    case '補助者':
      return ClinicRoleTypeEnum.COMEDICAL;
    case 'スタッフ':
      return ClinicRoleTypeEnum.STAFF;
    default:
      return ClinicRoleTypeEnum.OWNER;
  }
};

export const convertClinicRoleTypeC2Name = (
  type: ClinicRoleTypeEnum
): ClinicRoleTypeDisplayName => {
  switch (type) {
    case ClinicRoleTypeEnum.OWNER:
      return '管理者';
    case ClinicRoleTypeEnum.MANAGER:
      return '運用管理者（事務長）';
    case ClinicRoleTypeEnum.DOCTOR:
      return '主担当者';
    case ClinicRoleTypeEnum.COMEDICAL:
      return '補助者';
    case ClinicRoleTypeEnum.STAFF:
      return 'スタッフ';
    default:
      return '管理者';
  }
};

export const convertDocumentCategoryType2Name = (
  type: DocumentCategoryTypeEnum | null | undefined
) => {
  switch (type) {
    case DocumentCategoryTypeEnum.MANUAL:
      return 'マニュアル';
    case DocumentCategoryTypeEnum.POLICY:
      return 'ポリシー';
    default:
      return 'マニュアル';
  }
};

export const convertName2DocumentCategoryType = (
  typeDisplayName: DocumentCategorTypeDisplayName
) => {
  switch (typeDisplayName) {
    case 'マニュアル':
      return DocumentCategoryTypeEnum.MANUAL;
    case 'ポリシー':
      return DocumentCategoryTypeEnum.POLICY;
    default:
      return DocumentCategoryTypeEnum.MANUAL;
  }
};

export const ReportLangMedicalPartnersType2Name = (
  type: ReportLangMedicalPartnersTypeEnum
) => {
  switch (type) {
    case ReportLangMedicalPartnersTypeEnum.JA:
      return ReportLangMedicalPartnersNameEnum.JA;
    case ReportLangMedicalPartnersTypeEnum.EN:
      return ReportLangMedicalPartnersNameEnum.EN;
    case ReportLangMedicalPartnersTypeEnum.BOTH:
      return ReportLangMedicalPartnersNameEnum.BOTH;
    default:
      return ReportLangMedicalPartnersNameEnum.JA;
  }
};

export const ReportLangMedicalPartnersName2Type = (
  type: ReportLangMedicalPartnersNameEnum
) => {
  switch (type) {
    case ReportLangMedicalPartnersNameEnum.JA:
      return ReportLangMedicalPartnersTypeEnum.JA;
    case ReportLangMedicalPartnersNameEnum.EN:
      return ReportLangMedicalPartnersTypeEnum.EN;
    case ReportLangMedicalPartnersNameEnum.BOTH:
      return ReportLangMedicalPartnersTypeEnum.BOTH;
    default:
      return ReportLangMedicalPartnersTypeEnum.JA;
  }
};


export const convertPrefectureType2Name = (
  type: PrefectureTypeEnum | null | undefined
): any => {
  switch (type) {
    case PrefectureTypeEnum.HOKKAIDOU:
      return '北海道';
    case PrefectureTypeEnum.AOMORI:
      return '青森県';
    case PrefectureTypeEnum.IWATE:
      return '岩手県';
    case PrefectureTypeEnum.MIYAGI:
      return '宮城県';
    case PrefectureTypeEnum.AKITA:
      return '秋田県';
    case PrefectureTypeEnum.YAMAGATA:
      return '山形県';
    case PrefectureTypeEnum.FUKUSHIMA:
      return '福島県';
    case PrefectureTypeEnum.IBARAKI:
      return '茨城県';
    case PrefectureTypeEnum.TOCHIGI:
      return '栃木県';
    case PrefectureTypeEnum.GUNMA:
      return '群馬県';
    case PrefectureTypeEnum.SAITAMA:
      return '埼玉県';
    case PrefectureTypeEnum.CHIBA:
      return '千葉県';
    case PrefectureTypeEnum.TOKYO:
      return '東京都';
    case PrefectureTypeEnum.KANAGAWA:
      return '神奈川県';
    case PrefectureTypeEnum.NIGATA:
      return '新潟県';
    case PrefectureTypeEnum.TOYAMA:
      return '富山県';
    case PrefectureTypeEnum.ISHIKAWA:
      return '石川県';
    case PrefectureTypeEnum.FUKUI:
      return '福井県';
    case PrefectureTypeEnum.YAMANASHI:
      return '山梨県';
    case PrefectureTypeEnum.NAGANO:
      return '長野県';
    case PrefectureTypeEnum.GIFU:
      return '岐阜県';
    case PrefectureTypeEnum.SHIZUOKA:
      return '静岡県';
    case PrefectureTypeEnum.AICHI:
      return '愛知県';
    case PrefectureTypeEnum.MIE:
      return '三重県';
    case PrefectureTypeEnum.SHIGA:
      return '滋賀県';
    case PrefectureTypeEnum.KYOTO:
      return '京都府';
    case PrefectureTypeEnum.OSAKA:
      return '大阪府';
    case PrefectureTypeEnum.HYOGO:
      return '兵庫県';
    case PrefectureTypeEnum.NARA:
      return '奈良県';
    case PrefectureTypeEnum.WAKAYAMA:
      return '和歌山県';
    case PrefectureTypeEnum.TOTTORI:
      return '鳥取県';
    case PrefectureTypeEnum.SHIMANE:
      return '島根県';
    case PrefectureTypeEnum.OKAYAMA:
      return '岡山県';
    case PrefectureTypeEnum.HIROSHIMA:
      return '広島県';
    case PrefectureTypeEnum.YAMAGUCHI:
      return '山口県';
    case PrefectureTypeEnum.TOKUSHIMA:
      return '徳島県';
    case PrefectureTypeEnum.KAGAWA:
      return '香川県';
    case PrefectureTypeEnum.EHIME:
      return '愛媛県';
    case PrefectureTypeEnum.KOUCHI:
      return '高知県';
    case PrefectureTypeEnum.FUKUOKA:
      return '福岡県';
    case PrefectureTypeEnum.SAGA:
      return '佐賀県';
    case PrefectureTypeEnum.NAGASAKI:
      return '長崎県';
    case PrefectureTypeEnum.KUMAMOTO:
      return '熊本県';
    case PrefectureTypeEnum.OOITA:
      return '大分県';
    case PrefectureTypeEnum.MIYAZAKI:
      return '宮崎県';
    case PrefectureTypeEnum.KAGOSHIMA:
      return '鹿児島県';
    case PrefectureTypeEnum.OKINAWA:
      return '沖縄県';
    default:
      return '';
  }
};

export const convertGenderTypeToName = (genderType: string | undefined) => {
  return genderType === '男性'
    ? '男性'
    : genderType === '女性'
      ? '女性'
      : genderType === '答えたくない'
        ? '答えたくない'
        : null;
};
