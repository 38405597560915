import React from 'react';
import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';
import { roundToTwoDecimalPlaces } from '../../../utils/commons';

type SleepPieChartProps = {
  data: {
    name: string;
    value: number;
  }[];
  colors: string[];
};

export const SleepPieChart: React.FC<SleepPieChartProps> = ({ data, colors }) => {
  const total = data.reduce((sum, { value }) => sum + value, 0);
  const percentage = (value: number) => `${roundToTwoDecimalPlaces((value / total) * 100)}%`;

  return (
      <PieChart width={400} height={200}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={90}
          fill="#8884d8"
          startAngle={-270} // 90度から始まるように
          endAngle={90} // 90度から始まるように
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip 
          wrapperStyle={{ fontSize: 12 }}
        />
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          wrapperStyle={{ fontSize: 12 }}
          formatter={(value: string, entry: any) => `${value} : ${percentage(entry.payload.value)}`}
        />
      </PieChart>
  );
};
