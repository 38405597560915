import { useMemo } from "react";
import {SleepGudelineTable} from "../report/Table"

type Props = {
  lang: 'en'|'ja';
  small?: boolean;
}

export const GoodSleepCondition = (props: Props) => {
  const { lang, small } = props;
  const tableHeaderJa = ['睡眠12ヵ条', '解説'];
  const tableHeaderEn = ['12 conditions for good sleep', 'Explanation'];
  const tableRowJa = useMemo(() => {
    return [
      ['1.良い睡眠で、からだもこころも健康に', '●良い睡眠で、からだの健康づく\n●良い睡眠で、こころの健康づくり\n●良い睡眠で、事故防止'],
      ['2.適度な運動、しっかり朝食、ねむりとめざめのメリハリを','●定期的な運動や規則正しい食生活は良い睡眠をもたらす\n●朝食はからだとこころのめざめに重要\n●睡眠薬代わりの寝酒は睡眠を悪くする\n●就寝前の喫煙やカフェイン摂取を避ける'],
      ['3.良い睡眠は、生活習慣病予防につながります',  '●睡眠不足や不眠は生活習慣病の危険を高める\n●睡眠時無呼吸は生活習慣病の原因になる\n●肥満は睡眠時無呼吸のもと'],
      ['4.睡眠による休養感は、こころの健康に重要です', '●眠れない、睡眠による休養感が得られない場合、こころの SOS の場合あり\n●睡眠による休養感がなく、日中もつらい場合、うつ病の可能性も'],
      ['5.年齢や季節に応じて、ひるまの眠気で困らない程度の睡眠を',  '●必要な睡眠時間は人それぞれ\n●睡眠時間は加齢で徐々に短縮\n●年をとると朝型化 男性でより顕著\n●日中の眠気で困らない程度の自然な睡眠が一番'],
      ['6.良い睡眠のためには、環境づくりも重要です', '●自分にあったリラックス法が眠りへの心身の準備となる\n●自分の睡眠に適した環境づくり'],
      ['7.若年世代は夜更かし避けて、体内時計のリズムを保つ', '●子どもには規則正しい生活を\n●休日に遅くまで寝床で過ごすと夜型化を促進\n●朝目が覚めたら日光を取り入れる\n●夜更かしは睡眠を悪くする'],
      ['8.勤労世代の疲労回復・能率アップに、毎日十分な睡眠を', '●日中の眠気が睡眠不足のサイン\n●睡眠不足は結果的に仕事の能率を低下させる\n●睡眠不足が蓄積すると回復に時間がかかる\n●午後の短い昼寝で眠気をやり過ごし能率改善'],
      ['9.熟年世代は朝晩メリハリ、ひるまに適度な運動で良い睡眠', '●寝床で長く過ごしすぎると熟睡感が減る\n●年齢にあった睡眠時間を大きく超えない習慣を\n●適度な運動は睡眠を促進'],
      ['10.眠くなってから寝床に入り、起きる時刻は遅らせない', '●眠たくなってから寝床に就く、就床時刻にこだわりすぎない\n●眠ろうとする意気込みが頭を冴えさせ寝つきを悪くする\n●眠りが浅いときは、むしろ積極的に遅寝・早起きに'],
      ['11.いつもと違う睡眠には、要注意', '●睡眠中の激しいいびき・呼吸停止、手足のぴくつき・むずむず感や歯ぎしりは要注意\n●眠っても日中の眠気や居眠りで困っている場合は専門家に相談'],
      ['12.眠れない、その苦しみをかかえずに、専門家に相談を', '●専門家に相談することが第一歩\n●薬剤は専門家の指示で使用'],
    ]
  },[]);
  const tableRowEn = useMemo(() => {
    return [
      ['1.Good sleep maintains a healthy mind and body', '●Good sleep promotes a healthy body\n●Good sleep promotes a healthy mind\n●Good sleep prevents accidents'],
      ['2.Taking moderate exercise and eating a hearty breakfast will strike a good balance between sleeping and waking','●Regular exercise and a regular diet will result in good sleep\n●Breakfast is important for waking up the mind and body\n●Having a nightcap in place of sleeping tablets is not good for sleep\n●Avoid smoking and caffeine before going to bed'],
      ['3.Good sleep prevents lifestyle diseases',  '●Lack of sleep and insomnia increases the risk of lifestyle diseases\n●Lack of sleep and insomnia increases the risk of lifestyle diseases\n●Obesity is a risk factor for sleep apnea'],
      ['4.Restfulness from sleep is important for mental health', '●Insomnia and not getting rest from sleep can be a distress signal from the mind\n●Not getting rest from sleep and having stressful days may also result in depression'],
      ['5.Depending on the age and seasons, the level of sleep would not cause daytime drowsiness',  '●Everyone needs different amounts of sleep\n●Length of sleep decreases steadily with age\n●With age, men, in particular, tend to become morning people\n●Natural sleep with no drowsiness during the day is the best sleep'],
      ['6.Creating the right environment is also important for good sleep', '●A relaxation method suited to you, prepares the mind and body for sleep\n●Create an environment suited to your own sleep'],
      ['7.Young people should avoid staying up late into the night, and should maintain the rhythm of their body clock.', '●Children need a properly regulated lifestyle\n●Sleeping in late on holidays promotes night owl behavior\n●Children need to go out into the sunshine after they wake up in the morning\n●Staying up late will result in poor sleep'],
      ['8.Having adequate sleep everyday is essential to recover from fatigue and improve efficiency in the working generation', '●Drowsiness during the day is a sign of inadequate sleep\n●Lack of sleep will result in reduced efficiency at work\n●Accumulated lack of sleep will mean longer time needed for recovery\n●Managing to have a short daytime sleep in the afternoon  will help overcome drowsiness and improve efficiency'],
      ['9.Moderate exercise and sleeping well will strike a good balance between morning and evening for senior people', '●Spending a long time in bed reduces the feeling of sound sleep\n●Get into the habit of not significantly exceeding age-appropriate sleep time\n●Moderate exercise promotes sleep'],
      ['10.Go to bed once you are sleepy and do not wake up late', '●Go to bed once you are sleepy, and do not worry too much about set bed times\n●Trying hard to get to sleep can instead make you more alert and worsen the quality of your sleep\n●When you are having light sleep, it may be better instead to go to bed later or get up earlier'],
      ['11.You should be careful not to always change the length of your sleep', '●You need to take note if you snore intensely, stop breathing, have restless arms or legs, or grind your teeth while asleep\n●Consult with a specialist if you are having problems with drowsiness or falling asleep during the day even when you seem to be sleeping properly'],
      ['12.Do not suffer through insomnia, consult with a specialist', '●Consulting with a specialist is the first step\n●Use medication under the directions of a specialist'],
    ]
  },[]);
  return (
    <SleepGudelineTable
        headers={lang === "ja" ? tableHeaderJa : tableHeaderEn}
        rows={lang === "ja" ? tableRowJa : tableRowEn}
        lang={lang}
        small={small}
      />
  )
}
