import { Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
// import { theme } from '../../../provider/ThemeProvider'
import { nl2br } from "../../../utils/nl2br";

type TableProps = {
  rows: {title:string, value?:string | React.ReactNode}[];
  hover?:boolean;
  width?: string | number ;
};

export const HeadlessTable= (props: TableProps) => {
  const { rows, hover, width } = props;
  return (
    <Table>
      <TableBody style={{border: "1px solid  rgba(224, 224, 224, 1)"}}>
        {rows.map((row, index) => {
          return (
            <TableRow hover={hover} role='checkbox' tabIndex={-1} key={index}>
              <CustomizedTitle width={width}>{row.title}</CustomizedTitle>
              <CustomizedTableCell >{nl2br(row.value)}</CustomizedTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};


interface MultiColmunsTableProps {
  headers: string[];
  rows: {value: string, align: "left" | "center" | "right" | "justify" | "inherit" | undefined }[][];
  lang: "ja"| "en"
  small?: boolean;
}

/**
 * 複数列のテーブルを作成する
 * @param headers ヘッダー　配列の先頭は空白
 * @param rows 行　配列の先頭はタイトル
 */
export const ResultTable: React.FC<MultiColmunsTableProps> = ({
  headers,
  rows,
  lang,
  small
}) => {
  return (
    <StyledTableContainer>
      <CustomizedTable aria-label="custom table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <CustomizedTableHeadCell align="center" key={index} isTitle={index === 0}>{header}</CustomizedTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((r , cellIndex) => (
                <StyledTableCell key={cellIndex} isTitle={cellIndex === 0} small={small} lang={lang} align={r.align ?? 'center'}>{r.value}</StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </CustomizedTable>
    </StyledTableContainer>
  );
};

type CustomizedTitleProps = {
  width?: string | number;
}

const CustomizedTitle = styled(TableCell)<CustomizedTitleProps>(({ width = '65%' }) => ({
  fontSize: '16px',
  marginLeft: '8px',
  background: "#F2F2F2",
  width: width,
  fontFamily: 'ABeeZee'
}));

const CustomizedTableCell = styled(TableCell)({
  padding: '12px 16px',
  wordBreak: "break-all",
  fontFamily: 'ABeeZee',
  '@media print': {
    // padding:'8px'
  }
});



const CustomizedTable = styled(Table)({
  minWidth: 500,
  '& th': {
    border: '1px solid rgba(224, 224, 224, 1)',
    fontWeight: 'bold',
  },
  '& td': {
    border: '1px solid rgba(224, 224, 224, 1)',
    whiteSpace: 'pre-wrap'
  },
  '& tr:first-child th': {
    maxWidth: '15rem',
    minWidth: '4rem',
  },
  '& tr:last-child td': {
  },
  '& tr td:first-child': {
    maxWidth: '15rem',
    minWidth: '4rem',
  },
  '& tr td:last-child': {
  },
});

const CustomizedTableHeadCell = styled(TableCell)<{isTitle?: boolean}>(({isTitle})=>({
  background: "#E7F3FB",
  fontSize:'14px',
  padding:'4px',
  width: isTitle? '120px' : 'auto',
  letterSpacing: '0px',
  '@media print': {
  }
}));

const StyledTableCell = styled(TableCell)<{isTitle?: boolean, small?:boolean}>`
  background-color: ${(({isTitle})=> isTitle?'#F7F5F5' : '#FFF')};
  font-size: ${(({small, lang})=> {
    if(lang === "ja") {
      return small ? "11px":"13px"
    }
    return small ? "11px": "11px"
  })};
  padding: 8px 4px;
  /* width: ${(({isTitle})=> isTitle? '200px' : 'auto')}; */
  box-sizing: border-box;
  @media print {
    padding: 4px 8px;
    max-width: 30%;
  }
  letter-spacing: 0px;
`

const StyledTableContainer = styled(TableContainer)`
  @media print {
    .MuiTable-root {
      min-width: auto;
    }
  }
`