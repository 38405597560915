import MuiButton, { ButtonProps } from '@mui/material/Button';
import { CircularProgress, SxProps, Theme } from '@mui/material'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { styled } from '@mui/system';
import { theme } from '../../provider/ThemeProvider'
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import { EditIcon, DisabledEditIcon } from './icons/EditIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Color = 'primary' | 'secondary' | 'error';

type Props = ButtonProps & {
  width?: number | string;
  height?: number | string;
  color?: Color;
  margin?: string | number;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  component?: React.ElementType;

};

type GetButtonStyles = (
  color: Color,
  disabled?: boolean
) => {
  bgColor: string;
  color: string;
  border: string;
};

type CustomButtonProps = {
  type?: any;
  disabled?: boolean;
  onClick: (() => void) | (() => Promise<void>);
  color?: 'primary';
  style?: React.CSSProperties;
  isLoading?: boolean;
  value: string;
  isWhite?: boolean;
  small?: boolean;
  width?: string;
};

const getButtonStyles: GetButtonStyles = (color, disabled) => {
  if (disabled) {
    switch (color) {
      case 'secondary':
        return {
          bgColor: '#fff',
          color: '#E2E2E2 !important',
          border: '2px solid #E2E2E2',
        };
      default:
        return {
          bgColor: '#E2E2E2',
          color: '#FFF !important',
          border: '2px solid #E2E2E2',
        };
    }
  }
  switch (color) {
    case 'primary':
      return {
        bgColor: '#529BC5',
        color: '#FFF',
        border: '2px solid #529BC5',
      };
    case 'secondary':
      return {
        bgColor: '#FFF',
        color: '#529BC5',
        border: '2px solid #529BC5',
      };
    case 'error':
      return {
        bgColor: '#F15454',
        color: '#FFF',
        border: '2px solid #F15454',
      };
  }
};

export const Button = ({
  color,
  disabled,
  width,
  children,
  isLoading,
  sx,
  margin,
  height,
  ...props
}: Props) => {

  const btnColor = color ?? 'primary';
  return (
    <MuiButton
      {...props}
      sx={
        {
          ...sx,
          backgroundColor: getButtonStyles(btnColor, disabled).bgColor,
          border: getButtonStyles(btnColor, disabled).border,
          borderRadius: '46px',
          color: getButtonStyles(btnColor, disabled).color,
          fontSize: 16,
          fontWeight: 700,
          width,
          minWidth: 160,
          margin: margin ? margin : '',
          textTransform: 'none',
          letterSpacing: 0,
          ':hover': {
            backgroundColor: getButtonStyles(btnColor).bgColor,
            opacity: 0.8,
          },
        }
      }
      disabled={disabled}
      disableElevation
      startIcon={isLoading ? <CircularProgress size={20} /> : null}
    >
      {children}
    </MuiButton>
  );
};

export const StyledDefaultButton = (props: CustomButtonProps) => {
  return (
    <StyledSmallButton
      type={props.type}
      style={props.style}
      variant="contained"
      color={props.color}
      disabled={props.disabled}
      startIcon={props.isLoading ? <CircularProgress size={20} /> : null}
      onClick={props.onClick}
    >
      {props.value}
    </StyledSmallButton>
  );
};

type WithIconButtonProps = ButtonProps & {
  text: string
}
export const WithIconButton = ({ text, ...props }: WithIconButtonProps) => {
  return (
    <MuiButton sx={{ height: 52, minWidth: 46, padding: 0 }} {...props}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: theme.palette.text.primary,
          margin: '0px 0'
        }}
        color={"success"}
      >
        {props.disabled ? <DisabledEditIcon /> : <EditIcon />}
        <StyleHeaderText>{text}</StyleHeaderText>
      </Box>
    </MuiButton>
  )
}

export const LoginButton = (props: CustomButtonProps) => {
  return (
    <StyledLoginButton
      small={props.small}
      width={props.width}
      isWhite={props.isWhite}
      style={props.style}
      variant='contained'
      color={props.color}
      disabled={props.disabled}
      startIcon={props.isLoading ? <CircularProgress size={20} /> : null}
      onClick={props.onClick}
    >
      {props.value}
    </StyledLoginButton>
  );
};

export const DownloadButton = (props: CustomButtonProps) => {
  return (
    <StyledDownloadButton
      small={props.small}
      isWhite={props.isWhite}
      style={props.style}
      variant='contained'
      color={props.color}
      disabled={props.disabled}
      startIcon={props.isLoading ? <CircularProgress size={20} /> : <DownloadIcon />}
      onClick={props.onClick}
    >
      {props.value}
    </StyledDownloadButton>
  );
};
export const SearchDetailButton = ({
  color,
  disabled,
  width,
  onClick,
  children,
  sx,
  isLoading,
  variant,
  type
}: Props) => {
  return (
    <SearchStyledButton disabled={disabled} disableElevation endIcon={<ExpandMoreIcon />} onClick={onClick}>
      {children}
    </SearchStyledButton>
  )
}

const ButtonText = styled('span')({
  display: 'flex',
  fontSize: 13
});

const StyledLoginButton = styled(MuiButton).bind({
  // color: 'primary',
}) <{ isWhite?: boolean; small?: boolean, width?: string }>`
  min-width: 210px;
  width: ${({ width }) => (width || '210px')};
  height: ${({ small }) => (small ? '44px' : '52px')};
  margin: ${(props) => props.theme.spacing(0)}px
    ${(props) => props.theme.spacing(0.5)}px;
  margin-top: 40px;
  margin-bottom: 15px;
  border-radius: 25px;
  font-size: ${({ small }) => (small ? '16px' : '20px')};
  font-weight: normal;
  background: ${({ isWhite }) => (isWhite ? '#FFF' : '#529BC5')};
  color: ${({ isWhite }) => (isWhite ? '#529BC5' : '#FFF')};
  border: ${({ isWhite }) => (isWhite ? '2px solid #529BC5' : 'none')};
  border-radius: 46px;
  box-shadow: none;
  &:hover {
    opacity: 0.8;
    background-color: ${({ isWhite }) => (isWhite ? '#FFF' : '#529BC5')};
    box-shadow: none;
  }
`;

const StyledDownloadButton = styled(MuiButton).bind({
  // color: 'primary',
}) <{ isWhite?: boolean; small?: boolean }>`
  min-width: 210px;
  width: 210px;
  height: ${({ small }) => (small ? '44px' : '52px')};
  margin: ${(props) => props.theme.spacing(0)}px
    ${(props) => props.theme.spacing(0.5)}px;
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 25px;
  font-size: ${({ small }) => (small ? '16px' : '20px')};
  font-weight: bold;
  background: ${({ isWhite }) => (isWhite ? '#FFF' : '#529BC5')};
  color: ${({ isWhite }) => (isWhite ? '#529BC5' : '#FFF')};
  border: ${({ isWhite }) => (isWhite ? '2px solid #529BC5' : 'none')};
  border-radius: 46px;
  box-shadow: none;
  &:hover {
    opacity: 0.8;
    background-color: ${({ isWhite }) => (isWhite ? '#FFF' : '#529BC5')};
    box-shadow: none;
  }
`;

const StyledSmallButton = styled(MuiButton).bind({
  color: 'primary',
})`
  width: 234px;
  margin: ${(props) => props.theme.spacing(3)}px;
  border-radius: 25px;
  font-weight: bold;
  font-family: YuGothic;
  box-shadow: none;
`;

const StyleHeaderText = styled('div')`
  width: 100%;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #000;
`;

const SearchStyledButton = styled(MuiButton).bind({
  color: 'primary',
  variant: 'outlined',
})({
  display: 'flex',
  padding: '12px',
  gap: '4px',
  width: '120px',
  height: '44px',
  background: '#FFFFFF',
  color: '#000000',
  flex: 'none',
  order: 1,
  flexGrow: 0,
  border: '1px solid #D0D0D0',
  boxSizing: "border-box"
});
