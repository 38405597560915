import moment from 'moment-timezone';

const weeklyMap = new Map([
  ['0', '日'],
  ['1', '月'],
  ['2', '火'],
  ['3', '水'],
  ['4', '木'],
  ['5', '金'],
  ['6', '土'],
]);

export const getAge = (birthdayMillis: number) => {
  const today = moment().tz("Asia/Tokyo");
  const personalBirthday = moment(birthdayMillis).tz("Asia/Tokyo");
  return String(today.diff(personalBirthday, 'years'));
};

export const getYearOfBirthday = (birthdayMillis: number) => {
  return String(moment(birthdayMillis).tz("Asia/Tokyo").get('year'));
};

export const getMonthOfBirthday = (birthdayMillis: number) => {
  return String(moment(birthdayMillis).tz("Asia/Tokyo").get('month') + 1);
}

export const getDateOfBirthday = (birthdayMillis: number) => {
  return String(moment(birthdayMillis).tz("Asia/Tokyo").get('date'));
}

const convertNumber2DayOfWeek = (number: number) => {
  return weeklyMap.get(String(number));
};

export const getDayOfWeek = (number: number) => {
  const dayOfWeek = convertNumber2DayOfWeek(moment(number).tz("Asia/Tokyo").get('day'))
  if (dayOfWeek) {
    return dayOfWeek;
  } else {
    return '';
  }
};

export const isFutureDate = (date: Date) => {
  if (moment(date).diff(moment().startOf('day'), 'days') > 0) {
    return true;
  } else {
    return false;
  }
};

export const formatDateToYYYYMMDDHHMMSS = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${year}${month}${day}-${hours}${minutes}${seconds}`;
}


export const formatDateToYYYYMMDD = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}/${month}/${day}`;
}

export const formatDateToHHMM = (date: Date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}