import { LocalizationProvider, DatePicker, CalendarPickerView } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Title } from '../atoms/CustomTextField';
import { SearchDateRangeTitle } from '../atoms/TextField';
import { SimpleGrid } from '../atoms/Grid';
import ja from 'date-fns/locale/ja';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';

export type DateRange = {
  startDate: Date | null;
  endDate: Date | null;
}

type Props = {
  title: string;
  value: DateRange;
  required?: boolean;
  onChange: (v: DateRange) => void;
  inputFormat?: string;
  views?: CalendarPickerView[];
}

export const DateRangePicker = (props: Props) => {
  const {title, value, required, onChange} = props;
  return (
    <SimpleGrid>
      <Title data-required={required} variant="body1" gutterBottom mr={2} style={{fontSize: 14}} >{title}</Title>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <Box sx={{display: 'flex', alignItems: 'center'}} mb={2}>
          <DatePicker 
            value={value.startDate}
            onChange={(e: Date | null) => onChange({...value, startDate: e})}
            inputFormat='yyyy年MM月dd日'
            mask='____年__月__日'
            renderInput={(props: any) => <StyledTextField {...props} inputProps={{
              ...props.inputProps,
              placeholder: "日付を選択"
            }} style={{
              background: "white",
            }}/>}
          />
          <Box mr={2} ml={2}>〜</Box>
          <DatePicker 
            value={value.endDate}
            onChange={(e: Date | null) => onChange({...value, endDate: e})}
            inputFormat='yyyy年MM月dd日'
            mask='____年__月__日'
            renderInput={(props: any) => <StyledTextField {...props} inputProps={{
              ...props.inputProps,
              placeholder: "日付を選択"
            }} style={{ background: "white"}}/>}
          />
        </Box>
      </LocalizationProvider>
    </SimpleGrid>
  )
}

export const SearchDateRangePicker = (props: Props) => {
  const {title, value, required, onChange} = props;
  const inputFormat = props.inputFormat ? props.inputFormat : 'yyyy年MM月dd日';
  const views = props.views ? props.views : ['year', 'month', 'day'] as CalendarPickerView[];
  return (
    <SimpleGrid>
      <SearchDateRangeTitle data-required={required} variant="body1" gutterBottom mr={2}>{title}</SearchDateRangeTitle>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <DatePicker 
            value={value.startDate}
            onChange={(e: Date | null) => onChange({...value, startDate: e})}
            inputFormat={inputFormat}
            views={views} 
            mask='____年__月__日'
            renderInput={(props: any) => <TextField {...props} inputProps={{
              ...props.inputProps,
              placeholder: "日付を選択"
            }} style={{backgroundColor: '#fff'}}/>}
          />
          <Box mr={2} ml={2}>〜</Box>
          <DatePicker 
            value={value.endDate}
            onChange={(e: Date | null) => onChange({...value, endDate: e})}
            inputFormat={inputFormat}
            views={views} 
            mask='____年__月__日'
            renderInput={(props: any) => <TextField {...props} inputProps={{
              ...props.inputProps,
              placeholder: "日付を選択"
            }} style={{backgroundColor: '#fff'}}/>}
          />
        </Box>
      </LocalizationProvider>
    </SimpleGrid>
  )
}

const StyledTextField = styled(TextField)`
width: 200px;
.MuiInputBase-input {
  padding: 10px 12px
}
`