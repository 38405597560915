import { styled } from '@mui/system';
import {
  convertGenderTypeToIcon,
} from '../../../utils/Types';
import {
  getAge,
  getDateOfBirthday,
  getMonthOfBirthday,
  getYearOfBirthday,
  formatDateToYYYYMMDD,
  formatDateToHHMM
} from '../../../utils/Moment';
import dayjs from 'dayjs';
type Props = {
  gender: string;
  patientName: string;
  patientNumber: string | number;
  birthdayMillis: number;
  measureStartDate: number;
  age: number;
  affiliationName: string;
  lang: string;
}

export const PatientInfoCard = ({gender, patientName, patientNumber, birthdayMillis,measureStartDate, age, affiliationName, lang}:Props) => {
  const isJa = lang === 'ja';
  return (
    <Container>
      <GenderBox>
        <GenderIconContainer>
          <GenderIcon>
            {convertGenderTypeToIcon(gender)}
          </GenderIcon>
        </GenderIconContainer>
      </GenderBox>
      <div  style={{display: 'flex', flexDirection: 'column'}}>
      <InfoBox>
        <InfoTitle>{isJa ? '名前' : 'Name'}： </InfoTitle>
        <PatientName>{patientName}</PatientName>
        <InfoItem>
          <InfoTitle>{isJa ?  '受診者番号': 'ID'}：</InfoTitle>
          <InfoValue>{patientNumber}</InfoValue>
        </InfoItem>
        <InfoItem>
          <InfoTitle>{isJa ?  '生年月日' : 'Birthday'}：</InfoTitle>
          <InfoValue>
          {birthdayMillis
              ? dayjs(birthdayMillis).format('YYYY/MM/DD')
              : '-/-/-'}
          </InfoValue>
        </InfoItem>
        <InfoItem>
          <InfoTitle>{isJa ?  '年齢' : 'Age'}：</InfoTitle>
          <InfoValue>{age}{isJa ? '歳' : ' years old'}</InfoValue>
        </InfoItem>
        <InfoItem>
          <InfoTitle>{isJa ? '所属': 'Affiliation'}：</InfoTitle>
          <InfoValue>{affiliationName}</InfoValue>
        </InfoItem>
        </InfoBox>
        <InfoBox style={{borderLeft: 'none'}}>
        <InfoItem style={{marginLeft: 0}}>
          <InfoTitle>{isJa ? '検査実施日' : 'Inspection Date'}：</InfoTitle>
          <InfoValue>{formatDateToYYYYMMDD(new Date(measureStartDate))}</InfoValue>
        </InfoItem>
        <InfoItem>
          <InfoTitle>{isJa ? '測定開始時間' : 'Start Time of Measurement'}：</InfoTitle>
          <InfoValue>{formatDateToHHMM(new Date(measureStartDate))}</InfoValue>
        </InfoItem>
      </InfoBox>
     </div>
    </Container>
  )
}

const Container = styled('div')(()=>({
  display: 'flex',
  height:'60px',
  border: '1px solid #E5E5E5',
  boxSizing: 'border-box'
}))

const GenderBox = styled('div')(()=>({
  width: '100px',
  backgroundColor: '#F2F2F2'
}))

const InfoBox = styled('div')(()=>({
  padding: '4px 16px 2px 16px',
  borderLeft: '1px solid #E5E5E5',
  display: 'flex',
  alignItems: 'center'
}))

const PatientName = styled('div')(()=>({
  fontWeight: 'bold',
  fontSize:'16px',
  '@media print': {
    fontSize: "14px"
  }
}))

const InfoItem = styled('div')(()=>({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px'
}))

const InfoTitle = styled('div')(()=>({
  color: '#898989',
  fontSize:'14px',
  '@media print': {
    fontSize: "12px"
  }
}))

const InfoValue = styled('div')(()=>({
  color: '#4B4B4B',
  fontSize:'14px',
  '@media print': {
    fontSize: "12px"
  }
}))

const GenderIcon = styled('div')({
  transform: 'scale(0.8) translateY(3px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const GenderIconContainer = styled('div')({
  width: '50px',
  height:'50px',
  borderRadius:90,
  backgroundColor:'#fff',
  margin: '5px auto',
})