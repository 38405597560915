import { ManIcon, WomanIcon } from '../components/atoms/icons/SvgIcon';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export const convertGenderTypeToName = (genderType: string | undefined, lang: 'ja' | 'en') => {
  if(lang === 'ja') {
    return genderType === '男性'
    ? '男性'
    : genderType === '女性'
    ? '女性'
    : genderType === '答えたくない'
    ? '答えたくない'
    : null;
  } else {
    return genderType === '男性'
    ? 'man'
    : genderType === '女性'
    ? 'woman'
    : genderType === '答えたくない'
    ? 'no answer'
    : null;

  }
 
};
export const convertGenderType = (genderType: string | undefined) => {
  switch (genderType) {
    case "男性":
      return "man";
    case "女性":
        return "woman";
    default:
      break;
  }
};


export const convertGenderTypeToIcon = (genderType: string | undefined) => {
  return genderType === '男性' ? (
    <ManIcon />
  ) : genderType === '女性' ? (
    <WomanIcon />
  ) : genderType === '答えたくない' ? (
    <AccountCircleOutlinedIcon
      style={{ width: '50px', height: '50px', color: '#c0c0c0' }}
    />
  ) : null;
};

export enum PrefectureTypeEnum {
  HOKKAIDOU = 0,
  AOMORI = 1,
  IWATE = 2,
  MIYAGI = 3,
  AKITA = 4,
  YAMAGATA = 5,
  FUKUSHIMA = 6,
  IBARAKI = 7,
  TOCHIGI = 8,
  GUNMA = 9,
  SAITAMA = 10,
  CHIBA = 11,
  TOKYO = 12,
  KANAGAWA = 13,
  NIGATA = 14,
  TOYAMA = 15,
  ISHIKAWA = 16,
  FUKUI = 17,
  YAMANASHI = 18,
  NAGANO = 19,
  GIFU = 20,
  SHIZUOKA = 21,
  AICHI = 22,
  MIE = 23,
  SHIGA = 24,
  KYOTO = 25,
  OSAKA = 26,
  HYOGO = 27,
  NARA = 28,
  WAKAYAMA = 29,
  TOTTORI = 30,
  SHIMANE = 31,
  OKAYAMA = 32,
  HIROSHIMA = 33,
  YAMAGUCHI = 34,
  TOKUSHIMA = 35,
  KAGAWA = 36,
  EHIME = 37,
  KOUCHI = 38,
  FUKUOKA = 39,
  SAGA = 40,
  NAGASAKI = 41,
  KUMAMOTO = 42,
  OOITA = 43,
  MIYAZAKI = 44,
  KAGOSHIMA = 45,
  OKINAWA = 46,
}

export const prefectureList = [
  { value: PrefectureTypeEnum.HOKKAIDOU, label: '' },
  { value: PrefectureTypeEnum.AOMORI, label: '青森県' },
  { value: PrefectureTypeEnum.IWATE, label: '岩手県' },
  { value: PrefectureTypeEnum.MIYAGI, label: '宮城県' },
  { value: PrefectureTypeEnum.AKITA, label: '秋田県' },
  { value: PrefectureTypeEnum.YAMAGATA, label: '山形県' },
  { value: PrefectureTypeEnum.FUKUSHIMA, label: '福島県' },
  { value: PrefectureTypeEnum.IBARAKI, label: '茨城県' },
  { value: PrefectureTypeEnum.TOCHIGI, label: '栃木県' },
  { value: PrefectureTypeEnum.GUNMA, label: '群馬県' },
  { value: PrefectureTypeEnum.SAITAMA, label: '埼玉県' },
  { value: PrefectureTypeEnum.CHIBA, label: '千葉県' },
  { value: PrefectureTypeEnum.TOKYO, label: '東京都' },
  { value: PrefectureTypeEnum.KANAGAWA, label: '神奈川県' },
  { value: PrefectureTypeEnum.NIGATA, label: '新潟県' },
  { value: PrefectureTypeEnum.TOYAMA, label: '富山県' },
  { value: PrefectureTypeEnum.ISHIKAWA, label: '石川県' },
  { value: PrefectureTypeEnum.FUKUI, label: '福井県' },
  { value: PrefectureTypeEnum.YAMANASHI, label: '山梨県' },
  { value: PrefectureTypeEnum.NAGANO, label: '長野県' },
  { value: PrefectureTypeEnum.GIFU, label: '岐阜県' },
  { value: PrefectureTypeEnum.SHIZUOKA, label: '静岡県' },
  { value: PrefectureTypeEnum.AICHI, label: '愛知県' },
  { value: PrefectureTypeEnum.MIE, label: '三重県' },
  { value: PrefectureTypeEnum.SHIGA, label: '滋賀県' },
  { value: PrefectureTypeEnum.KYOTO, label: '京都府' },
  { value: PrefectureTypeEnum.OSAKA, label: '大阪府' },
  { value: PrefectureTypeEnum.HYOGO, label: '兵庫県' },
  { value: PrefectureTypeEnum.NARA, label: '奈良県' },
  { value: PrefectureTypeEnum.WAKAYAMA, label: '和歌山県' },
  { value: PrefectureTypeEnum.TOTTORI, label: '鳥取県' },
  { value: PrefectureTypeEnum.SHIMANE, label: '島根県' },
  { value: PrefectureTypeEnum.OKAYAMA, label: '岡山県' },
  { value: PrefectureTypeEnum.HIROSHIMA, label: '広島県' },
  { value: PrefectureTypeEnum.YAMAGUCHI, label: '山口県' },
  { value: PrefectureTypeEnum.TOKUSHIMA, label: '徳島県' },
  { value: PrefectureTypeEnum.KAGAWA, label: '香川県' },
  { value: PrefectureTypeEnum.EHIME, label: '愛媛県' },
  { value: PrefectureTypeEnum.KOUCHI, label: '高知県' },
  { value: PrefectureTypeEnum.FUKUOKA, label: '福岡県' },
  { value: PrefectureTypeEnum.SAGA, label: '佐賀県' },
  { value: PrefectureTypeEnum.NAGASAKI, label: '長崎県' },
  { value: PrefectureTypeEnum.KUMAMOTO, label: '熊本県' },
  { value: PrefectureTypeEnum.OOITA, label: '大分県' },
  { value: PrefectureTypeEnum.MIYAZAKI, label: '宮崎県' },
  { value: PrefectureTypeEnum.KAGOSHIMA, label: '鹿児島県' },
  { value: PrefectureTypeEnum.OKINAWA, label: '沖縄県' },
];


export const convertPrefectureType2Name = (
  type: PrefectureTypeEnum | null | undefined
): any => {
  switch (type) {
    case PrefectureTypeEnum.HOKKAIDOU:
      return '北海道';
    case PrefectureTypeEnum.AOMORI:
      return '青森県';
    case PrefectureTypeEnum.IWATE:
      return '岩手県';
    case PrefectureTypeEnum.MIYAGI:
      return '宮城県';
    case PrefectureTypeEnum.AKITA:
      return '秋田県';
    case PrefectureTypeEnum.YAMAGATA:
      return '山形県';
    case PrefectureTypeEnum.FUKUSHIMA:
      return '福島県';
    case PrefectureTypeEnum.IBARAKI:
      return '茨城県';
    case PrefectureTypeEnum.TOCHIGI:
      return '栃木県';
    case PrefectureTypeEnum.GUNMA:
      return '群馬県';
    case PrefectureTypeEnum.SAITAMA:
      return '埼玉県';
    case PrefectureTypeEnum.CHIBA:
      return '千葉県';
    case PrefectureTypeEnum.TOKYO:
      return '東京都';
    case PrefectureTypeEnum.KANAGAWA:
      return '神奈川県';
    case PrefectureTypeEnum.NIGATA:
      return '新潟県';
    case PrefectureTypeEnum.TOYAMA:
      return '富山県';
    case PrefectureTypeEnum.ISHIKAWA:
      return '石川県';
    case PrefectureTypeEnum.FUKUI:
      return '福井県';
    case PrefectureTypeEnum.YAMANASHI:
      return '山梨県';
    case PrefectureTypeEnum.NAGANO:
      return '長野県';
    case PrefectureTypeEnum.GIFU:
      return '岐阜県';
    case PrefectureTypeEnum.SHIZUOKA:
      return '静岡県';
    case PrefectureTypeEnum.AICHI:
      return '愛知県';
    case PrefectureTypeEnum.MIE:
      return '三重県';
    case PrefectureTypeEnum.SHIGA:
      return '滋賀県';
    case PrefectureTypeEnum.KYOTO:
      return '京都府';
    case PrefectureTypeEnum.OSAKA:
      return '大阪府';
    case PrefectureTypeEnum.HYOGO:
      return '兵庫県';
    case PrefectureTypeEnum.NARA:
      return '奈良県';
    case PrefectureTypeEnum.WAKAYAMA:
      return '和歌山県';
    case PrefectureTypeEnum.TOTTORI:
      return '鳥取県';
    case PrefectureTypeEnum.SHIMANE:
      return '島根県';
    case PrefectureTypeEnum.OKAYAMA:
      return '岡山県';
    case PrefectureTypeEnum.HIROSHIMA:
      return '広島県';
    case PrefectureTypeEnum.YAMAGUCHI:
      return '山口県';
    case PrefectureTypeEnum.TOKUSHIMA:
      return '徳島県';
    case PrefectureTypeEnum.KAGAWA:
      return '香川県';
    case PrefectureTypeEnum.EHIME:
      return '愛媛県';
    case PrefectureTypeEnum.KOUCHI:
      return '高知県';
    case PrefectureTypeEnum.FUKUOKA:
      return '福岡県';
    case PrefectureTypeEnum.SAGA:
      return '佐賀県';
    case PrefectureTypeEnum.NAGASAKI:
      return '長崎県';
    case PrefectureTypeEnum.KUMAMOTO:
      return '熊本県';
    case PrefectureTypeEnum.OOITA:
      return '大分県';
    case PrefectureTypeEnum.MIYAZAKI:
      return '宮崎県';
    case PrefectureTypeEnum.KAGOSHIMA:
      return '鹿児島県';
    case PrefectureTypeEnum.OKINAWA:
      return '沖縄県';
    default:
      return '';
  }
};
