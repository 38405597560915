import { SleepwellApi } from "@sleepwell_new_platform/common-functions/lib/api";
import { Announcement } from "@sleepwell_new_platform/common-types";
import axios from "axios";
import { useEffect, useState } from "react";

const Api = new SleepwellApi(axios);

const useAnnouncements = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const today = new Date();
        // 公開かつ掲載終了日前のお知らせを取得
        const data = (await Api.getAnnouncements())
          .filter((announcement) => announcement.isEditableUpdateDay === true && today.getTime() <= announcement.stopAt);
        setAnnouncements(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAnnouncements();
  }, []);

  return { announcements, isLoading };
};

export default useAnnouncements;
