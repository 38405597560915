export const EditIcon = () => {
  return (
    <svg width="64" height="32" viewBox="0 0 64 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.8284 5.22955L38.2074 1.18986C37.9804 0.936712 37.5768 0.936712 37.3498 1.18986L34.6335 4.21933L23.6592 16.459C23.5907 16.5354 23.5479 16.6274 23.519 16.7241C23.5158 16.7336 23.5083 16.7396 23.5061 16.7492L22.0243 22.4403C21.9622 22.6767 22.0211 22.9323 22.1774 23.1078C22.2931 23.2368 22.4472 23.306 22.6068 23.306C22.6635 23.306 22.7203 23.2977 22.7759 23.2798L27.8787 21.6271C27.8873 21.6247 27.8926 21.6152 27.9012 21.6128C27.9879 21.5806 28.0704 21.5328 28.1389 21.4564L39.1132 9.2167L41.8295 6.18723C42.0661 5.92214 42.0661 5.49345 41.8284 5.22955ZM23.4965 21.6355L24.3841 18.2239L26.5543 20.6444L23.4965 21.6355ZM27.7095 20.0198L24.9462 16.9378L35.0629 5.65466L37.8262 8.73666L27.7095 20.0198ZM38.6839 7.78018L37.3016 6.23858L35.9194 4.69698L37.7781 2.62399L40.5415 5.706L38.6839 7.78018Z" fill="#353535" />
    </svg>
  )
}

export const DisabledEditIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.4612 5.22955L18.8402 1.18986C18.6132 0.936712 18.2096 0.936712 17.9826 1.18986L15.2663 4.21933L4.29204 16.459C4.22351 16.5354 4.18069 16.6274 4.15178 16.7241C4.14857 16.7336 4.14107 16.7396 4.13893 16.7492L2.65713 22.4403C2.59503 22.6767 2.65392 22.9323 2.81024 23.1078C2.92587 23.2368 3.08004 23.306 3.23957 23.306C3.29632 23.306 3.35306 23.2977 3.40874 23.2798L8.51152 21.6271C8.52009 21.6247 8.52544 21.6152 8.53401 21.6128C8.62073 21.5806 8.70317 21.5328 8.77169 21.4564L19.746 9.2167L22.4623 6.18723C22.6989 5.92214 22.6989 5.49345 22.4612 5.22955ZM4.12929 21.6355L5.01688 18.2239L7.18711 20.6444L4.12929 21.6355ZM8.34236 20.0198L5.57897 16.9378L15.6957 5.65466L18.4591 8.73666L8.34236 20.0198ZM19.3167 7.78018L17.9344 6.23858L16.5522 4.69698L18.4109 2.62399L21.1743 5.706L19.3167 7.78018Z" fill="#CDCDCD"/>
    </svg>
  )
  
}