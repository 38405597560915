import { Headline, SubHeadline } from '../molecules/Headline';
import { MultiColmunsTable, MultiColspanColmunsTable } from '../molecules/Table';
import { SleepPieChart } from '../molecules/chart/SleepPieChart';
import { SleepGraphSet, SleepGraphSetEN } from '../../icons/SleepDetailTempSVG';
import { SubText } from '../atoms/Text';
import { DeltaPowerTimeChart } from '../molecules/chart/DeltaPowerTimeChart';
import { Box, Typography } from '@mui/material';
import { SleepwellApi } from "@sleepwell_new_platform/common-functions/lib/api";
import axios from 'axios';
import { useQuery, QueryFunctionContext } from 'react-query';
import { Progress } from '../atoms/Progress';
import { useEffect, useMemo } from 'react';
import { minToHHMM } from '../../utils/timeFormatter';
import SleepLineChart from '../molecules/chart/SleepLineChart';
import { Clinics, Patients } from '@sleepwell_new_platform/common-types';
import { styled } from '@mui/system';
import { convertGlobalToLocalPhoneNumber, roundToTwoDecimalPlaces } from '../../utils/commons';
import dayjs from 'dayjs';
import { Spacer } from '../atoms/Spacer';
import { roundToSecondDecimalPlace } from '../../utils/analyzations/roundFunctions';

type Props = {
  analyzationId: string;
  openReportModal: boolean;
  age: number;
  measureStartDate: number;
  clinic: Clinics | null,
  lang: 'ja' | 'en',
  type?: "analysis" | "reAnalysis" | "reCalculate",
  isPdfDownload?: boolean;
  edfFileName?: string;
  patients: Patients[];
  patientId: string;
  isImmediateAnalyize?: number
}

// 睡眠時間と睡眠潜時のテーブル
// ヘッダー
const tableSampleRowHeader2Ja = [
  '覚醒',
  'レム睡眠(R)',
  'ノンレム睡眠(N1)',
  'ノンレム睡眠(N2)',
  'ノンレム睡眠(N3)',
]
const tableSampleRowHeader2En = [
  'Wake',
  'REM Sleep',
  'Non REM 1 (N1)',
  'Non REM 2 (N2)',
  'Non REM 3 (N3)',
]

// タイトル列の色
const pieChartColors = [
  '#727170',
  '#F1715F',
  '#7ABAC6',
  '#5FA5D7',
  '#4F83C1'
]

type QueryKey = [string, string]; 
export const SleepAnalyticsDetail = (props: Props) => {
  // pathのanalyzationIdを取得
  const Api = new SleepwellApi(axios);
  const analyzationId = props.analyzationId;
  const openReportModal = props.openReportModal;
  const measureStartDate = props.measureStartDate;
  const lang = props.lang;  
  const clinic = props.clinic;
  const type = props.type;
  const edfFileName = props.edfFileName;
  const patients = props.patients;
  const patientId = props.patientId;
  const patient = patients.find((patient) => patient.id === patientId);
  const { data: analyzationResult, isLoading, refetch } = useQuery(
    [props.isPdfDownload ? 'analyzationResults' + analyzationId : 'analyzationResults', analyzationId], async (context: QueryFunctionContext<QueryKey>)=>{
    const analyzationIdFromKey = context.queryKey[1];
      if (!analyzationIdFromKey) return;
    const res = await Api.getAnalyzationResult(analyzationIdFromKey);
    return res.data;
  }
  );

  const tableHeader = useMemo(() => {
    if(lang === 'ja'){
      return ['項目', '結果', '解説'];
    } else {
      return ['Item', 'Result', 'Description'];
    }
  },[lang]);

  const tableHeader2 = useMemo(() => {
    if(lang === 'ja'){
      return ['項目', '結果', '割合（%）'];
    } else {
      return ['Item', 'Result', 'Percentage'];
    }
  },[lang]);
  
  const tableHeader3 = useMemo(() => {
    if(lang === 'ja'){
      return ['項目', '結果', '解説'];
    } else {
      return ['Item', 'Result', 'Description'];
    }
  },[lang]);

const tableHeader5 = useMemo(() => {
  if(lang === 'ja'){
    return ['項目', '結果(分)'];
    } else {
      return ['Item', 'Result (min)'];
  }},[lang]);

  const tableHeader6 = useMemo(() => {
    if(lang === 'ja'){
      return  ['項目', '結果(%)', '解説'];
      } else {
        return  ['Item', 'Result(%)', 'Description'];
    }},[lang]);

  const analyzationResultData = useMemo(() => {
    const sortedAnalaizationResult = analyzationResult?.result.Items.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1) ;
    return sortedAnalaizationResult ? sortedAnalaizationResult[0] ?? null : null;
  }, [analyzationResult, lang]);

  const tableSampleRows2Data = useMemo(() => {
    const isAllWake = analyzationResultData?.sl === 0 ? true : false;
    return [
      isAllWake ? analyzationResultData?.tib ?? 0 : analyzationResultData?.wStageTotalMin ?? 0, 
      isAllWake ? 0 : analyzationResultData?.rStageTotalMin ?? 0, 
      isAllWake ? 0 : analyzationResultData?.n1StageTotalMin ?? 0,  
      isAllWake ? 0 : analyzationResultData?.n2StageTotalMin ?? 0,  
      isAllWake ? 0 : analyzationResultData?.n3StageTotalMin ?? 0
    ]
  }, [analyzationResultData, lang]);

  const SleepPieChartData = useMemo(() => {
    if(lang === 'ja'){
    return tableSampleRowHeader2Ja.map((data, index) => {
      return {
        name: data,
        value: tableSampleRows2Data[index]
      }
    });
  } else {
    return tableSampleRowHeader2En.map((data, index) => {
      return {
        name: data,
        value: tableSampleRows2Data[index]
      }
    });
  }
  },[lang, tableSampleRows2Data]);

  // 結果の合計値
  const tableSampleRows2DataSum = tableSampleRows2Data.reduce((a, b) => a + b, 0);
  // テーブルのデータ
  const tableSampleRows2 = useMemo(() => {
    if(lang === 'ja'){
      return tableSampleRowHeader2Ja.map((data, index) => {
        return [
          { value: data, align: 'center' },
          { value: `${tableSampleRows2Data[index]}分`, align: 'right' },
          { value: `${roundToTwoDecimalPlaces(tableSampleRows2Data[index] / tableSampleRows2DataSum * 100)}%`, align: 'right' }
        ];
      });
    } else {
      return tableSampleRowHeader2En.map((data, index) => {
        return [
          { value: data, align: 'center' },
          { value: `${tableSampleRows2Data[index].toFixed(1)}min`, align: 'right' },
          { value: `${roundToTwoDecimalPlaces(tableSampleRows2Data[index] / tableSampleRows2DataSum * 100)}%`, align: 'right' }
        ];
      })as {value: string, align: "center" | "inherit" | "left" | "right" | "justify" | undefined}[][];
    }
  }, [lang, tableSampleRows2Data, tableSampleRows2DataSum]) as {value: string, align: "center" | "inherit" | "left" | "right" | "justify" | undefined}[][];

  const tableSampleRows = useMemo(() => {
    if(lang === 'ja'){
      return [
        [
          { value: '測定記録時間 (TIB)', align: 'left' },
          { value: `${analyzationResultData?.tib ? minToHHMM(analyzationResultData?.tib) : 'ー'}`, align: 'right' },
          { value: '就床から起床までの時間 （測定開始から終了までの時間）', align: 'left' }
        ],
        [
          { value: '入眠潜時 （SL）', align: 'left' },
          { value: `${analyzationResultData?.sl ? minToHHMM(analyzationResultData?.sl) : 'ー'}`, align: 'right' },
          { value: '就床(消灯から)入眠までの時間', align: 'left' }
        ],
        [
          { value: 'ノンレム 深睡眠潜時', align: 'left' },
          { value: `${analyzationResultData?.n3sl ? minToHHMM(analyzationResultData?.n3sl) : 'ー'}`, align: 'right' },
          { value: '入眠から最初の ノンレム睡眠(N3)までの時間', align: 'left' }
        ],
        [
          { value: 'レム睡眠潜時', align: 'left' },
          { value: `${analyzationResultData?.rsl ? minToHHMM(analyzationResultData?.rsl) : analyzationResultData?.rsl === 0 ? minToHHMM(analyzationResultData?.rsl)  : 'ー'}`, align: 'right' },
          { value: '入眠から最初の レム睡眠までの時間', align: 'left' }
        ],
        [
          { value: '睡眠時間 （SPT）', align: 'left' },
          { value: `${analyzationResultData?.spt ? minToHHMM(analyzationResultData?.spt) : 'ー'}`, align: 'right' },
          { value: '入眠から最終覚醒までの時間', align: 'left' }
        ],
        [
          { value: '全睡眠時間 （TST）', align: 'left' },
          { value: `${analyzationResultData?.tst ? minToHHMM(analyzationResultData?.tst) : 'ー'}`, align: 'right' },
          { value: '睡眠時間(SPT)から 中途覚醒時間を除いた時間', align: 'left' }
        ]
      ]
  } else {
      return [
        [
          { value: 'Time in Bed (TIB)', align: 'left' },
          { value: `${analyzationResultData?.tib ? minToHHMM(analyzationResultData?.tib, lang) : 'ー'}`, align: 'right' },
          { value: 'Time from going to bed until waking up (from beginning to end of measurement)', align: 'left' }
        ],
        [
          { value: 'Sleep Latency (SL)', align: 'left' },
          { value: `${analyzationResultData?.sl ? minToHHMM(analyzationResultData?.sl, lang) : 'ー'}`, align: 'right' },
          { value: 'Time from getting into bed (from lights out) until sleep onset', align: 'left' }
        ],
        [
          { value: 'N3 Latency', align: 'left' },
          { value: `${analyzationResultData?.n3sl ? minToHHMM(analyzationResultData?.n3sl, lang) : 'ー'}`, align: 'right' },
          { value: 'Time from sleep onset until first N3 sleep', align: 'left' }
        ],
        [
          { value: 'REM Latency', align: 'left' },
          { value: `${analyzationResultData?.rsl ? minToHHMM(analyzationResultData?.rsl, lang) : analyzationResultData?.rsl === 0 ? minToHHMM(analyzationResultData?.rsl, lang)  : 'ー'}`, align: 'right' },
          { value: 'Time from sleep onset until first REM sleep', align: 'left' }
        ],
        [
          { value: 'Sleep Period Time (SPT)', align: 'left' },
          { value: `${analyzationResultData?.spt ? minToHHMM(analyzationResultData?.spt, lang) : 'ー'}`, align: 'right' },
          { value: 'Time from sleep onset until final awakening', align: 'left' }
        ],
        [
          { value: 'Total Sleep Time (TST)', align: 'left' },
          { value: `${analyzationResultData?.tst ? minToHHMM(analyzationResultData?.tst, lang) : 'ー'}`, align: 'right' },
          { value: 'SPT excluding arousal', align: 'left' }
        ]
      ]
  }
  }, [analyzationResultData, lang]) as {value: string, align: "center" | "inherit" | "left" | "right" | "justify" | undefined}[][];

  const tableSampleRows3 = useMemo(() => {
    const isAllWake = analyzationResultData?.sl === 0 ? true : false;
    if(lang === 'ja'){
      return [
        [
          { value: '覚醒指数', align: 'center' },
          { value:  isAllWake ? 'ー' :`${roundToSecondDecimalPlace(analyzationResultData?.sptWIndex)}回`, align: 'right' },
          { value: '1時間あたりの総覚醒回数（エポック数）', align: 'left' }
        ],
        [
          { value: '覚醒反応時間 /エポック', align: 'center' },
          { value:  isAllWake ? 'ー' :`${analyzationResultData?.sptWakeResponseParEpoch ? analyzationResultData?.sptWakeResponseParEpoch < 0 ? 0 : analyzationResultData?.sptWakeResponseParEpoch : 0}秒`, align: 'right' },
          { value: '1エポックあたりの覚醒反応時間', align: 'left' }
        ],
        [
          { value: '連続覚醒時間の総和', align: 'center' },
          { value:  isAllWake ? 'ー' :`${analyzationResultData?.sptTotalContinuousWTime}分`, align: 'right' },
          { value: '覚醒（W）が3分（6エポック）以上持続した時間の合算時間', align: 'left' }
        ],
        [
          { value: '総覚醒時間（WASO）', align: 'center' },
          { value:  isAllWake ? 'ー' :`${analyzationResultData?.sptWaso}分`, align: 'right' },
          { value: '覚醒時間の総和', align: 'left' }
        ]
      ];
    } else {
      return [
        [
          { value: 'Wake Index', align: 'center' },
          { value:  isAllWake ? 'ー' :`${roundToSecondDecimalPlace(analyzationResultData?.sptWIndex)}times`, align: 'right' },
          { value: 'Number of wake per hour (number of epoch)', align: 'left' }
        ],
        [
          { value: 'Arousal Time/Epoch', align: 'center' },
          { value:  isAllWake ? 'ー' :`${analyzationResultData?.sptWakeResponseParEpoch ? analyzationResultData?.sptWakeResponseParEpoch < 0 ? 0 : analyzationResultData?.sptWakeResponseParEpoch : 0}sec`, align: 'right' },
          { value: 'Total arousal time in 30 seconds', align: 'left' }
        ],
        [
          { value: 'Total continuous awaking time', align: 'center' },
          { value:  isAllWake ? 'ー' :`${analyzationResultData?.sptTotalContinuousWTime}min`, align: 'right' },
          { value: 'Total time of awakening (W) lasting 3 minutes (6 epochs) or more', align: 'left' }
        ],
        [
          { value: 'Wake After Sleep Onset (WASO)', align: 'center' },
          { value:  isAllWake ? 'ー' :`${analyzationResultData?.sptWaso}min`, align: 'right' },
          { value: 'Total time of Wake', align: 'left' }
        ]
      ];
    }
  }, [analyzationResultData, lang])  as {value: string, align: "center" | "inherit" | "left" | "right" | "justify" | undefined}[][];

  const tableSampleRows4 = useMemo(() => {
    const isAllWake = analyzationResultData?.sl === 0 ? true : false;
    if(lang === 'ja'){
      return [
        [
          { value: '覚醒指数', align: 'center' },
          { value:  isAllWake ? 'ー' : `${roundToSecondDecimalPlace(analyzationResultData?.beforeW2hWIndex)}回`, align: 'right' },
          { value: '1時間あたりの総覚醒回数（エポック数）', align: 'left' }
        ],
        [
          { value: '覚醒反応時間 /エポック', align: 'center' },
          { value:  isAllWake ? 'ー' : `${analyzationResultData?.beforeSptWakeResponseParEpoch ? analyzationResultData?.beforeSptWakeResponseParEpoch > 0 ? roundToSecondDecimalPlace(analyzationResultData?.beforeSptWakeResponseParEpoch) : 0 : 0}秒`, align: 'right' },
          { value: '1エポックあたりの覚醒反応時間', align: 'left' }
        ],
        [
          { value: '連続覚醒時間の総和', align: 'center' },
          { value:  isAllWake ? 'ー' : `${analyzationResultData?.beforeW2hTotalContinuousWTime}分`, align: 'right' },
          { value: '覚醒（W）が3分（6エポック）以上持続した時間の合算時間', align: 'left' }
        ],
        [
          { value: '早朝覚醒割合', align: 'center' },
          { value:  isAllWake ? 'ー' : `${(analyzationResultData?.beforeW2hWaso ?? 0 ) /120 * 100 > 100 ? 100 : roundToSecondDecimalPlace((analyzationResultData?.beforeW2hWaso ?? 0 ) /120 * 100)}%`, align: 'right' },
          { value: '起床前2時間の覚醒割合', align: 'left' }
        ]
      ];
    } else {
      return [
        [
          { value: 'Wake Index', align: 'center' },
          { value:  isAllWake ? 'ー' : `${roundToSecondDecimalPlace(analyzationResultData?.beforeW2hWIndex)}times`, align: 'right' },
          { value: 'Number of wake per hour (number of epoch)', align: 'left' }
        ],
        [
          { value: 'Arousal Time/Epoch', align: 'center' },
          { value:  isAllWake ? 'ー' : `${analyzationResultData?.beforeSptWakeResponseParEpoch ? analyzationResultData?.beforeSptWakeResponseParEpoch > 0 ? roundToSecondDecimalPlace(analyzationResultData?.beforeSptWakeResponseParEpoch) : 0 : 0}sec`, align: 'right' },
          { value: 'Total time of awakening (W) lasting 3 minutes (6 epochs) or more', align: 'left' }
        ],
        [
          { value: 'Total continuous awaking time', align: 'center' },
          { value:  isAllWake ? 'ー' : `${analyzationResultData?.beforeW2hTotalContinuousWTime}min`, align: 'right' },
          { value: 'Total time of awakening (W) lasting 3 minutes (6 epochs) or more', align: 'left' }
        ],
        [
          { value: 'Wake After Sleep Onset', align: 'center' },
          { value:  isAllWake ? 'ー' : `${(analyzationResultData?.beforeW2hWaso ?? 0 ) /120 * 100 > 100 ? 100 : roundToSecondDecimalPlace((analyzationResultData?.beforeW2hWaso ?? 0 ) /120 * 100)}%`, align: 'right' },
          { value: 'Arousal ratio within 2 hours of pre-awakening', align: 'left' }
        ]
      ];
    }
  }, [analyzationResultData, lang]) as {value: string, align: "center" | "inherit" | "left" | "right" | "justify" | undefined}[][];

  const tableSampleRows5 = useMemo(() => {
    if(lang === 'ja'){
      return [
        [
          { value: '第1睡眠周期 \n 入眠から第1レム終了まで', align: 'center' },
          { value: `${(analyzationResultData?.firstSleepCycle === 0 || analyzationResultData?.firstSleepCycle === null) ? 'ー' : analyzationResultData?.firstSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.firstSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '第2睡眠周期 \n 第1レムの終了から第2レムの終了まで', align: 'center' },
          { value: `${(analyzationResultData?.secondSleepCycle === 0 || analyzationResultData?.secondSleepCycle === null) ? 'ー' : analyzationResultData?.secondSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.secondSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '第3睡眠周期 \n 第2レムの終了から第3レムの終了まで', align: 'center' },
          { value: `${(analyzationResultData?.thirdSleepCycle === 0 || analyzationResultData?.thirdSleepCycle === null) ? 'ー' : analyzationResultData?.thirdSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.thirdSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '第4睡眠周期 \n 第3レムの終了から第4レムの終了まで', align: 'center' },
          { value: `${(analyzationResultData?.fourthSleepCycle === 0 || analyzationResultData?.fourthSleepCycle === null) ? 'ー' : analyzationResultData?.fourthSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.fourthSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '第5睡眠周期 \n 第4レムの終了から第5レムの終了まで', align: 'center' },
          { value: `${(analyzationResultData?.fifthSleepCycle === 0 || analyzationResultData?.fifthSleepCycle === null) ? 'ー' : analyzationResultData?.fifthSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.fifthSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '第6睡眠周期 \n 第5レムの終了から第6レムの終了まで', align: 'center' },
          { value: `${(analyzationResultData?.sixthSleepCycle === 0 || analyzationResultData?.sixthSleepCycle === null) ? 'ー' : analyzationResultData?.sixthSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.sixthSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '平均睡眠周期 \n 平均からの乖離度の平均', align: 'center' },
          { value: `${(analyzationResultData?.averageSleepCycle === 0 || analyzationResultData?.averageSleepCycle === null) ? 'ー' : analyzationResultData?.averageSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.averageDeviation) ?? 'ー'})`, align: 'right' }
        ]
      ];
    } else {
      return [
        [
          { value: '1st Sleep Cycle \nfrom sleep onset to 1st REM', align: 'center' },
          { value: `${(analyzationResultData?.firstSleepCycle === 0 || analyzationResultData?.firstSleepCycle === null) ? 'ー' : analyzationResultData?.firstSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.firstSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '2nd Sleep Cycle \nfrom 1st to 2nd REM', align: 'center' },
          { value: `${(analyzationResultData?.secondSleepCycle === 0 || analyzationResultData?.secondSleepCycle === null) ? 'ー' : analyzationResultData?.secondSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.secondSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '3rd Sleep Cycle \nfrom 2nd to 3rd REM', align: 'center' },
          { value: `${(analyzationResultData?.thirdSleepCycle === 0 || analyzationResultData?.thirdSleepCycle === null) ? 'ー' : analyzationResultData?.thirdSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.thirdSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '4th Sleep Cycle \nfrom 3rd to 4th REM', align: 'center' },
          { value: `${(analyzationResultData?.fourthSleepCycle === 0 || analyzationResultData?.fourthSleepCycle === null) ? 'ー' : analyzationResultData?.fourthSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.fourthSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '5th Sleep Cycle \nfrom 4th to 5th REM', align: 'center' },
          { value: `${(analyzationResultData?.fifthSleepCycle === 0 || analyzationResultData?.fifthSleepCycle === null) ? 'ー' : analyzationResultData?.fifthSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.fifthSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: '6th Sleep Cycle \nfrom 5th to 6th REM', align: 'center' },
          { value: `${(analyzationResultData?.sixthSleepCycle === 0 || analyzationResultData?.sixthSleepCycle === null) ? 'ー' : analyzationResultData?.sixthSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.sixthSleepDeviation) ?? 'ー'})`, align: 'right' }
        ],
        [
          { value: 'Average Time of Sleep Cycle \nAverage of differences from the average for each cycle', align: 'center' },
          { value: `${(analyzationResultData?.averageSleepCycle === 0 || analyzationResultData?.averageSleepCycle === null) ? 'ー' : analyzationResultData?.averageSleepCycle}\n(${roundToSecondDecimalPlace(analyzationResultData?.averageDeviation) ?? 'ー'})`, align: 'right' }
        ]
      ];
    }
  }, [analyzationResultData, lang]) as {value: string, align: "center" | "inherit" | "left" | "right" | "justify" | undefined}[][];

  const tableSampleRows6 = useMemo(() => {
    if(lang === 'ja'){
      return [
        [
          { value: '睡眠効率（SE）\n上段：TST/TIB\n下段：TST/SPT', align: 'center' },
          { value: `${analyzationResultData?.seTib} \n(${roundToSecondDecimalPlace(analyzationResultData?.seSpt) ?? 'ー'})`, align: 'right' },
          { value: 'SE：眠っていた時間(TST)の割合\nTIB：測定記録時間\nSPT：入眠～最終覚醒', align: 'left' }
        ]
      ];
    } else {
      return [
        [
          { value: 'Sleep Efficiency (SE）\nUpper：TST/TIB\nLower：TST/SPT', align: 'center' },
          { value: `${analyzationResultData?.seTib} \n(${roundToSecondDecimalPlace(analyzationResultData?.seSpt) ?? 'ー'})`, align: 'right' },
          { value: 'Ratio of TST to TIB(Upper)\nRatio of TST to SPT(Lower)', align: 'left' }
        ]
      ];
    }
  }, [analyzationResultData, lang])  as {value: string, align: "center" | "inherit" | "left" | "right" | "justify" | undefined}[][];
  const tableSampleRows7 = useMemo(() => {
    if(lang === 'ja'){
      return [
        [
          { value: '①第1睡眠周期の総δパワー値', align: 'center' },
          { value: `${analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPower ? roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPower) : 'ー'}μV²`, align: 'right' },
          { value: '第1周期における ノンレム睡眠時の総デルタパワー値', align: 'left' }
        ],
        [
          { value: '②第1睡眠周期時間（REMは除く）', align: 'center' },
          { value: `${roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.totalSleepCycleTime) ?? 'ー'}分`, align: 'right' },
          { value: '第1周期におけるノンレム睡眠の時間', align: 'left' }
        ],
        [
          { value: '③第1睡眠周期のδパワー値 /分', align: 'center' },
          { value: `${roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPowParMin) ?? 'ー'}μV²/分`, align: 'right' },
          { value: '第1周期におけるノンレム睡眠時の 1分あたりのデルタパワー量（①/②）', align: 'left' }
        ],
        [
          { value: '④ノンレム睡眠時の総δパワー量', align: 'center' },
          { value: `${roundToSecondDecimalPlace(analyzationResultData?.totalNonremPow) ?? 'ー'}μV²`, align: 'right' },
          { value: '全ノンレム睡眠時の総デルタパワー量', align: 'left' }
        ],
        [
          { value: '⑤ノンレム睡眠時の総δパワー/分', align: 'center' },
          { value: `${roundToSecondDecimalPlace(analyzationResultData?.totalNonremDeltaPowParMin) ?? 'ー'}μV²/分`, align: 'right' },
          { value: '全ノンレム睡眠時における1分あたりのデルタパワー量（④/N1+N2+N3の合計時間）', align: 'left' }
        ],
        [
          { value: '⑥第1周期/ノンレム睡眠時', align: 'center' },
          { value: `${roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.deltaPowParTotalDeltaPow) ?? 'ー'}%`, align: 'right' },
          { value: '全ノンレム睡眠時の総デルタパワー量における第1周期のデルタパワー量（①/④）', align: 'left' }
        ]
      ];
    } else {
      return [
        [
          { value: '①Total Delta Power Volume at 1st Sleep Cycle', align: 'center' },
          { value: `${analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPower ? roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPower) : 'ー'}μV²`, align: 'right' },
          { value: 'Total delta power volume at 1st sleep cycle during NR', align: 'left' }
        ],
        [
          { value: '②Time of NR at 1st sleep cycle(excluding R and W)', align: 'center' },
          { value: `${roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.totalSleepCycleTime) ?? 'ー'}min`, align: 'right' },
          { value: 'Time of NR at 1st sleep cycle (excluding R and W)', align: 'left' }
        ],
        [
          { value: '③Delta Power Volume at 1st Cycle Per Minute', align: 'center' },
          { value: `${roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPowParMin) ?? 'ー'}μV²/min`, align: 'right' },
          { value: 'Delta power volume at 1st sleep cycle during NR per minute (①/②)', align: 'left' }
        ],
        [
          { value: '④Total Delta Power Volume at NR', align: 'center' },
          { value: `${roundToSecondDecimalPlace(analyzationResultData?.totalNonremPow) ?? 'ー'}minμV²`, align: 'right' },
          { value: 'Total delta power volume at NR', align: 'left' }
        ],
        [
          { value: '⑤Total Delta Power Volume at NR Per Minute', align: 'center' },
          { value: `${roundToSecondDecimalPlace(analyzationResultData?.totalNonremDeltaPowParMin) ?? 'ー'}μV²/min`, align: 'right' },
          { value: 'Delta power volume per minute during NR (④/total time of N1+N2+N3)', align: 'left' }
        ],
        [
          { value: '⑥1st Sleep Cycle/NR Sleep', align: 'center' },
          { value: `${roundToSecondDecimalPlace(analyzationResultData?.firstNonremDeltaPowerInfo.deltaPowParTotalDeltaPow) ?? 'ー'}%`, align: 'right' },
          { value: 'Percentage of 1st cycle delta power volume to total delta power volume of all non-REM sleep (①/④)', align: 'left' }
        ]
      ];
    }
  }, [analyzationResultData, lang]) as {value: string, align: "center" | "inherit" | "left" | "right" | "justify" | undefined}[][];
  const tableHeader8 = useMemo(() => {
    if(lang === 'ja'){
      return  [{ value: '', colspan: 1 }, { value: '第1睡眠周期', colspan: 2 }, { value: '第2睡眠周期', colspan: 2 }, { value: '第3睡眠周期', colspan: 2 }, { value: '第4睡眠周期', colspan: 2 }, { value: '第5睡眠周期', colspan: 2 }, { value: '第6睡眠周期', colspan: 2 }, { value: 'SPT(合計)', colspan: 2 }, { value: '平均', colspan: 1 }];
    } else {
      return  [{ value: '', colspan: 1 }, { value: '1st Sleep Cycle', colspan: 2 }, { value: '2nd Sleep Cycle', colspan: 2 }, { value: '3rd Sleep Cycle', colspan: 2 }, { value: '4th Sleep Cycle', colspan: 2 }, { value: '5th Sleep Cycle', colspan: 2 }, { value: '6th Sleep Cycle', colspan: 2 }, { value: 'SPT(Total)', colspan: 2 }, { value: 'Agerage', colspan: 1 }];
    }
  }, [lang]);
  
  const tableSampleRows8 = useMemo(() => {
    const firstSleepStageMinAndRatio = analyzationResultData?.firstSleepStageMinAndRatio;
    const secondSleepStageMinAndRatio = analyzationResultData?.secondSleepStageMinAndRatio;
    const thirdSleepStageMinAndRatio = analyzationResultData?.thirdSleepStageMinAndRatio;
    const fourrhSleepStageMinAndRatio = analyzationResultData?.fourthSleepStageMinAndRatio;
    const fifthSleepStageMinAndRatio = analyzationResultData?.fifthSleepStageMinAndRatio;
    const sixthSleepStageMinAndRatio = analyzationResultData?.sixthSleepStageMinAndRatio;
    const firstNonremDeltaPowerInfo = analyzationResultData?.firstNonremDeltaPowerInfo;
    const secondNonremDeltaPowerInfo = analyzationResultData?.secondNonremDeltaPowerInfo;
    const thirdNonremDeltaPowerInfo = analyzationResultData?.thirdNonremDeltaPowerInfo;
    const fourthNonremDeltaPowerInfo = analyzationResultData?.fourthNonremDeltaPowerInfo;
    const fifthNonremDeltaPowerInfo = analyzationResultData?.fifthNonremDeltaPowerInfo;
    const sixthNonremDeltaPowerInfo = analyzationResultData?.sixthNonremDeltaPowerInfo;
    const n1ToN3StageTotalMin = (analyzationResultData?.n1StageTotalMin ?? 0) + (analyzationResultData?.n2StageTotalMin ?? 0) + (analyzationResultData?.n3StageTotalMin ?? 0);
    if(lang === 'ja'){
    return [
      [{ value: '周期時間', colspan: 1 }, { value: analyzationResultData?.firstSleepCycle === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.firstSleepCycle) ?? '-', colspan: 2 }, { value: analyzationResultData?.secondSleepCycle === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.secondSleepCycle) ?? '-', colspan: 2 }, { value: analyzationResultData?.thirdSleepCycle === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.thirdSleepCycle) ?? '-', colspan: 2 }, { value: analyzationResultData?.fourthSleepCycle === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.fourthSleepCycle) ?? '-', colspan: 2 }, { value: analyzationResultData?.fifthSleepCycle === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.fifthSleepCycle) ?? '-', colspan: 2 }, { value: analyzationResultData?.sixthSleepCycle === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.sixthSleepCycle) ?? '-', colspan: 2 }, { value: '-', colspan: 2 }, { value: roundToSecondDecimalPlace(analyzationResultData?.averageSleepCycle) ?? '-', colspan: 1 }],
      [{ value: '平均からの乖離度', colspan: 1 }, { value: analyzationResultData?.firstSleepDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.firstSleepDeviation) ?? '-', colspan: 2 }, { value:analyzationResultData?.secondSleepDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.secondSleepDeviation) ?? ' -', colspan: 2 }, { value: analyzationResultData?.thirdSleepDeviation === 0 ? '-' :  roundToSecondDecimalPlace(analyzationResultData?.thirdSleepDeviation) ?? '-', colspan: 2 }, { value: analyzationResultData?.fourthSleepDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.fourthSleepDeviation) ?? '-', colspan: 2 }, { value: analyzationResultData?.fifthSleepDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.fifthSleepDeviation) ?? '-', colspan: 2 }, { value: analyzationResultData?.sixthSleepDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.sixthSleepDeviation) ?? '-', colspan: 2 }, { value: '-', colspan: 2 }, { value: analyzationResultData?.averageDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.averageDeviation) ?? '-', colspan: 1 }],
      [{ value: '', colspan: 1 }, { value: '分', colspan: 1 }, { value: '%', colspan: 1 }, { value: '分', colspan: 1 }, { value: '%', colspan: 1 }, { value: '分', colspan: 1 }, { value: '%', colspan: 1 }, { value: '分', colspan: 1 }, { value: '%', colspan: 1 }, { value: '分', colspan: 1 }, { value: '%', colspan: 1 }, { value: '分', colspan: 1 }, { value: '%', colspan: 1 }, { value: '分', colspan: 1 }, { value: '%', colspan: 1 }, { value: '', colspan: 1 },],
      [{ value: 'W', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.wStageRatio) ?? '-', colspan: 1 }, { value: '-', colspan: 1 }],
      [{ value: 'R', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.rStageRatio) ?? '-', colspan: 1 }, { value: '-', colspan: 1 }],
      [{ value: 'N1', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n1StageRatio) ?? '-', colspan: 1 }, { value: '-', colspan: 1 }],
      [{ value: 'N2', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.n2StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n2StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n2StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n2StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n2StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n2StageRatio) ?? '-', colspan: 1 }, { value: '-', colspan: 1 }],
      [{ value: 'N3', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n3StageRatio) ?? '-', colspan: 1 }, { value: '-', colspan: 1 }],
      [{ value: 'デルタパワー合計値', colspan: 1 }, { value: roundToSecondDecimalPlace((firstNonremDeltaPowerInfo?.totalDeltaPower ?? 0))  , colspan: 2 }, { value: roundToSecondDecimalPlace((secondNonremDeltaPowerInfo?.totalDeltaPower  ?? 0)), colspan: 2 }, { value: roundToSecondDecimalPlace(thirdNonremDeltaPowerInfo?.totalDeltaPower ?? 0), colspan: 2 }, { value: roundToSecondDecimalPlace(fourthNonremDeltaPowerInfo?.totalDeltaPower ?? 0), colspan: 2 }, { value: roundToSecondDecimalPlace(fifthNonremDeltaPowerInfo?.totalDeltaPower ?? 0), colspan: 2 }, { value: roundToSecondDecimalPlace(sixthNonremDeltaPowerInfo?.totalDeltaPower ?? 0), colspan: 2 }, { value: roundToSecondDecimalPlace(analyzationResultData?.totalNonremPow ?? 0), colspan: 2 }, { value: '-', colspan: 1 }],
      [{ value: '第1睡眠周期時間(REMは除く)', colspan: 1 }, { value: roundToSecondDecimalPlace(firstNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(secondNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(thirdNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fourthNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fifthNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(sixthNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(n1ToN3StageTotalMin), colspan: 2 }, { value: '-', colspan: 1 }],
      [{ value: 'デルタパワー値/分', colspan: 1 }, { value: roundToSecondDecimalPlace(firstNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(secondNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(thirdNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fourthNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fifthNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(sixthNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(analyzationResultData?.totalNonremDeltaPowParMin) ?? '-', colspan: 2 }, { value: '-', colspan: 1 }],
      [{ value: 'デルタパワー全体に占める割合', colspan: 1 }, { value:roundToSecondDecimalPlace( firstNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(secondNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(thirdNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fourthNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fifthNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(sixthNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: '-', colspan: 2 }, { value: '-', colspan: 1 }],
    ]
  } else {
    console.log('fourrhSleepStageMinAndRatio', analyzationResultData)
    return [
      [{ value: 'Sleep cycle duration', colspan: 1 }, { value: analyzationResultData?.firstSleepCycle === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.firstSleepCycle) ?? '-', colspan: 2 }, { value: analyzationResultData?.secondSleepCycle === 0 ? '-' :roundToSecondDecimalPlace(analyzationResultData?.secondSleepCycle) ?? '-', colspan: 2 }, { value: analyzationResultData?.thirdSleepCycle === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.thirdSleepCycle) ?? '-', colspan: 2 }, { value:  analyzationResultData?.fourthSleepCycle === 0 ? '-' :roundToSecondDecimalPlace(analyzationResultData?.fourthSleepCycle) ?? '-', colspan: 2 }, { value: analyzationResultData?.fifthSleepCycle === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.fifthSleepCycle) ?? '-', colspan: 2 }, { value: analyzationResultData?.sixthSleepCycle === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.sixthSleepCycle) ?? '-', colspan: 2 }, { value: '-', colspan: 2 }, { value: roundToSecondDecimalPlace(analyzationResultData?.averageSleepCycle) ?? '-', colspan: 1 }],
      [{ value: 'Differences from the average for each cycle', colspan: 1 }, { value: analyzationResultData?.firstSleepDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.firstSleepDeviation) ?? '-', colspan: 2 }, { value: analyzationResultData?.secondSleepDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.secondSleepDeviation) ?? '-', colspan: 2 }, { value: analyzationResultData?.thirdSleepDeviation === 0 ? '-' :  roundToSecondDecimalPlace(analyzationResultData?.thirdSleepDeviation) ?? '-', colspan: 2 }, { value: analyzationResultData?.fourthSleepDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.fourthSleepDeviation) ?? '-', colspan: 2 }, { value: analyzationResultData?.fifthSleepDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.fifthSleepDeviation) ?? '-', colspan: 2 }, { value: analyzationResultData?.sixthSleepDeviation === 0 ? '-' : roundToSecondDecimalPlace(analyzationResultData?.sixthSleepDeviation) ?? '-' , colspan: 2 }, { value: '-', colspan: 2 }, { value: roundToSecondDecimalPlace(analyzationResultData?.averageDeviation) ?? '-', colspan: 1 }],
      [{ value: '', colspan: 1 }, { value: 'min', colspan: 1 }, { value: '%', colspan: 1 }, { value: 'min', colspan: 1 }, { value: '%', colspan: 1 }, { value: 'min', colspan: 1 }, { value: '%', colspan: 1 }, { value: 'min', colspan: 1 }, { value: '%', colspan: 1 }, { value: 'min', colspan: 1 }, { value: '%', colspan: 1 }, { value: 'min', colspan: 1 }, { value: '%', colspan: 1 }, { value: 'min', colspan: 1 }, { value: '%', colspan: 1 }, { value: '', colspan: 1 },],
      [{ value: 'W', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: firstSleepStageMinAndRatio?.wStageRatio ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value:roundToSecondDecimalPlace( secondSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.wStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.wStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.wStageRatio) ?? '-', colspan: 1 }, { value: '-', colspan: 1 }],
      [{ value: 'R', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value: firstSleepStageMinAndRatio?.rStageRatio ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value:roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.rStageTotalMin) ?? '-', colspan: 1 }, { value:roundToSecondDecimalPlace( sixthSleepStageMinAndRatio?.rStageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.rStageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.rStageRatio) ?? '-', colspan: 1 }, { value: '-', colspan: 1 }],
      [{ value: 'N1', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: firstSleepStageMinAndRatio?.n1StageRatio ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value:roundToSecondDecimalPlace(analyzationResultData?.n1StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n1StageRatio) ?? '-', colspan: 1 }, { value: '-', colspan: 1 }],
      [{ value: 'N2', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: firstSleepStageMinAndRatio?.n2StageRatio ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n2StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n2StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value:roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n2StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n1StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n2StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n2StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n2StageRatio) ?? '-', colspan: 1 }, { value: '-', colspan: 1 }],
      [{ value: 'N3', colspan: 1 }, { value: roundToSecondDecimalPlace(firstSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: firstSleepStageMinAndRatio?.n3StageRatio ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(secondSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(thirdSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fourrhSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(fifthSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value:roundToSecondDecimalPlace( fifthSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(sixthSleepStageMinAndRatio?.n3StageRatio) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n3StageTotalMin) ?? '-', colspan: 1 }, { value: roundToSecondDecimalPlace(analyzationResultData?.n3StageRatio) ?? '-', colspan: 1 }, { value: '-', colspan: 1 }],
      [{ value: 'Total Delta Power Volume at 1st Sleep Cycle', colspan: 1 }, { value: roundToSecondDecimalPlace(firstNonremDeltaPowerInfo?.totalDeltaPower) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(secondNonremDeltaPowerInfo?.totalDeltaPower) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(thirdNonremDeltaPowerInfo?.totalDeltaPower) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fourthNonremDeltaPowerInfo?.totalDeltaPower) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fifthNonremDeltaPowerInfo?.totalDeltaPower) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(sixthNonremDeltaPowerInfo?.totalDeltaPower) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(analyzationResultData?.totalNonremPow) ?? '-', colspan: 2 }, { value: '-', colspan: 1 }],
      [{ value: 'Time of NR at 1st sleep cycle(excluding R and W)', colspan: 1 }, { value: roundToSecondDecimalPlace(firstNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value:roundToSecondDecimalPlace(secondNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(thirdNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fourthNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fifthNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(sixthNonremDeltaPowerInfo?.totalSleepCycleTime) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(n1ToN3StageTotalMin) ?? '-', colspan: 2 }, { value: '-', colspan: 1 }],
      [{ value: 'Delta Power Volume at 1st Cycle Per Minute', colspan: 1 }, { value: roundToSecondDecimalPlace(firstNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(secondNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(thirdNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fourthNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fifthNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(sixthNonremDeltaPowerInfo?.totalDeltaPowParMin) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(analyzationResultData?.totalNonremDeltaPowParMin) ?? '-', colspan: 2 }, { value: '-', colspan: 1 }],
      [{ value: 'Each sleep Cycle/NR Sleep', colspan: 1 }, { value: roundToSecondDecimalPlace(firstNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(secondNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(thirdNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fourthNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(fifthNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: roundToSecondDecimalPlace(sixthNonremDeltaPowerInfo?.deltaPowParTotalDeltaPow) ?? '-', colspan: 2 }, { value: '-', colspan: 2 }, { value: '-', colspan: 1 }],
    ]
  }
  }, [analyzationResultData, lang]);
  const reportMark = useMemo(() => {
    const isImmediateAnalyize = props.isImmediateAnalyize === 1 ? true : false
    const isReCalc  = type === "reCalculate" ? true : false
    if(isReCalc){
      return "R"
    }
    if(!isImmediateAnalyize){
      return "*"
    }
    return ""
  }, [clinic, type, props.isImmediateAnalyize]);

  if (isLoading || !analyzationId) return <div style={{ height: '60vh' }}><Progress width={'calc(100% - 64px);'} /></div>
  return (
    <>
    <Container>
      <div style={{marginTop: 24, display: 'flex', justifyContent: 'space-between', marginBottom: 8}}>
      <SubTextWrap>
      <StyledSubText>{lang === 'ja' ? '測定開始日時': 'Measure Date'}：<br/>{dayjs(measureStartDate).format('YYYY年MM月DD日 HH:mm')}</StyledSubText>
      </SubTextWrap>
      <SubTextWrap>
      <StyledSubText>{lang === 'ja' ? 'ファイル名': 'File Name'}：<br/>{edfFileName?.replace('.EDF', '')}</StyledSubText>
      </SubTextWrap>
      <SubTextWrap>
      <StyledSubText>ID：<br/>{patient?.patientNumber ?? ''}</StyledSubText>
      </SubTextWrap>
      </div>
      <Headline
        title= {lang === "ja" ? "睡眠経過図：睡眠時間における睡眠段階の経過推移" : "Hypnogram:changes of sleep stages"}
      />
      <SleepLineChart
        analyzationResultData={analyzationResultData}
        openReportModal={openReportModal}
        measureStartDate={measureStartDate}
        lang={lang}
      />
      <PrintFlex>
        <PrintFlexContents>
          <Headline
            title={lang === "ja" ? "睡眠時間と睡眠潜時" : "Sleep Duration and Latency"}
          />
          <Spacer height={8} />
          <MultiColmunsTable
            headers={tableHeader}
            rows={tableSampleRows}
          />
        </PrintFlexContents>
        <PrintFlexContents>
        <SubHeadline
          title={lang === "ja" ? "睡眠時間（SPT）における各ステージの合計時間と割合" : "Wake and Arousal index during Sleep Period Time(SPT)"}
        />
          <PrintSleepStage
            gap={'32px'}
          >
            <MultiColmunsTable
              headers={tableHeader2}
              rows={tableSampleRows2}
              indexColors={pieChartColors}
              isAllCellCenter={false}
              isWhiteIndexFont={true}
              // width='25vw'
            />
            <SleepPieChart
              data={SleepPieChartData}
              colors={pieChartColors}
            />
          </PrintSleepStage>
        </PrintFlexContents>
      </PrintFlex>
      {!props.isPdfDownload &&<PrintNone>
        <Headline
          title={lang === "ja" ? "睡眠経過図と主な睡眠変数" : "Hypnogram and sleep index"}
        />
        <Box
        >
        {lang === "ja" ? <SleepGraphSet /> : <SleepGraphSetEN/>}
        </Box>
      </PrintNone>}
      <PrintFlex>
        <PrintFlexContents>
          <Headline
            title={lang === "ja" ? "睡眠時間(SPT)における中途覚醒・覚醒反応" : "Wake and Arousal index during Sleep Period Time(SPT)"}
          />
          <Spacer height={8} />
          <MultiColmunsTable
            headers={tableHeader3}
            rows={tableSampleRows3}
          />
          <PageBreak />
          <Spacer height={8} />
  
          <Headline
            title={lang === "ja" ? "起床前2時間における覚醒・覚醒反応" : "Wake and Arousal index during 2 hours before waking up" }
          />
          <Spacer height={8} />
          <MultiColmunsTable
            headers={tableHeader3}
            rows={tableSampleRows4}
          />
        </PrintFlexContents>
        {props.isPdfDownload &&  <div style={{height: lang === "ja" ? 60 : 90}}/>}
        <PrintFlexContents>
          <Spacer height={8} />
          <Headline
            title={lang === "ja" ? "睡眠周期" : "Sleep Cycle"}
          />
          <Spacer height={8} />
          <MultiColmunsTable
            headers={tableHeader5}
            rows={tableSampleRows5}
            printHeaderWidthAuto
          />
          <PageBreak />
          <Spacer height={8} />
          <Headline
            title={lang === "ja" ?"睡眠効率" : "Sleep Efficiency"}
          />
          <div style={{marginTop: 8}}/>
          <MultiColmunsTable
            headers={tableHeader6}
            rows={tableSampleRows6}
          />
        </PrintFlexContents>
      </PrintFlex>
      <Spacer height={8} />
      <Headline
        title={lang === "ja" ?"ノンレム睡眠のデルタパワー" : "Delta Power During Non REM Periods"}
      />
       <Spacer height={8} />
      <SubText
        fontcolor='black'
        width={'97%'}
        mb={2}
      >
        {lang === "ja" ?`デルタ波は主にノンレム深睡眠時に出現する。脳波を一定の周波数帯域毎に分割し、0.5～2Hzの脳波（デルタ波）のパワースペクトルを周波数の関数として表したものをδパワーという。単位は脳波の振幅の2乗。この値は熟眠度や眠りの深さの指標として活用されている。` : "Delta waves mainlyappear during non-REMsleep. δpower is the frequency function representation of power spectrums of 0.5~2 Hz brainwaves (Delta waves), that were divided by fixed frequency bands. The unit is the square of the brainwaves' amplitude.This is an indicator of slow wave activity and depth of sleep."}
      </SubText>
      <MultiColmunsTable
        headers={tableHeader3}
        rows={tableSampleRows7}
      />
      <Spacer height={8} />
      <Headline
        title={lang === "ja" ?"デルタパワー経過図：睡眠時間におけるデルタ波の量（パワー量）推移" : "Change of delta power:change of delta power volume during sleep period"}
      />
      <div style={{marginTop: 8}}/>
      <DeltaPowerTimeChart
        analyzationResultData={analyzationResultData}
        age={props.age}
        measureStartDate={measureStartDate}
        lang={lang}
      />
      </Container>
      {clinic?.isEnableSleepDetailData &&
        <>
          <Headline
            title={lang === "ja" ?"睡眠周期ごとの睡眠変数" : "Sleep variables for each sleep cycle"}
          />
          <Spacer height={8} />
          <MultiColspanColmunsTable
            headers={tableHeader8}
            rows={tableSampleRows8}
            isAllCellCenter={true}
            width='100%'
          />
        </>}
        {props.isPdfDownload  &&  <div style={{height: lang === 'ja' ? 100 : 40}}/>}
    <div style={{marginTop: 16}}>
        {lang === "ja" ?<Typography style={{fontSize: 15, color: "#898989"}}>{`${reportMark}${clinic?.nameJP ?? ""}　電話番号: ${clinic?.phoneNumberJP ? convertGlobalToLocalPhoneNumber(clinic?.phoneNumberJP) : ""}　住所: ${clinic?.prefecture ? clinic?.prefecture : ""}${clinic?.address ?? ""}`}</Typography> : 
        <Typography style={{fontSize: 15, color: "#898989"}}>{`${reportMark}${clinic?.nameEN ?? ""}　電話番号: ${clinic?.phoneNumberEN ? convertGlobalToLocalPhoneNumber(clinic?.phoneNumberEN) : ""}　住所: ${clinic?.addressEn ?? ""}`}</Typography> }
    </div>
      {props.isPdfDownload  &&  <div style={{height: 20}}/>}
    </>
  );
};

const Container = styled('div')`
  @media print {
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
  }
`
const PageBreak = styled('div')`
break-before: page;
`

const PrintFlex = styled('div')`
  @media print {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const PrintSleepStage = styled(Box)`
  display: flex;
  @media print {
    display: block;
  }
`

const PrintNone = styled('div')`
  @media print {
    display: none;
  }
`
const PrintFlexContents = styled('div')`
  @media print {
    width: 48%;
    margin: 0 auto;
  } 
`

const StyledSubText = styled(SubText)`
color: #4B4B4B;
font-size: 13px;
overflow: hidden;           // 内容がボックスをはみ出したときに隠す
text-overflow: ellipsis;    // はみ出したテキストを...で示す
white-space: pre-line;      // 改行や空白を保持するが、必要に応じて折り返す
line-height: 1.2;           // 行の高さを指定
max-height: 2.4em;          // 2行分の高さを指定 (1行の高さ x 2)
@media print {
  font-size: 12px;
}
`;

const SubTextWrap = styled('div')`
width: 31%;
border-bottom: 1px solid #4B4B4B;
`;