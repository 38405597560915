import { useEffect } from 'react';
import { SimpleBackdrop } from '../molecules/Backdrop';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/');
    }, [navigate]);
    return(
        <>
        <SimpleBackdrop
            open={true}
            message={'ページ読み込み中'}
        />
        </>
    )

}

export default NotFoundPage;
