import {
  Affiliation,
  GetAffiliationsResponse,
  Patients,
  PostPatients,
  AdminsResponse,
  //ClinicsResponse,
  //ClinicResponse,
  ClinicMembersResponse,
  EquipmentRental,
  GetEquipmentRentalsResponse,
  ClinicsResponse,
  EquipmentClinicRental,
  Equipment,
  GetEquipmentClinicRentalsResponse,
  GetAdminSettingsResponse,
  Analyzation,
  AdminSettings,
  GetAnalyzationsResponse,
  MedicalPartnersResponse,
  GetAnnouncementResponse,
  CustomerMedicalPartnerDisabledResponse,
  Announcement,
  ReadAnnouncementLog,
  GetReadAnnouncementLogResponse,
  InitialAnswerForSleepResponse,
  InitialAnswerForFeelingResponse,
  DailyAnswersResponse,
  AnonymousProcessedAnalyzations
} from "@sleepwell_new_platform/common-types";
import {
  TargetHostEnum,
  AdminRolesTypeEnum,
  ClinicRolesTypeEnum
} from '../common'
import jwt_decode from 'jwt-decode';
import { AxiosStatic } from "axios";
import { DocumentsResponse } from "../documents";
import {
  Module,
  ModuleVersion
} from '@sleepwell_new_platform/common-types/src/modules'
import {
  GetAnonymousProcessedAnalyzationsResponse,
  GetModulesResponse,
  GetModuleVersionResponse,
  GetPatientsResponse,
  GetDiseasesResponse,
  GetSleepAnalyticsesResponse,
  GetSystemAnnouncementsResponse,
  GetSystemAnnouncementLogsResponse,
  GetAnalyzationResultsResponse,
  //GetAnalyzationResultsFilterResponse,
  GetAnonymousAnalyzationResultsResponse,
  GetAverageItemsResponse,
  GetAnalyzationAndResultResponse
} from '@sleepwell_new_platform/common-types/src/api'
import { SleepAnalyticsTypeWithoutIdPost, SleepAnalyticsPost } from '@sleepwell_new_platform/common-types/src/sleepAnalytics'
import { OtherDiseases } from '@sleepwell_new_platform/common-types/src/index'
import { SystemAnnouncementLog } from "@sleepwell_new_platform/common-types/src/systemAnnoucementLogs";
import { SystemAnnouncement } from "@sleepwell_new_platform/common-types/src/systemAnnouncements";
// ap-2
//export const endpoint = process.env.SLEEPWELL_API_GATEWAY_HOST || "https://3e2h9egjsc.execute-api.ap-southeast-2.amazonaws.com/v1";
//export const s3_endpoint = process.env.REACT_APP_API_S3_ENDPOINT || "https://sleepwell-stg-api-bucket-funase.s3.ap-southeast-2.amazonaws.com/";
// ap-1
export const endpoint = process.env.SLEEPWELL_API_GATEWAY_HOST || "https://hrm6n01rdl.execute-api.ap-northeast-1.amazonaws.com/v1";
export const clinicEndopoint = process.env.SLEEPWELL_API_GATEWAY_HOST || "https://yv561wssl6.execute-api.ap-northeast-1.amazonaws.com/v1";
export const s3_endpoint = process.env.REACT_APP_API_S3_ENDPOINT || "https://sleepwell-stg-api-bucket.s3.ap-northeast-1.amazonaws.com/";
export const region = process.env.REACT_APP_REGION || "ap-northeast-1"

export class SleepwellApi {
  axios: AxiosStatic;
  patientsEndpoint = `${clinicEndopoint}/clinics/patients`;

  constructor(axios: AxiosStatic) {
    this.axios = axios;
  }

  async apiGateWay(path: string) {
    const res = await this.axios.get(`${endpoint}${path}`).catch((err: Error) => console.log(err));
    console.log(res)
    return res
  }

  async getModuleVersions(moduleId: string) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetModuleVersionResponse>(`${endpoint}/modules/versions`, {
      params: { moduleId },
      headers: { "Content-type": "application/json" , "Authorization": `Bearer ${idToken}`},
    });
  }

  async postModuleVersions(module: ModuleVersion) {
    const idToken = this.getIdToken();
    return await this.axios.post(`${endpoint}/modules/versions`, module,
      { headers: { "Content-type": "application/json", "Authorization": `Bearer ${idToken}` } });
  }

  async postSleepAnalytics(module: SleepAnalyticsTypeWithoutIdPost) {
    const idToken = this.getIdToken();
    if (!idToken) {
      throw new Error("Authentication token is not available in local storage.");
    }
    return await this.axios.post(`${clinicEndopoint}/clinics/sleepAnalytics`, module,
      { headers: { "Content-type": "application/json", "Authorization": `Bearer ${idToken}` } });
  }

  async putSleepAnalytics(module: SleepAnalyticsPost) {
    const idToken = this.getIdToken();
    if (!idToken) {
      throw new Error("Authentication token is not available in local storage.");
    }
    return await this.axios.put(`${clinicEndopoint}/clinics/sleepAnalytics`, module,
      { headers: { "Content-type": "application/json", "Authorization": `Bearer ${idToken}` } });
  }

  async putModuleVersions(module: ModuleVersion) {
    const idToken = this.getIdToken();
    return await this.axios.put(`${endpoint}/modules/versions`, module,
      { headers: { "Content-type": "application/json", "Authorization": `Bearer ${idToken}` } });
  }

  async getModules() {
    const idToken = this.getIdToken();
    return await this.axios.get<GetModulesResponse>(`${endpoint}/modules`, {
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async postModules(module: Module) {
    const idToken = this.getIdToken();
    return await this.axios.post(`${endpoint}/modules`, module,
      { headers: { "Content-type": "text/plain" , "Authorization": `Bearer ${idToken}`} });
  }

  async putModules(module: Module) {
    const idToken = this.getIdToken();
    return await this.axios.put(`${endpoint}/modules`, module,
      { headers: { "Content-type": "text/plain" , "Authorization": `Bearer ${idToken}`} });
  }

  /**
   * 匿名加工した解析結果を取得する
   * @param anonymousProcessedAnalyzationId anonymousProcessedAnalyzationテーブルのID
   * @returns GetAnonymousAnalyzationResultsResponse
   */
  async getAnonymousAnalyzationResults(anonymousProcessedAnalyzationId: string) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetAnonymousAnalyzationResultsResponse>(`${endpoint}/anonymousAnalyzationResults`, {
      params: {
        // ここにクエリパラメータを指定する
        anonymousProcessedAnalyzationId: anonymousProcessedAnalyzationId,
      },
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async postAnonymousAnalyzationResults(module: Module) {
    const idToken = localStorage.getItem('idToken');
    return await this.axios.post(`${endpoint}/anonymousAnalyzationResults`, module,
      { headers: { "Content-type": "text/plain",  "Authorization": `Bearer ${idToken}`} });
  }

  async putAnonymousAnalyzationResults(module: Module) {
    const idToken = localStorage.getItem('idToken');
    return await this.axios.put(`${endpoint}/anonymousAnalyzationResults`, module,
      { headers: { "Content-type": "text/plain",  "Authorization": `Bearer ${idToken}` } });
  }

  async getAnonymousProcessedAnalyzations(limit?: number, offset?: string) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetAnonymousProcessedAnalyzationsResponse>(`${endpoint}/anonymousProcessedAnalyzations`, {
      params: {
        limit: limit,
        offset: offset,
      },
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async searchAnonymousProcessedAnalyzations(
    clinicName?: string,
    age?: string,
    gender?: string,
    startDate?: number,
    endDate?: number,
    limit?: number,
    offset?: string
  ) {
    const idToken = this.getIdToken();
    return await this.axios.post<GetAnonymousProcessedAnalyzationsResponse>(`${endpoint}/anonymousProcessedAnalyzations`,
      {
        clinicName: clinicName,
        age: age,
        gender: gender,
        startDate: startDate,
        endDate: endDate,
        limit: limit,
        offset: offset,
      },
      {
        headers: {
          "Authorization": `Bearer ${idToken}`,
        }
      }
    );
  }

  async postAnonymousProcessedAnalyzations(module: Module) {
    const idToken = localStorage.getItem('idToken');
    return await this.axios.post(`${endpoint}/anonymousProcessedAnalyzations`, module,
      { headers: { "Content-type": "text/plain",   "Authorization": `Bearer ${idToken}` } });
  }

  async putAnonymousProcessedAnalyzations(anonymousProcessdAnalyzation: AnonymousProcessedAnalyzations) {
    const idToken = localStorage.getItem('idToken');
    return await this.axios.put(`${endpoint}/anonymousProcessedAnalyzations`, anonymousProcessdAnalyzation,
      { headers: { "Content-type": "text/plain",   "Authorization": `Bearer ${idToken}` } });
  }

  async putApiGateWay(path: string) {
    const res = await this.axios.put(`${endpoint}item`, {
      price: 500,
      name: 'test'
    },
      { headers: { "Content-type": "text/plain" } }).catch((err: Error) => console.log(err));
    console.log(res)
    return
  }

  async getPatients(clinicId?: string) {
    const idToken = this.getIdToken();
    if (!idToken) {
      throw new Error("Authentication token is not available in local storage.");
    }
    return await this.axios.get<GetPatientsResponse>(this.patientsEndpoint, {
      params: {
        // ここにクエリパラメータを指定する
        clinicId: clinicId,
      },
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${idToken}`,
      },
    });
  }
  async postPatients(patients: PostPatients) {
    return await this.axios.post(this.patientsEndpoint, patients,
      { headers: { "Content-type": "text/plain" } });
  }

  async putPatients(patients: Patients) {
    return await this.axios.put(this.patientsEndpoint, patients,
      { headers: { "Content-type": "text/plain" } });
  }

  isAdminGroup = (groups: string[]) => {
    if (groups.some((group: string) => 
    group === AdminRolesTypeEnum.SUPERADMIN || 
    group === AdminRolesTypeEnum.ADMIN ||
    group === AdminRolesTypeEnum.ANONYMOUS)
    ) {
      return true;
    } else {
      return false;
    }
  }
  isClinicGroup = (groups: string[]) => {
    if (groups.some((group: string) => 
    group === ClinicRolesTypeEnum.OWNER || 
    group === ClinicRolesTypeEnum.MANAGER || 
    group === ClinicRolesTypeEnum.DOCTOR || 
    group === ClinicRolesTypeEnum.COMEDICAL || 
    group === ClinicRolesTypeEnum.STAFF)
    ) {
      return true;
    } else {
      return false;
    }
  }
  get_jwt_decoded = (
    idToken: string
  ): {
    [name: string]: string[];
  } => {
    const decoded = jwt_decode<{ [name: string]: string[] }>(idToken);
    console.log(decoded);
    return decoded;
  };
  login = async ({
    userName,
    password,
    targetHost
  }: {
    userName: string,
    password: string,
    targetHost: TargetHostEnum
  }
  ) => {
    const params = {
      userId: userName,
      password: password,
      authFlow: 'USER_PASSWORD_AUTH'
    }
    try {
      let result: any = await this.axios.post(
        endpoint + '/login',
        params,
        {
          headers: {
            "Content-type": "application/json",
          }
        });      

      if (result.data.result.ChallengeParameters.CODE_DELIVERY_DESTINATION) {
        localStorage.code_delivery_destination =
          result.data.result.ChallengeParameters.CODE_DELIVERY_DESTINATION.slice(2);
      } else {
        throw new Error('SMSが設定されていないユーザまたは電話番号が登録されていないユーザです');
      }
      localStorage.session = result.data.result.Session;

      //result.id = userInfo.sub;
      return result;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  createUser = async ({
    name,
    email,
    phoneNumber,
    role, // 権限（Cognitoのグループ名）
    clinicId,
    expireDate,
    idToken,
    patientNumber,
    affiliationId,
    gender,
    birthday,
    readOnly,
    prefecture,
    sickHistory,
    medicineInfo,
    mainDiseasId,
    diseases,
    isAnonymous,
    area,
    isPatientUserCreatedByEmail
  }: {
    name: string,
    email?: string,
    phoneNumber?: string,
    role: string,
    clinicId?: string,
    expireDate?: number,
    idToken: string,
    patientNumber?: string,
    affiliationId?: string,
    gender?: string,
    birthday?: string,
    readOnly?: boolean,
    prefecture?: string,
    sickHistory?: string,
    medicineInfo?: string
    mainDiseasId?: string,
    diseases?: OtherDiseases[],
    isAnonymous?: boolean,
    area?: string,
    isPatientUserCreatedByEmail?: boolean
  }) => {
    let params: any = {
      name: name,
      userId: role === 'patient' ?
        (isPatientUserCreatedByEmail ?
          email : phoneNumber)
        : email, // cognitoユーザアカウントになる
      phoneNumber: phoneNumber,
      groupName: role
    }
    // clinicユーザの場合
    if (this.isClinicGroup([role])) {
      params.clinicId = clinicId;
      params.expireDate = expireDate
    }
    // patientユーザの場合
    if (role === 'patient') {
      params.patientNumber = patientNumber;
      params.email = email;
      params.clinicId = clinicId;
      params.affiliationId = affiliationId;
      params.gender = gender;
      params.birthday = birthday;
      params.readOnly = readOnly;
      params.prefecture = prefecture;
      params.sickHistory = sickHistory;
      params.medicineInfo = medicineInfo;
      params.mainDiseasId = mainDiseasId;
      params.diseases = diseases;
      params.isAnonymous = isAnonymous;
      params.area = area;
      params.isPatientUserCreatedByEmail = isPatientUserCreatedByEmail;
    }
    try {
      const result: any = await this.axios.post(
        endpoint + '/createUser',
        params,
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${idToken}`
          }
        });
      return result;
    } catch (e) {
      throw e;
    }
  }
  // admins、clinicMembersのアップデート用
  updateUser = async ({
    tableName,
    id,
    role, // 任意： 更新対象のgroup名
    removeRole, // 任意: 現在のgroup名（削除するgroup名）
    name, // 任意
    clinicId,
    expireDate,
    idToken,
    phoneNumber,
    currentEmail,
    email
  }: {
    tableName: string,
    id: string,
    role?: string,
    name?: string,
    removeRole?: string,
    expireDate?: number,
    clinicId?: string,
    idToken: string,
    phoneNumber?: string,
    currentEmail?: string,
    email?: string
  }
  ) => {
    let params: any = {
      id: id,
      name: name,
      role: role || '',
      removeRole: removeRole || '',
      updatedBy: id,
      updatedAt: new Date().valueOf(),
      //phoneNumber: phoneNumber || '',
      //currentEmail: currentEmail || '',
      //email: email || '',
    }
    if (phoneNumber) {
      params.phoneNumber = phoneNumber;
    }
    if (email) {
      params.email = email;
    }
    if (currentEmail) {
      params.currentEmail = currentEmail;
    }
    if (clinicId) {
      params.clinicId = clinicId;
      params.expireDate = expireDate;
    }
    try {
      // tableNameにclinicsが含まれていればclinicEndpointを使う
      const tableEndpoint = tableName.includes('clinics')  ? clinicEndopoint : endpoint;
      const result: any = await this.axios.put(
        tableEndpoint + '/' + tableName,
        params,
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${idToken}`
          }
        });
      return result;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  // admins、clinicMembersのdelete用
  deleteUser = async ({
    tableName,
    id,
    email,
    phoneNumber,
    clinicId,
    idToken
  }: {
    tableName: string,
    id: string,
    email?: string,
    phoneNumber?: string,
    clinicId?: string,
    idToken: string
  }
  ) => {
    let params: any = {
      id: id,
      userId: tableName === 'clinics/patients' ? phoneNumber : email,
      isDeleted: 1
    }
    if (clinicId) {
      params.clinicId = clinicId;
    }
    console.log(params)
    try {
      const tableEndpoint = tableName.includes('clinics') ? clinicEndopoint : endpoint;
      const result: any = await this.axios.put(
        tableEndpoint + '/' + tableName,
        params,
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${idToken}`
          }
        });
      return result;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  resetUserPassword = async (userName: string) => {
    const params = {
      userId: userName
    }
    const result: any = await this.axios.post(
      endpoint + '/resetUserPassword',
      params,
      {
        headers: {
          "Content-type": "application/json",
        }
      })
      .catch((err: Error) => console.log(err));
    return result;
  }
  challangeSmsCode = async ({
    userName,
    smsMfaCode,
    session,
    targetHost
  }
    : {
      userName: string,
      smsMfaCode: string,
      session: string,
      targetHost: TargetHostEnum
    }) => {
    const params = {
      userId: userName,
      smsMfaCode: smsMfaCode,
      session: session
    }
    try {
      const result: any = await this.axios.post(
        endpoint + '/challengeSms',
        params,
        {
          headers: {
            "Content-type": "application/json",
          }
        });

      // jwtからcognitoユーザの情報をデコードし取得
      const userInfo = this.get_jwt_decoded(result.data.result.AuthenticationResult.IdToken);
      const groups = userInfo['cognito:groups'];
      console.log(`groups: ${groups}`);
      console.log(`targetHost: ${targetHost}`)
      if (targetHost === TargetHostEnum.ADMIN) {
        if (!this.isAdminGroup(groups)) {
          // グループがadminでなければエラーコード（文字列）をresultに埋め込む
          throw new Error('NotTargetUser')
        }
      }
      if (targetHost === TargetHostEnum.CLINIC) {
        if (!this.isClinicGroup(groups)) {
          // グループがadminでなければエラーコード（文字列）をresultに埋め込む
          throw new Error('NotTargetUser')
        }
      }
      console.log(result.data)

      // ローカルストレージへトークンなどを保存
      localStorage.id = userInfo.sub;
      localStorage.idToken = result.data.result.AuthenticationResult.IdToken;
      localStorage.accessToken = result.data.result.AuthenticationResult.AccessToken;
      localStorage.refreshToken = result.data.result.AuthenticationResult.RefreshToken;
      localStorage.name = userInfo['email'];
      localStorage.group = groups;
      result.id = userInfo.sub;
      return result;
    } catch (err) {
      console.log(err);
      throw err;
    }

  }
  resendConfirmationCode = async ({
    userName
  }: {
    userName: string
  }) => {
    const params = {
      userId: userName
    }
    try {
      const result: any = await this.axios.post(
        endpoint + '/resendConfirmationCode',
        params,
        {
          headers: {
            "Content-type": "application/json",
          }
        });
      return result;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  confirmForgotPassword = async (userName: string, code: string, password: string) => {
    const params = {
      userId: userName,
      confirmationCode: code,
      password: password
    }
    const result: any = await this.axios.post(
      endpoint + '/confirmForgotPassword',
      params,
      {
        headers: {
          "Content-type": "application/json",
        }
      })
      .catch((err: Error) => console.log(err));
    return result;
  }
  changePassword = async (
    accessToken: string,
    previousPassword: string,
    proposedPassword: string,
  ) => {
    const params = {
      accessToken: accessToken,
      previousPassword: previousPassword,
      proposedPassword: proposedPassword,
    }
    try {
      const result: any = await this.axios.post(
        endpoint + '/changePassword',
        params,
        {
          headers: {
            "Content-type": "application/json",
          }
        })
      return result;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  isIdTokenEnable = async (idToken: string) => {
    try {
      await this.axios.get(`${endpoint}/token?idToken=${idToken}`);
      return true;
    } catch (err) {
      console.log(err)
      return false;
    }
  }
  refreshIdToken = async (userName: string, refreshToken: string) => {
    const params = {
      userId: userName,
      refreshToken: refreshToken,
      authFlow: 'REFRESH_TOKEN_AUTH'
    }
    try {
      const result: any = await this.axios.post(
        endpoint + '/login',
        params,
        {
          headers: {
            "Content-type": "application/json",
          }
        });
      return result;
    } catch (err) {
      console.log(err)
      return err;
    }
  }
  addRecord = async ({
    tableName,
    params
  }: {
    tableName: string,
    params: any
  }) => {
    const idToken = this.getIdToken();
    const tableEndpoint =  tableName.includes('clinics')  ? clinicEndopoint  : tableName.includes('customerMedicalPartnerDisabled') ? clinicEndopoint+ '/clinics'  :  endpoint;
    const result: any = await this.axios.post(
      tableEndpoint + '/' + tableName,
      params,
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${idToken}`
        }
      })
      .catch((err: Error) => console.log(err));
    return result;
  }
  getAdmins = async () => {
    try {
      const idToken = this.getIdToken();
      const result = await this.axios.get<AdminsResponse>(
        endpoint + '/admins',
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getClinics = async (id?: string) => {
    try {
      const idToken = this.getIdToken();
      let l_endpoint = '';
      if (id) {
        l_endpoint = clinicEndopoint + `/clinics?id=${id}`;
      } else {
        l_endpoint = clinicEndopoint + '/clinics';
      }
      const result = await this.axios.get<ClinicsResponse>(
        l_endpoint,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getDocuments = async (id?: string) => {
    try {
      const idToken = this.getIdToken();
      let l_endpoint = '';
      if (id) {
        l_endpoint = endpoint + `/admins/documents?id=${id}`;
      } else {
        l_endpoint = endpoint + '/admins/documents';
      }
      const result = await this.axios.get<DocumentsResponse>(
        l_endpoint, 
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getAdminSettings = async (id?: string) => {
    try {
      const idToken = this.getIdToken();
      let l_endpoint = '';
      if (id) {
        l_endpoint = endpoint + `/admins/adminSettings?id=${id}`;
      } else {
        l_endpoint = endpoint + '/admins/adminSettings';
      }
      const result = await this.axios.get<GetAdminSettingsResponse>(
        l_endpoint,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.adminSettings.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getAnnouncements = async (id?: string) => {
    try {
      const idToken = this.getIdToken();
      let l_endpoint = '';
      if (id) {
        l_endpoint = endpoint + `/admins/announcements?id=${id}`;
      } else {
        l_endpoint = endpoint + '/admins/announcements';
      }
      const result = await this.axios.get<GetAnnouncementResponse>(
        l_endpoint,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.announcements.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getReadAnnouncementLog = async (userId: string) => {
    try {
      const idToken = this.getIdToken();
      const l_endpoint = clinicEndopoint + '/clinics/readAnnouncementLog?userId=' + userId;
      const result = await this.axios.get<GetReadAnnouncementLogResponse>(
        l_endpoint,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getClinicMembers = async ({
    tableName,
    id,
    clinicId,
    idToken
  }: {
    tableName: string,
    id?: string,
    clinicId?: string,
    idToken: string
  }
  ) => {
    try {
      let l_endpoint = '';
      const tableEndpoint = tableName.includes('clinics') ? clinicEndopoint : endpoint;
      if (id) {
        l_endpoint = tableEndpoint + `/${tableName}/clinicMembers?id=${id}`;
      } else {
        if (clinicId) {
          l_endpoint = tableEndpoint + `/${tableName}/clinicMembers?clinicId=${clinicId}`;
        } else {
          l_endpoint = tableEndpoint + `/${tableName}/clinicMembers`;
        }
      }
      const result = await this.axios.get<ClinicMembersResponse>(
        l_endpoint,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getCustomerMedicalPartnerDisabled = async ({
    tableName,
    id
  }: {
    tableName: string,
    id?: string
  }
  ) => {
    try {
      const idToken = this.getIdToken();
      let l_endpoint = '';
      const tableEndpoint = tableName.includes('clinics') ? clinicEndopoint : endpoint;
      if (id) {
        l_endpoint = tableEndpoint + `/${tableName}/customerMedicalPartnerDisabled?id=${id}`;
      } else {
        l_endpoint = tableEndpoint + `/${tableName}/customerMedicalPartnerDisabled`;
      }
      // 自身のクリニックIDに所属するレコードのみ返却
      const result = await this.axios.get<CustomerMedicalPartnerDisabledResponse>(
        l_endpoint,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getClinicMembersFromClinic = async ({
    id,
    clinicId,
  }: {
    id?: string,
    clinicId?: string
  }
  ) => {
    try {
      const idToken = this.getIdToken();
      let l_endpoint = '';
      if (id) {
        l_endpoint = clinicEndopoint + `/clinics/clinicMembers?id=${id}`;
      } else {
        if (clinicId) {
          l_endpoint = clinicEndopoint + `/clinics/clinicMembers?clinicId=${clinicId}`;
        } else {
          l_endpoint = clinicEndopoint + '/clinics/clinicMembers';
        }
      }
      const result = await this.axios.get<ClinicMembersResponse>(
        l_endpoint,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getMedicalPartners = async ({
    id,
    clinicId,
    idToken
  }: {
    id?: string,
    clinicId?: string,
    idToken: string
  }
  ) => {
    try {
      let l_endpoint = '';
      if (id) {
        l_endpoint = endpoint + `/admins/medicalPartners?id=${id}`;
      } else {
        if (clinicId) {
          l_endpoint = endpoint + `/admins/medicalPartners?clinicId=${clinicId}`;
        } else {
          l_endpoint = endpoint + '/admins/medicalPartners';
        }
      }
      const result = await this.axios.get<MedicalPartnersResponse>(
        l_endpoint,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  updateRecord = async ({
    id,
    tableName,
    params
  }: {
    id: string,
    tableName: string,
    params: any
  }
  ) => {
    let l_params = Object.assign({}, params);
    l_params.id = id;

    try {
      const idToken = this.getIdToken();
      const tableEndpoint = tableName === 'clinics' ?  clinicEndopoint :  (tableName.includes('clinics') || tableName === 'analyzationResults' || tableName === 'customerMedicalPartnerDisabled' ) ? clinicEndopoint + '/clinics': endpoint;
      const result: any = await this.axios.put(
        tableEndpoint + '/' + tableName,
        l_params,
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${idToken}`
          }
        });
      return result;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  uploadFile = async (tableName: string, id: string, file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    console.log(file.name)
    const fileExtention = file.name.split(".").pop().toLowerCase();
    try {
      const idToken = this.getIdToken();
      const tableEndpoint = tableName.includes('clinics') ? clinicEndopoint : endpoint;
      const result: any = await this.axios({
        method: 'PUT',
        url: tableEndpoint + '/' + tableName + '/files/' + id + '/' + file.name,
        headers: {
          "Content-Type": fileExtention === 'edf' ? // edfファイルの場合
            'binary/octet-stream' :
            file.type,
          "Authorization": `Bearer ${idToken}`,
        },
        data: file,
      })
      return result.request.responseURL;
    } catch (err) {
      console.log(err)
      return err;
    }
  }
  uploadDocumentFile = async (tableName: string, file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    const fileExtention = file.name.split(".").pop().toLowerCase();
    try {
      const idToken = this.getIdToken();
      const tableEndpoint = tableName.includes('clinics') ? clinicEndopoint : endpoint;
      const result: any = await this.axios({
        method: 'PUT',
        url: tableEndpoint + '/' + tableName + '/files/documents/' + file.name,
        headers: {
          "Content-Type": fileExtention === 'edf' ? // edfファイルの場合
            'binary/octet-stream' :
            file.type,
          "Authorization": `Bearer ${idToken}`,
        },
        data: file,
      })
      return result.request.responseURL;
    } catch (err) {
      console.log(err)
      return err;
    }
  }
  deleteDocumentFile = async ({
    tableName,
    id,
    fileName
  }: {
    tableName: string,
    id: string,
    fileName: string
  }
  ) => {
    try {
      const idToken = this.getIdToken();
      const tableEndpoint = tableName.includes('clinics') ? clinicEndopoint : endpoint;
      const result: any = await this.axios({
        method: 'DELETE',
        url: tableEndpoint + '/' + tableName + '/files/documents/' + fileName,
        headers: {
          "Authorization": `Bearer ${idToken}`,
        }
      })
      return result.request.responseURL;
    } catch (err) {
      console.log(err)
      return err;
    }
  }
  downloadDocument = async (fileUrl: string) => {
    const { data }: any = await this.axios.get(fileUrl, {
      responseType: "arraybuffer",
    })
    const fileUrlArray = fileUrl.split('/');
    // blobの作成
    const blob = new Blob([data], { type: "application/pdf" });
    // URLの作成
    const url = window.URL.createObjectURL(blob);
    // aタグの作成
    const link = document.createElement("a");

    // urlの追加
    link.href = url;
    // download属性を付与, ファイル名も指定
    link.setAttribute("download", fileUrlArray[fileUrlArray.length - 1]);
    // aタグをクリックしてダウンロード
    link.click();
    // urlの削除
    link.parentNode?.removeChild;
  }
  getInitialAnswerForSleep = async ({
    sleepAnalyticsId,
    idToken
  }: {
    sleepAnalyticsId: string,
    idToken: string
  }
  ) => {
    try {
      const result = await this.axios.get<InitialAnswerForSleepResponse>(
        clinicEndopoint + `/clinics/initialAnswerForSleep?sleepAnalyticsId=${sleepAnalyticsId}`,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getInitialAnswerForFeeling = async ({
    sleepAnalyticsId,
    idToken
  }: {
    sleepAnalyticsId: string,
    idToken: string
  }
  ) => {
    try {
      const result = await this.axios.get<InitialAnswerForFeelingResponse>(
        clinicEndopoint + `/clinics/initialAnswerForFeeling?sleepAnalyticsId=${sleepAnalyticsId}`,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getDailyAnswers = async ({
    clinicId,
    patientId,
    sleepAnalyticsId,
    idToken
  }: {
    clinicId: string,
    patientId: string,
    sleepAnalyticsId: string,
    idToken: string
  }
  ) => {
    try {
      const result = await this.axios.get<DailyAnswersResponse>(
        clinicEndopoint +
        `/clinics/dailyAnswers?clinicId=${clinicId}&patientId=${patientId}&sleepAnalyticsId=${sleepAnalyticsId}&idToken=${idToken}`,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getAccessLogs = async ({
    sleepAnalyticsId,
    idToken
  }: {
    sleepAnalyticsId: string,
    idToken: string
  }
  ) => {
    try {
      const result = await this.axios.get<DailyAnswersResponse>(
        clinicEndopoint +
        `/clinics/accessLogs?sleepAnalyticsId=${sleepAnalyticsId}&idToken=${idToken}`,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      );
      return result.data.result.Items;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  getQuestions = async () => {
    try {
      const result = await this.axios.get<DailyAnswersResponse>(
        endpoint +
        `/patients/questions`
      );
      return result.data.result;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
  async getAffiliations(clinicId?: string, isDeleted?: boolean) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetAffiliationsResponse>(`${clinicEndopoint}/clinics/affiliations?clinicId=${clinicId}${isDeleted ? `&isDeleted=${isDeleted}` : ''}`, 
    {
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async scanAffiliations() {
    const idToken = this.getIdToken();
    return await this.axios.get<GetAffiliationsResponse>(`${clinicEndopoint}/clinics/affiliations/all`, 
    {
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async putAffiliations(affiliations: Affiliation) {
    const idToken = this.getIdToken();
    return await this.axios.put(`${clinicEndopoint}/clinics/affiliations`, affiliations,
      { headers: { 
        "Content-type": "text/plain", 
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async postAffiliations(affiliations: Affiliation) {
    const idToken = this.getIdToken();
    return await this.axios.post(`${clinicEndopoint}/clinics/affiliations`, affiliations,
      { headers: { "Content-type": "text/plain", "Authorization": `Bearer ${idToken}` } });
  }

  async getEquipmentRentals(clinicId: string, isDeleted?: boolean) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetEquipmentRentalsResponse>(`${clinicEndopoint}/clinics/equipmentRentals?clinicId=${clinicId}${isDeleted ? `&isDeleted=${isDeleted}` : ''}`, 
    {
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async getAllEquipmentClinicRentals() {
    const idToken = this.getIdToken();
    return await this.axios.get<GetEquipmentClinicRentalsResponse>(`${clinicEndopoint}/clinics/equipmentClinicRentals`, 
    {
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async getEquipmentClinicRentalsByClinicId(clinicId: string, isDeleted?: boolean) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetEquipmentClinicRentalsResponse>(`${clinicEndopoint}/clinics/equipmentClinicRentals/clinic?clinicId=${clinicId}${isDeleted ? `&isDeleted=${isDeleted}` : ''}`, 
    {
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async putEquipmentRentals(equipmentRentals: EquipmentRental) {
    const idToken = this.getIdToken();
    return await this.axios.put(`${clinicEndopoint}/clinics/equipmentRentals`, equipmentRentals,
      { headers: { 
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
    } });
  }

  async postEquipmentRentals(equipmentRentals: EquipmentRental) {
    const idToken = this.getIdToken();
    if (!idToken) {
      throw new Error("Authentication token is not available in local storage.");
    }
    return await this.axios.post(`${clinicEndopoint}/clinics/equipmentRentals`, equipmentRentals,
      { headers: {
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async putEquipmentClinicRentals(equipmentClinicRentals: EquipmentClinicRental) {
    const idToken = this.getIdToken();
    if (!idToken) {
      throw new Error("Authentication token is not available in local storage.");
    }
    return await this.axios.put(`${clinicEndopoint}/clinics/equipmentClinicRentals`, equipmentClinicRentals,
      { headers: {
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async postEquipmentClinicRentals(equipmentClinicRentals: EquipmentClinicRental) {
    const idToken = this.getIdToken();
    return await this.axios.post(`${clinicEndopoint}/clinics/equipmentClinicRentals`, equipmentClinicRentals,
      { headers: {
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async putEquipments(equipment: Equipment) {
    const idToken = this.getIdToken();
    return await this.axios.put(`${clinicEndopoint}/clinics/equipments`, equipment,
      { headers: {
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async postEquipments(equipment: Equipment) {
    const idToken = this.getIdToken();
    return await this.axios.post(`${clinicEndopoint}/clinics/equipments`, equipment,
      { headers: {
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async putAdminSettings(adminSettings: AdminSettings) {
    const idToken = this.getIdToken();
    return await this.axios.put(`${endpoint}/admins/adminSettings`, adminSettings,
      { headers: { "Content-type": "text/plain", "Authorization": `Bearer ${idToken}`, } });
  }
  async postAnnouncements(announcement: Announcement) {
    const idToken = this.getIdToken();
    return await this.axios.post(`${endpoint}/admins/announcements`, announcement,
      { headers: { "Content-type": "text/plain", "Authorization": `Bearer ${idToken}`, } });
  }
  async putAnnouncements(announcement: Announcement) {
    const idToken = this.getIdToken();
    return await this.axios.put(`${endpoint}/admins/announcements`, announcement,
      { headers: { "Content-type": "text/plain", "Authorization": `Bearer ${idToken}`, } });
  }
  async postReadAnnouncementLog(announcement: ReadAnnouncementLog) {
    const idToken = this.getIdToken();
    return await this.axios.post(`${clinicEndopoint}/clinics/readAnnouncementLog`, announcement,
      { headers: { 
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async putReadAnnouncementLog(announcement: ReadAnnouncementLog) {
    const idToken = this.getIdToken();
    return await this.axios.put(`${clinicEndopoint}/clinics/readAnnouncementLog`, announcement,
      { headers: {
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
        }
      });
  }
  async getSignedUrl(fileName: string, id: string, clinicId: string, type: "put" | "get", fileType?: string, isAnonymous?: boolean) {
    return await this.axios.get<{ result: string }>(`${endpoint}/signedUrl`, {
      params: {
        // ここにクエリパラメータを指定する
        fileName: fileName,
        userId: id,
        clinicId: clinicId,
        type: type,
        fileType: fileType,
        isAnonymous: isAnonymous,
      }
    })
  }
  async postAnalyzation(analyzation: Analyzation) {
    const idToken = this.getIdToken();
    console.log(`${clinicEndopoint}/clinics/analyzations`)
    return await this.axios.post(`${clinicEndopoint}/clinics/analyzations`, analyzation,
      { headers: { 
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async getAnalyzation(
    clinicId?: string,
    patientId?: string,
    edfFileName?: string,
    type?: string,
    startDate?: number,
    endDate?: number,
    filterFlag?: boolean,
    filterParams?: Object,
    size?: number,
    start?: number
    ) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetAnalyzationsResponse>(`${clinicEndopoint}/clinics/analyzations`, {
      params: {
        // ここにクエリパラメータを指定する
        clinicId: clinicId,
        patientId: patientId,
        edfFileName: edfFileName,
        type: type,
        startDate: startDate,
        endDate: endDate,
        filterFlag: filterFlag,
        filterParams: filterParams,
        size:size,
        start: start
      },
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async putAnalyzation(analyzation: Analyzation) {
    const idToken = this.getIdToken();
    return await this.axios.put(`${clinicEndopoint}/clinics/analyzations`, analyzation,
      { headers: {
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
      } });
  }

  async getDiseases() {
    const idToken = this.getIdToken();
    return await this.axios.get<GetDiseasesResponse>(`${endpoint}/diseases`,
      { headers: { 
        "Content-type": "text/plain", 
        "Authorization": `Bearer ${idToken}`
      }});
  }

  async getSleepAnalyticses(patientId?: string, id?: string) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetSleepAnalyticsesResponse>(`${clinicEndopoint}/clinics/sleepAnalytics`,
      {
        headers: {
          "Content-type": "text/plain",
          "Authorization": `Bearer ${idToken}`,
        },
        params: {
          patientId: patientId,
          id: id
        }
      });
  }
  async getSystemAnnoucements(clinicId: string, patientId?: string) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetSystemAnnouncementsResponse>(`${clinicEndopoint}/clinics/systemAnnouncements`, {
      params: {
        // ここにクエリパラメータを指定する
        clinicId: clinicId,
        patientId: patientId
      },
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async postSystemAnnouncements(systemAnnouncements: SystemAnnouncement) {
    const idToken = this.getIdToken();
    return await this.axios.post(`${clinicEndopoint}/clinics/systemAnnouncements`, systemAnnouncements,
      {
        headers:
        {
          "Content-type": "text/plain",
          "Authorization": `Bearer ${idToken}`,
        }
      });
  }

  async getSystemAnnoucementLogs(clinicId: string, cliniceMemberId?: string) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetSystemAnnouncementLogsResponse>(`${clinicEndopoint}/clinics/systemAnnouncementLogs`, {
      params: {
        // ここにクエリパラメータを指定する
        clinicId: clinicId,
        cliniceMemberId: cliniceMemberId
      },
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async postSystemAnnouncementLog(systemAnnoucementLog: SystemAnnouncementLog) {
    const idToken = this.getIdToken();
    return await this.axios.post(`${clinicEndopoint}/clinics/systemAnnouncementLogs`, systemAnnoucementLog,
      { headers: {
        "Content-type": "text/plain",
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }

  async getAnalyzationResult(analyzationId?: string, patientId?: string, startDate?: number, endDate?: number) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetAnalyzationResultsResponse>(`${clinicEndopoint}/clinics/analyzationResults`, {
      params: {
        // ここにクエリパラメータを指定する
        analyzationId: analyzationId,
        patientId: patientId,
        startDate: startDate,
        endDate: endDate
      },
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async getAnalyzationResults(
    startDate?: number,
    endDate?: number,
    countResponseFlag?: boolean
  ) {
    const idToken = this.getIdToken();
    if(startDate && endDate) {
      return await this.axios.get<any>(
        `${clinicEndopoint}/clinics/analyzationResults?startDate=${startDate}&endDate=${endDate}&countResponseFlag=${countResponseFlag}`, {
        headers: {
          "Authorization": `Bearer ${idToken}`,
        }
      });
    } else {
      return await this.axios.get<any>(`${clinicEndopoint}/clinics/analyzationResults&countResponseFlag=${countResponseFlag}`, {
        headers: {
          "Authorization": `Bearer ${idToken}`,
        }
      });
    }
  }
  async getAnalyzationAndResult(patientId?: string, startDate?: number, endDate?: number) {
    const idToken = this.getIdToken();
    return await this.axios.get<GetAnalyzationAndResultResponse>(`${clinicEndopoint}/clinics/analyzationAndResults`, {
      params: {
        // ここにクエリパラメータを指定する
        patientId: patientId,
        startDate: startDate,
        endDate: endDate
      },
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async getAverageItems() {
    const idToken = this.getIdToken();
    return await this.axios.get<GetAverageItemsResponse>(`${endpoint}/averageItems`, {
      params: {
        // ここにクエリパラメータを指定する
      },
      headers: {
        "Authorization": `Bearer ${idToken}`,
      }
    });
  }
  async postSendMail(clinicId: string, title: string, content: string) {
    return await this.axios.post<any>(`${endpoint}/ses`, {
      // ここにクエリパラメータを指定する
      clinicId: clinicId,
      title: title,
      content: content
    }, { headers: { "Content-type": "text/plain" } });
  }

  getIdToken = () => {
    const idToken = localStorage.getItem('idToken');
    if (!idToken) {
      throw new Error("Authentication token is not available in local storage.");
    }
    return idToken;
  }
}
