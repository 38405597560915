
import { Analyzations } from "../organisms/analyzations/Analyzations";
import Page from "../templates/Page"

const AnalyzationsPage = () => {
  return(
    <Page
      title={"解析一覧ページ"}
    >
      <Analyzations/>
    </Page>
  )
}

export default  AnalyzationsPage;