import { styled } from '@mui/system';

export const Spacer = styled('div')<{ height: number; printHeight?: number }>`
  height: ${({ height }) => `${height}px`};
  width: 100%;
  @media print {
    height: ${({ height, printHeight }) =>
      printHeight ? `${printHeight}px` : `${height}px`};
  }
`;

export const HeaderSpacer = styled('div')`
  width: 100%;
`;
