import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';

type Props = {
  label: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
};

export const CheckBox = ({ label, checked, onChange }: Props) => {
  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <MuiCheckbox
            checked={checked}
            onChange={(_, value) => onChange?.(value)}
          />
        }
      />
    </>
  );
};

