import React, {useEffect } from 'react';
type Props = {
  title?: string;
  children: React.ReactNode;
}
const Page: React.FC<Props> = (props) => {
  const title = props.title ? `SleepWell ${props.title}` : 'SleepWell';
  useEffect(() => {
      document.title = title;
  }, [title]);

return (
    <div>
        {props.children}
    </div>);
};

export default Page;

