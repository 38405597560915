import { AnalyzationResult } from '@sleepwell_new_platform/common-types/src/analyzationResults';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Area, AreaChart, ResponsiveContainer, Legend, XAxisProps, ReferenceLine } from 'recharts';
import { styled } from '@mui/system';
import { getNearestHalfMinute } from '../../../utils/analyzations';

const xAxisProps: XAxisProps = {
  height: 50,
  dataKey: 'time',
};

type Props = {
  analyzationResultData: AnalyzationResult | null;
  age: number;
  measureStartDate: number;
  height?: number | string;
  lang: 'ja' | 'en';
}
const hourTickFormatter = (time: string, index: number) => {
  // 時刻を 'HH:mm:ss' 形式に整形
  const timeParts = time.split(':');
  const hour = timeParts[0];
  const minute = timeParts[1];
  if(index === 0) {
    return `${hour}:${minute}`;
  }
  if(index === 1) {
    return `${hour}:${minute}`;
  }
  return '';
};

export const DeltaPowerTimeChart = (props: Props) => {
  const age = props.age;
  const height = props.height
  const measureStartDate = props.measureStartDate;
  const lang = props.lang;
  const maxValue = useMemo(() => {
    if(age < 19){
      return 20000;
    }
    return 5000;
  },[age])
  const { analyzationResultData } = props;
  // 開始時間をから 0.5ずつ追加していく
  const chartData = useMemo(() => {
    const nearestHour = getNearestHalfMinute(dayjs(measureStartDate).format('YYYY/MM/DD HH:mm:ss'));
    const formattedNearestHour = nearestHour.format('HH:mm:ss');
    const startTime = formattedNearestHour;
    const deltaPowerList = analyzationResultData?.deltaPowerList;
    const sleepStageList = analyzationResultData?.sleepStageList;
    // startTimeを基準に30秒ずつ追加していく24時を超えたら0時に戻る
    // Array<{time: string, nonreme: number}> noremにdeletaPowerを入れる
    const chartData: {
      time: string;
      nonrem?: number;
      rem?: number;
    }[] = [];
    let time = startTime;
    if (!deltaPowerList || !sleepStageList) return [];
    deltaPowerList.forEach((deltaPower, index) => {
      const max = age < 19 ? 20000 : 5000;
      const [hours, minutes, seconds] = time.split(':').map(Number);
      let newSeconds = seconds;
      if(index !== 0){
        newSeconds = newSeconds + 30;
      }
      const carryMinutes = Math.floor(newSeconds / 60);
      const adjustedSeconds = newSeconds % 60;
      const newMinutes = minutes + carryMinutes;
      const carryHours = Math.floor(newMinutes / 60);
      const adjustedMinutes = newMinutes % 60;
      const newHours = hours + carryHours;
      const adjustedHours = newHours % 24;
      time = `${String(adjustedHours).padStart(2, '0')}:${String(adjustedMinutes).padStart(2, '0')}:${String(adjustedSeconds).padStart(2, '0')}`;
      const sleepStage = sleepStageList[index];
      if(sleepStage === 'W' || sleepStage === 'M'){
        chartData.push({
          time,
        });
      } else {
        const type = sleepStage === 'R' ? 'rem' : 'nonrem';
        chartData.push({
          time,
          [type]: Number(deltaPower),
        });
      }
    });
    return chartData;
  }, [analyzationResultData]);
  const ticks = useMemo(() => {
    if(age < 19){
      return [0, 5000, 10000, 15000,  20000];
    }
    return [0, 1000, 2000, 3000, 4000, 5000];
  },[age])

  const referenceLines = useMemo(() => {
    if(chartData.length === 0) return [];
    const startYYYYMMDD = dayjs(measureStartDate).format('YYYY/MM/DD');
    const startTime = dayjs(`${startYYYYMMDD} ${chartData[0]?.time}`);
    const startHour = Number(chartData[0]?.time.split(':')[0]);
    // endTimeが0時を超えている場合1日足す　そうではない場合はそのまま
    const endHour = Number(chartData[chartData.length - 1].time.split(':')[0]);
    // endTimeが0時を超えている場合1日足す　そうではない場合はそのまま
    const endYYYYMMDD = (startHour <= endHour && endHour < 24) ? startYYYYMMDD : dayjs(startYYYYMMDD).add(1, 'day').format('YYYY/MM/DD');
    const endTime = dayjs(`${endYYYYMMDD} ${chartData[chartData.length - 1].time}`);
    const referenceLines: JSX.Element[] = [];
    const isWithinTimeRange = (time: Dayjs): boolean => {
      const diffToStart = time.diff(startTime, 'minute');
      const diffToEnd = endTime.diff(time, 'minute');
      return diffToStart >= 20 && diffToEnd >= 20;
    };

    for (let i = 0; i < 24; i++) {
      const mm = dayjs(measureStartDate).format('mm');
      const timeStr = `${String(i).padStart(2, '0')}:${mm}:00`;
      const timeHour = Number(timeStr.split(':')[0]);
      const currentTimeYYYYMMDD = (startHour <= timeHour && timeHour < 24) ? startYYYYMMDD : dayjs(startYYYYMMDD).add(1, 'day').format('YYYY/MM/DD');
      const time = dayjs(`${currentTimeYYYYMMDD} ${timeStr}`);
      // startTimeやendTimeとの差が20分未満の場合はスキップ
      if (!isWithinTimeRange(time)) continue;

      const stroke =  'gray';
      const label = `${String(i).padStart(2, '0')}:${mm}`;
      referenceLines.push(
        <ReferenceLine x={timeStr} stroke={stroke} label={{ value: label, position: 'bottom', fill: stroke, fontSize: 14, dy: 3 }} />
      );
    }
    return referenceLines;
  }, [chartData, measureStartDate]);

  return (
    <Container>{analyzationResultData && 
    <ResponsiveContainer width="100%" height={props.height ?? 400}>
      <AreaChart width={400} height={height ? Number(height) : 300} data={chartData} margin={{left: 100, right: 20, top: 10}}> 
      <CartesianGrid strokeDasharray="1 1" />
        <XAxis {...xAxisProps} tickFormatter={(time, index) => hourTickFormatter(time, index)} interval={chartData.length -2 }  tick={{ fontSize: 14 }}/>
        <YAxis 
          domain={[0,  (dataMax: number) => Math.min(dataMax, maxValue)]} 
          ticks={ticks}
          interval={0}
          tick={{ fontSize: 14 }}
          label={{ value: lang === 'ja' ?'パワー値(μV2/エポック)' : 'power (μV²/epoch)', position: 'insideLeft', angle: -90, offset: -10, fontSize: 13 }}/>
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Area type="linear" dataKey="nonrem" name={lang === "ja" ? 'ノンレム睡眠' :'Non REM'} stroke="#529BC5" strokeWidth={2} fill="#529BC550" />
        <Area type="linear" dataKey="rem" name={lang === "ja" ? 'レム睡眠': 'REM Sleep'} stroke="#F17160" strokeWidth={2} fill="#F1545450" />
        <Tooltip />
        <Legend layout="horizontal" verticalAlign="top" align="right" />
        {referenceLines}
        <ReferenceLine x="21:00:00" stroke="red" label={{ value: '21:00', position: 'bottom' , fill: 'red'}} />
      </AreaChart>
    </ResponsiveContainer>}
    </Container>
  );
};


const Container = styled('div')`
  @media print {
    transform: translateX(-5%) translateY(-5%) scale(0.9);
  }
`
/*

const ChartWrap = styled('div')({
  borderRadius: "8px",
  border: "1px solid #E2E8F0",
  background: "#FFF",
  padding: "24px",
  paddingTop:'8px',
  paddingBottom:'8px',
  '@media print': {
    transform: 'scale(0.8) translateY(-5%)',
    border: 'none'
  }
});*/