import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  QuestionsDailyAnswersForSleep,
  QuestionsDailyAnswersForFeeling,
  QuestionsDailyAnswersForCondition,
  QuestionsDailyAnswersForActivityAndStress,
  QuestionsDailyAnswersForDailyLife,
  QuestionsDailyAnswersForConsultingToDoctor,
  DailyAnswersTypeEnum,
} from '../API';

export const initialState: QuestionsState = {
  questionsForSleep: null,
  questionsForFeeling: null,
  questionsForCondition: null,
  questionsForActivityAndStress: null,
  questionsForDailyLife: null,
  questionsForConsultingToDoctor: null,
};

export type QuestionsState = {
  questionsForSleep?: QuestionsDailyAnswersForSleep | null;
  questionsForFeeling?: QuestionsDailyAnswersForFeeling | null;
  questionsForCondition?: QuestionsDailyAnswersForCondition | null;
  questionsForActivityAndStress?: QuestionsDailyAnswersForActivityAndStress | null;
  questionsForDailyLife?: QuestionsDailyAnswersForDailyLife | null;
  questionsForConsultingToDoctor?: QuestionsDailyAnswersForConsultingToDoctor | null;
};

const questionsSlice = createSlice({
  name: 'questions',
  initialState: initialState,
  reducers: {
    // setQuestions: (_state, action: PayloadAction<QuestionsState>) => {
    //   return action.payload
    // },
    setQuestions: (_state, action: PayloadAction<QuestionsState>) => {
      return action.payload;
    },
  },
});

export const { setQuestions } = questionsSlice.actions;
export const questionsReducer = questionsSlice.reducer;
export const enum2KeyString = (type: DailyAnswersTypeEnum) => {
  switch (type) {
    case DailyAnswersTypeEnum.ANSWERSFORSLEEP:
      return 'questionsDailyAnswersForSleep';
    case DailyAnswersTypeEnum.ANSWERSFORFEELING:
      return 'questionsDailyAnswersForFeeling';
    case DailyAnswersTypeEnum.ANSWERSFORCONDITION:
      return 'questionsDailyAnswersForCondition';
    case DailyAnswersTypeEnum.ANSWERSFORACTIVITYANDSTRESS:
      return 'questionsDailyAnswersForActivityAndStress';
    case DailyAnswersTypeEnum.ANSWERSFORDAILYLIFE:
      return 'questionsDailyAnswersForDailyLife';
    case DailyAnswersTypeEnum.ANSWERSFORCONSULTINGTODOCTOR:
      return 'questionsDailyAnswersForConsultingToDoctor';
  }
};
