import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: boolean = false;

const hasNotReadNotificationSlice = createSlice({
  name: 'hasNotReadNotificationSlice',
  initialState: initialState,
  reducers: {
    setHasNotReadNotification: (_state, action: PayloadAction<boolean>) => action.payload,
    resetHasNotReadNotification: () => false,
  },
});

export const { setHasNotReadNotification, resetHasNotReadNotification } = hasNotReadNotificationSlice.actions;

export const hasNotReadNotificationSliceReducer = hasNotReadNotificationSlice.reducer;
